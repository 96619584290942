import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  getSustainabilityTrend,
  getThisYearTemperatureRise,
  localizeFloat,
  selectors,
  t,
} from '@formue-app/core';
import { BaseSection } from '../../../common/BaseSection';
import { H2, Paragraph } from '../../../texts';
import { backgroundWhite, textMain } from '../../../../constants/colors';
import {
  SPACING_32,
  SPACING_12,
  SPACING_8,
  SPACING_48,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../../../constants/spacing';
import { Button } from '../../../buttons';
import { Modal } from '../../../common/Modal';
import { TemperatureRiseBadge } from './TemperatureRiseBadge';
import { TemperatureRiseModalContent } from './TemperatureRiseModalContent';
import { TrendBadge } from './TrendBadge';

const i18nPrefix = 'app:sustainability:temperatureRiseScreen';

const {
  entities: {
    portfolioLenses: {
      temperatureRiseDataSelector,
      allPortfolioLensesSelector,
    },
  },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_32};
  display: flex;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${SPACING_12};
  padding-bottom: ${SPACING_32};
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
`;

export const TemperatureRise = (props) => {
  const {
    backgroundColor = backgroundWhite,
    textColor = textMain,
    badgeBackgroundColor,
    badgeTextColor,
    positiveBackgroundColor,
    positiveArrowColor,
    negativeBackgroundColor,
    negativeArrowColor,
    showModalButton = true,
  } = props;
  const temperatureRiseData = useSelector(temperatureRiseDataSelector);
  const allPortfolioLenses = useSelector(allPortfolioLensesSelector);

  const thisYearTemperatureRise =
    getThisYearTemperatureRise(allPortfolioLenses);
  const trend = getSustainabilityTrend(
    'temperature.impliedRise',
    'THISYEAR',
    false
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);

  if (!temperatureRiseData) return null;

  return (
    <StyledBaseSection {...props} style={{ backgroundColor }}>
      <Wrapper>
        <TemperatureRiseBadge
          temperature={temperatureRiseData.impliedRise.toFixed(1)}
          backgroundColor={badgeBackgroundColor}
          textColor={badgeTextColor}
        />
      </Wrapper>
      <H2 style={{ color: textColor }}>{t(`${i18nPrefix}:title`)}</H2>
      <Paragraph style={{ padding: `${SPACING_8} 0`, color: textColor }}>
        {t(`${i18nPrefix}:entryText`, {
          value: localizeFloat(temperatureRiseData.impliedRise.toFixed(1)),
        })}
      </Paragraph>
      <TrendBadge
        title={[
          <strong style={{ fontWeight: 500 }}>
            {localizeFloat(trend, 2)}°C
          </strong>,
          ` ${t('app:filters:period:THISYEAR').toLowerCase()}`,
        ]}
        direction={thisYearTemperatureRise > 0 ? 'Up' : 'Down'}
        trendSelector="temperature.impliedRise"
        positiveTrend={trend < 0}
        textColor={textColor}
        positiveBackgroundColor={positiveBackgroundColor}
        positiveArrowColor={positiveArrowColor}
        negativeBackgroundColor={negativeBackgroundColor}
        negativeArrowColor={negativeArrowColor}
      />
      {showModalButton && (
        <>
          <ButtonWrapper>
            <Button
              onClick={() => setModalIsOpen(true)}
              style={{ marginTop: 32 }}
            >
              {t('app:globals:readMore')}
            </Button>
          </ButtonWrapper>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel={t('app:sustainability:temperatureRiseScreen:title')}
            labelComponent={H2}
            padding={SPACING_48}
          >
            <TemperatureRiseModalContent />
          </Modal>
        </>
      )}
    </StyledBaseSection>
  );
};
