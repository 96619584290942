import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { config, t } from '@formue-app/core';

import { GridElement } from '../layout';

import { H1XL, Paragraph } from '../texts';
import { Button } from '../buttons';
import { CollapsibleListItem } from './CollapsibleListItem';
import { SpriteIcon } from './SpriteIcon';

import { SPACING_24, SPACING_48, SPACING_88 } from '../../constants/spacing';
import { textMain } from '../../constants/colors';

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  max-width: 700px;

  & + .details > div {
    padding-top: 0;

    pre {
      text-align: left;
      white-space: pre-wrap;
      margin: 0;
    }
  }
`;

const StyledGridElement = styled(GridElement)`
  margin-top: 20%;
`;

const Content = styled.div`
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${SPACING_24};
  margin-bottom: ${SPACING_88};
`;

export const ErrorFallback = ({
  error,
  resetErrorBoundary = () => {},
  showBackButton = true,
}) => {
  let history = useHistory();

  const supportMail = t('app:login:supportMail', {
    // TODO:country - Use international support mail
    context: config.countryCode,
  });

  const supportClickHandler = () => {
    window.open(`mailto:${supportMail}`, '_self');
  };

  return (
    <StyledGridElement columns={12} role="alert">
      <Content>
        <H1XL style={{ marginBottom: SPACING_48 }}>
          {t('app:globals:error:title')}
        </H1XL>
        <ButtonWrapper>
          {showBackButton ? (
            <Button
              onClick={() => {
                resetErrorBoundary();
                history.goBack();
              }}
            >
              {t('app:globals:error:backButton')}
            </Button>
          ) : null}
          <Button
            style={{ background: 'none', color: textMain }}
            onClick={supportClickHandler}
          >
            {t('app:globals:error:contactButton')}
          </Button>
        </ButtonWrapper>
        <StyledCollapsibleListItem
          details={<pre style={{ color: 'red' }}>{error.message}</pre>}
          detailsStyle={{
            paddingInline: SPACING_48,
            marginTop: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            paddingTop: 0,
          }}
          expandedStyle={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
          startExpanded={false}
          style={{ paddingBlock: SPACING_24, paddingInline: SPACING_48 }}
        >
          <SpriteIcon id="Info" navigational={true} />
          <Paragraph
            style={{ margin: 0, marginRight: 'auto', fontWeight: 600 }}
          >
            {t('app:globals:error:accordionTitle')}
          </Paragraph>
        </StyledCollapsibleListItem>
      </Content>
    </StyledGridElement>
  );
};
