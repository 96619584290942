import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { t, selectors, formatMoney, useResource } from '@formue-app/core';

import { H3, H6 } from '../../texts';
import { BaseSection } from '../../common/BaseSection';

import { accent, backgroundWhite } from '../../../constants/colors';
import { mobileCondition } from '../../../constants/media';
import { H2SizeMobile } from '../../../constants/text';

const StyledWrapper = styled(BaseSection)`
  background-color: transparent;
  padding: 0;
`;

const StyledAmount = styled(H3)`
  font-size: 26px;

  @media ${mobileCondition} {
    font-size: ${H2SizeMobile};
  }
`;

const {
  entities: {
    wealthBalance: { totalWealthBalanceSelector },
  },
} = selectors;

export const TotalWealth = (props) => {
  const { columns = 6, textColor = backgroundWhite, ...rest } = props;
  const totalWealth = useSelector(totalWealthBalanceSelector);
  useResource(['BALANCE_ENTRIES/INDEX']);

  // if there is no data, we don't want to render anything
  if (totalWealth === 0) {
    return null;
  }

  return (
    <StyledWrapper {...rest} columns={columns}>
      <H6 style={{ color: accent.velvet2, marginBottom: 5 }}>
        {t('app:wealthBalance:totalWealth').toUpperCase()}
      </H6>
      <StyledAmount style={{ color: textColor }}>
        {formatMoney(totalWealth, 'kr')}
      </StyledAmount>
    </StyledWrapper>
  );
};
