import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { isEqual, debounce } from 'lodash';
import { useSelector } from 'react-redux';

import {
  selectors,
  t,
  percentage,
  marketValues,
  transformActiveFilters,
  portfolios,
  formatBigMoney,
  periodToDate,
  toLocaleDateString,
  useResource,
} from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { SPACING_16, SPACING_24, SPACING_8 } from '../../../constants/spacing';
import { printCondition } from '../../../constants/media';

import { H2, H5, ParagraphSmall } from '../../texts';
import { BaseSection } from '../../common/BaseSection';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { DotIcon } from '../../common/DotIcon';
import { PortfolioReportingChart } from '../charts/PortfolioReportingChart';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};

  @media ${printCondition} {
    gap: ${SPACING_8};
    margin-right: ${SPACING_24} !important;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: ${SPACING_16};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: flex-end;
  gap: 4px;
  margin-left: auto;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_8};
`;

const {
  entities: {
    periodicIndices: { allPeriodicIndicesSelector },
  },
  ui: {
    portfolio: { activeFiltersSelector },
  },
} = selectors;

export const PeriodicGain = (props) => {
  const { chartHeight } = props;
  const loading = useResource(['PERIODIC_INDICES/INDEX']);

  const periodicIndices = useSelector(allPeriodicIndicesSelector);
  const activeFilters = useSelector(activeFiltersSelector);
  const debounceTime = useSelector((state) => state.ui.portfolio.debounceTime);

  const periodicIndicesPresent = periodicIndices.some(
    (item) => item.index.length
  );

  const prevActiveFilters = useRef(null);

  const [portfolioTotal, setPortfolioTotal] = useState(null);
  const [marketValueData, setMarketValueData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);

  const colorSet = [
    '#99BBDE',
    accent.ocean170,
    accent.ocean490,
    accent.sand1,
    accent.sand1,
  ];

  const indexColorSet = [accent.ocean2, accent.ocean3, accent.ocean4];

  const fetchData = useRef(
    debounce(async (filters) => {
      try {
        setLocalLoading(true);
        const transformedFilters = transformActiveFilters(filters);
        const args = {
          ...transformedFilters,
          startDate: new Date('12.31.12').toISOString().split('T')[0],
          endDate:
            transformedFilters.endDate ||
            new Date().toISOString().split('T')[0],
        };
        const marketValuesData = await marketValues.get(args);
        const [portfoliosData] = await portfolios.get(args);

        const formatedData = marketValuesData.data.map((item) => ({
          id: item.id,
          type: item.type,
          ...item.attributes,
        }));

        const total = portfoliosData.data?.find(
          (item) => item.attributes.dataType === 'total'
        )?.attributes;

        setStartDate(periodToDate(formatedData[0].p));
        setPortfolioTotal(total);
        setMarketValueData(formatedData);
      } catch (error) {
        console.error('Failed to fetch market values:', error);
      } finally {
        setLocalLoading(false);
      }
    }, debounceTime)
  ).current;

  useEffect(() => {
    fetchData(activeFilters);

    return () => {
      fetchData.cancel();
    };
  }, []);

  useEffect(() => {
    if (!isEqual(activeFilters, prevActiveFilters.current)) {
      prevActiveFilters.current = activeFilters;
      fetchData(activeFilters);
    }
  }, [activeFilters]);

  return (
    <StyledBaseSection columns={6}>
      <ContentWrapper>
        <TextWrapper>
          <ParagraphSmall>
            {t('app:portfolio:periodicReporting:benchmark', {
              period: toLocaleDateString(startDate),
            })}
          </ParagraphSmall>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              gap: SPACING_8,
            }}
          >
            <H2>{formatBigMoney(portfolioTotal?.gain, true)}</H2>
            <H5 style={{ paddingBottom: 6 }}>
              ({percentage(portfolioTotal?.twr * 100)})
            </H5>
          </div>
        </TextWrapper>
        {!localLoading && !loading ? (
          <LabelsWrapper>
            <Label>
              <DotIcon color={colorSet[2]} />
              <ParagraphSmall>
                {t('app:portfolio:periodicReporting:portfolioLabel')}
              </ParagraphSmall>
            </Label>
            {periodicIndicesPresent
              ? periodicIndices.map((item, index) => (
                  <Label>
                    <DotIcon
                      color={indexColorSet[index % indexColorSet.length]}
                    />
                    <ParagraphSmall>
                      {t(`app:portfolio:periodicReporting:${item.id}`)}
                    </ParagraphSmall>
                  </Label>
                ))
              : null}
          </LabelsWrapper>
        ) : null}
      </ContentWrapper>

      {localLoading || loading ? (
        <CenteredActivityIndicator />
      ) : (
        <PortfolioReportingChart
          indexData={periodicIndices}
          data={marketValueData}
          colorSet={colorSet}
          indexColorSet={indexColorSet}
          height={chartHeight}
        />
      )}
    </StyledBaseSection>
  );
};
