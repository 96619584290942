import React, { useState } from 'react';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import { generateFullName, selectors, t, config } from '@formue-app/core';
import { actions as authActions } from '../../store/auth';

import { H4 } from '../texts';

import { SPACING_24, SPACING_8 } from '../../constants/spacing';
import { NotMobileWrapper, printCondition } from '../../constants/media';
import { GridElement } from '../layout/GridElement';

import { ProfileIcon } from '../common/ProfileIcon';
import { SubMenu } from './SubMenu';
import { accent } from '../../constants/colors';
import { SpriteIcon } from './SpriteIcon';

const OuterWrapper = styled.div`
  padding-top: ${SPACING_24};
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 5px;
  gap: ${SPACING_8};
  cursor: pointer;

  &:hover {
    h4 {
      text-decoration: underline;
    }

    .profile-icon {
      background: ${accent.ocean330};
    }
  }
`;

const StyledGridElement = styled(GridElement)`
  @media ${printCondition} {
    display: none;
  }
`;

const { currentUserSelector } = selectors.ui.user;

export const ProfileBar = (props) => {
  const dispatch = useDispatch();

  const user = useSelector(currentUserSelector);

  const [showSubMenu, setShowSubMenu] = useState(false);

  if (!user) {
    return <StyledGridElement {...props} style={{ height: 52 }} />;
  }

  const name = generateFullName(user);

  const onLogOut = () => {
    dispatch(
      authActions.logout({
        url: config.domain,
      })
    );
  };

  const menuItems = [
    {
      onClick: () => dispatch(push('/profile')),
      icon: (
        <SpriteIcon id="Individual" navigational={true} size={SPACING_24} />
      ),
      title: t('app:menu:profile'),
    },
    {
      onClick: onLogOut,
      icon: <SpriteIcon id="Logout" navigational={true} size={SPACING_24} />,
      title: t('app:menu:logOut'),
    },
  ];

  return (
    <NotMobileWrapper>
      <StyledGridElement {...props}>
        <OuterWrapper>
          <ProfileWrapper onClick={() => setShowSubMenu(!showSubMenu)}>
            <H4>{name}</H4>
            <ProfileIcon name={name} size={32} color={accent.ocean270} />
          </ProfileWrapper>
          <SubMenu
            items={menuItems}
            showSubMenu={showSubMenu}
            style={{ right: 0, marginTop: 6 }}
          />
        </OuterWrapper>
      </StyledGridElement>
    </NotMobileWrapper>
  );
};
