import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';

import { t, selectors } from '@formue-app/core';

import { StyledCell } from '../lists/TableList';
import { StyledRow, TableList } from '../lists/TableList';
import { BaseSection } from '../common/BaseSection';
import { actions as authActions } from '../../store/auth';

import ArrowRight from '../../assets/icons/arrows/arrow-right-blue.svg';
import { SPACING_24 } from '../../constants/spacing';
import { easeInOut, shortAnimationTime } from '../../constants/animation';

const ConcealedText = styled(StyledCell)`
  filter: blur(2.5px);
  transition: 200ms filter ease-in;

  &:hover {
    filter: none;
  }
`;

const LightStyledRow = styled(StyledRow)`
  & > *:last-child {
    background-image: url(${ArrowRight});
    background-repeat: no-repeat;
    background-position: calc(100% - ${SPACING_24}) center;
    transition: background-position ${easeInOut} ${shortAnimationTime};
  }
  &:hover {
    & > *:last-child {
      background-position: calc(100% - ${SPACING_24} + 5px) center;
    }
  }
`;

const { currentUserSelector } = selectors.ui.user;

export const ClientList = (props) => {
  const { items: clients } = props;
  const dispatch = useDispatch();

  const user = useSelector(currentUserSelector);

  if (!clients?.length) {
    return (
      <BaseSection columns={12}>
        <p>{t('app:impersonation:noContentMessage')}</p>
      </BaseSection>
    );
  }

  const impersonateClientUser = (ssid) => {
    if (ssid !== user.ssid) {
      dispatch(authActions.resetImpersonation());
      dispatch(authActions.impersonateUser(ssid));
    }
    dispatch(push('/advisor'));
  };

  // Define the table columns
  const columns = [
    {
      key: 'name',
      label: 'Name',
      primary: true,
      width: '40%',
      component: ConcealedText,
    },
    { key: 'ssid', label: 'Source system ID', width: '60%' },
  ];

  return (
    <TableList
      sortable={false}
      rowComponent={LightStyledRow}
      columns={columns}
      rows={clients}
      clickCallback={({ ssid }) => impersonateClientUser(ssid)}
      keyExtractor={({ ssid }) => ssid}
    />
  );
};
