import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { t } from '@formue-app/core';

import { accent, textLightest } from '../../../../constants/colors';
import {
  SPACING_48,
  SPACING_32,
  SPACING_16,
  SPACING_24,
  BORDER_RADIUS_LARGE,
} from '../../../../constants/spacing';
import { H1, H3, Paragraph } from '../../../texts';
import { PortfolioConstructionList } from './PortfolioConstructionList';
import { GridElement } from '../../../layout';

import { ReactComponent as HexagonLeft } from '../../../../assets/portfolio/hexagon-left.svg';
import { ReactComponent as HexagonRight } from '../../../../assets/portfolio/hexagon-right.svg';
import {
  desktopCondition,
  DesktopWrapper,
  notDesktopCondition,
  NotDesktopWrapper,
} from '../../../../constants/media';
import {
  H1LineHeightMobile,
  H1SizeMobile,
  H1LineHeight,
  H1Size,
} from '../../../../constants/text';

const ContentWrapper = styled(GridElement)`
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${notDesktopCondition} {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  padding: ${SPACING_24} ${SPACING_48};
  width: 100%;

  @media ${notDesktopCondition} {
    flex-direction: column;
    padding: ${SPACING_48} ${SPACING_16};
  }
`;

const FundsWrapper = styled(Content)`
  background: ${accent.velvet4};
  border-top-left-radius: ${BORDER_RADIUS_LARGE};
  border-top-right-radius: ${BORDER_RADIUS_LARGE};
  overflow: hidden;
`;

const CompaniesWrapper = styled(Content)`
  background: ${accent.velvet270};
  border-bottom-left-radius: ${BORDER_RADIUS_LARGE};
  border-bottom-right-radius: ${BORDER_RADIUS_LARGE};
  overflow: hidden;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${desktopCondition} {
    width: 50%;
  }
`;

const HeaxagonWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 0 ${SPACING_32};

  svg {
    width: 205px;
    height: auto;
  }
`;

const HexagonLeftWrapper = styled(HeaxagonWrapper)`
  @media ${notDesktopCondition} {
    padding: 0;
    margin-right: -70px;
    margin-left: ${SPACING_16};
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -6px;
    translate: -50% -50%;
    color: ${textLightest};

    @media ${notDesktopCondition} {
      font-size: ${H1Size};
      line-height: ${H1LineHeight};
    }
  }
`;

const HexagonRightWrapper = styled(HeaxagonWrapper)`
  @media ${notDesktopCondition} {
    padding: 0;
    margin-left: -70px;
    margin-right: ${SPACING_16};
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    color: ${accent.velvet490};

    @media ${notDesktopCondition} {
      font-size: ${H1SizeMobile};
      line-height: ${H1LineHeightMobile};
    }
  }
`;

const StyledPortfolioConstructionList = styled(PortfolioConstructionList)`
  padding: 0;

  @media ${desktopCondition} {
    width: 50%;
  }
`;

const StyledLink = styled(Paragraph)`
  font-weight: 500;
  color: ${accent.velvet3};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const PortfolioConstructionHeader = (props) => {
  const {
    distinctFunds,
    distinctCompanies,
    funds,
    securities,
    onClickFundInFundModal,
    ...rest
  } = props;

  return (
    <ContentWrapper {...rest}>
      <FundsWrapper as="section">
        <TextWrapper>
          <div>
            <H3 style={{ color: accent.velvet2, marginBottom: SPACING_16 }}>
              <Trans
                i18nKey="app:portfolioConstruction:fundsIntroduction"
                values={{ fundsCount: distinctFunds }}
                components={{
                  strong: (
                    <H3
                      as="span"
                      style={{ color: accent.velvet3, display: 'inline' }}
                    />
                  ),
                }}
              />
            </H3>
            <StyledLink onClick={() => onClickFundInFundModal()}>
              {t('app:portfolioConstruction:fundsQuestion')}
            </StyledLink>
          </div>
          <NotDesktopWrapper>
            {distinctFunds > 0 && (
              <HexagonLeftWrapper>
                <HexagonLeft />
                <H1 as="span">{distinctFunds}</H1>
              </HexagonLeftWrapper>
            )}
          </NotDesktopWrapper>
        </TextWrapper>

        <DesktopWrapper>
          {distinctFunds > 0 && (
            <HexagonLeftWrapper>
              <HexagonLeft />
              <H1 as="span">{distinctFunds}</H1>
            </HexagonLeftWrapper>
          )}
        </DesktopWrapper>

        {!!funds.length && (
          <StyledPortfolioConstructionList
            data={funds.slice(0, 3)}
            title={t('app:portfolioConstruction:topFunds', { fundsCount: 3 })}
            titleColor={accent.velvet2}
            textColor={textLightest}
            numberBackgroundColor={accent.velvet3}
            numberColor={textLightest}
            sectionBackground={accent.velvet4}
            linkColor={accent.velvet3}
            linkText={t('app:portfolioConstruction:seeAllFunds')}
            to="/list/funds-list"
          />
        )}
      </FundsWrapper>
      <CompaniesWrapper as="section">
        <TextWrapper>
          <NotDesktopWrapper>
            <HexagonRightWrapper>
              <HexagonRight />
              <H3 as="span" style={{ fontSize: 30, color: accent.velvet490 }}>
                {distinctCompanies}
              </H3>
            </HexagonRightWrapper>
          </NotDesktopWrapper>
          <H3
            style={{
              color: accent.velvet490,
            }}
          >
            <Trans
              i18nKey="app:portfolioConstruction:companiesIntroduction"
              values={{ companiesCount: distinctCompanies }}
              components={{
                strong: (
                  <H3
                    as="span"
                    style={{ color: '#810CBB', display: 'inline' }}
                  />
                ),
              }}
            />
          </H3>
        </TextWrapper>

        <DesktopWrapper>
          <HexagonRightWrapper>
            <HexagonRight />
            <H3 as="span" style={{ fontSize: 30, color: accent.velvet490 }}>
              {distinctCompanies}
            </H3>
          </HexagonRightWrapper>
        </DesktopWrapper>

        {!!securities.length && (
          <StyledPortfolioConstructionList
            data={securities.slice(0, 3)}
            title={t('app:portfolioConstruction:topCompanies', {
              companiesCount: 3,
            })}
            titleColor={accent.velvet490}
            textColor={accent.velvet490}
            numberBackgroundColor={accent.velvet3}
            numberColor={textLightest}
            sectionBackground={accent.velvet270}
            linkColor="#810CBB"
            linkText={t('app:portfolioConstruction:seeTopCompanies', {
              companiesCount: 10,
            })}
            to="/list/companies-list"
          />
        )}
      </CompaniesWrapper>
    </ContentWrapper>
  );
};
