import React, { useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import {
  t,
  generateSpeedometerRiskChartColors,
  selectors,
  useResource,
  riskAndLockinValueToText,
} from '@formue-app/core';

import {
  BORDER_RADIUS_SMALL,
  SPACING_48,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import {
  mobileCondition,
  notMobileCondition,
  tabletCondition,
} from '../../../constants/media';

import { SpeedometerChart } from '../../graphs/SpeedometerChart';
import { SectionWithTitle } from '../../common/SectionWithTitle';
import { BaseSection } from '../../common/BaseSection';
import { ButtonSmall } from '../../buttons';
import { H3, H6, ParagraphSmall } from '../../texts';

import { AdvisorsComment } from './AdvisorsComment';
import { InvestmentProfileModal } from './InvestmentProfileModal';
import { RiskModalContent } from './RiskModalContent';
import { LockinModalContent } from './LockinModalContent';
import { SpriteIcon } from '../../common/SpriteIcon';

const StyledBaseSection = styled(BaseSection)`
  @media ${notMobileCondition} {
    padding: ${SPACING_48};
  }
`;

const StyledSpeedometerChart = styled(SpeedometerChart)`
  margin-inline: auto;

  @media ${mobileCondition} {
    margin-top: ${SPACING_24};
  }
`;

const Label = styled(H3)`
  margin-top: -22px;
  text-align: center;
`;

const CriteriaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${SPACING_24};
  margin-top: ${SPACING_48};
  gap: ${SPACING_16};

  @media ${mobileCondition} {
    margin-top: 0;
    flex-direction: column;
  }
`;

const CriteriaItem = styled.div`
  width: 100%;

  h6 {
    text-transform: uppercase;
    margin-bottom: 12px;

    @media ${mobileCondition} {
      margin-bottom: ${SPACING_8};
    }
  }
`;

const Criteria = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${accent.ocean408};
  border-radius: ${BORDER_RADIUS_SMALL};
  padding: ${SPACING_8};
  gap: ${SPACING_8};
`;

const {
  entities: {
    investmentProfile: { investmentStrategyRiskAndLockInSelector },
  },
} = selectors;

export const RiskAndLockIn = (props) => {
  useResource(['INVESTMENT_PROFILE/INDEX']);

  const [riskModalOpen, setRiskModalOpen] = useState(false);
  const [lockInModalOpen, setLockInModalOpen] = useState(false);
  const isTablet = useMediaQuery({ query: tabletCondition });

  const investmentStrategyRiskAndLockIn = useSelector(
    investmentStrategyRiskAndLockInSelector
  );

  if (!investmentStrategyRiskAndLockIn) {
    return null;
  }

  return (
    <>
      <SectionWithTitle
        title={t('app:wealthPlan:investmentProfile:riskAndLockin:riskTitle')}
        columns={isTablet ? 12 : 6}
        {...props}
      >
        <StyledBaseSection>
          <StyledSpeedometerChart
            value={investmentStrategyRiskAndLockIn.risk}
            pieCount={3}
            padAngle={3}
            cornerRadius={4}
            startAngle={-90}
            endAngle={90}
            width={200}
            height={100}
            arrowPosition="top"
            colorScale={generateSpeedometerRiskChartColors(
              investmentStrategyRiskAndLockIn.risk
            )}
          />
          <Label>
            {riskAndLockinValueToText(investmentStrategyRiskAndLockIn.risk)}
          </Label>
          <CriteriaWrapper>
            <CriteriaItem>
              <H6>
                {t('app:wealthPlan:investmentProfile:riskAndLockin:basedOn')}
              </H6>
              <Criteria>
                <SpriteIcon id="Capital" size={28} />
                <ParagraphSmall>
                  {t(
                    'app:wealthPlan:investmentProfile:riskAndLockin:riskAbility'
                  )}
                </ParagraphSmall>
              </Criteria>
            </CriteriaItem>
            <CriteriaItem>
              <H6>
                {t(
                  'app:wealthPlan:investmentProfile:riskAndLockin:combinedWith'
                )}
              </H6>
              <Criteria>
                <SpriteIcon id="Risk Tolerance" size={28} />
                <ParagraphSmall>
                  {t(
                    'app:wealthPlan:investmentProfile:riskAndLockin:riskWillingnes'
                  )}
                </ParagraphSmall>
              </Criteria>
            </CriteriaItem>
          </CriteriaWrapper>

          <ButtonSmall
            buttonstyle="tonal"
            onClick={() => setRiskModalOpen(true)}
          >
            {t('app:wealthPlan:investmentProfile:riskAndLockin:readMore')}
          </ButtonSmall>
        </StyledBaseSection>
      </SectionWithTitle>
      <SectionWithTitle
        title={t('app:wealthPlan:investmentProfile:riskAndLockin:lockInTitle')}
        columns={isTablet ? 12 : 6}
        {...props}
      >
        <StyledBaseSection>
          <StyledSpeedometerChart
            value={investmentStrategyRiskAndLockIn.lockIn}
            pieCount={3}
            padAngle={3}
            cornerRadius={4}
            startAngle={-90}
            endAngle={90}
            width={200}
            height={100}
            arrowPosition="top"
            colorScale={generateSpeedometerRiskChartColors(
              investmentStrategyRiskAndLockIn.lockIn
            )}
          />
          <Label>
            {riskAndLockinValueToText(investmentStrategyRiskAndLockIn.lockIn)}
          </Label>
          <CriteriaWrapper>
            <CriteriaItem>
              <H6>
                {t('app:wealthPlan:investmentProfile:riskAndLockin:basedOn')}
              </H6>
              <Criteria>
                <SpriteIcon id="Capital" size={28} />
                <ParagraphSmall>
                  {t(
                    'app:wealthPlan:investmentProfile:riskAndLockin:capitalNeeds'
                  )}
                </ParagraphSmall>
              </Criteria>
            </CriteriaItem>
          </CriteriaWrapper>

          <ButtonSmall
            buttonstyle="tonal"
            onClick={() => setLockInModalOpen(true)}
          >
            {t('app:wealthPlan:investmentProfile:riskAndLockin:readMore')}
          </ButtonSmall>
        </StyledBaseSection>
      </SectionWithTitle>
      <InvestmentProfileModal
        isOpen={riskModalOpen}
        onRequestClose={() => setRiskModalOpen(false)}
        title={t('app:wealthPlan:investmentProfile:riskAndLockin:risk:title')}
        subTitle={t(
          'app:wealthPlan:investmentProfile:riskAndLockin:risk:subTitle'
        )}
        value={investmentStrategyRiskAndLockIn.risk}
      >
        <RiskModalContent />
      </InvestmentProfileModal>
      <InvestmentProfileModal
        isOpen={lockInModalOpen}
        onRequestClose={() => setLockInModalOpen(false)}
        title={t('app:wealthPlan:investmentProfile:riskAndLockin:lockIn:title')}
        subTitle={t(
          'app:wealthPlan:investmentProfile:riskAndLockin:lockIn:subTitle'
        )}
        value={investmentStrategyRiskAndLockIn.lockIn}
      >
        <LockinModalContent />
      </InvestmentProfileModal>
      <AdvisorsComment
        title={t('app:wealthPlan:investmentProfile:advisorsComment')}
        subTitle={t('app:wealthPlan:investmentProfile:riskCapacity')}
        body={investmentStrategyRiskAndLockIn.riskAndLockInComment}
        columns={12}
        style={{ marginBottom: SPACING_24 }}
      />
    </>
  );
};
