import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  formatMoney,
  selectors,
  t,
  useErrorState,
  useResource,
} from '@formue-app/core';

import { SPACING_24, SPACING_64, SPACING_88 } from '../../../constants/spacing';

import { ReportTitle } from '../reportComponent/ReportTitle';
import { WaterfallChart } from '../../graphs/WaterfallChart';
import { FinancialValuesFilter } from '../slideComponents/wealthDevelopment/FinancialValuesFilter';
import { mobileCondition } from '../../../constants/media';
import { ReportErrorMessage } from '../reportComponent/ReportErrorMessage';

const {
  entities: {
    portfolios: { portfolioTotalSelector },
    financialValues: { allfinancialValuesItemsSelector },
  },
} = selectors;

const ContentWrapper = styled.div`
  max-width: 800px;
  height: 600px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: ${SPACING_88};

  @media ${mobileCondition} {
    height: 60vh;
    padding: 0 ${SPACING_24};
    margin-top: ${SPACING_64};
  }
`;

export const WealthDevelopmentSection = (props) => {
  const { backgroundColor, title, isVisible, ...rest } = props;

  const loading = useResource(['FINANCIALVALUES/INDEX']);
  const error = useErrorState(['FINANCIALVALUES/INDEX']);
  const portfolioTotal = useSelector(portfolioTotalSelector);
  const financialValues = useSelector(allfinancialValuesItemsSelector);

  const [years, setYears] = useState([]);
  const [activeYear, setActiveYear] = useState();

  useEffect(() => {
    setYears(financialValues.map((item) => item.p));
    setActiveYear(financialValues[financialValues.length - 1]?.p);
  }, [financialValues]);

  const prevYearData = financialValues.find(
    (item) => item.p === activeYear - 1
  );
  const currentYearData = financialValues.find((item) => item.p === activeYear);

  const mv = portfolioTotal?.mv || 0;

  if (error) {
    return <ReportErrorMessage {...rest} />;
  }

  return (
    <>
      <ReportTitle
        {...rest}
        title={title}
        values={{ mv: formatMoney(mv) }}
        style={{ marginBottom: 'auto' }}
      />

      <ContentWrapper>
        {!loading && (
          <WaterfallChart
            total={{
              label: t(
                'app:presentation:annualReview:portfolioDevelopment:endValue'
              ),
              value: isVisible ? currentYearData?.mv : 0,
              color: '#7DBEFF',
            }}
            data={[
              {
                label: t(
                  'app:presentation:annualReview:portfolioDevelopment:startValue'
                ),
                value: isVisible ? prevYearData?.mv : 0,
                color: '#99BBDE',
              },
              {
                label: t(
                  'app:presentation:annualReview:portfolioDevelopment:depositsWithdrawals'
                ),
                value: isVisible
                  ? currentYearData?.deposits + currentYearData?.withdrawals
                  : 0,
                color: '#CCDDEF',
              },
              {
                label: t(
                  'app:presentation:annualReview:portfolioDevelopment:return'
                ),
                value: isVisible ? currentYearData?.gain : 0,
                color: '#45A2FF',
              },
            ]}
          />
        )}
        <FinancialValuesFilter
          setActiveYear={setActiveYear}
          activeYear={activeYear}
          years={years}
        />
      </ContentWrapper>
    </>
  );
};
