import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { t, selectors } from '@formue-app/core';

import { BusinessAndOwnershipDetailsItem } from './BusinessAndOwnershipDetailsItem';
import { H2 } from '../../texts';
import { GridElement } from '../../layout';
import { SPACING_24 } from '../../../constants/spacing';

const {
  entities: {
    familyAndOwnership: { organizationSelector },
  },
} = selectors;

const StyledTitle = styled(H2)`
  margin-top: ${SPACING_24};
`;

export const BusinessAndOwnership = (props) => {
  const organizations = useSelector(organizationSelector);

  if (!organizations.length) {
    return null;
  }

  return (
    <>
      <GridElement columns={12}>
        <StyledTitle>
          {t('app:wealthPlan:familyAndOwnership:businessAndOwnership:title')}
        </StyledTitle>
      </GridElement>
      {organizations.map((item, index) => (
        <BusinessAndOwnershipDetailsItem
          item={item}
          key={`business-and-ownership-${index}`}
        />
      ))}
    </>
  );
};
