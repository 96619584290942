import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, t } from '@formue-app/core';

import { SPACING_48, SPACING_88 } from '../../../constants/spacing';

import { GoalsList } from '../slideComponents/goals/GoalsList';
import { ReportTitle } from '../reportComponent/ReportTitle';
import { ReportQuestion } from '../reportComponent/ReportQuestion';
import { mobileCondition } from '../../../constants/media';

const {
  entities: {
    goals: { allGoalsSelector },
  },
} = selectors;

const StyledReportTitle = styled(ReportTitle)`
  margin-bottom: ${SPACING_88};
`;

const StyledReportQuestion = styled(ReportQuestion)`
  margin-top: ${SPACING_88};

  @media ${mobileCondition} {
    margin-top: ${SPACING_48};
  }
`;

export const GoalsSection = (props) => {
  const { backgroundColor, title, isVisible, ...rest } = props;

  const goals = useSelector(allGoalsSelector);
  const inProgressGoals = goals.filter((item) => item.status !== 'Completed');

  return (
    <>
      <StyledReportTitle
        {...rest}
        title={title}
        values={{ goalsCount: inProgressGoals.length }}
      />
      <GoalsList
        style={{ marginTop: 0 }}
        isActiveSlide={isVisible}
        goals={inProgressGoals}
      />
      <StyledReportQuestion
        {...rest}
        questionText={t('app:report:annualReview:goals:question')}
      />
    </>
  );
};
