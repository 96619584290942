import React from 'react';
import styled from 'styled-components';

import { t, selectors } from '@formue-app/core';

import { SPACING_16, SPACING_24 } from '../../constants/spacing';
import { H3, H5, Paragraph } from '../texts';
import { GridElement } from '../layout';

import { InvestmentStrategyPreferences } from './InvestmentStrategyPreferences';
import { CollapsibleListItem } from '../common/CollapsibleListItem';
import { useSelector } from 'react-redux';

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  justify-content: space-between;
  & + .details > div {
    padding-top: 0 !important;
  }
`;

const Title = styled(H3)`
  padding-bottom: ${SPACING_16};
`;

const Text = styled(Paragraph)`
  max-width: 530px;
  white-space: pre-line;
`;

const {
  entities: {
    investmentStrategyPreferences: {
      investmentStrategyPreferencesByStrategyIdSelector,
    },
  },
} = selectors;

export const StrategyPreferences = (props) => {
  const { strategyId, ...rest } = props;

  const preferences = useSelector(
    investmentStrategyPreferencesByStrategyIdSelector(strategyId)
  );

  if (!preferences || preferences.length === 0) {
    return null;
  }

  return (
    <GridElement columns={12}>
      <StyledCollapsibleListItem
        details={
          <>
            <div style={{ paddingTop: SPACING_24 }}>
              <Title>
                {t('app:wealthPlan:investmentStrategy:preferences')}
              </Title>
              <Text>
                {t('app:wealthPlan:investmentStrategy:preferencesText')}
              </Text>
            </div>
            <InvestmentStrategyPreferences
              TitleComponent={H5}
              preferences={preferences}
              {...rest}
            />
          </>
        }
        detailsStyle={{
          paddingInline: SPACING_24,
        }}
        startExpanded={false}
        style={{ paddingBlock: SPACING_24, paddingInline: SPACING_24 }}
      >
        <H3>{t('app:wealthPlan:investmentStrategy:preferencesTitle')}</H3>
      </StyledCollapsibleListItem>
    </GridElement>
  );
};
