import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { constants, t } from '@formue-app/core';
import { actions as appPromotionActions } from '../../store/ui/appPromotion';

import { ParagraphSmall } from '../texts';

import { SPACING_12, SPACING_8 } from '../../constants/spacing';

import { BaseSection } from './BaseSection';
import { ButtonSmall } from '../buttons';
import { SpriteIconFunctional } from './SpriteIconFunctional';

import logo from '../../assets/images/logo-contact.png';

const StyledBaseSection = styled(BaseSection)`
  margin: ${SPACING_8};
`;

const AppLogo = styled.img`
  width: 48px;
  height: 48px;
  border-radius: ${SPACING_8};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 100%;
  gap: ${SPACING_12};
`;

export const AppPromotionBanner = (props) => {
  const { appStoreLink } = constants.app;
  const dispatch = useDispatch();

  const onInstallClick = () => {
    dispatch(appPromotionActions.dismissAppPromotion(new Date()));
    window.open(appStoreLink, '_blank');
  };

  return (
    <StyledBaseSection {...props}>
      <ContentWrapper>
        <div
          onClick={() => {
            dispatch(appPromotionActions.dismissAppPromotion(new Date()));
          }}
          style={{ padding: 4, display: 'flex' }}
        >
          <SpriteIconFunctional id="Close" size={12} />
        </div>
        <AppLogo src={logo} alt="App logo" />
        <ParagraphSmall>{t('app:home:appPromotion:body')}</ParagraphSmall>
        <ButtonSmall onClick={onInstallClick}>
          {t('app:home:appPromotion:button')}
        </ButtonSmall>
      </ContentWrapper>
    </StyledBaseSection>
  );
};
