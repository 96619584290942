import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  usePortfolioExternalReportingFilter,
  usePortfolioRelationshipFilter,
  usePortfolioTaxClassFilter,
  usePortfolioInvestmentAccountsFilter,
  config,
} from '@formue-app/core';

import { SPACING_8 } from '../../constants/spacing';

import {
  DefaultFilterControls,
  ToggleFilterGroup,
  PeriodFilter,
} from '../portfolio/filters';

import { PopoverHeader } from './PopoverHeader';
import { RelationshipFilter } from '../portfolio/filters/RelationshipFilter';

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

export const PortfolioFilterPopoverContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <PopoverHeader title={t('app:portfolio:filter')} />
      <FilterWrapper>
        <RelationshipFilter
          title={t('app:filters:relationshipsTitle')}
          options={usePortfolioRelationshipFilter()}
        />
        <ToggleFilterGroup
          title={t('app:filters:accountTitle')}
          options={usePortfolioExternalReportingFilter().map((item) => ({
            ...item,
            name: t(`app:filters:${item.name}`, {
              companyName: config.title,
            }),
          }))}
        />
        <ToggleFilterGroup
          title={t('app:filters:taxClassTitle')}
          options={usePortfolioTaxClassFilter()}
        />
        <ToggleFilterGroup
          title={t('app:filters:investmentSavingsAccount')}
          options={usePortfolioInvestmentAccountsFilter()}
        />
        <PeriodFilter title={t('app:filters:timeSpanTitle')} />

        <DefaultFilterControls />
      </FilterWrapper>
    </>
  );
};
