import React from 'react';
import {
  getAssetClassName,
  percentage,
  selectors,
  useResource,
  t,
  getAssetAllocationGroups,
} from '@formue-app/core';
import styled, { css } from 'styled-components';

import { PortfolioPyramid } from '../../common/PortfolioPyramid';
import { accent } from '../../../constants/colors';
import { H2, ParagraphSmall, ParagraphXSmall } from '../../texts';
import {
  BORDER_RADIUS_LARGE,
  SPACING_64,
  SPACING_16,
} from '../../../constants/spacing';
import { useSelector } from 'react-redux';
import {
  easeInOut,
  fadeInFromSide,
  mediumAnimationTime,
} from '../../../constants/animation';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { SpriteIcon } from '../../common/SpriteIcon';

const {
  entities: {
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsByStrategyIdSelector,
      investmentStrategyAssetAllocationsMetadataSelector,
    },
  },
} = selectors;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${accent.sand3};
  padding: 40px;
  flex: 1;
  max-width: 360px;
  border-radius: ${BORDER_RADIUS_LARGE};

  opacity: 0;
  transform: translateX(60px);

  ${(props) =>
    props.animate &&
    css`
      animation: ${fadeInFromSide} ${mediumAnimationTime} ${easeInOut} forwards;
      animation-delay: ${(props) => props.delay}s;
    `}
`;

const PyramidContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const Labels = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 35%;
  justify-content: space-between;
  position: relative;
  top: -20px;
  height: 195px;
`;

const Title = styled(H2)`
  text-align: center;
  padding-bottom: ${SPACING_64};
`;

const Label = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const PyramidWrapper = styled.div`
  position: relative;
`;

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  height: 177px;
`;

const Line = styled.div`
  width: 40px;
  border-bottom: 1px dashed ${accent.velvet170};
  position: relative;
  opacity: 0.3;
`;

const LabelTitle = styled(ParagraphXSmall)`
  padding-bottom: 2px;
`;

const LabelValue = styled(H2)``;

const Footer = styled.div`
  display: flex;
  margin-top: 30px;
  gap: ${SPACING_16};
`;

const FooterItem = styled.div`
  padding: ${SPACING_16} ${SPACING_16};
  background-color: ${accent.sand1};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const FooterItemTitle = styled(ParagraphSmall)`
  font-weight: 500;
  padding: 12px 0 5px 0;
  text-align: center;
`;

const FooterItemValue = styled(ParagraphSmall)`
  font-weight: 500;
  text-align: center;
`;

export const StrategyChoiceItem = (props) => {
  const { id, title, animate, index, ...rest } = props;

  const investmentStrategiesById = useSelector(
    (state) => state.entities.investmentStrategies.byId
  );

  const strategy = investmentStrategiesById[id];
  const assetAllocations = useSelector(
    investmentStrategyAssetAllocationsByStrategyIdSelector(id)
  );
  const strategiesMeta = useSelector(
    investmentStrategyAssetAllocationsMetadataSelector
  );

  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    {
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: id },
    },
  ]);

  const assetAllocationGroups = getAssetAllocationGroups(assetAllocations);

  if (!strategy) return null;

  const expectedRisk = strategiesMeta[id]?.expectedPerformance.risk;
  const expectedReturn = strategiesMeta[id]?.expectedPerformance.return;

  return (
    <Container animate={animate} delay={0.5 + index * 0.3} {...rest}>
      {loading ? (
        <CenteredActivityIndicator />
      ) : (
        <>
          <PyramidContainer>
            <Title>{title}</Title>
            <PyramidWrapper>
              <PortfolioPyramid
                height={174}
                style={{ position: 'absolute', left: -85 }}
              />
              <Lines>
                <Line style={{ left: 35 }} />
                <Line style={{ left: 65 }} />
                <Line style={{ left: 95 }} />
              </Lines>
            </PyramidWrapper>

            <Labels>
              <Label style={{ left: 0 }}>
                <LabelTitle>{getAssetClassName('AV')}</LabelTitle>
                <LabelValue>
                  {percentage(
                    assetAllocationGroups.AV.strategyShare * 100,
                    0,
                    0,
                    false
                  )}
                </LabelValue>
              </Label>
              <Label style={{ left: 30 }}>
                <LabelTitle>{getAssetClassName('MV')}</LabelTitle>
                <LabelValue>
                  {percentage(
                    assetAllocationGroups.MV.strategyShare * 100,
                    0,
                    0,
                    false
                  )}
                </LabelValue>
              </Label>
              <Label style={{ left: 60 }}>
                <LabelTitle>{getAssetClassName('S')}</LabelTitle>
                <LabelValue>
                  {percentage(
                    assetAllocationGroups.S.strategyShare * 100,
                    0,
                    0,
                    false
                  )}
                </LabelValue>
              </Label>
            </Labels>
          </PyramidContainer>
          <Footer>
            <FooterItem>
              <SpriteIcon id="Results" size={30} />
              <FooterItemTitle>
                {t(
                  'app:presentation:investmentStrategy:chooseStrategy:yearlyResults'
                )}
              </FooterItemTitle>
              <FooterItemValue>
                {percentage(expectedReturn * 100)}
              </FooterItemValue>
            </FooterItem>
            <FooterItem>
              <SpriteIcon id="Volatility" size={30} />
              <FooterItemTitle>
                {t(
                  'app:presentation:investmentStrategy:chooseStrategy:yearlyVolatility'
                )}
              </FooterItemTitle>
              <FooterItemValue>
                {percentage(expectedRisk * 100)}
              </FooterItemValue>
            </FooterItem>
          </Footer>
        </>
      )}
    </Container>
  );
};
