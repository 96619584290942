import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, formatMoney } from '@formue-app/core';
import { t } from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { SummaryBox } from '../../common/SummaryBox';
import { ShapeChart } from './ShapeChart';

import { H4, H6, ParagraphSmall, ParagraphXSmall } from '../../texts';
import { SPACING_32, SPACING_24, SPACING_8 } from '../../../constants/spacing';
import {
  DesktopWrapper,
  MobileWrapper,
  TabletWrapper,
  NotMobileWrapper,
  tabletCondition,
} from '../../../constants/media';

const {
  entities: {
    wealthBalance: { createWealthBalanceByCategorySelector },
  },
} = selectors;

const StyledH6 = styled(H6)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: ${SPACING_8};
  height: 32px;
`;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_32};

  @media ${tabletCondition} {
    grid-column: span 4;
  }
`;

const StyledItem = styled.div`
  text-align: center;
  width: calc(100% / 3);

  @media ${tabletCondition} {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  height: 100%;
`;

const Item = (props) => {
  const { title, value, chart } = props;

  return (
    <StyledItem>
      <ShapeChart category={chart} />
      <NotMobileWrapper>
        <StyledH6>{title}</StyledH6>
        <H4 style={{ marginTop: SPACING_8 }}>{value}</H4>
      </NotMobileWrapper>
      <MobileWrapper>
        <ParagraphXSmall style={{ marginTop: SPACING_24 }}>
          {title}
        </ParagraphXSmall>
        <ParagraphSmall style={{ marginTop: SPACING_8 }}>
          {value}
        </ParagraphSmall>
      </MobileWrapper>
    </StyledItem>
  );
};

const Content = (props) => {
  const { safety, preserveAndGrow, create } = props;

  return (
    <ContentWrapper>
      <Item
        title={t('app:wealthBalance:categories:safety')}
        value={formatMoney(safety.amount)}
        chart="safety"
      />
      <Item
        title={t('app:wealthBalance:categories:preserveAndGrow')}
        value={formatMoney(preserveAndGrow.amount)}
        chart="preserveAndGrow"
      />
      <Item
        title={t('app:wealthBalance:categories:create')}
        value={formatMoney(create.amount)}
        chart="create"
      />
    </ContentWrapper>
  );
};

export const DistributionExcerpt = (props) => {
  const { columns = 5 } = props;

  const safety = useSelector(createWealthBalanceByCategorySelector('Safety'));
  const preserveAndGrow = useSelector(
    createWealthBalanceByCategorySelector('Preserve & Grow')
  );
  const create = useSelector(createWealthBalanceByCategorySelector('Create'));

  return (
    <>
      <DesktopWrapper>
        <StyledBaseSection columns={columns}>
          <Content
            safety={safety}
            preserveAndGrow={preserveAndGrow}
            create={create}
          />
        </StyledBaseSection>
      </DesktopWrapper>

      <TabletWrapper>
        <StyledBaseSection columns={12 / 3}>
          <Item
            title={t('app:wealthBalance:categories:safety')}
            value={formatMoney(safety.amount)}
            chart="safety"
          />
        </StyledBaseSection>
        <StyledBaseSection columns={12 / 3}>
          <Item
            title={t('app:wealthBalance:categories:preserveAndGrow')}
            value={formatMoney(preserveAndGrow.amount)}
            chart="preserveAndGrow"
          />
        </StyledBaseSection>
        <StyledBaseSection columns={12 / 3}>
          <Item
            title={t('app:wealthBalance:categories:create')}
            value={formatMoney(create.amount)}
            chart="create"
          />
        </StyledBaseSection>
      </TabletWrapper>

      <MobileWrapper>
        <SummaryBox
          columns={columns}
          label={t('app:globals:readMore')}
          url={'/wealth-plan/wealth-balance'}
        >
          <Content
            safety={safety}
            preserveAndGrow={preserveAndGrow}
            create={create}
          />
        </SummaryBox>
      </MobileWrapper>
    </>
  );
};
