import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { t, selectors } from '@formue-app/core';

import { GridElement, MainGrid } from '../../layout';
import { BaseSection } from '../../common/BaseSection';
import { Modal } from '../../common/Modal';

import { H2, Paragraph } from '../../texts';
import { MultipleSwitch, Toggle } from '../../formElements';

import { PortfolioStrategyList } from './PortfolioStrategyList';
import { PortfolioStrategyModalContent } from './PortfolioStrategyModalContent';

import {
  accent,
  backgroundWhite,
  textLightest,
} from '../../../constants/colors';
import {
  mobileCondition,
  MobileWrapper,
  NotDesktopWrapper,
  NotMobileWrapper,
} from '../../../constants/media';
import { SPACING_24, SPACING_16, SPACING_48 } from '../../../constants/spacing';

const SubGrid = styled(MainGrid)`
  padding: 0;
  max-width: 100%;
`;

const StyledMultipleSwitch = styled(MultipleSwitch)`
  margin-left: auto;

  @media ${mobileCondition} {
    margin-left: 0;
  }
`;

const SwitchWrapper = styled(GridElement)`
  display: flex;
  align-items: flex-start;
`;

const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${SPACING_24};
`;

const {
  entities: {
    strategy: { allStrategiesSelector },
  },
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

const NotMobileStrategyContent = (props) => {
  const { setModalIsOpen, dataExtractor, setDataExtractor, activeStrategy } =
    props;

  return (
    <BaseSection columns={12} style={{ marginTop: SPACING_24 }}>
      <SubGrid>
        <GridElement columns={7}>
          <H2>{t('app:portfolio:portfolioStrategy:subTitle')}</H2>
          <Paragraph style={{ marginTop: SPACING_16 }}>
            {t('app:portfolio:portfolioStrategy:subIntro')}
          </Paragraph>
          <Paragraph
            style={{
              marginTop: SPACING_16,
              fontWeight: 500,
              color: accent.velvet5,
              cursor: 'pointer',
            }}
            onClick={() => setModalIsOpen(true)}
          >
            {t('app:portfolio:portfolioStrategy:readMore')}
          </Paragraph>
        </GridElement>
        <NotDesktopWrapper>
          <SwitchWrapper columns={5}>
            <StyledMultipleSwitch
              color={accent.velvet1}
              activeBackgroundColor={accent.velvet1}
              activeColor={textLightest}
              onChange={(value) => {
                setDataExtractor(value);
              }}
              options={[
                {
                  label: t('app:portfolio:portfolioStrategy:overview'),
                  value: 'overview',
                },
                {
                  label: t('app:portfolio:portfolioStrategy:details'),
                  value: 'details',
                },
              ]}
            />
          </SwitchWrapper>
        </NotDesktopWrapper>

        <GridElement columns={12}>
          <PortfolioStrategyList
            activeStrategy={activeStrategy}
            dataExtractor={dataExtractor}
            style={{ marginTop: SPACING_24 }}
          />
        </GridElement>
      </SubGrid>
    </BaseSection>
  );
};

const MobileStrategyContent = (props) => {
  const {
    setShowAll,
    showAll,
    activeStrategy,
    openItems,
    setOpenItems,
    dataExtractor,
  } = props;

  return (
    <>
      <GridElement columns={12}>
        <MobileHeader>
          <H2>{t('app:portfolio:portfolioStrategy:subTitle')}</H2>
          <Toggle
            onChange={() => setShowAll(!showAll)}
            value={showAll}
            label={t('app:portfolio:portfolioStrategy:seeAllDetails')}
            style={{ marginLeft: 'auto' }}
          />
        </MobileHeader>
      </GridElement>
      <GridElement columns={12}>
        <PortfolioStrategyList
          activeStrategy={activeStrategy}
          openItems={openItems}
          setOpenItems={setOpenItems}
          dataExtractor={dataExtractor}
          style={{ marginTop: SPACING_24 }}
        />
      </GridElement>
    </>
  );
};

export const PortfolioStrategyContent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openItems, setOpenItems] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [activeStrategy, setActiveStrategy] = useState(null);
  const [dataExtractor, setDataExtractor] = useState('overview');

  const allStrategies = useSelector(allStrategiesSelector);
  const activeStrategyId = useSelector(activeStrategySelector);

  useEffect(() => {
    setActiveStrategy(
      allStrategies.find((strategy) => strategy.id === activeStrategyId)
    );
  }, [allStrategies, activeStrategyId]);

  useEffect(() => {
    if (showAll) {
      setOpenItems(activeStrategy.strategy.map((item, index) => index));
    } else {
      setOpenItems([]);
    }
  }, [showAll, activeStrategy]);

  return (
    <>
      <NotMobileWrapper>
        <NotMobileStrategyContent
          setModalIsOpen={setModalIsOpen}
          dataExtractor={dataExtractor}
          setDataExtractor={setDataExtractor}
          activeStrategy={activeStrategy}
        />
      </NotMobileWrapper>

      <MobileWrapper>
        <MobileStrategyContent
          setShowAll={setShowAll}
          showAll={showAll}
          activeStrategy={activeStrategy}
          openItems={openItems}
          setOpenItems={setOpenItems}
          dataExtractor={dataExtractor}
        />
      </MobileWrapper>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          content: {
            background: backgroundWhite,
            padding: SPACING_48,
            border: 'none',
          },
        }}
      >
        <PortfolioStrategyModalContent />
      </Modal>
    </>
  );
};
