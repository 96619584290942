import React from 'react';
import styled from 'styled-components';
import { useQueryState } from 'react-router-use-location-state';

import { t } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_64,
} from '../../../constants/spacing';

import { H1 } from '../../texts';
import { Button } from '../../buttons';
import { SidebarImage } from '../SidebarImage';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Title = styled(H1)`
  color: ${accent.ocean250};
  margin-bottom: ${SPACING_64};
  margin-top: ${ANNUAL_REVIEW_CONTENT_SPACING};
  font-size: 84px;
`;

const TextContainer = styled.div`
  width: 60%;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

export const Welcome = (props) => {
  const { id, setActiveIndex, activeIndex, title, presentationType } = props;

  const [exportMode] = useQueryState('exportMode', false);

  return (
    <Wrapper>
      <TextContainer>
        <Title>{t(title)}</Title>
        {!exportMode ? (
          <Button
            buttonstyle="tonal"
            buttoncolor={accent.ocean370}
            buttoncolorhover={accent.ocean330}
            textcolor={accent.ocean4}
            onClick={() => setActiveIndex(activeIndex + 1)}
          >
            {t('app:presentation:annualReview:welcome:button')}
          </Button>
        ) : null}
      </TextContainer>
      <SidebarImage id={id} presentationType={presentationType} />
    </Wrapper>
  );
};
