import React from 'react';

import { t, useResource } from '@formue-app/core';

import { H3 } from '../../components/texts';
import { InvestmentStrategiesListItem } from './InvestmentStrategiesListItem';
import { SPACING_24 } from '../../constants/spacing';
import { CenteredActivityIndicator } from '../common/ActivityIndicator';
import { GridElement } from '../layout';

export const InvestmentStrategyChildren = (props) => {
  const { childStrategies, assetAllocations, advisorTool = false } = props;

  const loading = useResource([
    ...childStrategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: strategy.id },
    })),
  ]);

  return loading ? (
    <CenteredActivityIndicator />
  ) : (
    <>
      <GridElement columns={12}>
        <H3 style={{ paddingTop: SPACING_24 }}>
          {t('app:wealthPlan:investmentStrategy:childStrategies')}
        </H3>
      </GridElement>
      {childStrategies.map((strategy) => {
        const assetClasses = assetAllocations.filter(
          (assetAllocation) =>
            assetAllocation.investmentStrategy.id === strategy.id
        );

        return (
          <InvestmentStrategiesListItem
            key={`child-strategy-${strategy.id}`}
            columns={12}
            assetClasses={assetClasses}
            to={
              advisorTool
                ? `/advisor/strategy-simulator/details/${strategy.id}`
                : `/investment-strategy/${strategy.id}`
            }
            {...strategy}
          />
        );
      })}
    </>
  );
};
