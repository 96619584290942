import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { selectors, t } from '@formue-app/core';

import { BusinessAndOwnershipItem } from './businessAndOwnershipItem';
import { H2Small } from '../../../texts';
import { accent } from '../../../../constants/colors';
import { SPACING_32, SPACING_16 } from '../../../../constants/spacing';
import { easeInOut, longAnimationTime } from '../../../../constants/animation';
import { mobileCondition } from '../../../../constants/media';

const OrganizationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${SPACING_32} ${SPACING_16};

  @media ${mobileCondition} {
    gap: ${SPACING_16};
  }
`;

const StyledTitle = styled(H2Small)`
  position: relative;
  color: ${accent.raspberry250};
  margin-bottom: ${SPACING_32};
  left: 25px;
  opacity: 0;
  transition: all ${easeInOut} ${longAnimationTime};

  ${(props) =>
    props.isActiveSlide &&
    css`
      left: 0;
      opacity: 1;
    `}
`;

const {
  entities: {
    familyAndOwnership: { organizationSelector },
  },
} = selectors;

export const BusinessAndOwnership = (props) => {
  const { isActiveSlide, itemsPrRow = 3, ...rest } = props;
  const [activeOrganization, setActiveOrganization] = useState(null);

  const organizations = useSelector(organizationSelector);

  if (!organizations.length) {
    return null;
  }

  return (
    <>
      <StyledTitle isActiveSlide={isActiveSlide}>
        {t('app:presentation:annualReview:familyAndOwnership:ownershipTitle')}
      </StyledTitle>
      <OrganizationWrapper {...rest}>
        {organizations.map((item, index) => (
          <BusinessAndOwnershipItem
            item={item}
            index={index}
            itemsPrRow={itemsPrRow}
            key={`business-${index}`}
            isActiveSlide={isActiveSlide}
            activeOrganization={activeOrganization}
            setActiveOrganization={setActiveOrganization}
          />
        ))}
      </OrganizationWrapper>
    </>
  );
};
