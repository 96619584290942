import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, t } from '@formue-app/core';

import { accent, backgroundWhite } from '../../../../constants/colors';
import {
  SPACING_48,
  SPACING_32,
  SPACING_64,
  SPACING_8,
  SPACING_16,
} from '../../../../constants/spacing';
import { mobileCondition, MobileWrapper } from '../../../../constants/media';
import {
  easeInOut,
  extraLongAnimationTime,
  longAnimationTime,
  shortAnimationTime,
  slideAnimationDelay,
} from '../../../../constants/animation';

import { H3, Paragraph, ParagraphSmall } from '../../../texts';

import { GoalIcon } from '../../../wealthPlan/goals/GoalIcon';
import { SpriteIconFunctional } from '../../../common/SpriteIconFunctional';

const {
  entities: {
    goals: { goalsHasContentSelector },
  },
} = selectors;

const GoalsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${SPACING_64};
  overflow: hidden;
  height: 100%;
  align-items: center;
`;

const GoalsInner = styled.div`
  width: auto;
  min-width: 100%;
  display: flex;
  justify-content: space-evenly;
  transition: left ${easeInOut} ${longAnimationTime};
  position: relative;

  @media ${mobileCondition} {
    flex-direction: column;
    align-items: center;
    gap: ${SPACING_64};
  }
`;

const GoalWrapper = styled.div`
  width: calc(
    100% /
      ${(props) => Math.min(props.goalsCount, props.maxItemsBeforeSlideShow)}
  );
  max-width: 265px;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;

  @media ${mobileCondition} {
    width: 100%;
  }

  h3 {
    position: relative;
    top: 50px;
    opacity: 0;
    transition: all ${easeInOut} ${extraLongAnimationTime};
    transition-delay: ${(props) =>
      `${slideAnimationDelay + props.index * 0.2}s`};

    ${(props) =>
      props.isActiveSlide &&
      `
    top: 0;
    opacity: 1;
  `}
  }

  span {
    position: relative;
    top: 30px;
    opacity: 0;
    transition: all ${easeInOut} ${extraLongAnimationTime};
    transition-delay: ${(props) =>
      `${slideAnimationDelay + props.index * 0.3}s`};

    ${(props) =>
      props.isActiveSlide &&
      `
    top: 0;
    opacity: 1;
  `}
  }

  p {
    position: relative;
    transition: all ${easeInOut} ${shortAnimationTime};
  }
`;

const IconWrapper = styled.div`
  background-color: ${(props) =>
    props.activeGoal ? backgroundWhite : accent.forest1};
  border-radius: 50%;
  width: ${(props) => (props.goalsCount >= props.maxBigItems ? 144 : 190)}px;
  height: ${(props) => (props.goalsCount >= props.maxBigItems ? 144 : 190)}px;
  margin-bottom: ${SPACING_48};
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  scale: 0;
  opacity: 0;
  transition: scale ${easeInOut} ${extraLongAnimationTime},
    opacity ${easeInOut} ${extraLongAnimationTime},
    background ${easeInOut} ${shortAnimationTime};
  transition-delay: ${(props) => `${slideAnimationDelay + props.index * 0.2}s`},
    ${(props) => `${slideAnimationDelay + props.index * 0.2}s`}, 0s;

  ${(props) =>
    props.isActiveSlide &&
    `
    opacity: 1;
    scale: 1;
  `}

  ${(props) =>
    !props.activeGoal &&
    `svg path {
    fill: ${backgroundWhite};
  }`}

  svg path {
    transition: all ${easeInOut} ${shortAnimationTime};
  }

  &:hover {
    background-color: ${(props) =>
      props.activeGoal ? backgroundWhite : accent.forest170};
  }

  @media ${mobileCondition} {
    width: 96px;
    height: 96px;
    margin-bottom: ${SPACING_32};

    svg {
      width: 53px;
      height: 53px;
    }
  }
`;

const Arrow = styled.span`
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  cursor: pointer;
  background: ${accent.forest490};
  border-radius: 50px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg path {
    fill: ${accent.forest250};
  }
`;

export const GoalsList = (props) => {
  const { isActiveSlide, goals, ...rest } = props;
  const hasContent = useSelector(goalsHasContentSelector);

  const [activeGoalId, setActiveGoalId] = useState(null);
  const [slideOffsetCount, setSlideOffsetCount] = useState(0);

  const maxItemsBeforeSlideShow = 5;
  const maxBigItems = 3;

  // Deselect goal if slide is not active
  useEffect(() => {
    if (!isActiveSlide) {
      setActiveGoalId(null);
    }
  }, [isActiveSlide]);

  if (!hasContent) {
    return null;
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {goals.length >= maxItemsBeforeSlideShow && slideOffsetCount > 0 ? (
        <Arrow
          style={{ left: -40 }}
          onClick={() => setSlideOffsetCount(slideOffsetCount - 1)}
        >
          <SpriteIconFunctional
            id="Simple arrow"
            direction="Left"
            color={accent.forest250}
            size={SPACING_16}
          />
        </Arrow>
      ) : null}
      <GoalsWrapper {...rest}>
        <GoalsInner
          style={{
            left: `calc(-100% / ${maxItemsBeforeSlideShow} * ${slideOffsetCount})`,
            justifyContent:
              goals.length >= maxItemsBeforeSlideShow ? 'flex-start' : null,
          }}
        >
          {goals.map((item, index) => (
            <GoalWrapper
              onClick={() =>
                setActiveGoalId(item.id === activeGoalId ? null : item.id)
              }
              index={index}
              activeGoal={activeGoalId === item.id}
              isActiveSlide={isActiveSlide}
              goalsCount={goals.length}
              maxItemsBeforeSlideShow={maxItemsBeforeSlideShow}
              key={`goal-${index}`}
            >
              <IconWrapper
                index={index}
                activeGoal={activeGoalId === item.id}
                isActiveSlide={isActiveSlide}
                goalsCount={goals.length}
                maxBigItems={maxBigItems}
              >
                <GoalIcon
                  width={goals.length >= maxBigItems ? 80 : 106}
                  height={goals.length >= maxBigItems ? 80 : 106}
                  goalType={item.goalType}
                  inverted={true}
                  navigational={activeGoalId === item.id ? false : true}
                />
              </IconWrapper>
              <H3 style={{ color: accent.forest250 }}>{item.title}</H3>
              <MobileWrapper>
                <Paragraph
                  as="span"
                  style={{
                    color: accent.forest370,
                    fontWeight: 'bold',
                    marginTop: SPACING_8,
                  }}
                >
                  {activeGoalId === item.id
                    ? t('app:globals:readLess')
                    : t('app:globals:readMore')}
                </Paragraph>
              </MobileWrapper>
              {activeGoalId === item.id ? (
                <ParagraphSmall
                  style={{
                    color: accent.forest250,
                    marginTop: SPACING_8,
                  }}
                >
                  {item.description}
                </ParagraphSmall>
              ) : null}
            </GoalWrapper>
          ))}
        </GoalsInner>
      </GoalsWrapper>
      {goals.length >= maxItemsBeforeSlideShow &&
      slideOffsetCount < goals.length - maxItemsBeforeSlideShow ? (
        <Arrow
          style={{ right: -40 }}
          onClick={() => setSlideOffsetCount(slideOffsetCount + 1)}
        >
          <SpriteIconFunctional
            id="Simple arrow"
            direction="Right"
            color={accent.forest250}
            size={SPACING_16}
          />
        </Arrow>
      ) : null}
    </div>
  );
};
