import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

import {
  getAssetClassName,
  getColorForAssetClass,
  monthRange,
  percentage,
  sortByAssetClass,
  t,
} from '@formue-app/core';

import { SPACING_32, SPACING_8 } from '../../../constants/spacing';
import { assetClassDefaultColorScheme } from '../../../constants/colors';
import { TableList } from '../../lists/TableList';
import { DotIcon } from '../../common/DotIcon';

const AssetClass = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_8};
`;

const StyledTableList = styled(TableList)`
  margin-top: ${SPACING_32};
`;

let columns = [
  {
    key: 'assetClass',
    label: t('app:advisor:strategySimulator:implementation:assetClass'),
    render: ({ assetClass }) => (
      <AssetClass>
        <DotIcon
          color={getColorForAssetClass(
            assetClass,
            assetClassDefaultColorScheme
          )}
        />
        {getAssetClassName(assetClass)}
      </AssetClass>
    ),
    width: '30%',
  },
  {
    key: 'strategyShare',
    label: t('app:advisor:strategySimulator:implementation:target'),
    width: '10%',
    render: ({ target }) => percentage(target * 100),
    type: 'number',
  },
];

export const ImplementationPlanTable = (props) => {
  const { rounds, monthsPerRound, implementationPlan, ...rest } = props;

  const [activeColumns, setActiveColumns] = useState(columns);

  useEffect(() => {
    // Copy original columns to add updated amount of rounds
    const arr = [...columns];
    const columnWidth = 70 / (rounds + 1);

    // We only want to show rounds if there are more than 1 since one round doesn't really make sense,
    // in those cases the first round is equal to the target, which is the last column in the table.
    if (rounds > 1) {
      // Add dynamic columns based on implementation rounds
      for (let i = 0; i < rounds; i++) {
        arr.splice(i + 1, 0, {
          key: `step-${i + 1}`,
          label: t('app:advisor:strategySimulator:implementation:steps', {
            step: i + 1,
          }),
          subLabel: t('app:advisor:strategySimulator:implementation:months', {
            range: monthRange(i, monthsPerRound),
          }),
          width: `${columnWidth}%`,
          render: ({ sharePerRound }) => percentage(sharePerRound[i] * 100),
          type: 'number',
        });
      }
    }

    // Update last item (target) to be same as rounds
    const last = arr.at(-1);
    last.width = `${columnWidth}%`;
    // Hack to make sure label shows, for some reason it doesn't always
    // show up when just defined in the original `columns` array
    last.label = t('app:advisor:strategySimulator:implementation:target');

    setActiveColumns(arr);
  }, [rounds, monthsPerRound]);

  if (!implementationPlan || !implementationPlan?.assetClasses) {
    return null;
  }

  return (
    <StyledTableList
      id="implementationPlanList"
      sortable={false}
      columns={activeColumns}
      rows={implementationPlan?.assetClasses.sort(sortByAssetClass)}
      keyExtractor={(item, index) => `implementation-plan-${index}`}
      {...rest}
    />
  );
};
