import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import {
  formatBigMoney,
  formatMoney,
  localizeFloat,
  toLocaleDateString,
  groupBy,
  getFlagsSelector,
} from '@formue-app/core';

import { backgroundMain } from '../../../constants/colors';
import { mobileCondition, tabletCondition } from '../../../constants/media';
import {
  SPACING_12,
  SPACING_16,
  SPACING_32,
  SPACING_8,
} from '../../../constants/spacing';
import { TableList, StyledCell } from '../../lists/TableList';
import { H4, H6, ParagraphSmall, ParagraphXSmall } from '../../texts';
import {
  paragraphSmallLineHeight,
  paragraphSmallSize,
} from '../../../constants/text';
import { GridElement } from '../../layout';
import { useSelector } from 'react-redux';

const LightStyledCell = styled(StyledCell)`
  padding: ${SPACING_12};

  span {
    font-size: ${paragraphSmallSize};
    font-weight: 400;
    line-height: ${paragraphSmallLineHeight};
  }
`;

const StyledGridElement = styled(GridElement)`
  margin-top: ${SPACING_32};
`;

const StyledTableList = styled(TableList)`
  margin-top: ${SPACING_8};
`;

const TitleWrapper = styled.div`
  width: 60%;
`;

const AmountWrapper = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MetadataWrapper = styled.div`
  border-top: 2px solid ${backgroundMain};
  display: flex;
  gap: ${SPACING_16};
  width: calc(100% + 48px);
  margin-top: 10px;
  padding-top: 10px;
  padding-inline: 24px;
  margin-left: -24px;
`;

export const TransactionList = (props) => {
  const { t } = useTranslation();
  const { items: transactions } = props;

  const { features } = useSelector(getFlagsSelector);

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  const tabletSize = useMediaQuery({
    query: tabletCondition,
  });

  if (!transactions.length) {
    return null;
  }

  const groupedTransactions = groupBy(transactions, 'account');

  let columns = [
    {
      key: 'product',
      label: t('app:portfolio:product'),
      component: LightStyledCell,
      width: '30%',
    },
    {
      key: 'date',
      label: t('app:globals:date'),
      component: LightStyledCell,
      render: ({ date, currencyCode, currencyRate, isMultiCurrency }) => (
        <div>
          <ParagraphSmall>{toLocaleDateString(date)}</ParagraphSmall>
          {false && isMultiCurrency && currencyRate ? (
            <ParagraphXSmall style={{ marginTop: SPACING_8 }}>
              {currencyCode} {localizeFloat(currencyRate, 3)}
            </ParagraphXSmall>
          ) : null}
        </div>
      ),
      width: '10%',
      defaultSort: true,
    },
    {
      key: 'type',
      label: t('app:portfolio:type'),
      component: LightStyledCell,
      width: '10%',
    },
    {
      key: 'quantity',
      label: t('app:portfolio:shares'),
      render: ({ quantity: q }) => (q ? localizeFloat(q, 4, true, true) : ''),
      type: 'number',
      component: LightStyledCell,
      width: '10%',
    },
    {
      key: 'cost',
      label: t('app:portfolio:costPrice'),
      render: ({ cost, currencyCode, costCurrency, isMultiCurrency }) => (
        <div>
          <ParagraphSmall style={{ textAlign: 'right' }}>
            {formatMoney(cost, '', false, '')}
          </ParagraphSmall>
          {false && isMultiCurrency && costCurrency ? (
            <ParagraphXSmall style={{ marginTop: SPACING_8 }}>
              {currencyCode} {formatMoney(costCurrency)}
            </ParagraphXSmall>
          ) : null}
        </div>
      ),
      type: 'number',
      component: LightStyledCell,
      width: '13%',
    },
    {
      key: 'amount',
      label: t('app:globals:amount'),
      render: ({ amount, currencyCode, amountCurrency, isMultiCurrency }) => (
        <div>
          <ParagraphSmall style={{ textAlign: 'right' }}>
            {formatMoney(amount, '', false, '')}
          </ParagraphSmall>
          {false && isMultiCurrency && amountCurrency ? (
            <ParagraphXSmall style={{ marginTop: SPACING_8 }}>
              {currencyCode} {formatMoney(amountCurrency)}
            </ParagraphXSmall>
          ) : null}
        </div>
      ),
      type: 'number',
      component: LightStyledCell,
      width: '13%',
    },
    {
      key: 'realizedGainLoss',
      label: t('app:portfolio:lossGain'),
      render: ({
        realizedGainLoss,
        currencyCode,
        realizedGainLossCurrency,
        isMultiCurrency,
      }) => (
        <div>
          <ParagraphSmall style={{ textAlign: 'right' }}>
            {formatMoney(realizedGainLoss, '', false, '')}
          </ParagraphSmall>
          {false && isMultiCurrency && realizedGainLossCurrency ? (
            <ParagraphXSmall style={{ marginTop: SPACING_8 }}>
              {currencyCode} {formatMoney(realizedGainLossCurrency)}
            </ParagraphXSmall>
          ) : null}
        </div>
      ),
      type: 'number',
      component: LightStyledCell,
      width: '13%',
    },
  ];

  if (tabletSize || mobileSize) {
    columns = [
      {
        key: 'product',
        render: ({
          product,
          type,
          date,
          amount,
          quantity,
          realizedGainLoss,
        }) => (
          <ContentWrapper>
            <HeaderWrapper>
              <TitleWrapper>
                <H4>{product}</H4>
                <H6 style={{ marginTop: SPACING_8 }}>
                  {type} - {toLocaleDateString(date)}
                </H6>
              </TitleWrapper>
              <AmountWrapper>
                <H4>{amount ? formatBigMoney(amount, false) : ''}</H4>
              </AmountWrapper>
            </HeaderWrapper>
            {quantity || realizedGainLoss ? (
              <MetadataWrapper>
                {quantity ? (
                  <ParagraphSmall>
                    {t('app:portfolio:shares')}: {localizeFloat(quantity, 4)}
                  </ParagraphSmall>
                ) : null}

                {realizedGainLoss ? (
                  <ParagraphSmall>
                    {realizedGainLoss < 0
                      ? t('app:portfolio:loss')
                      : t('app:portfolio:gain')}
                    : {formatMoney(realizedGainLoss)}
                  </ParagraphSmall>
                ) : null}
              </MetadataWrapper>
            ) : null}
          </ContentWrapper>
        ),
        label: '',
        width: '60%',
      },
    ];
  }

  console.log('groupedTransactions', groupedTransactions, transactions);

  if (features?.accountFilterTester) {
    return Object.keys(groupedTransactions).map((account) => (
      <StyledGridElement key={account} columns={12}>
        <H4>{account}</H4>
        <StyledTableList
          className="table-list"
          columns={columns}
          rows={groupedTransactions[account]}
          sortable={mobileSize ? false : true}
          keyExtractor={(item, index) => `transaction-${index}`}
          infiniteScroll={true}
        />
      </StyledGridElement>
    ));
  } else {
    return (
      <StyledTableList
        className="table-list"
        columns={columns}
        rows={transactions}
        sortable={mobileSize ? false : true}
        keyExtractor={(item, index) => `transaction-${index}`}
        infiniteScroll={true}
      />
    );
  }
};
