import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import styled from 'styled-components';

import { t, selectors, actions } from '@formue-app/core';

import { SPACING_24, SPACING_8 } from '../../constants/spacing';
import { textLightest } from '../../constants/colors';
import { Modal } from '../common/Modal';
import { accent } from '../../constants/colors';
import { Input } from '../formElements';

import { SPACING_48 } from '../../constants/spacing';
import { H2, Paragraph } from '../texts';
import { ButtonSmall } from '../buttons';

const { currentUserSelector } = selectors.ui.user;
const { updateRequestBegin } = actions.entities.users;

const StyledFormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const StyledInputWrapper = styled.div`
  margin-bottom: ${SPACING_24};
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const LeadRegistrationForm = (props) => {
  const { onRequestClose } = props;
  const user = useSelector(currentUserSelector);
  const dispatch = useDispatch();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [status, setValidateStatus] = useState({
    email: false,
  });

  useEffect(() => {
    if (user) {
      setPhone(user.mobilePhone || '');
      setEmail(user.email);
    }
  }, [user]);

  const onChangePhone = (value) => {
    // setValidateStatus({ ...status, phone: validator.isMobilePhone(value) });
    setPhone(value);
  };

  const onChangeEmail = (value) => {
    setValidateStatus({ ...status, email: validator.isEmail(value) });
    setEmail(value);
  };

  const handleSubmit = () => {
    user.email = email;
    user.mobilePhone = phone;

    dispatch(updateRequestBegin({ args: { id: user.id }, data: user }));
    onRequestClose();
  };

  const disabled = !Object.values(status).some((item) => item === true);

  return (
    <StyledFormContainer>
      <H2 style={{ color: textLightest, paddingBottom: SPACING_8 }}>
        {t('app:contactInfoRegistration:title')}
      </H2>
      <Paragraph style={{ color: textLightest, marginBottom: SPACING_24 }}>
        {t('app:contactInfoRegistration:text')}
      </Paragraph>

      <StyledInputWrapper>
        <StyledInput
          onChange={(event) => onChangeEmail(event.target.value)}
          type="text"
          value={email}
          placeholder={t('app:form:emailPlaceholder')}
        />
      </StyledInputWrapper>

      <StyledInputWrapper>
        <StyledInput
          onChange={(event) => onChangePhone(event.target.value)}
          type="text"
          value={phone}
          placeholder={t('app:form:phonePlaceholder')}
        />
      </StyledInputWrapper>

      <Paragraph style={{ color: textLightest, marginBottom: SPACING_24 }}>
        {t('app:contactInfoRegistration:newsletterConsentDescription')}
      </Paragraph>
      <Paragraph style={{ marginBottom: SPACING_24 }}>
        <a
          href={t('app:contactInfoRegistration:newsletterConsentLink')}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'underline',
            color: textLightest,
          }}
        >
          {t('app:contactInfoRegistration:newsletterConsentLinkText')}
        </a>
      </Paragraph>

      <ButtonSmall
        onClick={handleSubmit}
        disabled={disabled}
        style={{ width: '180px', alignSelf: 'center' }}
      >
        {t('app:contactInfoRegistration:registerButton')}
      </ButtonSmall>
    </StyledFormContainer>
  );
};

export const LeadRegistrationModal = (props) => {
  const { onRequestClose, ...rest } = props;

  return (
    <Modal
      style={{
        content: {
          background: accent.ocean4,
          padding: SPACING_48,
          border: 'none',
          width: '500px',
        },
      }}
      closable={false}
      onRequestClose={null}
      {...rest}
    >
      <LeadRegistrationForm onRequestClose={onRequestClose} />
    </Modal>
  );
};
