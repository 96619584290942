import React from 'react';
import styled from 'styled-components';

import { sortByAssetClass, t } from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { ProductSectionLight } from './ProductItems';
import { HorizontalDivider } from '../../common/HorizontalDivider';
import { SPACING_16 } from '../../../constants/spacing';
import { ButtonSmall } from '../../buttons';

const Wrapper = styled.div`
  width: 100%;
`;

const StyledBaseSection = styled(BaseSection)`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${SPACING_16};
`;

export const PreferencesProducts = (props) => {
  const { assetAllocations, products, strategy, ...rest } = props;

  if (!assetAllocations || !products) return null;

  return (
    <Wrapper {...rest}>
      <StyledBaseSection>
        {assetAllocations.sort(sortByAssetClass).map((item, index) => (
          <>
            <ProductSectionLight
              item={item}
              strategy={strategy}
              products={products.filter(
                (product) => product.assetClass === item.assetClass
              )}
              key={`section-${index}`}
            />
            <HorizontalDivider />
          </>
        ))}
        <ButtonsWrapper>
          <ButtonSmall style={{ marginLeft: 'auto' }} onClick={() => {}}>
            {t(
              'app:advisor:strategySimulator:preferences:addExternalPortfolio'
            )}
          </ButtonSmall>
          <ButtonSmall onClick={() => {}}>
            {t(
              'app:advisor:strategySimulator:preferences:manuallyEditPortfolio'
            )}
          </ButtonSmall>
        </ButtonsWrapper>
      </StyledBaseSection>
    </Wrapper>
  );
};
