import React from 'react';
import styled from 'styled-components';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_48,
  SPACING_24,
} from '../../../constants/spacing';

import { MainGrid } from '../../layout';

import { RiskModalContent } from '../../wealthPlan/investmentProfile/RiskModalContent';
import { contentMaxWitdh } from '../../../constants/grid';
import { accent, backgroundWhite } from '../../../constants/colors';
import { H2 } from '../../texts';
import { t } from '@formue-app/core';
import { LockinModalContent } from '../../wealthPlan/investmentProfile/LockinModalContent';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  background-color: ${accent.sand1};
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: ${contentMaxWitdh}px;
`;

const Content = styled(MainGrid)`
  width: 100%;
  padding: ${SPACING_48};
  background-color: ${backgroundWhite};
  margin: ${SPACING_24} 0 ${SPACING_48} 0;
`;

export const RiskProfileDetails = (props) => {
  const { isActiveSlide } = props;

  if (!isActiveSlide) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <H2>{t('app:wealthPlan:investmentProfile:riskAndLockin:riskTitle')}</H2>
        <Content>
          <RiskModalContent />
        </Content>

        <H2>
          {t('app:wealthPlan:investmentProfile:riskAndLockin:lockInTitle')}
        </H2>
        <Content>
          <LockinModalContent />
        </Content>
      </Container>
    </Wrapper>
  );
};
