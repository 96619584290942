import React from 'react';
import styled, { keyframes } from 'styled-components';

import { t } from '@formue-app/core';

import {
  BOX_PADDING_HORIZONTAL,
  SPACING_48,
  SPACING_32,
  SPACING_16,
  SPACING_88,
} from '../../../constants/spacing';

import { H1, H2Small, ParagraphLarge } from '../../texts';

import { Logo } from '../../common/Logo';

import { easeInOut, longAnimationTime } from '../../../constants/animation';
import {
  mobileCondition,
  NotMobileWrapper,
  tabletCondition,
} from '../../../constants/media';

import welcomeImageUrl from '../../../assets/images/annual-review/report/welcome-background.jpg';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';

const bounce = keyframes`
  0% {
    translate: 0 0;
  }
  50% {
    translate: 0 -10px;
  }
  100% {
    translate: 0 0;
  }
`;

const textReveal = keyframes`
  0% {
    top: 40px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center center;
  padding: ${SPACING_48} ${SPACING_88};

  @media ${mobileCondition} {
    padding: ${SPACING_48} ${BOX_PADDING_HORIZONTAL};
    padding-top: 91px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 70%,
        rgba(0, 0, 0, 0.39) 100%
      ),
      url(${(props) => props.backgroundImage});
    background-size: cover;
    background-position: center center;
  }
`;

const Title = styled(H1)`
  text-align: center;
  position: relative;
  opacity: 0;
  animation: ${textReveal} ${longAnimationTime} forwards ${easeInOut} 0.5s;
  font-size: 88px;
  width: 100%;
  white-space: pre-line;

  @media ${tabletCondition} {
    font-size: 52px;
  }

  @media ${mobileCondition} {
    font-size: 32px;
  }
`;

const StyledParagraph = styled(ParagraphLarge)`
  text-align: center;
  position: relative;
  opacity: 0;
  animation: ${textReveal} ${longAnimationTime} forwards ${easeInOut} 0.6s;
  max-width: 600px;
  margin: 0 auto;

  @media ${mobileCondition} {
    width: 80%;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
  gap: ${SPACING_32};

  @media ${mobileCondition} {
    margin-top: 110px;
  }
`;

const ScrollWrapper = styled.div`
  width: auto;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
`;

const StyledArrowWrapper = styled.div`
  animation: ${bounce} 1.5s infinite ease;
`;

export const WelcomeSection = (props) => {
  const { title, subTitle } = props;

  const handleScroll = () => {
    const element = document.getElementById('slides-wrapper');

    element.scroll({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  return (
    <Wrapper backgroundImage={welcomeImageUrl}>
      <NotMobileWrapper>
        <Logo style={{ marginBottom: 'auto' }} height="24" />
      </NotMobileWrapper>
      <TextContainer>
        <Title>{t(title)}</Title>
        <StyledParagraph>{t(subTitle)}</StyledParagraph>
      </TextContainer>
      <ScrollWrapper onClick={handleScroll}>
        <H2Small style={{ marginBottom: SPACING_16 }}>
          {t('app:report:annualReview:welcome:scrollDown')}
        </H2Small>
        <StyledArrowWrapper>
          <SpriteIconFunctional id="Simple arrow" direction="Down" />
        </StyledArrowWrapper>
      </ScrollWrapper>
    </Wrapper>
  );
};
