import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { percentage, t, selectors } from '@formue-app/core';

import { ComparisonWithOthers } from './ComparisonWithOthers';
import { ModalSection } from './ModalSection';
import { SustainabilityLineChart } from './SustainabilityLineChart';
import { accent, backgroundWhite } from '../../../../constants/colors';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_24,
  SPACING_32,
  SPACING_8,
} from '../../../../constants/spacing';
import imageShakingHands from '../../../../assets/images/sustainability/shaking-hands.png';
import imageMountain from '../../../../assets/images/sustainability/mountain.png';
import imageNature from '../../../../assets/images/sustainability/nature.png';
import { H2Small, H4, ParagraphLarge, ParagraphSmall } from '../../../texts';
import { BaseSection } from '../../../common/BaseSection';
import { notDesktopCondition } from '../../../../constants/media';
import { TrendBadge } from './TrendBadge';
import { paragraphLineHeightMobile } from '../../../../constants/text';
import { Coverage } from './Coverage';
import { INVERTED_SOCIAL_RESPONSIBILITY_TRENDS } from '@formue-app/core/src/constants/sustainability';

const {
  entities: {
    portfolioLenses: { socialResponsibilityTimelineSelector },
  },
} = selectors;

const Container = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${SPACING_24};
  flex-flow: column nowrap;
  gap: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  @media ${notDesktopCondition} {
    flex-flow: column;
    padding: 0;
  }
  background: ${accent.neutral150};
`;

const StyledModalSection = styled(ModalSection)`
  background-color: ${accent.sand1};
`;

const RowWrapper = styled.div`
  display: flex;
  gap: ${SPACING_12};
  flex: 1;
  width: 100%;
`;

const LearnMoreItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${accent.sand1};
  padding-bottom: 0;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  overflow: hidden;
`;

const LearnMoreItemInner = styled.div`
  padding: ${SPACING_32};
  padding-bottom: 0;
`;

const LearnMoreItemTitle = styled(H4)`
  padding: ${SPACING_16} 0 ${SPACING_8} 0;
`;

const CounterBadge = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: ${accent.raspberry1};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SectionTitle = styled(H2Small)`
  padding-bottom: ${SPACING_8};
`;

const ImageWrapper = styled.div`
  display: grid;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const SocialResponsibilityAccordionContent = (props) => {
  const {
    socialResponsibilityData,
    benchmark,
    itemKey,
    i18nPrefix,
    trendSelector,
    ...rest
  } = props;

  const value = socialResponsibilityData[itemKey];
  const coverage = socialResponsibilityData[`${itemKey}Coverage`];
  const { global, domestic } = benchmark;

  const socialResponsibilityTimeline = useSelector(
    socialResponsibilityTimelineSelector
  );

  const isGlobalCompliantOrEvidence =
    itemKey === 'globalCompactCompliant' || itemKey === 'globalCompactEvidence';

  const learnMoreItems = [
    {
      title: t(`${i18nPrefix}:learnMore:slide1:title`),
      text: t(`${i18nPrefix}:learnMore:slide1:text`),
      image: (
        <img
          src={imageShakingHands}
          style={{ width: 120, justifySelf: 'center', alignSelf: 'end' }}
        />
      ),
    },
    ...(isGlobalCompliantOrEvidence
      ? [
          {
            title: t(`${i18nPrefix}:learnMore:slide2:title`),
            text: t(`${i18nPrefix}:learnMore:slide2:text`),
            image: (
              <img
                src={imageMountain}
                style={{ width: 150, justifySelf: 'flex-end' }}
              />
            ),
          },
        ]
      : []),
    {
      title: t(`${i18nPrefix}:learnMore:slide3:title`),
      text: t(`${i18nPrefix}:learnMore:slide3:text`),
      image: (
        <img
          src={imageNature}
          style={{ width: 280, justifySelf: 'flex-end', alignSelf: 'flex-end' }}
        />
      ),
    },
  ];

  return (
    <Container {...rest}>
      <RowWrapper>
        <StyledModalSection style={{ flex: 1 }} highlighted>
          <SustainabilityLineChart
            trendSelector={trendSelector}
            trendInPercentage={false}
            formatTrendValue={(trendValue) =>
              percentage(trendValue * 100, 2, false, false)
            }
            title={false}
            data={socialResponsibilityTimeline.map((item) => ({
              ...item,
              value: item[itemKey],
            }))}
            positiveTrend={(trendValue) =>
              INVERTED_SOCIAL_RESPONSIBILITY_TRENDS.includes(itemKey)
                ? trendValue <= 0
                : trendValue > 0
            }
          />
        </StyledModalSection>
        <StyledModalSection style={{ flex: 1 }} highlighted>
          <SectionTitle>
            {t('app:sustainability:accordions:comparisonWithOthers:title')}
          </SectionTitle>
          <ComparisonWithOthers
            chartColor={accent.ocean230}
            value={value * 100}
            global={global * 100}
            domestic={domestic * 100}
            i18nSelector={`${i18nPrefix}:comparisonText`}
            formatValue={(val) => percentage(val, 1, false, false)}
          />
        </StyledModalSection>
      </RowWrapper>

      <RowWrapper>
        {learnMoreItems.map((item, index) => (
          <LearnMoreItem>
            <LearnMoreItemInner>
              <CounterBadge>
                <ParagraphLarge
                  style={{
                    color: backgroundWhite,
                    lineHeight: 1,
                    fontSize: paragraphLineHeightMobile,
                    fontWeight: 600,
                  }}
                >
                  {index + 1}
                </ParagraphLarge>
              </CounterBadge>
              <LearnMoreItemTitle>{item.title}</LearnMoreItemTitle>
              <ParagraphSmall>{item.text}</ParagraphSmall>
            </LearnMoreItemInner>
            <div style={{ flex: 1 }}>
              <ImageWrapper>{item.image}</ImageWrapper>
            </div>
          </LearnMoreItem>
        ))}
      </RowWrapper>

      <Coverage
        coverage={coverage}
        style={{ marginTop: SPACING_8 }}
        backgroundColor={accent.sand1}
      />
    </Container>
  );
};
