import React, { useState } from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_24,
  SPACING_32,
  SPACING_8,
} from '../../../constants/spacing';
import { printCondition } from '../../../constants/media';

import { Button } from '../../buttons';
import { BaseSection } from '../../common/BaseSection';
import { SpriteIcon } from '../../common/SpriteIcon';
import { ReportingPortfolioFilter } from './ReportingPortfolioFilter';
import { ReportingPeriodFilter } from './ReportingPeriodFilter';
import { Modal } from '../../common/Modal';
import { H2, H6, ParagraphLarge } from '../../texts';
import { RadioButton } from '../../formElements';
import { ReportingStrategyFilter } from './ReportingStrategyFilter';
import { DataVerificationNotice } from './DataVerificationNotice';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  gap: ${SPACING_16};

  @media ${printCondition} {
    display: none;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
  list-style: none;
  padding: ${SPACING_16};
  margin: 0;
  border: 1px solid ${accent.ocean440};
  border-radius: ${BORDER_RADIUS_LARGE};
  margin-bottom: ${SPACING_24};
`;

const ListItem = styled.li`
  display: flex;
  gap: ${SPACING_24};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
  margin-bottom: ${SPACING_8};
`;

export const PeriodicReportingHeader = (props) => {
  const { onPrint } = props;
  const [modalOpen, setModalOpen] = useState();
  const [selected, setSelected] = useState(['pdf']);

  const options = [
    {
      icon: 'Document Single',
      label: t('app:portfolio:periodicReporting:pdfDownload'),
      value: 'pdf',
    },
  ];

  const handleExport = () => {
    if (selected[0] === 'pdf') {
      setModalOpen(false);
      onPrint();
    } else {
      console.log('Export to excel');
    }
  };

  return (
    <>
      <StyledBaseSection columns={12}>
        <ReportingStrategyFilter style={{ flex: 1, maxWidth: 270 }} />
        <ReportingPortfolioFilter style={{ flex: 1, maxWidth: 270 }} />
        <ReportingPeriodFilter style={{ flex: 1, maxWidth: 270 }} />

        <Button
          buttonstyle="tonal"
          icon={<SpriteIcon size={16} id="Share" navigational={true} />}
          style={{ marginLeft: 'auto' }}
          onClick={() => setModalOpen(true)}
        >
          {t('app:globals:export')}
        </Button>
      </StyledBaseSection>

      <DataVerificationNotice useBaseSection={true} />

      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        contentWidth={580}
        padding={SPACING_32}
        iconSize="12px"
      >
        <TitleWrapper>
          <H2>{t('app:portfolio:periodicReporting:saveAs')}</H2>
          <H6>{t('app:portfolio:periodicReporting:format')}</H6>
        </TitleWrapper>
        <List>
          {options.map((option, index) => (
            <ListItem
              onClick={() => setSelected([option.value])}
              key={`option-${index}`}
            >
              <SpriteIcon inverted={true} id={option.icon} />
              <ParagraphLarge>{option.label}</ParagraphLarge>
              <RadioButton
                id={`radio-${option.value}`}
                name={`radio-${option.value}`}
                checked={selected.some((item) => item === option.value)}
                style={{ marginLeft: 'auto' }}
              />
            </ListItem>
          ))}
        </List>

        <Button onClick={() => handleExport()}>
          {t('app:globals:export')}
        </Button>
      </Modal>
    </>
  );
};
