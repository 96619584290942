import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';

import { t, selectors, generateRiskText } from '@formue-app/core';

import { SPACING_8, SPACING_24, SPACING_16 } from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { mobileCondition } from '../../../constants/media';

import { BaseSection } from '../../common/BaseSection';
import { H6, Paragraph, ParagraphSmall } from '../../texts';
import { RiskToleranceChart } from './RiskToleranceChart';

const {
  entities: {
    investmentProfile: {
      lossAversionSelector,
      winningAppetiteSelector,
      riskProfileIdSelector,
    },
  },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_24};
  background: ${accent.sand140};
  display: flex;

  & > div {
    width: 50%;
  }

  @media ${mobileCondition} {
    flex-direction: column;

    & > div {
      width: 100%;

      &:last-child {
        margin-top: ${SPACING_24};
      }
    }
  }
`;

const StyledTitle = styled(Paragraph)`
  font-weight: 500;
  margin-top: ${SPACING_8};
  margin-bottom: ${SPACING_16};
`;

export const RiskTolerance = (props) => {
  const riskProfileId = useSelector(riskProfileIdSelector);
  const lossAversion = useSelector(lossAversionSelector);
  const winningAppetite = useSelector(winningAppetiteSelector);
  const text = generateRiskText(lossAversion, winningAppetite);
  const sentences = text.split('\n');

  if (isNil(riskProfileId)) return null;

  return (
    <StyledBaseSection {...props}>
      <div>
        <H6 style={{ color: accent.ocean470 }}>
          {t('app:wealthPlan:investmentProfile:sensitivityTitle')}
        </H6>
        <StyledTitle>{sentences[0]}</StyledTitle>
        <ParagraphSmall style={{ paddingBottom: SPACING_16 }}>
          {sentences[2]}
        </ParagraphSmall>
        <ParagraphSmall>{sentences[4]}</ParagraphSmall>
      </div>

      <div>
        <RiskToleranceChart />
      </div>
    </StyledBaseSection>
  );
};
