import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectors, t, useResource } from '@formue-app/core';

import { accent, backgroundMain } from '../../constants/colors';

import { GridElement } from '../../components/layout';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { SPACING_48, SPACING_16, SPACING_8 } from '../../constants/spacing';
import { PortfolioConstructionHeader } from '../../components/portfolio/lenses/construction/PortfolioConstructionHeader';
import { H2, Paragraph } from '../../components/texts';

import { ReactComponent as PyramidTop } from '../../assets/portfolio/pyramid-full-top.svg';
import { ReactComponent as PyramidMiddle } from '../../assets/portfolio/pyramid-full-middle.svg';
import { ReactComponent as PyramidBottom } from '../../assets/portfolio/pyramid-full-bottom.svg';
import { PortfolioConstructionSection } from '../../components/portfolio/lenses/construction/PortfolioConstructionSection';
import { PortfolioConstructionFooter } from '../../components/portfolio/lenses/construction/PortfolioConstructionFooter';
import { Modal } from '../../components/common/Modal';
import { FundInFund } from '../../components/portfolio/lenses/FundInFund';
import { CoverageReadMore } from '../../components/portfolio/lenses/CoverageReadMore';

const {
  entities: {
    securities: {
      allFunds,
      allSecurities,
      securitiesMetadataSelector,
      allStabilitySecurities,
      allStabilityFunds,
      allMarketGrowthSecurities,
      allMarketGrowthFunds,
      allAlternativeGrowthSecurities,
      allAlternativeGrowthFunds,
    },
  },
  ui: {
    portfolio: { assetGroupDataSelector },
  },
} = selectors;

const StyledCenteredActivityIndicator = styled(CenteredActivityIndicator)`
  margin-top: 50px;
`;

export const PortfolioConstructionPage = () => {
  const loading = useResource(['PORTFOLIOS/INDEX', 'SECURITIES/INDEX']);

  const metadata = useSelector(securitiesMetadataSelector);
  const assetGroupData = useSelector(assetGroupDataSelector);

  const { distinctCompanies = 0, distinctFunds = 0, coverage = 0 } = metadata;

  const allSFunds = useSelector(allStabilityFunds);
  const allSSecurities = useSelector(allStabilitySecurities);
  const allMVFunds = useSelector(allMarketGrowthFunds);
  const allMVSecurities = useSelector(allMarketGrowthSecurities);
  const allAVFunds = useSelector(allAlternativeGrowthFunds);
  const allAVSecurities = useSelector(allAlternativeGrowthSecurities);

  const funds = useSelector(allFunds);
  const securities = useSelector(allSecurities);

  const [modal, setModal] = useState('');

  if (loading) {
    return (
      <GridElement columns={12}>
        <StyledCenteredActivityIndicator
          columns={12}
          size={30}
          background={backgroundMain}
        />
      </GridElement>
    );
  }

  return (
    <>
      <PortfolioConstructionHeader
        distinctCompanies={distinctCompanies}
        distinctFunds={distinctFunds}
        funds={funds}
        securities={securities}
        columns={12}
        onClickFundInFundModal={() => {
          setModal('fundInFund');
        }}
      />
      <GridElement columns={12} style={{ marginTop: SPACING_16 }}>
        <H2 style={{ marginBottom: SPACING_8 }}>
          {t('app:portfolioConstruction:constructionTitle')}
        </H2>
        <Paragraph>
          {t('app:portfolioConstruction:constructionIngress')}
        </Paragraph>
      </GridElement>
      <PortfolioConstructionSection
        columns={4}
        icon={PyramidBottom}
        funds={allSFunds}
        securities={allSSecurities}
        marketValue={assetGroupData?.stability?.marketValue}
        partOfPortfolio={assetGroupData?.stability?.share}
        highlightColor={accent.velvet490}
        translationKey="stability"
      />
      <PortfolioConstructionSection
        columns={4}
        icon={PyramidMiddle}
        funds={allMVFunds}
        securities={allMVSecurities}
        marketValue={assetGroupData?.marketGrowth?.marketValue}
        partOfPortfolio={assetGroupData?.marketGrowth?.share}
        highlightColor={accent.velvet490}
        translationKey="marketGrowth"
      />
      <PortfolioConstructionSection
        columns={4}
        icon={PyramidTop}
        funds={allAVFunds}
        securities={allAVSecurities}
        marketValue={assetGroupData?.alternativeGrowth?.marketValue}
        partOfPortfolio={assetGroupData?.alternativeGrowth?.share}
        highlightColor={accent.velvet490}
        translationKey="alternativeGrowth"
      />
      <PortfolioConstructionFooter
        coverage={coverage}
        onClick={() => {
          setModal('coverage');
        }}
      />
      <Modal
        isOpen={modal.length ? true : false}
        onRequestClose={() => setModal('')}
        padding={SPACING_48}
      >
        {modal === 'fundInFund' && <FundInFund />}
        {modal === 'coverage' && <CoverageReadMore />}
        {/* {modal === 'carbonFootprint' && <CarbonFootprintReadMore />} */}
      </Modal>
    </>
  );
};
