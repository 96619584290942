import React from 'react';
import styled from 'styled-components';

import { SPACING_16, SPACING_32, SPACING_8 } from '../../../constants/spacing';
import { H4, ParagraphSmall } from '../../texts';

import { CollapsibleListItem } from '../../common/CollapsibleListItem';
import { GridElement } from '../../layout';
import { MarkdownContent } from '../../common/MarkdownContent';
import { t } from '@formue-app/core';

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  display: flex;
  border-bottom: 1px solid #e6e1da;
  border-radius: 0;
  padding: ${SPACING_16};
  margin-top: ${SPACING_32};
  align-items: center;
  background: none;

  &:hover {
    box-shadow: none;
  }

  & + .details {
    margin-top: 0;
    border-radius: 0;
    background: none;

    & > div {
      padding: ${SPACING_16} !important;
    }
  }
`;

const StyledParagraphSmall = styled(ParagraphSmall)`
  margin-bottom: ${SPACING_8};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const PortfolioDisclaimer = () => {
  return (
    <GridElement columns={12}>
      <StyledCollapsibleListItem
        details={
          <MarkdownContent components={{ p: StyledParagraphSmall }}>
            {t('app:portfolio:disclaimer:body')}
          </MarkdownContent>
        }
      >
        <H4>{t('app:portfolio:disclaimer:title')}</H4>
      </StyledCollapsibleListItem>
    </GridElement>
  );
};
