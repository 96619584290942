import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as PyramidIcon } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-small-icon.svg';

import {
  SPACING_64,
  SPACING_32,
  SPACING_24,
  SPACING_8,
} from '../../../constants/spacing';
import { mobileCondition, tabletCondition } from '../../../constants/media';
import { BaseSection } from '../../common/BaseSection';
import { Modal } from '../../common/Modal';
import { H2, Paragraph } from '../../texts';
import { PortfolioStructureContent } from './PortfolioStructureContent';
import { Button } from '../../buttons';
import { Link } from 'react-router-dom';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  align-items: center;
  padding: 52px 78px;
  flex-flow: row nowrap;
  margin-top: ${SPACING_8};

  @media ${tabletCondition} {
    position: relative;
    padding: ${SPACING_32};
  }

  @media ${mobileCondition} {
    position: relative;
    flex-flow: column;
    padding: 21px 18px;
    align-items: flex-start;
  }
`;

const StyledIconWrapper = styled.div`
  margin-right: ${SPACING_64};

  @media ${tabletCondition} {
    margin-right: ${SPACING_32};
  }

  @media ${mobileCondition} {
    position: absolute;
    top: 21px;
    left: 18px;
    margin-right: ${SPACING_24};
  }
`;

const StyledTextWrapper = styled.div`
  p {
    margin-top: ${SPACING_8};

    @media ${tabletCondition} {
      padding-right: 130px;
    }
  }
  @media ${mobileCondition} {
    margin-left: 120px;
  }
`;

const StyledButton = styled(Button)`
  margin-left: auto;

  @media ${tabletCondition} {
    position: absolute;
    right: ${SPACING_32};
    bottom: ${SPACING_32};
  }

  @media ${mobileCondition} {
    margin-left: 0;
    margin-top: ${SPACING_24};
    align-self: flex-end;
  }
`;

export const ExtendedReportingSummary = (props) => {
  const { columns } = props;
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <StyledBaseSection columns={columns}>
      <StyledIconWrapper>
        <PyramidIcon width={67} height={67} />
      </StyledIconWrapper>

      <StyledTextWrapper>
        <H2 as="h3">{t('app:portfolio:extendedReporting:summary:title')}</H2>
        <Paragraph>
          {t('app:portfolio:extendedReporting:summary:text')}
        </Paragraph>
      </StyledTextWrapper>

      <Link to="/extended-reporting" style={{ marginLeft: 'auto' }}>
        <StyledButton onClick={() => setModalIsOpen(true)}>
          {t('app:portfolio:extendedReporting:summary:buttonText')}
        </StyledButton>
      </Link>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel={t('app:portfolio:extendedReporting:summary:title')}
      >
        <PortfolioStructureContent />
      </Modal>
    </StyledBaseSection>
  );
};
