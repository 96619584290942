import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  formatBigMoney,
  getAssetClassName,
  isPartOfTheAssetClassGroup,
  percentage,
  selectors,
  sumAssetClassesByAssetGroup,
  t,
} from '@formue-app/core';

import {
  SPACING_8,
  SPACING_16,
  SPACING_88,
  SPACING_120,
  SPACING_48,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

import { H4 } from '../../texts';
import { GridElement, MainGrid } from '../../layout';

import { BaseSection } from '../../common/BaseSection';
import { ProductSectionLight } from '../../advisorsCorner/strategySimulator/ProductItems';
import { SlideHeader } from '../investmentStrategyExportComponents/SlideHeader';
import { SlideFooter } from '../investmentStrategyExportComponents/SlideFooter';

const Wrapper = styled(MainGrid)`
  flex: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: ${SPACING_120};
  margin-bottom: ${SPACING_88};
  max-width: 1232px;
`;

const AssetGroup = styled(GridElement)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  margin-bottom: auto;
  margin-top: ${SPACING_48};
`;

const AssetGroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${SPACING_8};
`;

const StyledProductSectionLight = styled(ProductSectionLight)``;

const StyledBaseSection = styled(BaseSection)`
  border: 1px solid ${accent.sand2};
  border-radius: 8px;
  padding: ${SPACING_16};
`;

const {
  entities: {
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsByStrategyIdSelector,
    },
    investmentStrategyProducts: {
      investmentStrategyProductsByStrategyIdSelector,
    },
    investmentStrategies: { investmentStrategyByIdSelector },
  },
} = selectors;

export const StrategyProducts = (props) => {
  const { title } = props;
  const { search } = useLocation();
  const strategyId = new URLSearchParams(search).get('strategyId');

  const strategy = useSelector(investmentStrategyByIdSelector(strategyId));
  const products = useSelector(
    investmentStrategyProductsByStrategyIdSelector(strategyId)
  );
  const assetAllocations = useSelector(
    investmentStrategyAssetAllocationsByStrategyIdSelector(strategyId)
  );

  const assetGroups = sumAssetClassesByAssetGroup(assetAllocations);

  return (
    <Wrapper>
      <SlideHeader
        title={title}
        items={[
          {
            title: t(
              'app:advisor:strategySimulator:assetAllocation:totalPortfolio'
            ),
            value: strategy
              ? formatBigMoney(strategy.totalPortfolio, false)
              : '–',
          },
          // {
          //   title: t(
          //     'advisor:strategySimulator:assetAllocation:formuePortfolio'
          //   ),
          //   value: formatBigMoney(10000000),
          // },
          // {
          //   title: t(
          //     'advisor:strategySimulator:assetAllocation:externalPortfolio'
          //   ),
          //   value: formatBigMoney(10000000),
          // },
        ]}
      />

      {assetGroups.length
        ? assetGroups.map((group, index) => (
            <AssetGroup columns={4} key={`asset-group-${index}`}>
              <AssetGroupHeader>
                <H4>{getAssetClassName(group.id)}</H4>
                <H4>{percentage(group.value * 100)}</H4>
              </AssetGroupHeader>
              {assetAllocations
                .filter((assetClass) =>
                  isPartOfTheAssetClassGroup(group.id, assetClass.assetClass)
                )
                .map((assetClass) => (
                  <StyledBaseSection>
                    <StyledProductSectionLight
                      item={assetClass}
                      strategy={strategy}
                      products={products.filter(
                        (item) => item.assetClass === assetClass.assetClass
                      )}
                      key={`section-${assetClass.assetClass}-${index}`}
                    />
                  </StyledBaseSection>
                ))}
            </AssetGroup>
          ))
        : null}

      <SlideFooter
        body={t('app:advisor:strategySimulator:export:portfolioDisclaimer')}
      />
    </Wrapper>
  );
};
