import React from 'react';
import styled from 'styled-components';
import { times } from 'lodash';
import { useSelector } from 'react-redux';

import { t, selectors } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { BORDER_RADIUS_SMALL } from '../../../constants/spacing';
import { H5 } from '../../texts';

const {
  entities: {
    investmentProfile: { lossAversionSelector },
  },
} = selectors;

const StyledChart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

const StyledItem = styled.div`
  width: 50px;
  border-radius: ${BORDER_RADIUS_SMALL};
`;

const StyledBar = styled.div`
  margin: 0 23px;
`;

const StyledLegend = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  justify-content: center;
`;

const StyledLegendText = styled(H5)`
  width: 80px;
  margin: 0 8px;
  text-align: center;
`;

export const RiskToleranceChart = (props) => {
  const { height = 125 } = props;
  const lossAversion = useSelector(lossAversionSelector);
  const numberOfMaxItems = Math.ceil(
    Math.max(lossAversion.value, 1 / lossAversion.value)
  );

  const itemSpacing = 4;
  const itemHeight = Math.round(
    (height - itemSpacing * numberOfMaxItems) / numberOfMaxItems
  );

  const renderBars = (value, color) => {
    const numberOfItems = Math.ceil(value);
    let remainingvalue = parseFloat(value);

    return times(numberOfItems, (index) => {
      const count = index + 1;

      remainingvalue = remainingvalue - (1 % count);
      let realItemheight = itemHeight;

      if (remainingvalue < 1) {
        realItemheight = itemHeight * remainingvalue;
      }

      return (
        <StyledItem
          key={index}
          style={{
            height: realItemheight,
            backgroundColor: color,
            marginTop: itemSpacing,
          }}
        />
      );
    });
  };

  const barOneValue =
    lossAversion.value < 1 ? 1 : parseFloat(lossAversion.value);
  const barTwoValue =
    lossAversion.value < 1 ? parseFloat(1 / lossAversion.value) : 1;

  return (
    <>
      <StyledChart style={{ height: height }}>
        <StyledBar>
          {renderBars(barOneValue, accent.ocean4).reverse()}
        </StyledBar>
        <StyledBar>
          {renderBars(barTwoValue, accent.ocean2).reverse()}
        </StyledBar>
      </StyledChart>
      <StyledLegend>
        <StyledLegendText>
          {t('app:wealthPlan:investmentProfile:thePain')}
        </StyledLegendText>
        <StyledLegendText>
          {t('app:wealthPlan:investmentProfile:theJoy')}
        </StyledLegendText>
      </StyledLegend>
    </>
  );
};
