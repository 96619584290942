import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import {
  generateSpeedometerCustomColors,
  riskAndLockinValueToText,
  selectors,
  t,
} from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_16,
  SPACING_48,
  SPACING_64,
  SPACING_88,
} from '../../../constants/spacing';

import {
  easeInOut,
  longAnimationTime,
  slideAnimationDelay,
} from '../../../constants/animation';
import { accent, backgroundWhite } from '../../../constants/colors';

import { SlideErrorMessage } from '../slideComponents/SlideErrorMessage';
import { H2, H3, ParagraphLarge } from '../../texts';
import { SpeedometerChart } from '../../graphs/SpeedometerChart';
import { UnwantedRisk } from '../../wealthPlan/investmentProfile/UnwantedRisk';
import { InvestmentPreferencesChoices } from '../slideComponents/investmentProfile/InvestmentPreferencesChoices';
import { Question } from '../slideComponents/Question';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const InvestmentPrfileWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 95px;
`;

const Item = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${SPACING_64} 72px;
`;

const Label = styled(H3)`
  margin-top: -22px;
  text-align: center;
  color: ${accent.ocean250};
`;

const Title = styled(H2)`
  padding: ${SPACING_48} 0 ${SPACING_16} 0;
  top: 0;
  opacity: 0;
  position: relative;
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: ${(props) => `${slideAnimationDelay}s`};
  color: ${accent.ocean250};

  ${(props) =>
    props.isActiveSlide &&
    css`
      opacity: 1;
      top: -25px;
    `}
`;

const Text = styled(ParagraphLarge)`
  text-align: center;
  white-space: pre-line;
  max-width: 430px;
  top: 0;
  opacity: 0;
  position: relative;
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: ${(props) => `${slideAnimationDelay + 0.1}s`};
  color: ${accent.ocean250};

  ${(props) =>
    props.isActiveSlide &&
    css`
      opacity: 1;
      top: -25px;
    `}
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  &:last-child {
    margin-right: 0;
  }
`;

const {
  entities: {
    investmentProfile: { investmentProfileHasContent },
    investmentProfile: { investmentStrategyRiskAndLockInSelector },
  },
} = selectors;

const RiskAndLockInSlideContent = (props) => {
  const { risk, lockIn, isActiveSlide } = props;

  return (
    <InvestmentPrfileWrapper>
      <Item>
        <SpeedometerChart
          value={isActiveSlide ? risk : 0}
          pieCount={3}
          padAngle={3}
          cornerRadius={4}
          startAngle={-90}
          endAngle={90}
          width={200}
          height={100}
          arrowPosition="top"
          colorScale={generateSpeedometerCustomColors(
            risk,
            accent.ocean2,
            accent.ocean1
          )}
          style={{ margin: '0 auto' }}
          animationDelay={slideAnimationDelay + 0.4}
          pointerColor={backgroundWhite}
        />
        <Label>{riskAndLockinValueToText(risk)}</Label>
        <Title isActiveSlide={isActiveSlide}>
          {t('app:presentation:investmentStrategy:riskProfile:riskLevel:title')}
        </Title>
        <Text isActiveSlide={isActiveSlide}>
          {t('app:presentation:investmentStrategy:riskProfile:riskLevel:body')}
        </Text>
      </Item>

      <Item>
        <SpeedometerChart
          value={isActiveSlide ? lockIn : 0}
          pieCount={3}
          padAngle={3}
          cornerRadius={4}
          startAngle={-90}
          endAngle={90}
          width={200}
          height={100}
          arrowPosition="top"
          colorScale={generateSpeedometerCustomColors(
            lockIn,
            accent.ocean2,
            accent.ocean1
          )}
          style={{ margin: '0 auto' }}
          animationDelay={slideAnimationDelay + 0.4}
          pointerColor={backgroundWhite}
        />
        <Label>{riskAndLockinValueToText(lockIn)}</Label>
        <Title isActiveSlide={isActiveSlide}>
          {t(
            'app:presentation:investmentStrategy:riskProfile:lockInLevel:title'
          )}
        </Title>
        <Text isActiveSlide={isActiveSlide}>
          {t(
            'app:presentation:investmentStrategy:riskProfile:lockInLevel:body'
          )}
        </Text>
      </Item>
    </InvestmentPrfileWrapper>
  );
};

const LegacyInvestmentProfileSlideContent = (props) => {
  const { isActiveSlide } = props;

  return (
    <InvestmentPrfileWrapper>
      <ContentWrapper>
        <Question
          isActiveSlide={isActiveSlide}
          bubbleColor={accent.ocean1}
          textColor={accent.ocean270}
          questionText={t(
            'app:presentation:annualReview:investmentProfile:question'
          )}
          style={{ marginBottom: SPACING_64 }}
          customAnimationDelay={slideAnimationDelay * 4}
        />
        <UnwantedRisk active={isActiveSlide} />
      </ContentWrapper>
      <ContentWrapper style={{ marginTop: SPACING_88 }}>
        <InvestmentPreferencesChoices isActiveSlide={isActiveSlide} />
      </ContentWrapper>
    </InvestmentPrfileWrapper>
  );
};

export const InvestmentProfileSlide = (props) => {
  const { isActiveSlide } = props;
  const hasContent = useSelector(investmentProfileHasContent);

  const investmentStrategyRiskAndLockIn = useSelector(
    investmentStrategyRiskAndLockInSelector
  );

  let risk = null;
  let lockIn = null;

  if (investmentStrategyRiskAndLockIn) {
    risk = investmentStrategyRiskAndLockIn.risk;
    lockIn = investmentStrategyRiskAndLockIn.lockIn;
  }

  if (!hasContent) {
    return <SlideErrorMessage />;
  }

  return (
    <Wrapper>
      {investmentStrategyRiskAndLockIn ? (
        <RiskAndLockInSlideContent
          risk={risk}
          lockIn={lockIn}
          isActiveSlide={isActiveSlide}
        />
      ) : (
        <LegacyInvestmentProfileSlideContent isActiveSlide={isActiveSlide} />
      )}
    </Wrapper>
  );
};
