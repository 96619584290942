import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  useResource,
  selectors,
  toLocaleDateString,
  formatMoney,
  filterList,
  t,
  localizeFloat,
  groupBy,
  getFlagsSelector,
} from '@formue-app/core';

import { SPACING_16, SPACING_8 } from '../../../constants/spacing';

import { PrintPageBreaker } from '../../common/PrintPageBreaker';
import { BaseSection } from '../../common/BaseSection';
import {
  StyledCell,
  StyledHeaderCell,
  StyledRow,
  TableList,
} from '../../lists';
import { useQueryState } from 'react-router-use-location-state';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { printCondition } from '../../../constants/media';
import { accent } from '../../../constants/colors';
import { Button } from '../../buttons';
import { H3, H4, ParagraphSmall, ParagraphXSmall } from '../../texts';
import {
  paragraphSmallLineHeight,
  paragraphSmallSize,
} from '../../../constants/text';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const StyledTableList = styled(TableList)`
  border-spacing: 0 !important;
`;

const LightStyledHeaderCell = styled(StyledHeaderCell)`
  @media ${printCondition} {
    font-size: 10px;
    vertical-align: bottom;
  }

  &:first-child {
    padding-left: ${SPACING_16};
    border-radius: 0;
  }

  &:last-child {
    padding-right: ${SPACING_16};
    border-radius: 0;
  }
`;

const LightStyledRow = styled(StyledRow)`
  &:nth-child(odd) {
    background: ${accent.sand150};
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
`;

const LightStyledCell = styled(StyledCell)`
  padding: ${SPACING_8};

  &:first-child {
    padding-left: ${SPACING_16};
    border-radius: 0;
  }

  &:last-child {
    padding-right: ${SPACING_16};
    border-radius: 0;
  }
  span {
    font-size: ${paragraphSmallSize};
    line-height: ${paragraphSmallLineHeight};
    font-weight: 400;
    text-align: left;
  }

  @media ${printCondition} {
    span,
    p {
      font-size: 10px;
      line-height: ${paragraphSmallLineHeight};
      vertical-align: bottom;
    }
  }
`;

const StyledBaseSection = styled(BaseSection)`
  @media ${printCondition} {
    padding: 0 !important;
    border: none;
    background: none;

    H4 {
      padding-bottom: ${SPACING_8};
      border-bottom: 2px solid ${accent.sand150};
    }
  }
`;

const {
  entities: {
    transactions: { transactionAllIdsSelector, transactionByIdSelector },
  },
} = selectors;

const TransactionsList = ({
  transactions,
  account,
  columns,
  expanded,
  setExpanded,
}) => {
  return (
    <>
      <PrintPageBreaker />
      <StyledBaseSection columns={12} key={`transactions-${account}`}>
        {account ? (
          <H3 style={{ marginBottom: SPACING_16 }}>{account}</H3>
        ) : null}
        <StyledTableList
          id="holdingsList"
          sortable={true}
          columns={columns}
          rows={transactions.slice(0, expanded ? transactions.length : 100)}
          rowComponent={LightStyledRow}
          keyExtractor={(item, index) => `asset-class-${index}`}
          stickyHeader={true}
        />

        {
          // Due to the large number of transactions on larger timeframes we limit the number of
          // transactions shown. The large number of rows makes the filters a bit unresponsive.
          transactions.length > 100 && !expanded ? (
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <Button
                onClick={() => setExpanded(true)}
                style={{
                  marginTop: SPACING_16,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                buttonstyle="tonal"
              >
                {t('app:portfolio:periodicReporting:transactions:showAll')}
              </Button>
            </div>
          ) : null
        }
      </StyledBaseSection>
    </>
  );
};

export const PeriodicTransactions = (props) => {
  const loading = useResource(['TRANSACTIONS/INDEX']);

  const [expanded, setExpanded] = useState(false);

  const { features } = useSelector(getFlagsSelector);

  useEffect(() => {
    // For print we want to show all transactions so we expand the list before print
    const handleBeforePrint = () => {
      setExpanded(true);
    };

    const handleAfterPrint = () => {
      setExpanded(false);
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);

  const [transactionType, setTransactionType] = useQueryState(
    'transactionType',
    ''
  );
  const [product, setProduct] = useQueryState('product', '');
  const filters = {
    type: {
      type: 'select',
      value: transactionType,
      onChange: setTransactionType,
      label: t('app:portfolio:transaction'),
    },
    product: {
      type: 'select',
      value: product,
      onChange: setProduct,
      label: t('app:portfolio:product'),
    },
  };

  const allIds = useSelector(transactionAllIdsSelector);
  const byId = useSelector(transactionByIdSelector);
  const [transactions] = filterList(allIds, byId, filters);

  const groupedTransactions = groupBy(transactions, 'account');

  // Define the table columns
  let columns = [
    {
      key: 'product',
      label: t('app:portfolio:product'),
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
      width: '30%',
    },
    {
      key: 'date',
      label: t('app:globals:date'),
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
      render: ({ date, currencyCode, currencyRate, isMultiCurrency }) => (
        <div>
          <ParagraphSmall>{toLocaleDateString(date)}</ParagraphSmall>
          {false && isMultiCurrency && currencyRate ? (
            <ParagraphXSmall style={{ marginTop: SPACING_8 }}>
              {currencyCode} {localizeFloat(currencyRate, 3)}
            </ParagraphXSmall>
          ) : null}
        </div>
      ),
      width: '10%',
      defaultSort: true,
    },
    {
      key: 'type',
      label: t('app:portfolio:type'),
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
      width: '10%',
    },
    {
      key: 'quantity',
      label: t('app:portfolio:shares'),
      render: ({ quantity: q }) => (q ? localizeFloat(q, 4, true, true) : ''),
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
      width: '10%',
    },
    {
      key: 'cost',
      label: t('app:portfolio:costPrice'),
      render: ({ cost, currencyCode, costCurrency, isMultiCurrency }) => (
        <div>
          <ParagraphSmall style={{ textAlign: 'right' }}>
            {formatMoney(cost, '', false, '', 2)}
          </ParagraphSmall>
          {false && isMultiCurrency && costCurrency ? (
            <ParagraphXSmall style={{ marginTop: SPACING_8 }}>
              {currencyCode} {formatMoney(costCurrency)}
            </ParagraphXSmall>
          ) : null}
        </div>
      ),
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
      width: '13%',
    },
    {
      key: 'amount',
      label: t('app:globals:amount'),
      render: ({ amount, currencyCode, amountCurrency, isMultiCurrency }) => (
        <div>
          <ParagraphSmall style={{ textAlign: 'right' }}>
            {formatMoney(amount, '', false, '', 2)}
          </ParagraphSmall>
          {false && isMultiCurrency && amountCurrency ? (
            <ParagraphXSmall style={{ marginTop: SPACING_8 }}>
              {currencyCode} {formatMoney(amountCurrency)}
            </ParagraphXSmall>
          ) : null}
        </div>
      ),
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
      width: '13%',
    },
    {
      key: 'realizedGainLoss',
      label: t('app:portfolio:lossGain'),
      render: ({
        realizedGainLoss,
        currencyCode,
        realizedGainLossCurrency,
        isMultiCurrency,
      }) => (
        <div>
          <ParagraphSmall style={{ textAlign: 'right' }}>
            {formatMoney(realizedGainLoss, '', false, '', 2)}
          </ParagraphSmall>
          {false && isMultiCurrency && realizedGainLossCurrency ? (
            <ParagraphXSmall style={{ marginTop: SPACING_8 }}>
              {currencyCode} {formatMoney(realizedGainLossCurrency)}
            </ParagraphXSmall>
          ) : null}
        </div>
      ),
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
      width: '13%',
    },
  ];

  return (
    <SectionWrapper>
      {loading ? (
        <CenteredActivityIndicator />
      ) : features?.accountFilterTester ? (
        Object.keys(groupedTransactions).map((account) => (
          <TransactionsList
            transactions={groupedTransactions[account]}
            account={account}
            columns={columns}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ))
      ) : (
        <TransactionsList
          transactions={transactions}
          columns={columns}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      )}
    </SectionWrapper>
  );
};
