import React from 'react';
import styled from 'styled-components';

import { formatBigMoney, t } from '@formue-app/core';

import { ButtonSmall } from '../buttons';
import { BaseSection } from '../common/BaseSection';
import { H3, ParagraphXSmall } from '../texts';
import { SPACING_48, SPACING_24, SPACING_8 } from '../../constants/spacing';
import { accent } from '../../constants/colors';
import { H2Size } from '../../constants/text';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: ${SPACING_48};
  padding: ${SPACING_24};
`;

const Number = styled(H3)`
  font-size: ${H2Size};
`;

const Label = styled(ParagraphXSmall)`
  font-weight: 500;
  color: ${accent.ocean470};
  margin-bottom: ${SPACING_8};
`;

const i18nPrefix = 'app:advisor:strategySimulator:assetAllocation';

export const StrategyMarketValues = (props) => {
  const {
    strategy,
    strategyToolBeta,
    editMode,
    onClick = () => {},
    ...rest
  } = props;

  const formuePortfolio =
    strategy && strategy.externalPortfolio
      ? strategy.totalPortfolio - strategy.externalPortfolio
      : null;

  return (
    <StyledBaseSection {...rest}>
      <div>
        <Label>{t(`${i18nPrefix}:totalPortfolio`)}</Label>
        <Number>{formatBigMoney(strategy.totalPortfolio, false)}</Number>
      </div>
      {strategy?.externalPortfolio ? (
        <>
          <div>
            <Label>{t(`${i18nPrefix}:formuePortfolio`)}</Label>
            <Number>{formatBigMoney(formuePortfolio, false)}</Number>
          </div>
          <div>
            <Label>{t(`${i18nPrefix}:externalPortfolio`)}</Label>
            <Number>{formatBigMoney(strategy.externalPortfolio, false)}</Number>
          </div>
        </>
      ) : null}
      {strategyToolBeta ? (
        <ButtonSmall
          buttonstyle="outline"
          buttoncolorhover={accent.ocean220}
          onClick={onClick}
          style={{ marginLeft: 'auto' }}
        >
          {!editMode ? t('app:globals:edit') : t('app:globals:cancel')}
        </ButtonSmall>
      ) : null}
    </StyledBaseSection>
  );
};
