import React, { useEffect, useMemo, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import styled, { css } from 'styled-components';

import { config, t, usePrevious } from '@formue-app/core';

import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
  SPACING_24,
  SPACING_32,
  SPACING_40,
  SPACING_48,
  SPACING_8,
} from '../../constants/spacing';
import { accent, backgroundWhite } from '../../constants/colors';

import { Button } from '../buttons';
import { H1Small, H2, H4 } from '../texts';
import { ContentWrapper } from '../layout';
import { SpriteIcon } from '../common/SpriteIcon';
import { BackButton } from '../common/BackButton';
import { Tooltip } from '../common/Tooltip';
import { mobileCondition, tabletCondition } from '../../constants/media';
import { useMediaQuery } from 'react-responsive';

const onboardingAnimation = require('../../assets/lottie/onboarding2.json');

const StyledBackButton = styled(BackButton)`
  position: absolute;
  top: 79px;
  left: 40px;

  @media ${mobileCondition} {
    top: 55px;
    left: 16px;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  gap: ${SPACING_16};
  padding: 0 ${SPACING_24};

  @media ${mobileCondition} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Section = styled.div`
  padding: ${SPACING_32};
  background-color: ${accent.sand150};
  cursor: pointer;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  @media ${tabletCondition} {
    padding: ${SPACING_24};
  }

  @media ${mobileCondition} {
    padding: ${SPACING_16};
  }

  &:hover {
    background-color: ${backgroundWhite};
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${accent.ocean230};

      &:hover {
        background-color: ${accent.ocean230};
      }
    `}
`;

const StyledContentWrapper = styled(ContentWrapper)`
  flex-direction: column;
  align-items: center;
  background: radial-gradient(
    4600px 118% at bottom center,
    #eae2d7 0%,
    #eae2d7 50%,
    #f7f5f3 50%,
    #f7f5f3 100%
  );

  @media ${mobileCondition} {
    padding-top: 120px;

    background: radial-gradient(
      2500px 138% at bottom center,
      #eae2d7 0%,
      #eae2d7 50%,
      #f7f5f3 50%,
      #f7f5f3 100%
    );
  }
`;

const StyledH1Small = styled(H1Small)`
  margin-top: ${SPACING_24};
  margin-bottom: ${SPACING_48};
  text-align: center;
  max-width: 600px;
`;

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${SPACING_24};
  gap: ${SPACING_8};
`;

export const WealthRegistration = (props) => {
  const { onHandleNext, user, setUser } = props;

  const animationRef = useRef(null);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [activeSection, setActiveSection] = useState(0);
  const prevState = usePrevious({ activeSection });

  const isMobile = useMediaQuery({
    query: mobileCondition,
  });
  const rangeLookup = useMemo(
    () => ({
      '0-0': { startFrame: 0, endFrame: 29 },
      '0-1': { startFrame: 30, endFrame: 57 },
      '0-2': { startFrame: 62, endFrame: 87 },
      '0-3': { startFrame: 89, endFrame: 116 },
      '0-4': { startFrame: 118, endFrame: 146 },
      '0-5': { startFrame: 148, endFrame: 180 },
      '1-2': { startFrame: 182, endFrame: 206 },
      '1-3': { startFrame: 208, endFrame: 232 },
      '1-4': { startFrame: 234, endFrame: 263 },
      '1-5': { startFrame: 266, endFrame: 295 },
      '2-3': { startFrame: 297, endFrame: 327 },
      '2-4': { startFrame: 329, endFrame: 363 },
      '2-5': { startFrame: 365, endFrame: 399 },
      '3-4': { startFrame: 401, endFrame: 440 },
      '3-5': { startFrame: 441, endFrame: 480 },
      '4-5': { startFrame: 489, endFrame: 542 },
    }),
    []
  );

  const sections = useMemo(() => {
    const rangesLookup = [
      { value: 3000000 },
      { value: 8000000 },
      { value: 16000000 },
      { value: 35000000 },
      { value: 75000000 },
      { value: 100000000 },
    ];

    return t('app:login:registration:wealth:range', {
      returnObjects: true,
    }).map((item, index) => ({
      title: item,
      value: rangesLookup[index].value,
    }));
  }, []);

  useEffect(() => {
    setUser({
      ...user,
      potentialValue: sections[activeSection].value,
    });
  }, []);

  useEffect(() => {
    if (activeSection !== null && prevState?.activeSection !== activeSection) {
      const prevActiveSection = prevState?.activeSection || 0;

      const reverse = prevActiveSection > activeSection;

      let key = `${prevActiveSection}-${activeSection}`;
      if (reverse) {
        key = `${activeSection}-${prevActiveSection}`;
      }
      const { startFrame, endFrame } = rangeLookup[key];
      const section = [startFrame, endFrame];
      if (reverse) {
        section.reverse();
      }
      animationRef.current.playSegments(section, true);
    }
  }, [activeSection, prevState, rangeLookup]);

  return (
    <StyledContentWrapper position="center">
      <StyledBackButton />
      <Lottie
        lottieRef={animationRef}
        animationData={onboardingAnimation}
        style={{
          height: isMobile ? 200 : 400,
        }}
        loop={false}
        autoplay={false}
      />
      <StyledH1Small>
        {t('app:login:registration:wealth:title', {
          platform: t(
            `app:globals:platform:${config.app.platform.toLowerCase()}`
          ),
        })}
      </StyledH1Small>
      <InfoWrapper
        onMouseEnter={() => setOpenTooltip(true)}
        onMouseLeave={() => setOpenTooltip(false)}
      >
        <H4>{t('app:login:registration:wealth:body')}</H4>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Tooltip width={200} open={openTooltip}>
            {t('app:login:registration:wealth:tooltip')}
          </Tooltip>
          <SpriteIcon
            id="Info"
            size={16}
            inverted={false}
            navigational={true}
          />
        </div>
      </InfoWrapper>
      <SectionWrapper style={{ marginBottom: SPACING_40 }}>
        {sections.map((section, index) => {
          return (
            <Section
              key={index}
              active={activeSection === index}
              onClick={() => {
                setActiveSection(index);
                setUser({
                  ...user,
                  potentialValue: sections[index].value,
                });
              }}
            >
              <H2>{section.title}</H2>
            </Section>
          );
        })}
      </SectionWrapper>
      <Button
        style={{ marginBottom: SPACING_16, width: 320 }}
        disabled={typeof activeSection !== 'number'}
        onClick={() => onHandleNext(1)}
      >
        {t('app:login:registration:wealth:nextButton')}
      </Button>
      <Button buttonstyle="text" onClick={() => onHandleNext(1)}>
        {t('app:login:registration:wealth:notRelevant')}
      </Button>
    </StyledContentWrapper>
  );
};
