import React, { useEffect, useState } from 'react';
import { useQueryState } from 'react-router-use-location-state';

import { t } from '@formue-app/core';

import { ANNUAL_REVIEW_REPORT_CONTENT_SPACING } from '../../../constants/spacing';

import { useEnabledStrategies } from '../slides/useEnabledStrategies';

import { SelectDropdown } from '../../formElements';
import { DotIcon } from '../../common/DotIcon';

export const InvestmentStrategyStrategyPicker = (props) => {
  const strategies = useEnabledStrategies();

  const [selectedStrategy, setSelectedStrategy] = useState(strategies[0]);

  const [, setQueryParamsSelectedStrategy] = useQueryState(
    'selectedStrategies',
    ''
  );

  useEffect(() => {
    setQueryParamsSelectedStrategy(selectedStrategy?.id);
  }, [setQueryParamsSelectedStrategy, selectedStrategy]);

  if (!strategies.length) return null;

  return (
    <SelectDropdown
      options={strategies.map((strategy) => ({
        ...strategy,
        label: strategy.title,
        value: strategy.id,
      }))}
      icon={(item) => <DotIcon radius={8} color={item?.color} />}
      optionsIcon={(item) => <DotIcon radius={8} color={item?.color} />}
      selectedCallback={(options) => setSelectedStrategy(options[0])}
      subtitle={t('app:portfolio:portfolioStrategy:chooseStrategy')}
      style={{
        position: 'absolute',
        right: ANNUAL_REVIEW_REPORT_CONTENT_SPACING,
        top: -43,
        zIndex: 1,
      }}
      {...props}
    />
  );
};
