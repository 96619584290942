import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  formatHistoricalDrawdownChartData,
  percentage,
  selectors,
  t,
  useResource,
} from '@formue-app/core';

import { SPACING_88, SPACING_120 } from '../../../constants/spacing';
import { accent, backgroundWhite } from '../../../constants/colors';

import { SpaghettiChart } from '../../charts/SpaghettiChart';
import { MainGrid } from '../../layout';
import { BaseSection } from '../../common/BaseSection';
import { SlideHeader } from '../investmentStrategyExportComponents/SlideHeader';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';

const Wrapper = styled(MainGrid)`
  flex: 0;
  width: 100%;
  height: auto;
  padding: 0;
  margin-top: ${SPACING_120};
  margin-bottom: ${SPACING_88};
  grid-template-rows: max-content;
  max-width: 1232px;
`;

const StyledBaseSection = styled(BaseSection)`
  position: relative;
  display: flex;
  width: 100%;
  border: 1px solid ${accent.sand2};
  overflow: hidden;
  padding: 0;
  margin-top: 40px;
`;

const {
  entities: {
    investmentStrategyIndexChanges: {
      investmentStrategyIndexChangesByIdSelector,
      investmentStrategyIndexChangesMetadataByIdSelector,
    },
  },
} = selectors;

export const HistoricalDrawdown = (props) => {
  const { title, subTitle } = props;
  const { search } = useLocation();
  const strategyId = new URLSearchParams(search).get('strategyId');

  const loading = useResource([
    {
      type: 'INVESTMENT_STRATEGY_INDEX_CHANGES/INDEX',
      args: { strategyId },
    },
  ]);

  const investmentStrategyIndexChangesById = useSelector(
    investmentStrategyIndexChangesByIdSelector(strategyId)
  );

  const indexChangesMetadata = useSelector(
    investmentStrategyIndexChangesMetadataByIdSelector(strategyId)
  );

  const chartData = { 'Historic Return': investmentStrategyIndexChangesById };

  return (
    <Wrapper>
      {loading ? (
        <CenteredActivityIndicator />
      ) : (
        <>
          <SlideHeader
            title={title}
            subTitle={subTitle}
            items={[
              {
                title: t(
                  'app:advisor:strategySimulator:preferences:negativeYears'
                ),
                value:
                  indexChangesMetadata.historicalPerformance.negativeYears
                    .length,
              },
              {
                title: t(
                  'app:advisor:strategySimulator:preferences:lowestYearly'
                ),
                value: percentage(
                  indexChangesMetadata.historicalPerformance.lowestYearly * 100
                ),
              },
            ]}
          />

          <StyledBaseSection columns={12} style={{ position: 'relative' }}>
            <SpaghettiChart
              height={195}
              dataObject={chartData}
              valueKey="change"
              xAxisTicks={investmentStrategyIndexChangesById.map(
                (value) => new Date(value.changedAt)
              )}
              xAxisTicksFormat={(changedAt) => changedAt.getFullYear()}
              domainPadding={{ x: [0, -3], y: [10, 20] }}
              colorizeFunction={(entryKey, index) => {
                return accent.ocean490;
              }}
              labelFormat={(entryKey) => entryKey}
              ticksColor={accent.ocean170}
              ticksBackground={backgroundWhite}
              gridColor={accent.sand1}
              loading={!investmentStrategyIndexChangesById.length}
              tickCount={11}
              areaChart={true}
              areaChartOpacity={0.04}
              formatLineData={(data) => formatHistoricalDrawdownChartData(data)}
              interpolation="catmullRom"
              showLineLabels={false}
              xAxisStickToBottom={true}
              padding={{ bottom: 12, top: 5 }}
            />
          </StyledBaseSection>
        </>
      )}
    </Wrapper>
  );
};
