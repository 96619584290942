import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';
import { ParagraphSmall } from '../../../texts';
import {
  accent,
  backgroundWhite,
  hoverBoxShadowLvl2,
} from '../../../../constants/colors';
import { BaseSection } from '../../../common/BaseSection';
import { ReactComponent as IconPieChart } from '../../../../assets/icons/portfolio/lenses/pie-chart-icon.svg';
import {
  BORDER_RADIUS_LARGE,
  BOX_PADDING_VERTICAL,
  SPACING_32,
  SPACING_16,
  SPACING_8,
  SPACING_2,
} from '../../../../constants/spacing';
import { shortAnimationTime, easeInOut } from '../../../../constants/animation';

import BloombergLogo from '../../../../assets/images/bloomberg-logo-new.png';
import BurgissLogo from '../../../../assets/images/burgiss-logo.png';
import { LensesCoverageText } from '../LensesCoverageText';
import { SpriteIconFunctional } from '../../../common/SpriteIconFunctional';

const StyledContainer = styled(BaseSection)`
  padding: ${BOX_PADDING_VERTICAL} 0;
  margin-top: ${SPACING_32};
  align-items: center;
  background: transparent;
  text-align: center;
`;

const StyledListItem = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  gap: ${SPACING_16};
  width: 100%;
  padding: ${SPACING_16};
  flex-direction: row;
  align-items: center;
  margin: ${SPACING_2} 0;
  background-color: ${backgroundWhite};
  border-radius: ${BORDER_RADIUS_LARGE};
  transition: box-shadow ${shortAnimationTime} ${easeInOut};

  &:hover {
    box-shadow: ${hoverBoxShadowLvl2};
  }
`;

const StyledIcon = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background-color: ${accent.sand1};
  border-radius: 15px;
  margin-right: ${SPACING_2};
`;

const StyledImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: ${SPACING_8};
  justify-content: center;
`;

const StyledSeparator = styled.div`
  background-color: ${accent.neutral5};
  height: 27px;
  width: 1px;
  margin: 0 ${SPACING_16};
`;

export const PortfolioConstructionFooter = (props) => {
  const { coverage, onClick } = props;

  return (
    <StyledContainer columns={12}>
      <StyledImagesContainer>
        <img
          src={BloombergLogo}
          style={{ width: 100, height: 20 }}
          alt="Bloomberg logo"
        />
        <StyledSeparator />
        <img
          src={BurgissLogo}
          alt="Burgiss logo"
          style={{ width: 64, height: 26 }}
        />
      </StyledImagesContainer>
      <ParagraphSmall style={{ marginBottom: SPACING_32 }}>
        {t('app:portfolioConstruction:footer:text')}
      </ParagraphSmall>

      <StyledListItem onClick={onClick}>
        <IconPieChart />
        <LensesCoverageText coverage={coverage} />
        <StyledIcon>
          <SpriteIconFunctional
            id="Question mark"
            size={SPACING_16}
            color="#593F16"
            style={{ opacity: 0.7 }}
          />
        </StyledIcon>
      </StyledListItem>
    </StyledContainer>
  );
};
