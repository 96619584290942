import React from 'react';
import styled from 'styled-components';

import { t, usePortfolioDefaultFilters } from '@formue-app/core';

import { Toggle } from '../../formElements';
import { ParagraphSmall } from '../../texts';
import { SPACING_8 } from '../../../constants/spacing';

const StyledToggle = styled(Toggle)`
  & > div {
    margin-left: auto;
  }
`;

export const DefaultFilterControls = () => {
  const [isDefault, onChange] = usePortfolioDefaultFilters();

  return (
    <section
      style={{ display: 'flex', alignItems: 'center', padding: SPACING_8 }}
    >
      <ParagraphSmall style={{ marginRight: 'auto' }}>
        {t('app:filters:defaultFilters:saveAsDefault')}
      </ParagraphSmall>
      <StyledToggle
        value={isDefault}
        small={true}
        onChange={() => onChange(!isDefault ? true : null)}
      />
    </section>
  );
};
