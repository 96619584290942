import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Modal } from '../../common/Modal';
import { H2, H6, Paragraph } from '../../texts';

import {
  BORDER_RADIUS_LARGE,
  SPACING_48,
  SPACING_24,
  SPACING_16,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { Input, RadioButton } from '../../formElements';
import { CollapsibleListItem } from '../../common/CollapsibleListItem';
import { StrategyMatrix } from '../../common/StrategyMatrix';
import { ButtonSmall } from '../../buttons';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions,
  riskAndLockinValueToText,
  selectors,
  t,
  useResource,
} from '@formue-app/core';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledInput = styled(Input)`
  border: 1px solid ${accent.ocean440};
`;

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  display: flex;
  border: 1px solid ${accent.ocean440};
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: ${SPACING_16} 18px;
  align-items: center;
  padding-bottom: 12px;

  & + .details {
    border: 1px solid ${accent.ocean450};
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -13px;
    min-height: ${BORDER_RADIUS_LARGE} !important;
    display: block !important;

    & > div {
      padding-block: 12px !important;
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0;
  margin-right: auto;
`;

const Item = styled.div`
  display: flex;
  gap: ${SPACING_16};
  align-items: center;
  padding: 12px ${SPACING_24};
  cursor: pointer;
`;

const StyledRadioButton = styled(RadioButton)`
  margin-left: auto;
`;

const StyledButtonSmall = styled(ButtonSmall)`
  margin-right: auto;
`;

const {
  entities: { investmentStrategies: investmentStrategiesActions },
} = actions;

const {
  entities: {
    investmentProfile: { investmentStrategyRiskAndLockInSelector },
  },
} = selectors;

const AvailableStrategiesList = (props) => {
  const { selectedRisk, selectedLockIn, setSelectedRisk, setSelectedLockIn } =
    props;

  const availableStrategies = [];

  for (let risk = 0; risk <= 2; risk++) {
    for (let lockIn = 0; lockIn <= 3; lockIn++) {
      availableStrategies.push({ risk, lockIn });
    }
  }

  return (
    <>
      {availableStrategies.map((item, index) => (
        <Item
          key={`item-${index}`}
          onClick={() => {
            setSelectedRisk(item.risk);
            setSelectedLockIn(item.lockIn);
          }}
        >
          <StrategyMatrix
            risk={item.risk}
            lockin={item.lockIn}
            width={34}
            height={34}
          />
          <Paragraph>
            {t('app:advisor:strategySimulator:risk')}:{' '}
            {riskAndLockinValueToText(item.risk)},{' '}
            {t('app:advisor:strategySimulator:lockIn')}:{' '}
            {riskAndLockinValueToText(item.lockIn)}
          </Paragraph>

          <StyledRadioButton
            disabled={true}
            checked={
              selectedRisk === item.risk && selectedLockIn === item.lockIn
            }
          />
        </Item>
      ))}
    </>
  );
};

export const CreateStrategyModal = (props) => {
  const dispatch = useDispatch();
  const { onRequestClose, ...rest } = props;

  useResource(['INVESTMENT_PROFILES/INDEX']);
  const investmentStrategyRiskAndLockIn = useSelector(
    investmentStrategyRiskAndLockInSelector
  );

  const [strategyName, setStrategyName] = useState(null);
  const [selectedRisk, setSelectedRisk] = useState(null);
  const [selectedLockIn, setSelectedLockIn] = useState(null);

  useEffect(() => {
    if (investmentStrategyRiskAndLockIn) {
      setSelectedRisk(investmentStrategyRiskAndLockIn.risk);
      setSelectedLockIn(investmentStrategyRiskAndLockIn.lockIn);
    }
  }, [investmentStrategyRiskAndLockIn]);

  const saveStrategy = () => {
    dispatch(
      investmentStrategiesActions.createRequestBegin({
        data: {
          title: strategyName,
          account: '',
          risk: selectedRisk,
          'lock-in': selectedLockIn,
        },
      })
    );

    onRequestClose(false);
  };

  return (
    <Modal
      {...rest}
      onRequestClose={onRequestClose}
      contentWidth={580}
      padding={SPACING_48}
      iconSize="12px"
    >
      <Content>
        <H2>{t('app:advisor:strategySimulator:createForm:title')}</H2>
        <Label>
          <H6>{t('app:advisor:strategySimulator:createForm:name')}</H6>
          <StyledInput
            type="text"
            placeholder={t(
              'app:advisor:strategySimulator:createForm:namePlaceholder'
            )}
            onChange={(event) => {
              setStrategyName(event.target.value);
            }}
          />
        </Label>
        <Label>
          <H6>{t('app:advisor:strategySimulator:createForm:value')}</H6>
          <StyledInput
            type="text"
            placeholder={t(
              'app:advisor:strategySimulator:createForm:valuePlaceholder'
            )}
            onChange={(event) => {
              setStrategyName(event.target.value);
            }}
          />
        </Label>
        <Label>
          <H6>
            {t('app:advisor:strategySimulator:createForm:selectStrategy')}
          </H6>
          <StyledCollapsibleListItem
            details={
              <AvailableStrategiesList
                setSelectedRisk={setSelectedRisk}
                setSelectedLockIn={setSelectedLockIn}
                selectedRisk={selectedRisk}
                selectedLockIn={selectedLockIn}
              />
            }
          >
            <StrategyMatrix
              risk={selectedRisk}
              lockin={selectedLockIn}
              width={55}
              height={55}
            />
            <TextWrapper>
              <H6>
                {t('app:advisor:strategySimulator:createForm:reccommended')}
              </H6>
              <Paragraph>
                {t('app:advisor:strategySimulator:risk')}:{' '}
                {riskAndLockinValueToText(selectedRisk)},{' '}
                {t('app:advisor:strategySimulator:lockIn')}:{' '}
                {riskAndLockinValueToText(selectedLockIn)}
              </Paragraph>
            </TextWrapper>
          </StyledCollapsibleListItem>
        </Label>
        <StyledButtonSmall disabled={!strategyName} onClick={saveStrategy}>
          {t('app:advisor:strategySimulator:createForm:save')}
        </StyledButtonSmall>
      </Content>
    </Modal>
  );
};
