import React from 'react';
import styled from 'styled-components';
import {
  VictoryChart,
  VictoryScatter,
  VictoryAxis,
  VictoryZoomContainer,
} from 'victory';
import { maxBy, minBy } from 'lodash';

import { bodyFontStack } from '../../fonts';
import { accent } from '../../constants/colors';
import { H6 } from '../texts';
import { percentage, t } from '@formue-app/core';

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const LabelX = styled(H6)`
  position: absolute;
  bottom: 18.5%;
  left: 12%;
  background: ${accent.sand1};
  padding: 0 10px;
  text-align: center;
`;

const LabelY = styled(H6)`
  position: absolute;
  bottom: 22%;
  left: 98px;
  transform: rotate(-90deg);
  background: ${accent.sand1};
  padding: 0 10px;
  text-align: center;
  transform-origin: left;
`;

export const ScatterChart = (props) => {
  const {
    height = 300,
    selected = [],
    alwaysSelected = [],
    data = [],
    origin,
  } = props;
  let chartData = data;

  if (!data.length) return null;

  if (selected.length) {
    chartData = chartData.filter(
      (item) => selected.includes(item.key) || alwaysSelected.includes(item.key)
    );
  }

  // We want to zoom-in the chart, so the domain doesn't start from zero.
  // However, we want to "provide some space" around the actual dots when the chart is zoomed.
  // This constant controls the space around it.
  const DOMAIN_HEADROOM = 0.0075;

  // Calculate the min and max for both X and Y values in the charts data.
  // We will set our initial "zoomed domain" based on this.
  const maxX = maxBy(data, 'x').x;
  const maxY = maxBy(data, 'y').y;
  const minX = minBy(data, 'x').x;
  const minY = minBy(data, 'y').y;

  const maxDomainX = +maxX.toFixed(2) + DOMAIN_HEADROOM;
  const maxDomainY = +maxY.toFixed(2) + DOMAIN_HEADROOM;
  const minDomainX = +minX.toFixed(2) - DOMAIN_HEADROOM;
  const minDomainY = +minY.toFixed(2) - DOMAIN_HEADROOM;

  return (
    <ChartWrapper>
      <VictoryChart
        minDomain={0}
        maxDomain={{ x: maxDomainX, y: maxDomainY }}
        height={height}
        padding={{ top: 10, bottom: 60, left: 40, right: 40 }}
        containerComponent={
          <VictoryZoomContainer
            zoomDomain={{
              x: [origin.length ? origin[0] : minDomainX, maxDomainX],
              y: [origin.length ? origin[1] : minDomainY, maxDomainY],
            }}
            allowZoom={false}
            allowPan={false}
          />
        }
      >
        <VictoryAxis
          animate={{
            duration: 0,
          }}
          crossAxis={false}
          dependentAxis
          tickFormat={(tick) => percentage(tick * 100, 1, true, false)}
          style={{
            grid: {
              stroke: accent.ocean415,
              strokeDasharray: '0, 10, 100000',
            },
            axis: { stroke: accent.ocean440 },
            tickLabels: {
              fontSize: 5,
              fontWeight: 500,
              padding: 14,
              fill: accent.ocean440,
              fontFamily: bodyFontStack,
              textAnchor: 'start',
            },
          }}
        />
        <VictoryAxis
          animate={{
            duration: 0,
          }}
          padding={{ right: 10, left: 10 }}
          standalone={false}
          tickFormat={(tick) => percentage(tick * 100, 1, true, false)}
          style={{
            axis: {
              stroke: accent.ocean440,
            },
            tickLabels: {
              fontSize: 5,
              fontWeight: 500,
              padding: 5,
              fill: accent.ocean440,
              fontFamily: bodyFontStack,
              textAnchor: 'middle',
            },
          }}
        />

        <VictoryScatter
          style={{
            data: {
              fill: ({ datum }) => {
                return datum.color;
              },
            },
          }}
          size={8}
          data={chartData}
          y0={0}
        />
      </VictoryChart>
      <LabelX>
        {t('app:presentation:investmentStrategy:common:standardDeviation')}
      </LabelX>
      <LabelY>
        {t('app:presentation:investmentStrategy:common:returnInPercentage')}
      </LabelY>
    </ChartWrapper>
  );
};
