import React from 'react';
import styled, { css } from 'styled-components';

import { t } from '@formue-app/core';

import {
  SPACING_32,
  SPACING_24,
  SPACING_64,
  SPACING_8,
  BORDER_RADIUS_LARGE,
  SPACING_16,
} from '../../../constants/spacing';
import { accent, backgroundWhite } from '../../../constants/colors';

import { H3, Paragraph } from '../../texts';
import { SpriteIcon } from '../../common/SpriteIcon';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';
import { Button } from '../../buttons';

const StyledErrorMessage = styled.div`
  background-color: ${backgroundWhite};
  padding: ${SPACING_24} ${SPACING_64} ${SPACING_32} 74px;
  max-width: 460px;
  border: 1px solid ${accent.raspberry3};
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const StyledWarningIcon = styled(SpriteIcon)`
  position: absolute;
  left: 24px;
  top: 24px;
`;

const StyledSpriteIconFunctional = styled(SpriteIconFunctional)`
  position: absolute;
  right: ${SPACING_8};
  top: ${SPACING_8};
  cursor: pointer;
`;

const StyledList = styled.ul`
  padding: ${SPACING_8} 0 ${SPACING_24} 0;
  margin: 0;
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const StyledListText = styled(Paragraph)`
  font-weight: 500;
  color: ${accent.forest4};
  margin-left: ${SPACING_8};
  line-height: 100%;
  padding-bottom: 2px;

  ${(props) =>
    props.isError &&
    css`
      color: ${accent.raspberry4};
    `};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const renderIcon = (isError) => {
  if (isError) return <SpriteIcon id="Stop" size={SPACING_16} />;
  return <SpriteIcon id="OK" size={SPACING_16} />;
};

export const PresentationErrorMessage = ({
  resources = [],
  closeCallback = () => {},
  showButton = true,
}) => {
  return (
    <StyledErrorMessage>
      <StyledWarningIcon id="Warning" size={SPACING_32} />
      <StyledSpriteIconFunctional
        id="Close"
        size={16}
        onClick={() => {
          closeCallback();
        }}
      />
      <H3 style={{ marginBottom: 8 }}>
        {t('app:presentation:messages:errorMessage:title')}
      </H3>
      <Paragraph>
        {t('app:presentation:messages:errorMessage:description')}
      </Paragraph>
      <StyledList>
        {resources.map((item) => (
          <StyledListItem>
            {renderIcon(item.isError)}
            <StyledListText isError={item.isError}>{item.title}</StyledListText>
          </StyledListItem>
        ))}
      </StyledList>
      {showButton ? (
        <StyledButton
          label={t('app:presentation:messages:errorMessage:reload')}
          onClick={() => {
            window.location.reload(false);
          }}
        />
      ) : null}
    </StyledErrorMessage>
  );
};
