import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { SPACING_24, SPACING_8 } from '../../../constants/spacing';

import { List } from '../../common/List';
import { GridElement, MainGrid } from '../../layout';
import { H4, Paragraph } from '../../texts';

const StyledContainer = styled(MainGrid)`
  padding: 0;
  margin-top: ${SPACING_24};
`;

const Line = styled(GridElement)`
  margin: ${SPACING_8} 0;
  background-color: ${accent.sand370};
  height: 1px;
`;

const StyledList = styled(List)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

export const ProductCostContent = (props) => {
  const items = [
    {
      title: t('app:portfolio:costReport:productCost:list:title1'),
      content: t('app:portfolio:costReport:productCost:list:body1'),
    },
    {
      title: t('app:portfolio:costReport:productCost:list:title2'),
      content: t('app:portfolio:costReport:productCost:list:body2'),
      component: (
        <div>
          <H4 style={{ marginTop: SPACING_24, marginBottom: SPACING_8 }}>
            {t('app:portfolio:costReport:productCost:list:title3')}
          </H4>
          <List
            items={[
              {
                content: t('app:portfolio:costReport:productCost:list:body4'),
              },
              {
                content: t('app:portfolio:costReport:productCost:list:body5'),
              },
            ]}
          />
        </div>
      ),
    },
    {
      title: t('app:portfolio:costReport:productCost:list:title6'),
      content: t('app:portfolio:costReport:productCost:list:body6'),
    },
  ];

  return (
    <StyledContainer>
      <GridElement columns={8}>
        <Paragraph>{t('app:portfolio:costReport:productCost:intro')}</Paragraph>
      </GridElement>
      <Line columns={12} />
      <GridElement columns={8}>
        <StyledList style={{ margin: 0 }} items={items} />
      </GridElement>
    </StyledContainer>
  );
};
