import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';

import { t, selectors, generateRiskText } from '@formue-app/core';
import { SectionWithTitle } from '../../common/SectionWithTitle';
import { MainGrid } from '../../layout';
import { BaseSection } from '../../common/BaseSection';
import { H3, H6, ParagraphSmall } from '../../texts';
import { RiskToleranceChart } from './RiskToleranceChart';
import { ReactionList } from './ReactionList';
import { SPACING_8, SPACING_24, SPACING_16 } from '../../../constants/spacing';
import { RiskProfile } from './RiskProfile';

const {
  entities: {
    investmentProfile: {
      lossAversionSelector,
      winningAppetiteSelector,
      riskProfileIdSelector,
    },
  },
} = selectors;

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const StyledTitle = styled(H3)`
  padding: ${SPACING_8} 0 ${SPACING_24} 0;
`;

const StyledSentence = styled(ParagraphSmall)`
  padding-bottom: ${SPACING_16};
`;

export const RiskToleranceV1 = (props) => {
  const riskProfileId = useSelector(riskProfileIdSelector);
  const lossAversion = useSelector(lossAversionSelector);
  const winningAppetite = useSelector(winningAppetiteSelector);
  const text = generateRiskText(lossAversion, winningAppetite);
  const sentences = text.split('\n');

  return (
    <SectionWithTitle
      title={t('app:wealthPlan:investmentProfile:riskTolerance')}
      {...props}
    >
      <StyledSubGrid>
        {!isNil(riskProfileId) && (
          <>
            <BaseSection columns={12}>
              <StyledSubGrid>
                <BaseSection columns={6} style={{ padding: 0 }}>
                  <H6 style={{ opacity: 0.7 }}>
                    {t('app:wealthPlan:investmentProfile:sensitivityTitle')}
                  </H6>
                  <StyledTitle>{sentences[0]}</StyledTitle>
                  <StyledSentence>{sentences[2]}</StyledSentence>
                  <StyledSentence style={{ paddingBottom: 0 }}>
                    {sentences[4]}
                  </StyledSentence>
                </BaseSection>

                <BaseSection columns={6} style={{ padding: 0 }}>
                  <RiskToleranceChart />
                </BaseSection>
              </StyledSubGrid>
            </BaseSection>
            <BaseSection columns={6}>
              <ReactionList type="lossAversion" />
            </BaseSection>
            <BaseSection columns={6}>
              <ReactionList type="winningAppetite" />
            </BaseSection>
          </>
        )}
        <BaseSection columns={12}>
          <RiskProfile />
        </BaseSection>
      </StyledSubGrid>
    </SectionWithTitle>
  );
};
