import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { accent, textLightest } from '../../../constants/colors';
import {
  SPACING_48,
  SPACING_16,
  SPACING_8,
  SPACING_20,
} from '../../../constants/spacing';

import { GridElement, MainGrid } from '../../layout';
import { H4, Paragraph } from '../../texts';

import { SpriteIcon } from '../../common/SpriteIcon';

const StyledContainer = styled(MainGrid)`
  padding: 0;
  margin-top: ${SPACING_48};
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${SPACING_16};
  gap: ${SPACING_8};
`;

const Icon = styled.span`
  padding: ${SPACING_20};
  border-radius: 2px;
  background-color: ${accent.ocean1};
  color: ${textLightest};
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CostOverviewContent = (props) => {
  return (
    <StyledContainer>
      <GridElement columns={6}>
        <IconWrapper>
          <Icon>
            <SpriteIcon id="Investment" inverted={true} size={SPACING_20} />
          </Icon>
          <Icon style={{ background: '#99BBDE' }}>
            <SpriteIcon
              id="Time"
              inverted={true}
              navigational={true}
              size={SPACING_20}
            />
          </Icon>
        </IconWrapper>
        <H4 style={{ marginBottom: SPACING_8 }}>
          {t('app:portfolio:costReport:about:accountTitle')}
        </H4>
        <Paragraph>{t('app:portfolio:costReport:about:accountBody')}</Paragraph>
      </GridElement>
      <GridElement columns={6}>
        <IconWrapper>
          <Icon style={{ background: '#99BBDE', color: accent.ocean1 }}>
            kr
          </Icon>
          <Icon>%</Icon>
        </IconWrapper>
        <H4 style={{ marginBottom: SPACING_8 }}>
          {t('app:portfolio:costReport:about:percentTitle')}
        </H4>
        <Paragraph>{t('app:portfolio:costReport:about:percentBody')}</Paragraph>
      </GridElement>
    </StyledContainer>
  );
};
