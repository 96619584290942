import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import { t, selectors, useSelfDeclarationFormData } from '@formue-app/core';

import { GridElement } from '../../layout';
import {
  Formset,
  Fieldset,
  Checkbox,
  Textarea,
  DatePicker,
} from '../../formElements';

import { Paragraph, H3Small, H4, H5, H6, List, ListItem } from '../../texts';

import {
  SPACING_8,
  SPACING_24,
  SPACING_32,
  SPACING_48,
} from '../../../constants/spacing';
import { notDesktopCondition } from '../../../constants/media';
import { TaxIdForm } from './TaxIdForm';
import { CitizenshipFieldset } from './CitizenshipFieldset';
import { uiNegative } from '../../../constants/colors';
import { SpriteIcon } from '../../common/SpriteIcon';
import { Button } from '../../buttons';

const { operatingCountrySelector } = selectors.auth;

const StyledSubmissionConfirmation = styled.div`
  text-align: center;
  margin-top: ${SPACING_24};

  svg {
    margin-bottom: ${SPACING_24};
  }
`;

const StyledIntroText = styled(Paragraph)`
  height: 90px;
`;

const StyledHelpTextContainer = styled(GridElement)`
  margin-top: 90px;
  border: 1px solid #e6ddd1;
  padding: ${SPACING_48};

  > article {
    margin-bottom: ${SPACING_32};
  }
`;

const ErrorMessage = styled(Paragraph)`
  color: ${uiNegative};
  margin-top: ${SPACING_8};
`;

const HelpText = (props) => {
  const { text } = props;

  return (
    <article>
      <ReactMarkdown
        components={{
          p: Paragraph,
          h1: H3Small,
          h2: H4,
          h3: H5,
          h4: H6,
          ul: List,
          li: ListItem,
        }}
      >
        {text}
      </ReactMarkdown>
    </article>
  );
};

const SubmissionConfirmation = (props) => {
  return (
    <StyledSubmissionConfirmation>
      <SpriteIcon id="Task" size={100} />
      <ReactMarkdown
        components={{
          p: Paragraph,
          h1: H3Small,
          h2: H4,
        }}
      >
        {t('app:profile:selfDeclaration:confirmation')}
      </ReactMarkdown>
    </StyledSubmissionConfirmation>
  );
};

export const SelfDeclarationForm = (props) => {
  const isTabletOrMobile = useMediaQuery({
    query: notDesktopCondition,
  });

  const {
    primaryCitizenship,
    setPrimaryCitizenship,
    secondaryCitizenship,
    setSecondaryCitizenship,
    internationalResident,
    setInternationalResident,
    taxIdNumbers,
    setTaxIdNumbers,
    politicallyExposed,
    setPoliticallyExposed,
    politicallyExposedComment,
    setPoliticallyExposedComment,
    politicallyExposedEndedAt,
    setPoliticallyExposedEndedAt,
    showEndedAt,
    consent,
    setConsent,
    canSubmitForm,
    onSubmit,
    submitted,
  } = useSelfDeclarationFormData();

  const citizenhipProps = {
    primaryCitizenship,
    setPrimaryCitizenship,
    secondaryCitizenship,
    setSecondaryCitizenship,
  };

  const operatingCountry = useSelector(operatingCountrySelector);

  return (
    <>
      {submitted ? (
        <GridElement columns={isTabletOrMobile ? 12 : 6}>
          <SubmissionConfirmation />
        </GridElement>
      ) : (
        <>
          <GridElement columns={isTabletOrMobile ? 12 : 6}>
            <StyledIntroText>
              {t(`app:profile:selfDeclaration:introText`)}
            </StyledIntroText>

            <form id="self-declaration-form" onSubmit={onSubmit}>
              <CitizenshipFieldset {...citizenhipProps} />

              <Fieldset
                legend={t('app:profile:selfDeclaration:internationalResident', {
                  context: operatingCountry,
                })}
                helpText={
                  <HelpText
                    text={t('app:profile:selfDeclaration:fatcaHelpText')}
                  />
                }
              >
                <Checkbox
                  type="radio"
                  id="internationalYes"
                  name="international"
                  checked={internationalResident === true}
                  onChange={() => setInternationalResident(true)}
                  label={t('app:globals:yes')}
                />
                <Checkbox
                  type="radio"
                  id="internationalNo"
                  name="international"
                  checked={internationalResident === false}
                  onChange={() => setInternationalResident(false)}
                  label={t('app:globals:no')}
                />

                {internationalResident && (
                  <Formset
                    form={TaxIdForm}
                    style={{ padding: 0 }}
                    defaults={{
                      country: '',
                      taxIdNumber: '',
                    }}
                    addable={true}
                    addLabel={t('app:profile:selfDeclaration:addLabel')}
                    data={taxIdNumbers}
                    setData={setTaxIdNumbers}
                  />
                )}
              </Fieldset>

              <Fieldset
                legend={t('app:profile:selfDeclaration:politicallyExposed')}
                helpText={
                  <HelpText
                    text={t('app:profile:selfDeclaration:pepHelpText')}
                  />
                }
              >
                <Checkbox
                  type="radio"
                  id="politically-exposed-yes"
                  name="politically-exposed"
                  checked={politicallyExposed === true}
                  onChange={() => setPoliticallyExposed(true)}
                  label={t('app:globals:yes')}
                />
                <Checkbox
                  type="radio"
                  id="politically-exposed-no"
                  name="politically-exposed"
                  checked={politicallyExposed === false}
                  onChange={() => {
                    setPoliticallyExposed(false);
                    setPoliticallyExposedComment('');
                  }}
                  label={t('app:globals:no')}
                />

                {politicallyExposed && (
                  <Fieldset style={{ padding: 0 }}>
                    <Textarea
                      id="politically-exposed-comment"
                      name="politically-exposed-comment"
                      style={{
                        width: '100%',
                      }}
                      value={politicallyExposedComment}
                      label={t(
                        'app:profile:selfDeclaration:politicallyExposedCommentLabel'
                      )}
                      popoutLabel={true}
                      onChange={(event) =>
                        setPoliticallyExposedComment(event.target.value)
                      }
                    />
                  </Fieldset>
                )}
                {showEndedAt && (
                  <Fieldset style={{ padding: 0 }}>
                    <DatePicker
                      id="politically-exposed-ended-at"
                      name="politically-exposed-ended-at"
                      style={{
                        width: '100%',
                      }}
                      value={politicallyExposedEndedAt}
                      onChange={(event) =>
                        setPoliticallyExposedEndedAt(event.target.value)
                      }
                      label={t(
                        'app:profile:selfDeclaration:politicallyExposedEndedAtLabel'
                      )}
                      popoutLabel={true}
                      inputstyle="outline"
                    />
                  </Fieldset>
                )}
              </Fieldset>

              <Checkbox
                type="checkbox"
                id="consent"
                name="consent"
                checked={consent}
                onChange={() => setConsent(!consent)}
                label={`${t('app:profile:selfDeclaration:consent')} *`}
              />
              <br />

              <Button
                type="submit"
                form="self-declaration-form"
                disabled={!canSubmitForm}
              >
                {t('app:globals:confirm')}
              </Button>
              {!canSubmitForm && (
                <ErrorMessage>
                  {t('app:profile:selfDeclaration:invalidForm')}
                </ErrorMessage>
              )}
            </form>
          </GridElement>

          {!isTabletOrMobile && (
            <GridElement columns={6} as="aside">
              <StyledHelpTextContainer>
                <HelpText
                  text={t('app:profile:selfDeclaration:fatcaHelpText')}
                />
                <HelpText text={t('app:profile:selfDeclaration:pepHelpText')} />
              </StyledHelpTextContainer>
            </GridElement>
          )}
        </>
      )}
    </>
  );
};
