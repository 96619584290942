import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { Select } from '../../formElements';
import { GridElement } from '../../layout/GridElement';

import { SPACING_16 } from '../../../constants/spacing';
import { mobileCondition } from '../../../constants/media';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_16};
  width: 50%;

  @media ${mobileCondition} {
    justify-content: space-between;
    width: 100%;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;

  @media ${mobileCondition} {
    width: 50%;
  }
`;

const LoaderWrapper = styled.div`
  margin-top: 17px;
  width: 30px;

  @media ${mobileCondition} {
    display: none;
  }
`;

export const InvestmentRisk = (props) => {
  const {
    selectedRiskLevel,
    setSelectedRiskLevel,
    selectedAlternativeGrowthLevel,
    setSelectedAlternativeGrowthLevel,
    investmentRisksValues,
    alternativeGrowthLevelValues,
    loading,
  } = props;

  return (
    <GridElement columns="12">
      <Wrapper>
        <Item>
          <Select
            value={selectedRiskLevel}
            onChange={(e) => setSelectedRiskLevel(e.target.value)}
            label={t('app:wealthPlan:investmentSimulator:investmentRisk:risk')}
            options={investmentRisksValues}
            popoutLabel={true}
          />
        </Item>
        <Item>
          <Select
            value={selectedAlternativeGrowthLevel}
            onChange={(e) => setSelectedAlternativeGrowthLevel(e.target.value)}
            label={t('app:wealthPlan:investmentSimulator:investmentRisk:lock')}
            popoutLabel={true}
            options={alternativeGrowthLevelValues}
          />
        </Item>
        {loading ? (
          <LoaderWrapper>
            <CenteredActivityIndicator columns={12} size={30} />
          </LoaderWrapper>
        ) : null}
      </Wrapper>
    </GridElement>
  );
};
