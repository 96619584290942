import React from 'react';
import styled from 'styled-components';

import { t, useResource } from '@formue-app/core';

import {
  accent,
  backgroundWhite,
  textLightest,
} from '../../../constants/colors';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_24,
  SPACING_8,
} from '../../../constants/spacing';
import { SummaryBoxClickable } from '../../common/SummaryBox';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { SpriteIcon } from '../../common/SpriteIcon';
import { ConstructionText } from '../lenses/construction/ConstructionText';

import backgroundImage from '../../../assets/portfolio/portfolio-construction-background.png';

const StyledSummaryBox = styled(SummaryBoxClickable)`
  margin-top: ${SPACING_8};
  margin-bottom: -48px;

  .content-wrapper {
    background: ${accent.velvet4} url(${backgroundImage}) no-repeat -178px center;
    background-size: cover;
    height: 148px !important;
    padding-top: 20px;
    border-bottom-left-radius: ${BORDER_RADIUS_EXTRA_LARGE};
    border-bottom-right-radius: ${BORDER_RADIUS_EXTRA_LARGE};
    padding: ${SPACING_16};
  }
  footer {
    background: transparent;
    position: relative;
    top: -48px;
    border-top: 1px solid ${accent.velvet1};

    a {
      color: ${textLightest};
    }
  }
`;

export const PortfolioConstructionSummary = (props) => {
  const { columns } = props;
  const loading = useResource(['SECURITIES/INDEX']);

  return (
    <StyledSummaryBox
      icon={<SpriteIcon id="Portfolio" size={24} />}
      iconSpacing={12}
      title={t('app:portfolio:portfolioConstruction')}
      titleFontSize={15}
      url="/portfolio/lenses/portfolio-construction"
      linkColor={textLightest}
      columns={columns}
    >
      {loading ? (
        <CenteredActivityIndicator loadingColor={backgroundWhite} />
      ) : (
        <ConstructionText fontSize={18} lineHeight={21} />
      )}
    </StyledSummaryBox>
  );
};
