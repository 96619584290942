import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { getFlagsSelector, selectors, t, useResource } from '@formue-app/core';

import {
  accent,
  backgroundWhite,
  hoverBoxShadowLvl2,
} from '../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_24,
  SPACING_16,
} from '../../constants/spacing';
import { easeInOut, shortAnimationTime } from '../../constants/animation';

import { H3 } from '../texts';
import { CreateStrategyModal } from '../advisorsCorner/strategySimulator/CreateStrategyModal';
import { CenteredActivityIndicator } from '../common/ActivityIndicator';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';
import { StrategyListItem } from './StrategyListItem';

const StrategiesListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
  list-style: none;
  padding: 0;
  margin: 0;
`;
const StrategyCreateItem = styled.li`
  display: flex;
  align-items: center;
  background: ${backgroundWhite};
  border-radius: ${BORDER_RADIUS_LARGE};
  padding: ${SPACING_24};
  cursor: pointer;
  text-decoration: none;
  transition: box-shadow ${easeInOut} ${shortAnimationTime};

  &:hover {
    box-shadow: ${hoverBoxShadowLvl2};
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`;

const {
  entities: {
    investmentStrategies: { allInvestmentStrategiesSelector },
    investmentProfile: { investmentStrategyRiskAndLockInSelector },
  },
} = selectors;

export const StrategiesList = () => {
  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    'INVESTMENT_PROFILES/INDEX',
  ]);
  const investmentStrategyRiskAndLockIn = useSelector(
    investmentStrategyRiskAndLockInSelector
  );

  const strategies = useSelector(allInvestmentStrategiesSelector);
  const { features } = useSelector(getFlagsSelector);

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <StrategiesListWrapper>
        {loading ? (
          <CenteredActivityIndicator
            style={{ marginTop: SPACING_24, marginBottom: SPACING_24 }}
          />
        ) : (
          strategies
            .filter((item) => !item?.parent?.id)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((strategy) => (
              <StrategyListItem strategy={strategy} strategies={strategies} />
            ))
        )}
        {features.strategyToolBeta ? (
          <StrategyCreateItem
            style={{ background: accent.sand110 }}
            onClick={() => features.strategyToolBeta && setModalOpen(true)}
            disabled={!investmentStrategyRiskAndLockIn}
          >
            <H3 style={{ color: accent.ocean470, marginRight: 'auto' }}>
              {t('app:advisor:strategySimulator:addNew')}
            </H3>
            <SpriteIconFunctional id="Plus" />
          </StrategyCreateItem>
        ) : null}
      </StrategiesListWrapper>
      <CreateStrategyModal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      />
    </>
  );
};
