import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';

import { H1, H2, H3, H4, H5, H6, List, ListItem, Paragraph } from '../texts';
import { SPACING_24, SPACING_16, SPACING_8 } from '../../constants/spacing';

const StyledH1 = styled(H1)`
  margin-bottom: ${SPACING_24};
`;

const StyledH2 = styled(H2)`
  margin-bottom: ${SPACING_16};
`;

const StyledH3 = styled(H3)`
  margin-bottom: ${SPACING_16};
`;

const StyledH4 = styled(H4)`
  margin-bottom: ${SPACING_8};
`;

const StyledH5 = styled(H5)`
  margin-bottom: ${SPACING_8};
`;

const StyledH6 = styled(H6)`
  margin-bottom: ${SPACING_8};
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${SPACING_8};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MarkdownContent = ({ components, children }) => {
  return (
    <ReactMarkdown
      components={{
        h1: StyledH1,
        h2: StyledH2,
        h3: StyledH3,
        h4: StyledH4,
        h5: StyledH5,
        h6: StyledH6,
        p: StyledParagraph,
        ul: List,
        li: ListItem,
        ...components,
      }}
      remarkPlugins={[breaks]}
    >
      {children}
    </ReactMarkdown>
  );
};
