import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy } from 'lodash';

import {
  t,
  selectors,
  actions,
  sortStrategyType,
  useEntities,
  useStartegyOptions,
  truncateString,
} from '@formue-app/core';

import { H3, H6, Paragraph } from '../../texts';

import {
  accent,
  backgroundWhite,
  hoverBoxShadowLvl1,
  hoverBoxShadowLvl2,
} from '../../../constants/colors';
import {
  SPACING_24,
  SPACING_16,
  SPACING_8,
  SPACING_4,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../../constants/spacing';
import { mobileCondition } from '../../../constants/media';
import {
  H4LineHeight,
  H4Size,
  H6LineHeight,
  H6Size,
} from '../../../constants/text';
import { easeInOut, shortAnimationTime } from '../../../constants/animation';

import { SpriteIcon } from '../../common/SpriteIcon';
import { ProfileIcon } from '../../common/ProfileIcon';
import {
  FilledSelectStyle,
  OutlineSelectStyle,
  RadioButton,
  StyledInputBase,
} from '../../formElements';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${SPACING_4};
  max-width: 310px;
  margin-left: auto;

  @media ${mobileCondition} {
    width: 100%;
    max-width: 100%;
  }
`;

const Select = styled.div`
  ${StyledInputBase}
  ${(props) => props.inputstyle === 'filled' && FilledSelectStyle}
  ${(props) => props.inputstyle === 'outline' && OutlineSelectStyle}
  border-color: ${(props) => props.color};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_8};
  cursor: pointer;
  z-index: 1;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};

  p {
    color: ${(props) => props.textColor};
  }

  & > svg {
    transition: rotate ${easeInOut} ${shortAnimationTime};
    ${(props) =>
      props.position === 'top'
        ? css`
            rotate: ${(props) => (props.open ? '0deg' : '180deg')};
          `
        : css`
            rotate: ${(props) => (props.open ? '180deg' : '0deg')};
          `}
  }
  @media ${mobileCondition} {
    width: 100%;
    gap: ${SPACING_16};
  }

  &:hover:not(:disabled):not(:focus) {
    border-color: ${(props) => props.hoverColor};
    background-color: ${(props) => props.backgroundHoverColor};
  }
`;

const StyledSpriteIconFunctional = styled(SpriteIconFunctional)`
  margin-right: 3px;
  margin-left: auto;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_8};

  @media ${mobileCondition} {
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  background: ${backgroundWhite};
  padding: ${SPACING_24};
  position: absolute;
  width: 416px;
  right: 0;
  box-shadow: ${hoverBoxShadowLvl2};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  z-index: 3;

  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};

  ${(props) =>
    props.position === 'bottom'
      ? css`
          translate: 0 100%;
          bottom: -5px;
        `
      : css`
          top: -5px;
          translate: 0 -100%;
        `}

  @media ${mobileCondition} {
    width: 100%;
    box-shadow: ${hoverBoxShadowLvl1};

    & > h3 {
      text-transform: uppercase;
      font-size: ${H6Size};
      line-height: ${H6LineHeight};
    }
  }
`;

const Item = styled.div`
  border-bottom: 1px solid ${accent.sand3};
  padding-bottom: ${SPACING_24};

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  @media ${mobileCondition} {
    padding: ${SPACING_16} 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_16};

  @media ${mobileCondition} {
    & > h3 {
      /* text-transform: uppercase; */
      font-size: ${H4Size};
      line-height: ${H4LineHeight};
    }
  }
`;

const SubList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding-left: 48px;
  margin-top: ${SPACING_16};

  @media ${mobileCondition} {
    margin-top: ${SPACING_8};
  }
`;

const SubListItem = styled.li`
  margin-bottom: ${SPACING_16};
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }
`;

const {
  entities: {
    accounts: { allAccountsSelector },
  },
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

const {
  ui: { portfolio: portfolioActions },
} = actions;

export const formatedName = (list) => {
  return list.reduce(
    (text, value, i, array) =>
      text + (i < array.length - 1 ? ', ' : ' & ') + value
  );
};

export const activeStrategyAccountsName = (strategyAccounts, accounts) =>
  strategyAccounts.map(
    (id) => accounts.find((account) => account.id === id)?.name
  );

export const accountTypeIcon = (
  strategyAccounts,
  accounts,
  iconStyles = { navigational: true, inverted: true }
) => {
  const conditions = strategyAccounts
    .map((id) => accounts.find((account) => account.id === id))
    .map((item) => ({
      ssn: item?.socialSecurityNumber,
      orgNumber: item?.organizationNumber,
    }));

  if (conditions.length > 1) {
    const personCount = conditions.filter((item) => item.ssn);
    const orgCount = conditions.filter((item) => item.orgNumber);

    if (personCount.length > 0 && orgCount.length > 0) {
      return () =>
        SpriteIcon({
          id: 'Team',
          size: SPACING_16,
          inverted: iconStyles.inverted,
          navigational: iconStyles.navigational,
        });
    } else if (personCount.length === 0 && orgCount.length > 0) {
      return () =>
        SpriteIcon({
          id: 'Company',
          size: SPACING_16,
          inverted: iconStyles.inverted,
          navigational: iconStyles.navigational,
        });
    } else if (personCount.length > 0 && orgCount.length === 0) {
      return () =>
        SpriteIcon({
          id: 'Team',
          size: SPACING_16,
          inverted: iconStyles.inverted,
          navigational: iconStyles.navigational,
        });
    }
  } else {
    if (conditions[0].ssn) {
      return () =>
        SpriteIcon({
          id: 'Individual',
          size: SPACING_16,
          inverted: iconStyles.inverted,
          navigational: iconStyles.navigational,
        });
    } else if (conditions[0].orgNumber) {
      return () =>
        SpriteIcon({
          id: 'Company',
          size: SPACING_16,
          inverted: iconStyles.inverted,
          navigational: iconStyles.navigational,
        });
    } else {
      return () =>
        SpriteIcon({
          id: 'Individual',
          size: SPACING_16,
          inverted: iconStyles.inverted,
          navigational: iconStyles.navigational,
        });
    }
  }
};

export const StrategyPickerContent = (props) => {
  const {
    accounts,
    position,
    color,
    iconStyles,
    combinedStrategies,
    activeStrategy,
    setDryrunActiveStrategyId,
    setOpen = () => {},
    ...rest
  } = props;

  const dispatch = useDispatch();

  // To prevent an error where multiple instances of the same component where interfering with each
  // other we add an id on runtime to each component. This is related to the radio button component.
  const id = Math.floor(Math.random() * 10000);

  return (
    <ContentWrapper className="content" position={position} {...rest}>
      <H3>{t('app:portfolio:portfolioStrategy:chooseStrategy')}</H3>
      {Object.keys(combinedStrategies).length ? (
        Object.keys(combinedStrategies).map((key) => {
          const strategies = combinedStrategies[key];
          return (
            <Item className="item" key={`strategy-${key}`}>
              <Header>
                <ProfileIcon
                  size={32}
                  color={color}
                  CustomIcon={accountTypeIcon(
                    strategies[0].accounts,
                    accounts,
                    iconStyles
                  )}
                />
                <H3>
                  {formatedName(
                    activeStrategyAccountsName(strategies[0].accounts, accounts)
                  )}
                </H3>
              </Header>
              <SubList>
                {strategies.sort(sortStrategyType).map((strategy) => (
                  <SubListItem
                    key={`sub-item-${strategy.id}`}
                    onClick={() => {
                      setOpen(false);

                      if (!!setDryrunActiveStrategyId) {
                        setDryrunActiveStrategyId(strategy.id);
                      } else {
                        dispatch(portfolioActions.activeStrategy(strategy.id));
                      }
                    }}
                  >
                    <Paragraph style={{ marginRight: 'auto' }}>
                      {t(
                        `app:presentation:annualReview:portfolioStrategy:strategyTypes:${strategy.strategyType}`
                      )}
                    </Paragraph>
                    <RadioButton
                      id={`strategy-${strategy.id}-${id}`}
                      name={`strategy-${strategy.id}-${id}`}
                      checked={activeStrategy?.id === strategy.id}
                      onChange={() => {}}
                    />
                  </SubListItem>
                ))}
              </SubList>
            </Item>
          );
        })
      ) : (
        <CenteredActivityIndicator />
      )}
    </ContentWrapper>
  );
};

export const StrategyPicker = (props) => {
  const {
    color = accent.velvet190,
    hoverColor,
    backgroundHoverColor = accent.velvet490,
    backgroundColor,
    textColor = accent.velvet250,
    arrowColor = accent.velvet250,
    setDryrunStrategy,
    setDryrunActiveStrategyId,
    dryrunActiveStrategyId,
    position = 'bottom',
    popoutLabel = false,
    showProfileIcon = true,
    filterStrategies = true,
    label,
    ...rest
  } = props;
  const dispatch = useDispatch();

  useEntities(['ACCOUNTS/INDEX']);

  const isMobile = useMediaQuery({ query: mobileCondition });

  const accounts = useSelector(allAccountsSelector);
  const activeStrategyId = useSelector(activeStrategySelector);
  const allStrategies = useStartegyOptions(filterStrategies);
  const combinedStrategies = groupBy(allStrategies, 'accounts');

  const [open, setOpen] = useState(false);
  let [activeStrategy, setActiveStrategy] = useState(null);

  // Override strategyId if its passed in the queryParams
  useEffect(() => {
    const queryParams = new URLSearchParams(
      document.location.search.substring(1)
    );
    const queryStrategyId = queryParams.get('strategyId');

    // Check if all strategies are set to override strategyID at the right time
    // the setActiveStrategy saga that listnes to the strategies success call
    if (allStrategies.length) {
      if (queryStrategyId) {
        dispatch(portfolioActions.activeStrategy(queryStrategyId));
      } else {
        if (!allStrategies.find((item) => item.id === activeStrategyId)) {
          dispatch(portfolioActions.activeStrategy(allStrategies[0].id));
        }
      }
    }
  }, [allStrategies, dispatch]);

  // Some times we want to disconnect the strategy selector from the global store and provide our
  // own strategy handling. by providing custom dryrunfunction we can change strategies without
  // effecting the strategy stored in store
  useEffect(() => {
    if (!!setDryrunActiveStrategyId) {
      setDryrunActiveStrategyId(activeStrategyId);
    }
  }, [activeStrategyId, setDryrunActiveStrategyId]);

  useEffect(() => {
    if (!!setDryrunActiveStrategyId) {
      setActiveStrategy(
        allStrategies.find((strategy) => strategy.id === dryrunActiveStrategyId)
      );
      setDryrunStrategy(
        allStrategies.find((strategy) => strategy.id === dryrunActiveStrategyId)
      );
    } else {
      const strategy = allStrategies.find(
        (strategy) => strategy.id === activeStrategyId
      );

      // If we dont have a strategy awailable due to filtering of accounts
      // we set the first strategy as active
      // Whenever the active account is turned on again and active it will be the active strategy
      if (!strategy) {
        setActiveStrategy(allStrategies[0]);
      } else {
        setActiveStrategy(strategy);
      }
    }
  }, [
    allStrategies,
    activeStrategyId,
    setDryrunActiveStrategyId,
    dryrunActiveStrategyId,
    setDryrunStrategy,
  ]);

  if (!activeStrategy) {
    return null;
  }

  const strategyName = formatedName(
    activeStrategyAccountsName(activeStrategy.accounts, accounts)
  );
  const strategyType = t(
    `app:presentation:annualReview:portfolioStrategy:strategyTypes:${activeStrategy.strategyType}`
  );
  const truncateTitleLimit = 18;
  const truncateStrategyLimit = 5;

  let truncatedStrategyName = truncateString(strategyName, truncateTitleLimit);
  let remainingLen = Math.max(
    truncateTitleLimit + truncateStrategyLimit - truncatedStrategyName?.length,
    truncateStrategyLimit
  );
  let truncatedStrategyType = truncateString(
    strategyType,
    isMobile ? 25 : remainingLen
  );

  return (
    <Wrapper {...rest}>
      {popoutLabel && label && <H6>{label}</H6>}
      <Select
        onClick={() => setOpen(!open)}
        open={open}
        position={position}
        color={color}
        hoverColor={hoverColor}
        backgroundHoverColor={backgroundHoverColor}
        backgroundColor={backgroundColor}
        textColor={textColor}
        className="select"
        popoutLabel={popoutLabel}
        placeholder={label}
        inputstyle="outline"
      >
        {showProfileIcon ? (
          <ProfileIcon
            size={20}
            color={color}
            CustomIcon={accountTypeIcon(activeStrategy.accounts, accounts)}
            style={{ padding: 5 }}
          />
        ) : null}
        <TextWrapper>
          <Paragraph style={{ fontWeight: 500 }}>
            {truncatedStrategyName}
          </Paragraph>
          <Paragraph>{truncatedStrategyType}</Paragraph>
        </TextWrapper>
        <StyledSpriteIconFunctional
          id="Simple arrow"
          direction="Down"
          color={arrowColor}
          size={14}
        />
      </Select>
      {open ? (
        <StrategyPickerContent
          accounts={accounts}
          position={position}
          color={color}
          combinedStrategies={combinedStrategies}
          activeStrategy={activeStrategy}
          setDryrunActiveStrategyId={setDryrunActiveStrategyId}
          setOpen={setOpen}
        />
      ) : null}
    </Wrapper>
  );
};
