import React from 'react';
import styled from 'styled-components';

import { t, selectors } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_12,
  SPACING_24,
  SPACING_48,
} from '../../../constants/spacing';
import { contentMaxWitdh } from '../../../constants/grid';
import { accent, backgroundWhite } from '../../../constants/colors';

import { H2 } from '../../texts';

import { StrategyProductsTable } from '../../investmentStrategies/StrategyProductsTable';
import { InvestmentStrategyPreferences } from '../../investmentStrategies/InvestmentStrategyPreferences';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  background-color: ${accent.sand1};
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: ${contentMaxWitdh}px;
`;

const {
  entities: {
    investmentStrategyPreferences: {
      investmentStrategyPreferencesByStrategyIdSelector,
    },
  },
} = selectors;

export const StrategyAlternativeDetails = (props) => {
  const { strategyId } = props;

  const preferences = useSelector(
    investmentStrategyPreferencesByStrategyIdSelector(strategyId)
  );

  if (!preferences || preferences.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <H2 style={{ paddingBottom: SPACING_12 }}>
          {t('app:advisor:strategySimulator:preferences:title')}
        </H2>
        <InvestmentStrategyPreferences
          preferences={preferences}
          gap={SPACING_24}
          style={{ backgroundColor: backgroundWhite, border: 'none' }}
        />

        <H2 style={{ paddingTop: SPACING_48, paddingBottom: SPACING_12 }}>
          {t('app:advisor:strategySimulator:preferences:products')}
        </H2>
        <StrategyProductsTable strategyId={strategyId} displayProducts />
      </Container>
    </Wrapper>
  );
};
