import React, { useState } from 'react';
import styled from 'styled-components';

import { actions, t } from '@formue-app/core';

import {
  BORDER_RADIUS_LARGE,
  SPACING_24,
  SPACING_8,
} from '../../../constants/spacing';

import { Paragraph } from '../../texts';
import { Textarea } from '../../formElements';
import { useDispatch } from 'react-redux';
import { ButtonSmall } from '../../buttons';
import { whiteTranslucent } from '../../../constants/colors';

const StyledTextarea = styled(Textarea)`
  width: 100%;
  height: 150px;
  resize: vertical;
`;

const Form = styled.form`
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const StyledButtonSmall = styled(ButtonSmall)`
  margin-top: ${SPACING_8};
  margin-left: auto;
`;

const ThankYouWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${whiteTranslucent(0.4)};
  max-width: 450px;
  width: 100%;
  padding: ${SPACING_24};
  height: 246px;
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const StyledForm = styled(Form)`
  padding: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_LARGE};
  background: ${whiteTranslucent(0.4)};
`;

const {
  ui: {
    meeting: { sendFeedback },
  },
} = actions;

export const ContactForm = (props) => {
  const dispatch = useDispatch();

  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (message) {
      setSubmitted(true);

      dispatch(sendFeedback({ feedback: message }));
    }
  };

  return (
    <>
      {!submitted ? (
        <StyledForm onSubmit={(e) => handleSubmit(e)} {...props}>
          <StyledTextarea
            onChange={(e) => handleChange(e)}
            placeholder={t('app:report:annualReview:contact:form:placeholder')}
          />
          <StyledButtonSmall type="submit" disabled={!message}>
            {t('app:report:annualReview:contact:form:submit')}
          </StyledButtonSmall>
        </StyledForm>
      ) : null}

      {submitted ? (
        <ThankYouWrapper>
          <Paragraph style={{ fontWeight: 400 }}>
            {t('app:report:annualReview:contact:form:submitted')}
          </Paragraph>
        </ThankYouWrapper>
      ) : null}
    </>
  );
};
