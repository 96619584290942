import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { times } from 'lodash';

import { localizeFloat, selectors, t } from '@formue-app/core';
import { ReactComponent as PointerLineImage } from '../../../../assets/icons/pointer-line.svg';
import { H2, Paragraph, ParagraphSmall, ParagraphXSmall } from '../../../texts';
import { accent } from '../../../../constants/colors';
import {
  SPACING_32,
  SPACING_12,
  SPACING_8,
} from '../../../../constants/spacing';

const getBackgroundColor = (index) => {
  if (index < 16) return accent.forest2;
  if (index < 21) return accent.forest250;
  if (index < 33) return accent.raspberry250;
  return accent.raspberry370;
};

const i18nPrefix = 'app:sustainability:thermometer';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: ${(props) => props.height}px;
`;

const ThermometerShape = styled.div`
  outline: 2px solid ${accent.ocean4};
  border-radius: 12px;
  height: 100%;
  width: ${(props) => props.width}px;
  flex-direction: column-reverse;
  position: relative;
`;

const Scale = styled.div`
  height: 100%;
  left: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column-reverse;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  padding: 0;
`;

const ScalePoint = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  background-color: ${(props) => props.backgroundColor};
`;

const ScaleLine = styled.div`
  position: absolute;
  height: 1px;
  width: 8px;
  left: 0;
  top: 0;
  background-color: ${accent.ocean4};
  width: ${(props) => (props.isMajorPoint ? 13 : 8)}px;
`;

const Pointer = styled.div`
  position: absolute;
  left: -87px;
  width: 80px;
  justify-content: center;
  align-items: center;
`;
const PointerLine = styled.div`
  position: absolute;
  botttom: -1px;
  right: -30px;
  width: 60px;
  height: 1px;
  > svg {
    vertical-align: top;
  }
`;
const PointerText = styled.div`
  position: relative;
  left: -28px;
  bottom: -6px;
`;
const ParisAgreementPointer = styled.div`
  position: absolute;
  right: -120px;
  width: 100px;
  justify-content: center;
  align-items: center;
`;

const ParisAgreementPointerLine = styled.div`
  position: absolute;
  bottom: -1px;
  left: -37px;
  width: 60px;
  height: 1px;
  > svg {
    vertical-align: top;
  }
`;

const ParisAgreementPointerText = styled.div`
  bottom: -28px;
  right: -15px;
  position: relative;
`;

const ScaleNumbers = styled.div`
  position: absolute;
  left: -20px;
  top: 0;
  height: 100%;
  width: 10px;
  display: flex;
  flex-direction: column-reverse;
`;

const NumberPoint = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  flex: 1;
`;

const Number = styled(H2)`
  font-size: 12px;
  line-height: 15.6px;
  position: relative;
`;

export const Thermometer = (props) => {
  const {
    height = 282,
    width = 30,
    pointer = 2,
    parisAgreement = 2.0,
    ...rest
  } = props;

  // We need to calculate what is the spacing between each point on the scale
  // Scale consist out of 35 x 0.1 degrees points + additional 2 spacings (as a padding)
  const pointHeight = height / 37;
  const pointSpacing = pointHeight - 1;

  return (
    <Container height={height} {...rest}>
      <ThermometerShape width={width}>
        <Scale>
          {times(37, (index) => {
            const isMajorPoint = index % 10 === 0;
            return (
              <ScalePoint backgroundColor={getBackgroundColor(index)}>
                <ScaleLine isMajorPoint={isMajorPoint} />
              </ScalePoint>
            );
          })}
        </Scale>
        <ScaleNumbers>
          {times(37, (index) => {
            const isMajorPoint = index % 10 === 0;
            return (
              <NumberPoint>
                {isMajorPoint && (
                  <Number>{index === 0 ? '0' : index / 10}</Number>
                )}
              </NumberPoint>
            );
          })}
        </ScaleNumbers>

        <Pointer style={{ bottom: pointer * 10 * pointHeight + pointHeight }}>
          <PointerText>
            <ParagraphSmall style={{ paddingBottom: SPACING_8 }}>
              {`${t(`${i18nPrefix}:youAreHere`)}:`}
            </ParagraphSmall>
            <H2>{`${localizeFloat(pointer, 1, true)}°C`}</H2>
          </PointerText>
          <PointerLine>
            <PointerLineImage />
          </PointerLine>
        </Pointer>
        <ParisAgreementPointer
          style={{ bottom: parisAgreement * 10 * pointHeight }}
        >
          <ParisAgreementPointerText>
            <ParagraphSmall style={{ paddingBottom: 2 }}>
              {t(`${i18nPrefix}:parisAgreement`)}
            </ParagraphSmall>
            <ParagraphXSmall style={{ fontWeight: 500 }}>
              {t(`${i18nPrefix}:below2`)}
            </ParagraphXSmall>
          </ParisAgreementPointerText>
          <ParisAgreementPointerLine>
            <PointerLineImage />
          </ParisAgreementPointerLine>
        </ParisAgreementPointer>
      </ThermometerShape>
    </Container>
  );
};
