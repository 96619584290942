import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, t, useResource } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_48,
  SPACING_64,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { H6 } from '../../texts';
import { MatrixItem } from '../investmentStrategyComponents/MatrixItem';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';

const Base = styled.div`
  display: flex;
  flex: 1;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: ${SPACING_64};
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: ${SPACING_48};
`;

const Axis = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const LeftAxis = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  border-left: 1px solid ${accent.ocean440};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomAxis = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid ${accent.ocean440};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AxisLabel = styled(H6)`
  position: relative;
  top: 68px;
  background: ${accent.sand1};
  padding: 0 4px;
  text-align: center;
`;

const MatrixContainer = styled.div`
  display: flex;
  flex-flow: wrap-reverse;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${accent.ocean420};
  border-left: 1px solid ${accent.ocean420};
  box-sizing: border-box;
`;

const {
  entities: {
    investmentStrategies: { investmentStrategiesMetadataSelector },
    investmentProfile: { investmentStrategyRiskAndLockInSelector },
  },
} = selectors;

export const MatrixSlide = ({ isActiveSlide }) => {
  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    'INVESTMENT_PROFILES/INDEX',
  ]);

  const investmentStrategiesMetadata = useSelector(
    investmentStrategiesMetadataSelector
  );
  const riskAndLockIn = useSelector(investmentStrategyRiskAndLockInSelector);
  const { investmentStrategyMatrix } = investmentStrategiesMetadata;

  const matrix = [
    { risk: 0, lockIn: null },
    { risk: 1, lockIn: null },
    { risk: 2, lockIn: null },
    { risk: 0, lockIn: 0 },
    { risk: 1, lockIn: 0 },
    { risk: 2, lockIn: 0 },
    { risk: 0, lockIn: 1 },
    { risk: 1, lockIn: 1 },
    { risk: 2, lockIn: 1 },
    { risk: 0, lockIn: 2 },
    { risk: 1, lockIn: 2 },
    { risk: 2, lockIn: 2 },
  ];

  return (
    <Base>
      <Container>
        <Axis>
          <LeftAxis>
            <AxisLabel
              style={{
                transform: 'rotate(270deg)',
                top: 'auto',
                left: -122,
                width: 120,
              }}
            >
              {t('app:wealthPlan:investmentSimulator:investmentRisk:lock')}
            </AxisLabel>
          </LeftAxis>
          <BottomAxis>
            <AxisLabel>
              {t('app:wealthPlan:investmentSimulator:investmentRisk:risk')}
            </AxisLabel>
          </BottomAxis>
          <MatrixContainer>
            {loading ? (
              <CenteredActivityIndicator />
            ) : (
              matrix.map((item, index) => {
                const matrixItem = investmentStrategyMatrix.filter(
                  (i) => i.risk === item.risk && i.lockIn === item.lockIn
                )[0];

                const selected =
                  matrixItem?.risk === riskAndLockIn?.risk &&
                  matrixItem?.lockIn === riskAndLockIn?.lockIn;
                return (
                  <MatrixItem
                    {...matrixItem}
                    risk={item.risk}
                    lockIn={item.lockIn}
                    key={`matrix-item-${index}`}
                    index={index}
                    animate={isActiveSlide}
                    selected={selected}
                  />
                );
              })
            )}
          </MatrixContainer>
        </Axis>
      </Container>
    </Base>
  );
};
