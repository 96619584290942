import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  formatMoney,
  formatPieChartData,
  selectors,
  t,
} from '@formue-app/core';
import {
  SPACING_32,
  SPACING_24,
  SPACING_8,
  SPACING_4,
} from '../../../constants/spacing';
import { BaseSection } from '../../common/BaseSection';
import { TableListSmall } from '../../lists/TableListSmall';
import { H4, H6, ParagraphSmall } from '../../texts';
import { DotIcon } from '../../common/DotIcon';
import { PieChart } from '../../graphs/PieChart';
import { generateWealthBalanceAccentColors } from '../../../services/utils/colorize';

const {
  entities: {
    wealthBalance: { createWealthBalanceByGroupSelector },
  },
} = selectors;

const StyledContainer = styled(BaseSection)`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${SPACING_32};
`;

const StyledContentContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 60%;
  top: 0;
  left: 50%;
  translate: -50%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${SPACING_8};
`;

export const Finance = (props) => {
  const income = useSelector(createWealthBalanceByGroupSelector('Income'));

  const liquidity = useSelector(
    createWealthBalanceByGroupSelector('Liquidity')
  );

  // if there is no data, we don't want to render anything
  if (!income.items && !liquidity.items) {
    return null;
  }

  const accentColors = generateWealthBalanceAccentColors(
    income.items ? income.items.length : 0
  );

  const liabilityChartEntries = formatPieChartData(income.items, accentColors);

  let columns = [
    {
      key: 'name',
      width: '60%',
      render: ({ name, color }) => (
        <NameWrapper>
          <DotIcon radius={4} color={color} />
          <ParagraphSmall>{name}</ParagraphSmall>
        </NameWrapper>
      ),
    },
    {
      key: 'value',
      width: '40%',
      defaultSort: true,
      render: ({ value }) => (
        <ParagraphSmall style={{ fontWeight: 500 }}>
          {formatMoney(value)}
        </ParagraphSmall>
      ),
      type: 'number',
    },
  ];

  return (
    <StyledContainer>
      <div style={{ position: 'relative' }}>
        <PieChart
          size={180}
          innerRadius={70}
          padAngle={2}
          cornerRadius={2}
          data={liabilityChartEntries}
          style={{ margin: SPACING_24 }}
        />
        <StyledContentContainer>
          <H6 style={{ marginBottom: SPACING_4 }}>
            {t('app:wealthBalance:estimatedYearlyIncome')}
          </H6>
          <H4>{formatMoney(income.amount)}</H4>
        </StyledContentContainer>
      </div>

      <TableListSmall
        id="liabilityList"
        sortable={false}
        columns={columns}
        rows={liabilityChartEntries}
        keyExtractor={(item, index) => `liability-${index}`}
        style={{ marginBottom: 'auto' }}
      />
    </StyledContainer>
  );
};
