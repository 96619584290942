import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useCountUp } from 'react-countup';

import {
  t,
  formatMoney,
  selectors,
  useResource,
  useErrorState,
} from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { ANNUAL_REVIEW_CONTENT_SPACING } from '../../../constants/spacing';
import { H1 } from '../../texts';
import { WaterfallChart } from '../../graphs/WaterfallChart';
import { FinancialValuesFilter } from '../slideComponents/wealthDevelopment/FinancialValuesFilter';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { slideAnimationDelay } from '../../../constants/animation';
import { useQueryState } from 'react-router-use-location-state';
import { SlideErrorMessage } from '../slideComponents/SlideErrorMessage';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: 0;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const {
  entities: {
    portfolios: { portfolioTotalSelector },
    financialValues: { allfinancialValuesItemsSelector },
  },
} = selectors;

export const WealthDevelopmentSlide = (props) => {
  const { isActiveSlide, titleColor, highlightColor } = props;
  const loading = useResource(['FINANCIALVALUES/INDEX']);
  const error = useErrorState(['FINANCIALVALUES/INDEX']);
  const portfolioTotal = useSelector(portfolioTotalSelector);
  const financialValues = useSelector(allfinancialValuesItemsSelector);
  const [exportMode] = useQueryState('exportMode', false);

  const [years, setYears] = useState([]);
  const [activeYear, setActiveYear] = useState();
  const [animate, setAnimate] = useState();

  useEffect(() => {
    setYears(financialValues.map((item) => item.p));
    setActiveYear(financialValues[financialValues.length - 1]?.p);
  }, [financialValues]);

  const prevYearData = financialValues.find(
    (item) => item.p === activeYear - 1
  );
  const currentYearData = financialValues.find((item) => item.p === activeYear);

  const mv = portfolioTotal?.mv || 0;

  const mvCounter = useRef(null);

  const { start } = useCountUp({
    ref: mvCounter,
    start: 0,
    end: mv,
    duration: exportMode ? 0 : 3,
    useEasing: true,
    startOnMount: false,
    delay: exportMode ? 0 : slideAnimationDelay,
    formattingFn: formatMoney,
  });

  useEffect(() => {
    if (mv > 0 && isActiveSlide) {
      start();
    }
  }, [mv, start, isActiveSlide]);

  useEffect(() => {
    let timer = null;
    if (exportMode) {
      setAnimate(true);
    } else {
      if (isActiveSlide) {
        // Delay initial animation when entering the slide
        timer = setTimeout(() => {
          setAnimate(isActiveSlide);
        }, slideAnimationDelay * 1000 + 200);
      } else {
        setAnimate(isActiveSlide);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isActiveSlide, exportMode]);

  if (error) {
    return <SlideErrorMessage />;
  }

  return (
    <Wrapper>
      <ContentWrapper>
        <H1 style={{ color: accent[titleColor] }}>
          {t('app:presentation:annualReview:portfolioDevelopment:marketValue')}
        </H1>
        <H1 style={{ color: accent[highlightColor] }} ref={mvCounter}>
          0
        </H1>
      </ContentWrapper>
      <ContentWrapper style={{ height: '80%' }}>
        {loading ? (
          <CenteredActivityIndicator loadingColor={accent.ocean1} />
        ) : (
          <WaterfallChart
            total={{
              label: t(
                'app:presentation:annualReview:portfolioDevelopment:endValue'
              ),
              value: animate ? currentYearData?.mv : 0,
              color: '#7DBEFF',
            }}
            data={[
              {
                label: t(
                  'app:presentation:annualReview:portfolioDevelopment:startValue'
                ),
                value: animate ? prevYearData?.mv : 0,
                color: '#99BBDE',
              },
              {
                label: t(
                  'app:presentation:annualReview:portfolioDevelopment:depositsWithdrawals'
                ),
                value: animate
                  ? currentYearData?.deposits + currentYearData?.withdrawals
                  : 0,
                color: '#CCDDEF',
              },
              {
                label: t(
                  'app:presentation:annualReview:portfolioDevelopment:return'
                ),
                value: animate ? currentYearData?.gain : 0,
                color: '#45A2FF',
              },
            ]}
          />
        )}
        <FinancialValuesFilter
          setActiveYear={setActiveYear}
          activeYear={activeYear}
          years={years}
        />
      </ContentWrapper>
    </Wrapper>
  );
};
