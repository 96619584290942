import React from 'react';
import { Switch } from 'react-router-dom';

import { t } from '@formue-app/core';

import { RouteWithSubRoutes } from '../../components/router';
import { SubPageNavigation } from '../../components/common/SubPageNavigation';

export const PeriodicReportingOverviewPage = (props) => {
  const { routes, title, children } = props;

  return (
    <>
      <SubPageNavigation
        title={title}
        subTitle={t('app:portfolio:periodicReporting:subTitle')}
        routes={routes}
        beta={true}
      />
      {children}
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </>
  );
};
