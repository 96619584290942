import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import {
  SPACING_24,
  SPACING_40,
  BORDER_RADIUS_LARGE,
} from '../../constants/spacing';
import { accent } from '../../constants/colors';
import { H4, ParagraphSmall } from '../texts';
import { ButtonSmall } from '../buttons';
import { SpriteIcon } from './SpriteIcon';

const StyledErrorMessage = styled.div`
  display: flex;
  background-color: ${accent.sand250};
  padding: ${SPACING_40} 0;
  flex: 1;
  border-radius: ${BORDER_RADIUS_LARGE};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  padding: ${SPACING_24} 0;
  text-align: center;
  width: 440px;
`;

const StyledButton = styled(ButtonSmall)``;

export const ReloadPageMessage = ({
  title = t('app:presentation:messages:errorMessage:title'),
  text = t('app:presentation:messages:errorMessage:description'),
  buttonText = t('app:globals:reload'),
}) => {
  return (
    <StyledErrorMessage>
      <SpriteIcon id="Settings" navigational={true} size={32} />
      <TextWrapper>
        <H4 style={{ marginBottom: 8 }}>{title}</H4>
        <ParagraphSmall>{text}</ParagraphSmall>
      </TextWrapper>
      <StyledButton
        label={buttonText}
        onClick={() => {
          window.location.reload(false);
        }}
      />
    </StyledErrorMessage>
  );
};
