import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';
import { BaseSection } from '../../common/BaseSection';
import { TotalWealth } from './TotalWealth';
import { ButtonSmall } from '../../buttons';

import { SPACING_48, SPACING_24 } from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { mobileCondition, tabletCondition } from '../../../constants/media';

const StyledButtonSmall = styled(ButtonSmall)`
  align-self: flex-start;

  @media ${mobileCondition} {
    display: none;
  }
`;

const StyledWrapper = styled(BaseSection)`
  background-position: bottom;
  background-size: cover;
  padding: ${SPACING_48};

  @media ${mobileCondition} {
    padding: ${SPACING_24};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media ${tabletCondition} {
    flex-direction: row;
    justify-items: center;
  }
`;

export const TotalWealthExcerpt = (props) => {
  const { columns = 6, backgroundImage, to } = props;

  return (
    <StyledWrapper
      columns={columns}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <ContentWrapper>
        <TotalWealth />

        <StyledButtonSmall
          buttoncolor={accent.velvet250}
          buttoncolorhover={accent.velvet270}
          buttoncoloractive={accent.velvet250}
          textcolor={accent.velvet4}
          to={to}
        >
          {t('app:wealthBalance:readMore')}
        </StyledButtonSmall>
      </ContentWrapper>
    </StyledWrapper>
  );
};
