import React from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';

import { t } from '@formue-app/core';
import {
  TRANSITION_CHART_DATA,
  EXCLUSION_CHART_DATA,
} from '@formue-app/core/src/constants/sustainability';

import { accent } from '../../../../constants/colors';
import {
  SPACING_16,
  SPACING_8,
  SPACING_2,
  SPACING_32,
} from '../../../../constants/spacing';
import { CollapsibleListItem } from '../../../common/CollapsibleListItem';

import { H3, H4, H6, Paragraph, ParagraphSmall } from '../../../texts';
import { SustainabilityBaseItem } from './SustainabilityBaseItem';
import { StackedChart } from '../../../charts/StackedChart';
import { ProsAndCons } from './ProsAndCons';

const Label = styled(H6)`
  text-transform: uppercase;
  color: ${accent.ocean470};
  margin-bottom: ${SPACING_8};
`;

const Title = styled(H3)`
  margin-bottom: ${SPACING_16};
`;

const Description = styled(Paragraph)`
  margin-bottom: ${SPACING_16};
`;

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  background-color: ${accent.sand1};
  padding: 12px 15px;
  margin: ${SPACING_8} 0 -${SPACING_2} 0;
  width: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &:hover {
    box-shadow: none;
    background-color: #f2eee7;
  }
`;

const DetailsInner = styled.div`
  padding: ${SPACING_16};
`;

const StyledStackedChart = styled(StackedChart)`
  max-width: 600px;
  margin: 0 auto;
`;

const chartColorPalette = [
  accent.ocean170,
  accent.ocean1,
  accent.raspberry250,
  accent.raspberry3,
];

const colorizeChartData = (data) =>
  data.map((item, index) => ({ ...item, color: chartColorPalette[index] }));

export const EngagementStrategy = (props) => {
  const {
    engagementStrategy: engagementStrategyRaw,
    hasPreferences,
    ...rest
  } = props;

  // Default if user has no preferences
  let engagementStrategy = 'transition';

  if (hasPreferences && engagementStrategyRaw) {
    engagementStrategy = engagementStrategyRaw.toLowerCase();
  }

  let descriptions = [];
  const i18nPrefix =
    'app:wealthPlan:investmentProfile:sustainabilityPreferences:engagementStrategy';

  const isTranstition = engagementStrategy === 'transition';
  const isExclusion = engagementStrategy === 'exclusion';
  const isMix = engagementStrategy === 'mix';

  if (isMix) {
    descriptions = [
      t(`${i18nPrefix}:descriptionMix01`),
      t(`${i18nPrefix}:descriptionMix02`),
    ];
  }
  if (isTranstition) {
    descriptions = [t(`${i18nPrefix}:descriptionTransition`)];
  }
  if (isExclusion) {
    descriptions = [t(`${i18nPrefix}:descriptionExclusion`)];
  }

  const detailsStyle = {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: '#F2EEE7',
    padding: '6px 12px',
  };

  return (
    <SustainabilityBaseItem {...rest}>
      <Label>{t(`${i18nPrefix}:label`)}</Label>
      <Title>{t(`${i18nPrefix}:title${capitalize(engagementStrategy)}`)}</Title>
      {descriptions.map((description, index) => (
        <Description key={`description-${index}`}>{description}</Description>
      ))}

      {isTranstition && (
        <StyledStackedChart
          data={colorizeChartData(TRANSITION_CHART_DATA)}
          barWidth={90}
        />
      )}

      {isExclusion && (
        <StyledStackedChart
          data={colorizeChartData(EXCLUSION_CHART_DATA)}
          barWidth={90}
        />
      )}

      {!isMix && <ProsAndCons engagementStrategy={engagementStrategy} />}

      {isMix && (
        <div>
          <StyledCollapsibleListItem
            detailsPadding={0}
            details={
              <DetailsInner>
                <ParagraphSmall style={{ marginBottom: SPACING_32 }}>
                  {t(`${i18nPrefix}:descriptionTransition`)}
                </ParagraphSmall>
                <StyledStackedChart
                  data={colorizeChartData(TRANSITION_CHART_DATA)}
                />
                <ProsAndCons
                  engagementStrategy="transition"
                  style={{ paddingBottom: 0 }}
                />
              </DetailsInner>
            }
            detailsStyle={detailsStyle}
            arrowSize={10}
            expandedStyle={{ backgroundColor: '#F2EEE7' }}
          >
            <H4 style={{ marginRight: 'auto' }}>
              {t(`${i18nPrefix}:transition`)}
            </H4>
          </StyledCollapsibleListItem>
          <StyledCollapsibleListItem
            detailsPadding={0}
            details={
              <DetailsInner>
                <ParagraphSmall style={{ marginBottom: SPACING_32 }}>
                  {t(`${i18nPrefix}:descriptionExclusion`)}
                </ParagraphSmall>
                <StyledStackedChart
                  data={colorizeChartData(EXCLUSION_CHART_DATA)}
                />
                <ProsAndCons
                  engagementStrategy="exclusion"
                  style={{ paddingBottom: 0 }}
                />
              </DetailsInner>
            }
            detailsStyle={detailsStyle}
            arrowSize={10}
            expandedStyle={{ backgroundColor: '#F2EEE7' }}
          >
            <H4 style={{ marginRight: 'auto' }}>
              {t(`${i18nPrefix}:exclusion`)}
            </H4>
          </StyledCollapsibleListItem>
        </div>
      )}
    </SustainabilityBaseItem>
  );
};
