import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { t, selectors, useResource } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_24,
  SPACING_32,
  SPACING_40,
} from '../../../constants/spacing';

import { H2 } from '../../texts';
import { ShareChart } from '../../wealthPlan/investmentProfile/sustainabilityPreferences/ShareChart';
import { GridElement, MainGrid } from '../../layout';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { StackedChart } from '../../charts/StackedChart';
import {
  EXCLUSION_CHART_DATA,
  TRANSITION_CHART_DATA,
} from '@formue-app/core/src/constants/sustainability';

const {
  entities: {
    investmentProfile: { sustainabilityPreferencesSelector },
  },
} = selectors;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: ${SPACING_40};
`;

const Container = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
  grid-gap: 56px;
`;
const InnerContainer = styled(GridElement)``;

const GridWrapper = styled.div`
  width: 100%;
`;

const Content = styled.div`
  height: 100%;
  padding: ${SPACING_40};
  border: 1px solid ${accent.forest170};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
`;

const ChartWrapper = styled.div`
  padding: ${SPACING_32} 0;
`;

const Subtitle = styled(H2)`
  color: ${accent.forest250};
  margin-bottom: ${SPACING_24};
`;

const chartColorPalette = [
  accent.forest3,
  accent.forest1,
  accent.raspberry1,
  accent.raspberry3,
];

const colorizeChartData = (data) =>
  data.map((item, index) => ({ ...item, color: chartColorPalette[index] }));

const i18nPrefix = 'app:wealthPlan:investmentProfile:sustainabilityPreferences';

export const SustainabilityPreferences = (props) => {
  const sustainabilityPreferences = useSelector(
    sustainabilityPreferencesSelector
  );
  const loading = useResource(['INVESTMENT_PROFILES/INDEX']);

  if (!sustainabilityPreferences) return null;

  const {
    shareOfPortfolioToAllocate,
    engagementStrategy: engagementStrategyRaw,
  } = sustainabilityPreferences;

  const engagementStrategy = engagementStrategyRaw.toLowerCase();

  const isTranstition = engagementStrategy === 'transition';
  const isExclusion = engagementStrategy === 'exclusion';
  const isMix = engagementStrategy === 'mix';

  return (
    <Wrapper>
      {!loading ? (
        <GridWrapper>
          <Container>
            <InnerContainer columns={4}>
              <Content>
                <Subtitle>{t(`${i18nPrefix}:share:title`)}</Subtitle>
                <ChartWrapper>
                  <ShareChart
                    shareOfPortfolioToAllocate={shareOfPortfolioToAllocate}
                    textColor={accent.forest250}
                    showDescription={false}
                  />
                </ChartWrapper>
              </Content>
            </InnerContainer>
            <InnerContainer columns={8}>
              <Content>
                {isTranstition || isMix ? (
                  <>
                    <Subtitle>
                      {t(`${i18nPrefix}:engagementStrategy:titleTransition`)}
                    </Subtitle>
                    <ChartWrapper>
                      <StackedChart
                        barWidth={160}
                        barSpacing={8}
                        barHeight={12}
                        labelColor={accent.forest250}
                        data={colorizeChartData(TRANSITION_CHART_DATA)}
                      />
                    </ChartWrapper>
                  </>
                ) : null}
                {isExclusion || isMix ? (
                  <>
                    <Subtitle style={{ marginTop: isMix ? 40 : 0 }}>
                      {t(`${i18nPrefix}:engagementStrategy:titleExclusion`)}
                    </Subtitle>
                    <ChartWrapper>
                      <StackedChart
                        barWidth={160}
                        barSpacing={8}
                        barHeight={12}
                        labelColor={accent.forest250}
                        data={colorizeChartData(EXCLUSION_CHART_DATA)}
                      />
                    </ChartWrapper>
                  </>
                ) : null}
              </Content>
            </InnerContainer>
          </Container>
        </GridWrapper>
      ) : (
        <CenteredActivityIndicator columns={12} size={60} />
      )}
    </Wrapper>
  );
};
