import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { t, selectors } from '@formue-app/core';

import { Paragraph, ParagraphSmall } from '../../../texts';
import { SPACING_24, SPACING_8 } from '../../../../constants/spacing';
import { StretchedHorizontalBarChart } from '../../../graphs/StretchedHorizontalBarChart';
import { accent } from '../../../../constants/colors';

const {
  auth: { operatingCountrySelector },
} = selectors;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  max-width: ${(props) => props.maxWidth}px;
`;

const ChartWrapper = styled.div`
  padding: ${SPACING_24} 0;
`;

export const ComparisonWithOthers = (props) => {
  const {
    chartColor = accent.forest250,
    value = 0,
    global = 0,
    domestic = 0,
    i18nSelector,
    formatValue = (val) => Math.round(val * 10) / 10,
    maxWidth = 300,
  } = props;

  const operatingCountry = useSelector(operatingCountrySelector);

  const localText = value > domestic ? 'higher' : 'lower';

  const globalText = value > global ? 'higher' : 'lower';

  return (
    <Container>
      <Paragraph>
        {t(i18nSelector, {
          local: t(`app:globals:${localText}`),
          global: t(`app:globals:${globalText}`),
        })}
      </Paragraph>
      <Wrapper maxWidth={maxWidth}>
        <ChartWrapper style={{ paddingVertical: SPACING_8 }}>
          <StretchedHorizontalBarChart
            barWidth={32}
            color={chartColor}
            formatValue={formatValue}
            data={[
              {
                label: t('app:esg:localization:world'),
                value: global,
                delay: 200,
              },
              {
                label: t('app:esg:localization:your'),
                value: value,
                delay: 0,
              },
              {
                label: t('app:esg:localization:country', {
                  context: operatingCountry,
                }),
                value: domestic,
                delay: 100,
              },
            ]}
          />
        </ChartWrapper>
        <ParagraphSmall style={{ fontStyle: 'italic' }}>
          {t('app:esg:localization:index', {
            context: operatingCountry,
          })}
        </ParagraphSmall>
      </Wrapper>
    </Container>
  );
};
