import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { times, max } from 'lodash';

import { selectors, t, nearest } from '@formue-app/core';
import { ReactComponent as IconCoin } from '../../../assets/icons/coin-icon.svg';
import { ReactComponent as IconCoinZero } from '../../../assets/icons/coin-zero-icon.svg';
import { H3, H4, Paragraph, ParagraphSmall } from '../../texts';
import { backgroundWhite } from '../../../constants/colors';
import { BaseSection } from '../../common/BaseSection';
import { SPACING_48, SPACING_8 } from '../../../constants/spacing';
import { desktopCondition } from '../../../constants/media';

const {
  entities: {
    investmentProfile: { riskCapacityTimeHorizonSelector },
  },
} = selectors;

const COIN_BOTTOM_OFFSET = '18px';
const SPACING = '10px';
const MAX_NUMBER_OF_COINS = 10;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  padding: ${SPACING} ${SPACING_48};
  background-color: ${backgroundWhite};
`;

const StyledBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 34px;
  margin: ${SPACING} 0 28px 0;
`;

const StyledText = styled(ParagraphSmall)`
  @media ${desktopCondition} {
    width: 60%;
  }
`;

const moveLabelsStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
};

const barMoveLabelsStyles = {
  marginBottom: COIN_BOTTOM_OFFSET,
  marginTop: 0,
};

export const RiskChartV1 = ({ numberOfBars = 4, moveLabels, ...props }) => {
  const { years0To2, years3To5, years6To10, years10Plus } = useSelector(
    riskCapacityTimeHorizonSelector
  );

  const maxValue = max([years0To2, years3To5, years6To10, years10Plus]);
  const divisor = Math.ceil(maxValue / MAX_NUMBER_OF_COINS);
  const values = [
    {
      label: `0-2 ${t('app:filters:years').toLowerCase()}`,
      percentage: years0To2,
      value: nearest(years0To2 / divisor, 1),
    },
    {
      label: `2-5 ${t('app:filters:years').toLowerCase()}`,
      percentage: years3To5,
      value: nearest(years3To5 / divisor, 1),
    },
    {
      label: `5-10 ${t('app:filters:years').toLowerCase()}`,
      percentage: years6To10,
      value: nearest(years6To10 / divisor, 1),
    },
    {
      label: `10+ ${t('app:filters:years').toLowerCase()}`,
      percentage: years10Plus,
      value: nearest(years10Plus / divisor, 1),
    },
  ];

  const renderBars = () => {
    return values.map((item, index) => {
      const coins = renderCoins(item.value);
      if (index < numberOfBars) {
        return (
          <StyledBarWrapper
            style={moveLabels && moveLabelsStyles}
            key={`bar-${item.percentage}-${index}`}
          >
            {!moveLabels && (
              <H4>{`${item.percentage ? item.percentage : 0}%`}</H4>
            )}

            <StyledBar style={moveLabels && barMoveLabelsStyles}>
              {coins.reverse()}
            </StyledBar>

            <div
              style={
                moveLabels && {
                  flexDirection: 'column',
                  marginLeft: SPACING,
                }
              }
            >
              {moveLabels && (
                <H4>{`${item.percentage ? item.percentage : 0}%`}</H4>
              )}

              <Paragraph>{item.label}</Paragraph>
            </div>
          </StyledBarWrapper>
        );
      }
      return null;
    });
  };

  const renderCoins = (number) => {
    if (number === 0) {
      return [
        <div style={{ marginBottom: -19 }} key={'coins-0'}>
          <IconCoinZero width={34} key={number} />
        </div>,
      ];
    }
    return times(number, (index) => {
      return (
        <div
          style={{ marginBottom: -19, zIndex: index }}
          key={`coins-${index}`}
        >
          <IconCoin width={34} key={index} />
        </div>
      );
    });
  };

  return (
    <BaseSection {...props} style={{ maxHeight: 438 }}>
      <H3 style={{ paddingBottom: SPACING_8 }}>
        {t('app:wealthPlan:investmentProfile:riskCapacityChartTitle')}
      </H3>
      <StyledText>
        {t('app:wealthPlan:investmentProfile:riskCapacityChartInfo')}
      </StyledText>
      <StyledContainer>{renderBars()}</StyledContainer>
    </BaseSection>
  );
};
