import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { selectors } from '@formue-app/core';
import { H3 } from '../../../texts';
import { accent } from '../../../../constants/colors';

const {
  entities: {
    securities: { securitiesMetadataSelector },
  },
} = selectors;

export const ConstructionText = ({ fontSize = 24, lineHeight = 32, style }) => {
  const { distinctCompanies = 0, distinctFunds = 0 } = useSelector(
    securitiesMetadataSelector
  );

  return (
    <div style={style}>
      <H3
        style={{
          color: accent.velvet250,
          fontSize,
          lineHeight: `${lineHeight}px`,
        }}
      >
        <Trans
          i18nKey="app:portfolioConstruction:teaser"
          values={{
            companiesCount: distinctCompanies,
            fundsCount: distinctFunds,
          }}
          components={{
            strong: <span style={{ color: accent.velvet3, fontWeight: 500 }} />,
          }}
        />
      </H3>
    </div>
  );
};
