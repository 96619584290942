import React from 'react';
import styled from 'styled-components';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_24,
  SPACING_40,
} from '../../../constants/spacing';
import { contentMaxWitdh } from '../../../constants/grid';
import { CarbonFootprintModalContent } from '../../portfolio/lenses/sustainability/CarbonFootprintModalContent';
import { TemperatureRiseModalContent } from '../../portfolio/lenses/sustainability/TemperatureRiseModalContent';
import { SocialResponsibilityModalContent } from '../../portfolio/lenses/sustainability/SocialResponsibilityModalContent';
import { FundClassificationModalContent } from '../../portfolio/lenses/sustainability/FundClassificationModalContent';
import { t, useResource } from '@formue-app/core';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { H2 } from '../../texts';
import { ScienceBasedTargetsModalContent } from '../../portfolio/lenses/sustainability/ScienceBasedTargetsModalContent';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${contentMaxWitdh}px;
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  flex: 1;
  margin: 0 auto;
`;

const SectionTitle = styled(H2)`
  padding: ${SPACING_40} 0 ${SPACING_24} 0;
`;

export const SustainabilityLensDetails = (props) => {
  const loading = useResource(['PORTFOLIO_LENSES/INDEX']);

  return (
    <Wrapper>
      {loading && <CenteredActivityIndicator />}
      {!loading && (
        <>
          <SectionTitle style={{ paddingTop: 0 }}>
            {t('app:sustainability:carbonFootprintScreen:title')}
          </SectionTitle>
          <CarbonFootprintModalContent
            renderInPresentation={true}
            style={{ backgroundColor: 'transparent', paddingTop: 0 }}
          />

          <SectionTitle>
            {t('app:sustainability:temperatureRiseScreen:title')}
          </SectionTitle>
          <TemperatureRiseModalContent
            renderInPresentation={true}
            style={{ backgroundColor: 'transparent', paddingTop: 0 }}
          />

          <SectionTitle>
            {t('app:sustainability:scienceBasedTargetsScreen:title')}
          </SectionTitle>
          <ScienceBasedTargetsModalContent
            renderInPresentation={true}
            style={{ backgroundColor: 'transparent', paddingTop: 0 }}
          />

          <SectionTitle>
            {t('app:sustainability:socialResponsibility:title')}
          </SectionTitle>
          <SocialResponsibilityModalContent
            renderInPresentation={true}
            style={{ backgroundColor: 'transparent', paddingTop: 0 }}
          />

          <SectionTitle>
            {t('app:sustainability:fundClassification:title')}
          </SectionTitle>
          <FundClassificationModalContent
            renderInPresentation={true}
            style={{ backgroundColor: 'transparent', paddingTop: 0 }}
          />
        </>
      )}
    </Wrapper>
  );
};
