import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, t, formatMoney, useResource } from '@formue-app/core';
import { H2, H6 } from '../../texts';
import { SPACING_8 } from '../../../constants/spacing';
import { accent, backgroundWhite } from '../../../constants/colors';
import { PortfolioActivityIndicator } from './PortfolioActivityIndicator';

const {
  entities: {
    portfolios: { portfolioTotalSelector },
  },
} = selectors;

const StyledTitle = styled(H6)`
  color: ${accent.velvet2};
  margin-bottom: ${SPACING_8};
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const Wrapper = styled.div``;

export const PortfolioMarketValueTotal = (props) => {
  const {
    color = backgroundWhite,
    highlightColor = accent.ocean2,
    style,
    ...rest
  } = props;

  const portfolioTotal = useSelector(portfolioTotalSelector);
  const loading = useResource(['PORTFOLIOS/INDEX']);

  if (loading) {
    return (
      <PortfolioActivityIndicator
        style={style}
        highlightColor={highlightColor}
        size={24}
      />
    );
  }

  // If we have no data
  if (!portfolioTotal) {
    return (
      <Wrapper {...rest}>
        <StyledTitle>{t('app:errorMessages:noPortfolio:title')}</StyledTitle>
      </Wrapper>
    );
  }

  const { mv: totalValue } = portfolioTotal;

  // if there is no data, we don't want to render anything
  if (totalValue === 0) {
    return null;
  }

  return (
    <Wrapper {...rest}>
      <StyledTitle style={{ color: highlightColor }}>
        {t('app:portfolio:marketValue').toUpperCase()}
      </StyledTitle>
      <StyledRow>
        <H2 as="h3" style={{ color }}>
          {formatMoney(totalValue)}
        </H2>
      </StyledRow>
    </Wrapper>
  );
};
