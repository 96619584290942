import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  getConfigurationVariable,
  t,
  usePortfolioRelationshipFilter,
} from '@formue-app/core';

import { H2, H3, H4, H6, Paragraph } from '../components/texts';

import { ReportPage as ReportPage2020 } from './tax/no/2020/ReportPage';
import { ReportPage as ReportPage2021 } from './tax/no/2021/ReportPage';
import { ReportPage as ReportPage2022 } from './tax/no/2022/ReportPage';
import { ReportPage as ReportPage2023 } from './tax/no/2023/ReportPage';
import { ReportPage as ReportPage2024 } from './tax/no/2024/ReportPage';

import {
  DesktopWrapper,
  notDesktopCondition,
  printCondition,
  PrintWrapper,
} from '../constants/media';
import { SPACING_32, SPACING_8 } from '../constants/spacing';
import { Select } from '../components/formElements';
import { GridElement } from '../components/layout';
import { ButtonSmall } from '../components/buttons';
import { VisibleInCountry } from '../components/common/VisibleInCountry';
import { SpriteIcon } from '../components/common/SpriteIcon';

const HelpSection = styled.div`
  padding-top: 50px;
  text-align: center;
`;

const StyledGridElement = styled(GridElement)`
  @media ${notDesktopCondition} {
    grid-column: span 12;
  }

  @media ${printCondition} {
    display: none !important;
  }
`;

const ReportPage = (props) => {
  const { year, user } = props;

  // Prevent rendering report pages until we have both year and user.
  // We do this because some bug that happened in the report pages if
  // we used the useResource hook while "user" was undefined.
  if (!user || !year) {
    return null;
  }

  switch (year.toString()) {
    case '2020':
      return <ReportPage2020 year={year} user={user} />;
    case '2021':
      return <ReportPage2021 year={year} user={user} />;
    case '2022':
      return <ReportPage2022 year={year} user={user} />;
    case '2023':
      return <ReportPage2023 year={year} user={user} />;
    case '2024':
      return <ReportPage2024 year={year} user={user} />;
    default:
      return null;
  }
};

export const TaxReportPageBasePage = (props) => {
  const { year } = useParams();

  const { taxNumbersPublished } = getConfigurationVariable(
    'taxNumbersPublished'
  );

  const [activeYear, setActiveYear] = useState(year);
  const [activeUser, setActiveUser] = useState(null);

  const relationsshipsOptions = usePortfolioRelationshipFilter().map(
    (item) => ({
      value: item.ssid,
      label: item.name,
    })
  );

  const options = [
    { label: '2020', value: 2020, enabled: true },
    { label: '2021', value: 2021, enabled: true },
    { label: '2022', value: 2022, enabled: true },
    { label: '2023', value: 2023, enabled: true },
    // Enable this years tax report option only when nunmbers are ready
    { label: '2024', value: 2024, enabled: taxNumbersPublished },
  ];

  useEffect(() => {
    if (relationsshipsOptions.length && !activeUser) {
      setActiveUser(relationsshipsOptions[0].value);
    }
  }, [relationsshipsOptions, activeUser]);

  if (!relationsshipsOptions.length) {
    return null;
  }

  return (
    <>
      <GridElement columns={9}>
        <H2>Poster til Skattemelding {activeYear}</H2>
        <H3>for dine investeringer hos Formue</H3>
      </GridElement>
      <DesktopWrapper>
        <StyledGridElement columns={3} style={{ display: 'flex' }}>
          <ButtonSmall
            onClick={() => window.print()}
            style={{
              marginTop: SPACING_32,
              marginLeft: 'auto',
              marginBottom: 'auto',
            }}
            invert={true}
          >
            {t('app:globals:print')}
          </ButtonSmall>
        </StyledGridElement>
      </DesktopWrapper>
      <PrintWrapper>
        <H2 style={{ marginBottom: SPACING_32 }}>
          {
            relationsshipsOptions.find((item) => item.value === activeUser)
              ?.label
          }
        </H2>
      </PrintWrapper>

      <StyledGridElement columns={12} style={{ marginBottom: SPACING_32 }}>
        <H6 style={{ marginBottom: SPACING_8 }}>Velg kundeforhold</H6>
        <Select
          options={relationsshipsOptions}
          onChange={(e) => {
            setActiveUser(e.target.value);
          }}
          style={{ maxWidth: 300 }}
        />
      </StyledGridElement>

      <VisibleInCountry country={'no'}>
        <ReportPage year={activeYear} user={activeUser} />
      </VisibleInCountry>

      <StyledGridElement columns={12} style={{ marginTop: SPACING_32 }}>
        <Select
          style={{ width: 260 }}
          label="Se Tidligere års skatterapportering"
          popoutLabel={true}
          defaultValue={activeYear}
          options={options.filter((item) => item.enabled)}
          onChange={(e) => {
            window.history.replaceState(
              null,
              '',
              `/portfolio/reporting/tax/${e.target.value}`
            );
            window.scrollTo(0, 0);
            setActiveYear(e.target.value);
          }}
        />
      </StyledGridElement>

      <StyledGridElement columns={12}>
        <HelpSection>
          <SpriteIcon id="Contact Mail" navigational={true} />
          <Paragraph>Har du spørsmål?</Paragraph>
          <H4>
            Kontakt oss på
            <a href="mailto:kundenett@formue.no">kundenett@formue.no</a>
          </H4>
        </HelpSection>
      </StyledGridElement>
    </>
  );
};
