import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { actions as webauthnActions } from '../../store/webauthn/actions';
import { Button } from '../buttons';

const StyledButton = styled(Button)`
  width: 100%;
  border: none;
`;

export const PasskeyLogin = (props) => {
  const { className } = props;
  const dispatch = useDispatch();

  const handleLogin = (event) => {
    event.preventDefault();
    dispatch(webauthnActions.startAuthentication());
  };

  return (
    <div className={className}>
      <StyledButton
        onClick={handleLogin}
        label={t(`app:login:authMethods:passkeys`)}
      />
    </div>
  );
};
