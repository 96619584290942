import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { accent, textLightest } from '../../../../constants/colors';
import { SPACING_32, SPACING_24 } from '../../../../constants/spacing';
import { notDesktopCondition } from '../../../../constants/media';

import { BaseSection } from '../../../common/BaseSection';
import { PortfolioConstructionList } from '../../../portfolio/lenses/construction/PortfolioConstructionList';
import { ParagraphSmall } from '../../../texts';
import { PortfolioConstructionListHeader } from '../../../portfolio/lenses/construction/PortfolioConstructionListHeader';

const StyledBaseSection = styled(BaseSection)`
  @media ${notDesktopCondition} {
    grid-column: span 12;
  }
`;

export const PortfolioConstructionSection = (props) => {
  const {
    highlightColor,
    funds,
    securities,
    partOfPortfolio,
    marketValue,
    icon,
    translationKey,
    ...rest
  } = props;

  const empty = !marketValue;

  return (
    <StyledBaseSection {...rest} style={{ opacity: !empty ? 1 : 0.5 }}>
      <PortfolioConstructionListHeader
        Icon={icon}
        title={t(`app:portfolioConstruction:${translationKey}:title`)}
        percentBackgroundColor={accent.velvet1}
        percentColor={textLightest}
        percent={partOfPortfolio}
        amount={marketValue || 0}
        style={{ marginBottom: SPACING_24 }}
      />

      <ParagraphSmall>
        {t(`app:portfolioConstruction:${translationKey}:ingress`)}
      </ParagraphSmall>

      {!!funds.length && (
        <PortfolioConstructionList
          style={{
            padding: 0,
            paddingBottom: 0,
            marginTop: SPACING_32,
          }}
          data={funds.slice(0, 3)}
          title={t('app:portfolioConstruction:topFunds', {
            fundsCount: 3,
          })}
          titleColor={highlightColor}
          textColor={highlightColor}
          numberBackgroundColor={accent.ocean250}
          numberColor={accent.ocean4}
        />
      )}

      {!!securities.length && (
        <PortfolioConstructionList
          style={{
            padding: 0,
            paddingBottom: 0,
            marginTop: SPACING_24,
          }}
          data={securities.slice(0, 3)}
          title={t('app:portfolioConstruction:topCompanies', {
            companiesCount: 3,
          })}
          titleColor={highlightColor}
          textColor={highlightColor}
          numberBackgroundColor={accent.ocean250}
          numberColor={accent.ocean4}
        />
      )}
    </StyledBaseSection>
  );
};
