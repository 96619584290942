import React from 'react';
import styled from 'styled-components';

import { SPACING_24 } from '../constants/spacing';

import { SectionWithTitle } from '../components/common/SectionWithTitle';
import { GridElement } from '../components/layout';

import { MenuPage } from './base/MenuPage';
import { Button } from '../components/buttons';
import { StrategiesList } from '../components/strategySimulator/StrategiesList';
import { InvestmentProfileHeader } from '../components/strategySimulator/InvestmentProfileHeader';

import { useSelector } from 'react-redux';
import { selectors, t, useResource } from '@formue-app/core';
import { SpriteIcon } from '../components/common/SpriteIcon';

const StyledGridElement = styled(GridElement)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-block: ${SPACING_24};
`;

const {
  entities: {
    investmentStrategies: { allInvestmentStrategiesSelector },
  },
} = selectors;

export const StrategySimulatorPage = (props) => {
  useResource(['INVESTMENT_STRATEGIES/INDEX']);
  const strategies = useSelector(allInvestmentStrategiesSelector);

  return (
    <MenuPage {...props}>
      <StyledGridElement columns={3}>
        <Button
          disabled={!strategies.length}
          to="/advisor/presentation/investment-strategy"
        >
          <SpriteIcon
            id="Art"
            inverted={true}
            navigational={true}
            size={SPACING_24}
          />{' '}
          {t('app:advisor:strategySimulator:startPresentation')}
        </Button>
      </StyledGridElement>

      <InvestmentProfileHeader />

      <SectionWithTitle
        title={t('app:advisor:strategySimulator:strategies')}
        columns={12}
      >
        <StrategiesList />
      </SectionWithTitle>
    </MenuPage>
  );
};
