import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { H2, H4, Paragraph } from '../../texts';
import { List, ListItem } from '../../common/List';

import {
  BORDER_RADIUS_SMALL,
  SPACING_24,
  SPACING_16,
  SPACING_32,
  SPACING_8,
} from '../../../constants/spacing';
import { GridElement, MainGrid } from '../../layout';
import { Bar } from './PortfolioStrategyItem';
import { MarkdownContent } from '../../common/MarkdownContent';

const SubGrid = styled(MainGrid)`
  padding: 0;
`;

const LimitsBox = styled.div`
  border: 1px solid ${accent.sand370};
  border-radius: ${BORDER_RADIUS_SMALL};
  padding: ${SPACING_24};
`;

const BarWrapper = styled.div`
  width: 40%;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PortfolioStrategyModalContent = () => {
  return (
    <>
      <H2 style={{ marginBottom: SPACING_32 }}>
        {t('app:portfolio:portfolioStrategy:modal:title')}
      </H2>
      <SubGrid>
        <GridElement columns={6}>
          <LimitsBox style={{ marginBottom: SPACING_16 }}>
            <H4 style={{ marginBottom: SPACING_8 }}>
              {t('app:portfolio:portfolioStrategy:modal:inside:title')}
            </H4>
            <MarkdownContent>
              {t('app:portfolio:portfolioStrategy:modal:inside:text')}
            </MarkdownContent>
            <BarWrapper>
              <Bar
                min={0}
                max={50}
                strategy={30}
                market={30}
                style={{
                  width: '100%',
                  marginTop: SPACING_16,
                }}
              />
            </BarWrapper>
          </LimitsBox>
          <LimitsBox>
            <H4 style={{ marginBottom: SPACING_8 }}>
              {t('app:portfolio:portfolioStrategy:modal:outside:title')}
            </H4>
            <MarkdownContent>
              {t('app:portfolio:portfolioStrategy:modal:outside:text')}
            </MarkdownContent>
            <BarWrapper>
              <Bar
                min={0}
                max={50}
                strategy={30}
                market={60}
                style={{
                  width: '100%',
                  marginTop: SPACING_16,
                }}
              />
            </BarWrapper>
          </LimitsBox>
        </GridElement>
        <GridElement columns={6}>
          <Content>
            <Paragraph>
              {t('app:portfolio:portfolioStrategy:modal:introText1')}
            </Paragraph>
            <Paragraph>
              {t('app:portfolio:portfolioStrategy:modal:introText2')}
            </Paragraph>
            <List>
              <ListItem>
                {t('app:portfolio:portfolioStrategy:modal:listItem1')}
              </ListItem>
              <ListItem>
                {t('app:portfolio:portfolioStrategy:modal:listItem2')}
              </ListItem>
            </List>
            <Paragraph style={{ marginTop: 'auto', fontWeight: '500' }}>
              {t('app:portfolio:portfolioStrategy:modal:contact')}
            </Paragraph>
          </Content>
        </GridElement>
      </SubGrid>
    </>
  );
};
