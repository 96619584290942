import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { MainGrid } from '../layout';
import { Breadcrumbs } from '../common/Breadcrumbs';

const ScrollWrapper = styled(MainGrid)`
  width: 100%;
  max-width: 100%;
  padding: 0;
  grid-column: span 12;
`;

export const InvestmentStrategyStickyNavigation = (props) => {
  const { items, children, ...rest } = props;

  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const scrollerRef = useRef(null);
  // We need to include the sticky header itself into the calculation since it's positioned
  // fixed to the top of the page. Otherwise, we'll end up scrolling to the position where
  // sticky header goes over the top (practically hides) the section title
  const STICKY_HEADER_OFFSET = 32;

  // We need to decide how early we want to trigger setActiveSection. This offset will trigger
  // it n pixels before the header reaches it.
  const NEXT_SECTION_OFFSET = 300;

  // How close to end of scrolling we should set the last section to be an active one.
  const SCROLL_END_OFFSET = 100;

  const getOffsetTop = (element) => {
    let offsetTop = 0;
    while (element) {
      offsetTop += element.offsetTop;
      element = element.offsetParent;
    }
    return offsetTop;
  };

  const handleScrollToElement = (index) => {
    if (scrollerRef.current) {
      const targetScrollPosition = getOffsetTop(
        scrollerRef.current.children[index]
      );
      window.scrollTo({
        top: targetScrollPosition - STICKY_HEADER_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const handleScroll = (event) => {
      const documentScrollPosition = document.documentElement.scrollTop;
      const totalScrollLength =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      let counter = 0;
      for (let element of scrollerRef.current.children) {
        if (
          documentScrollPosition >
          getOffsetTop(element) - STICKY_HEADER_OFFSET - NEXT_SECTION_OFFSET
        ) {
          setActiveSectionIndex(counter);
        }
        counter++;
      }

      // If we are close to end of scrolling, set the last section as active one.
      if (documentScrollPosition + SCROLL_END_OFFSET > totalScrollLength)
        setActiveSectionIndex(scrollerRef.current.children.length - 1);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Breadcrumbs
        items={items}
        activeIndex={activeSectionIndex}
        onClick={(index) => handleScrollToElement(index)}
        dynamic
        sticky
        {...rest}
      />
      <ScrollWrapper ref={scrollerRef}>{children}</ScrollWrapper>
    </>
  );
};
