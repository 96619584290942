import React from 'react';
import { useSelector } from 'react-redux';
import camelcase from 'camelcase';

import { percentage, t, selectors } from '@formue-app/core';

import { StretchedHorizontalBarChart } from '../../../graphs/StretchedHorizontalBarChart';
import { SectorIcon } from '../sectors/SectorIcon';

const {
  entities: {
    portfolioLenses: { carbonFootprintDataSelector },
  },
} = selectors;

export const ContributionBySector = (props) => {
  const carbonFootprintData = useSelector(carbonFootprintDataSelector);
  const { emissionBySector } = carbonFootprintData;

  const chartData = emissionBySector
    .sort((a, b) => b.value - a.value)
    .filter((item) => item.sector !== 'UnknownSector')
    .map((item) => ({
      icon: (
        <SectorIcon sector={camelcase(item.sector)} width={16} height={16} />
      ),
      label: t(`app:sectors:${camelcase(item.sector)}:title`),
      ...item,
    }));

  return (
    <div {...props}>
      <StretchedHorizontalBarChart
        data={chartData}
        color="#f4e2e4"
        formatValue={(value) => percentage(value * 100, 1, false, false)}
        barWidth={32}
      />
    </div>
  );
};
