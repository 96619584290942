import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { t } from '@formue-app/core';

import { GridElement } from '../layout';

import { H2Small, Paragraph } from '../texts';
import { backgroundWhite } from '../../constants/colors';

import promotedTaskImageUrl from '../../assets/images/annual-review/report/promoted-task-summary.jpg';

import {
  BORDER_RADIUS_LARGE,
  SPACING_48,
  SPACING_32,
  SPACING_24,
  SPACING_12,
} from '../../constants/spacing';
import { DesktopWrapper, mobileCondition } from '../../constants/media';
import { ButtonSmall } from '../buttons';

const StyledGridElement = styled(GridElement)`
  background: ${backgroundWhite};
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media ${mobileCondition} {
    flex-direction: column-reverse;
  }
`;

const Content = styled.div`
  flex: 2;
  padding: ${SPACING_48};

  @media ${mobileCondition} {
    padding: ${SPACING_24};
  }
`;

const Image = styled.img`
  width: 31.9%;
  flex: 1;
  object-fit: cover;
  border-top-right-radius: ${BORDER_RADIUS_LARGE};
  border-bottom-right-radius: ${BORDER_RADIUS_LARGE};

  @media ${mobileCondition} {
    width: 100%;
    height: 100px !important;
    object-fit: cover;
    border-bottom-right-radius: 0;
    border-top-left-radius: ${BORDER_RADIUS_LARGE};
  }
`;

const StyledTitle = styled(H2Small)`
  padding-bottom: ${SPACING_12};
`;

const StyledParagraph = styled(Paragraph)`
  padding-bottom: ${SPACING_32};
`;

export const PromotedSigningTasks = (props) => {
  const { tasks } = props;

  if (!tasks.length) {
    return null;
  }

  return (
    <StyledGridElement columns={12}>
      <Wrapper>
        <Content>
          <div>
            <StyledTitle>
              {t('app:home:promotedSigningTasks:title')}
            </StyledTitle>
            <StyledParagraph>
              {t('app:home:promotedSigningTasks:description')}
            </StyledParagraph>
            <Link to={`/todo`}>
              <ButtonSmall>
                {t('app:home:promotedSigningTasks:signButton')}
              </ButtonSmall>
            </Link>
          </div>
        </Content>
        <DesktopWrapper>
          <Image src={promotedTaskImageUrl} />
        </DesktopWrapper>
      </Wrapper>
    </StyledGridElement>
  );
};
