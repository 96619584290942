import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  selectors,
  humanFriendlyDate,
  useImageUrlWithAuthToken,
  t,
  useResource,
} from '@formue-app/core';

import { MenuPage } from './base/MenuPage';
import { GridElement } from '../components/layout';
import { H2, H4 } from '../components/texts';
import { BaseSection } from '../components/common/BaseSection';
import { SPACING_48, SPACING_16, SPACING_8 } from '../constants/spacing';
import { MarkdownContent } from '../components/common/MarkdownContent';
import { H4LineHeight, H4Size } from '../constants/text';
import { textLink } from '../constants/colors';

const imageFallback = require('../assets/images/default/formue.png');

const StyledHeading = styled(H2)`
  padding: ${SPACING_8} 0 ${SPACING_16} 0;
`;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_48};
`;

const StyledImageBackground = styled(BaseSection)`
  height: 100%;
  background-size: cover;
  background-position: center;
`;

const StyledLink = styled.a`
  text-decoration: none;
  font-size: ${H4Size};
  line-height: ${H4LineHeight};
  font-weight: 500;
  color: ${textLink};
  margin-top: ${SPACING_16};
  display: inline-block;
  cursor: pointer;
`;

const {
  entities: {
    events: { eventsById },
  },
} = selectors;

export const EventPage = (props) => {
  const loading = useResource(['EVENTS/INDEX']);
  const { id } = useParams();
  const events = useSelector(eventsById);
  const event = events[id];
  const imageUrl = useImageUrlWithAuthToken(!loading ? event.image : null);

  if (loading) return null;

  return (
    <MenuPage {...props} title={t('app:eventCalendar:events')} subpage={true}>
      <GridElement columns={4}>
        <StyledImageBackground
          style={{
            backgroundImage: `url('${imageUrl}'), url('${imageFallback.default}')`,
            maxHeight: 450,
            minHeight: 240,
          }}
        />
      </GridElement>
      <GridElement columns={8}>
        <StyledBaseSection>
          <H4>{humanFriendlyDate(event.startsAt)}</H4>
          <StyledHeading>{event.title}</StyledHeading>
          <MarkdownContent>{event.description}</MarkdownContent>
          <StyledLink href={event.url} target="_blank">
            {t('app:globals:readMore')}
          </StyledLink>
        </StyledBaseSection>
      </GridElement>
    </MenuPage>
  );
};
