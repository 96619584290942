import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { formatBigMoney, t, selectors } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { SPACING_16, SPACING_20, SPACING_8 } from '../../../constants/spacing';

import { H4, Paragraph } from '../../texts';
import { BaseSection } from '../../common/BaseSection';
import { SpriteIcon } from '../../common/SpriteIcon';

const {
  entities: {
    securityPerformance: { securityPerformanceBySymbolSelector },
  },
  auth: { operatingCountrySelector },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  padding: 16px 16px 0 16px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING_16} 0;
  border-bottom: 1px solid ${accent.sand1};
  align-items: flex-start;

  &:last-of-type {
    border-bottom: none;
  }
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${SPACING_16};
`;

const StyledText = styled(Paragraph)`
  font-weight: 500;
  margin-left: 15px;
`;

export const FundFacts = ({
  totalFundValue,
  isin,
  referenceIndex,
  ...rest
}) => {
  const securityPerformanceBySymbol = useSelector(
    securityPerformanceBySymbolSelector
  );
  const operatingCountry = useSelector(operatingCountrySelector);
  const indexPerformance = securityPerformanceBySymbol[referenceIndex];

  // We have one wrong name in coming from API that can't be corrected at source.
  // In a case when securityId is 33454 basically we need to use a translation.
  // In other cases just use a name field from the API.
  let name = '';
  if (indexPerformance[0]) {
    name = indexPerformance && indexPerformance[0].name;
    if (indexPerformance[0].securityId === '33454') {
      name = t('app:portfolio:funds:MSCIWorldIndex', {
        currency: operatingCountry === 'no' ? 'NOK' : 'SEK',
      });
    }
  }

  const value = formatBigMoney(totalFundValue, false);
  return (
    <StyledBaseSection style={{ gridRow: 1 }} {...rest}>
      <StyledContainer>
        <H4>{t('app:portfolio:funds:fundFacts')}</H4>
        <StyledRow>
          <StyledIconWrapper>
            <SpriteIcon id="Capital" size={SPACING_20} />
            <StyledText>{t('app:portfolio:funds:fundSize')}</StyledText>
          </StyledIconWrapper>
          <Paragraph>{value}</Paragraph>
        </StyledRow>
        <StyledRow>
          <StyledIconWrapper>
            <SpriteIcon id="Info" size={SPACING_20} />
            <StyledText>{t('app:portfolio:funds:isin')}</StyledText>
          </StyledIconWrapper>
          <Paragraph>{isin}</Paragraph>
        </StyledRow>
        <StyledRow>
          <StyledIconWrapper>
            <SpriteIcon id="Search" size={SPACING_20} />
            <StyledText>{t('app:portfolio:funds:benchmark')}</StyledText>
          </StyledIconWrapper>
          <Paragraph style={{ textAlign: 'right', paddingLeft: SPACING_8 }}>
            {name}
          </Paragraph>
        </StyledRow>
      </StyledContainer>
    </StyledBaseSection>
  );
};
