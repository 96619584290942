import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { t } from '@formue-app/core';
// import { RiskChart } from './RiskChart';
import { RiskChartV1 } from './RiskChartV1';
import { GridElement } from '../../layout';
import { KnowledgeAndExperience } from './KnowledgeAndExperience';
import { tabletCondition } from '../../../constants/media';
import { H2 } from '../../texts';

export const RiskCapacity = () => {
  const isTablet = useMediaQuery({ query: tabletCondition });
  return (
    <>
      <GridElement columns={12}>
        <H2>{t('app:wealthPlan:investmentProfile:riskCapacity')}</H2>
      </GridElement>
      <GridElement columns={isTablet ? 12 : 6}>
        <RiskChartV1 />
        {/* <RiskChart style={{ background: backgroundWhite }} /> */}
      </GridElement>
      <KnowledgeAndExperience columns={isTablet ? 12 : 6} />
    </>
  );
};
