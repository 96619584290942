import React from 'react';
import styled from 'styled-components';

import { t, useCountryOptions } from '@formue-app/core';

import { Fieldset, Select, Input } from '../../formElements';
import { SPACING_24, BORDER_RADIUS_LARGE } from '../../../constants/spacing';
import { ButtonSmall } from '../../buttons';

const StyledBorder = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_LARGE};
  border: 1px solid rgba(24, 35, 97, 0.1);
`;

export const TaxIdForm = (props) => {
  const { index, onDelete, onChange, data, list, style } = props;

  // List is the dataset for all tax id forms, while data is the
  // data for this one form we are representing
  const countryOptions = useCountryOptions(list, data);

  const onCountryChange = (event) => {
    data.country = event.target.value;
    onChange(data, index);
  };

  const onTaxIdChange = (event) => {
    data.taxIdNumber = event.target.value;
    onChange(data, index);
  };

  return (
    <Fieldset style={style}>
      <StyledBorder>
        <ButtonSmall
          style={{ marginLeft: 'auto' }}
          onClick={onDelete}
          buttonstyle="text"
        >
          {t('app:globals:remove')}
        </ButtonSmall>
        <Select
          placeholder={`${t('app:globals:select')} ${t(
            'app:profile:selfDeclaration:countryLabel'
          )}`}
          onChange={onCountryChange}
          id={`country-${index}`}
          name={`country-${index}`}
          value={data.country.toLowerCase()}
          options={countryOptions}
          inputstyle="outline"
          popoutLabel={true}
          label={`${t('app:profile:selfDeclaration:countryLabel')} *`}
          style={{
            width: '100%',
          }}
        />
        <br />

        <Input
          onChange={onTaxIdChange}
          type="text"
          value={data.taxIdNumber}
          id={`tax-id-${index}`}
          name={`tax-id-${index}`}
          inputstyle="outline"
          popoutLabel={true}
          label={`${t('app:profile:selfDeclaration:taxId')} *`}
          style={{
            width: '100%',
          }}
        />
      </StyledBorder>
    </Fieldset>
  );
};
