import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { groupBy } from 'lodash';

import {
  percentage,
  selectors,
  useResource,
  t,
  formatBigMoney,
} from '@formue-app/core';

import { BaseSection } from '../components/common/BaseSection';
import { H2, H3, H4, Paragraph, ParagraphXSmall } from '../components/texts';

import { CenteredActivityIndicator } from '../components/common/ActivityIndicator';
import { GridElement } from '../components/layout';
import { accent, backgroundWhite } from '../constants/colors';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_24,
  SPACING_8,
} from '../constants/spacing';
import { CostTable } from '../components/advisorsCorner/strategySimulator/CostTable';
import { MultipleSwitch, Toggle } from '../components/formElements';
import { SpriteIcon } from '../components/common/SpriteIcon';
import { PortfolioCharacteristicsItemPieChart } from '../components/presentation/investmentStrategyComponents/PortfolioCharacteristicsItemPieChart';
import { ProductCostTable } from '../components/advisorsCorner/strategySimulator/ProductCostTable';

const StyledGridElement = styled(GridElement)`
  display: flex;
  gap: ${SPACING_16};
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Header = styled.div`
  display: flex;
  gap: ${SPACING_8};
  align-items: center;
  margin-bottom: ${SPACING_24};
`;

const MetadataWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${SPACING_8};
`;

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  padding: ${SPACING_12} ${SPACING_16};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background: ${accent.sand150};
`;

const {
  entities: {
    investmentStrategyCosts: {
      investmentStrategyCostsByStrategyIdSelector,
      investmentStrategyCostMetadataByIdSelector,
    },
    investmentStrategies: { investmentStrategyByIdSelector },
    investmentStrategyProducts: {
      investmentStrategyProductsByStrategyIdSelector,
    },
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsByStrategyIdSelector,
    },
  },
} = selectors;

export const StrategyDetailsCostPage = () => {
  const { id } = useParams();

  const [dataExtractor, setDataExtractor] = useState('percentage');
  const [showAll, setShowAll] = useState(false);

  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    {
      type: 'INVESTMENT_STRATEGY_COSTS/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGY_PRODUCT_ALLOCATIONS/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: id },
    },
  ]);

  const strategy = useSelector(investmentStrategyByIdSelector(id));
  const investmentStrategyCosts = useSelector(
    investmentStrategyCostsByStrategyIdSelector(id)
  );
  const products = useSelector(
    investmentStrategyProductsByStrategyIdSelector(id)
  );
  const assetAllocations = useSelector(
    investmentStrategyAssetAllocationsByStrategyIdSelector(id)
  );

  const metadata = useSelector(investmentStrategyCostMetadataByIdSelector(id));

  const advisoryFeeRelevant = metadata?.advisoryFeeRelevant;
  const structuringFeeRelevant = metadata?.structuringFeeRelevant;

  const twoColumnTableCondition = advisoryFeeRelevant || structuringFeeRelevant;

  const costGroups = groupBy(investmentStrategyCosts, 'group');

  const totalsYear1 = Object.keys(costGroups).map((key) => ({
    id: key,
    group: t(`app:advisor:strategySimulator:cost:${key}`),
    value: costGroups[key]
      .map((item) => item.costByYear[0][dataExtractor])
      .reduce((acc, cur) => acc + cur, 0),
  }));

  const totalsYear3pluss = Object.keys(costGroups).map((key) => ({
    id: key,
    group: t(`app:advisor:strategySimulator:cost:${key}`),
    value: costGroups[key]
      .map((item) => item.costByYear[3][dataExtractor])
      .reduce((acc, cur) => acc + cur, 0),
  }));

  const formatValues = (value) => {
    if (!value) return '–';
    if (dataExtractor === 'percentage') return percentage(value * 100, 2);
    if (dataExtractor === 'amount') return formatBigMoney(value, false);
  };

  if (loading) {
    return (
      <GridElement columns={12}>
        <CenteredActivityIndicator />
      </GridElement>
    );
  }

  if (!investmentStrategyCosts.length) {
    return (
      <BaseSection columns={12}>
        <Paragraph style={{ fontStyle: 'italic' }}>
          {t('app:advisor:strategySimulator:cost:missingData')}
        </Paragraph>
      </BaseSection>
    );
  }

  return (
    <>
      <StyledGridElement columns={12}>
        <H2>{t('app:advisor:strategySimulator:cost:title')}</H2>
      </StyledGridElement>
      <BaseSection columns={twoColumnTableCondition ? 6 : 7}>
        {twoColumnTableCondition ? (
          <Header>
            <SpriteIcon id="Liquidity" />
            <H4>{t('app:advisor:strategySimulator:cost:year4plus')}</H4>
          </Header>
        ) : null}
        <MetadataWrapper>
          <Metadata>
            <ParagraphXSmall>
              {t(
                'app:advisor:strategySimulator:assetAllocation:totalPortfolio'
              )}
            </ParagraphXSmall>
            <H3>{formatBigMoney(strategy.totalPortfolio, false)}</H3>
          </Metadata>
          <Metadata>
            <ParagraphXSmall>
              {t('app:advisor:strategySimulator:cost:tables:serviceFee:title')}
            </ParagraphXSmall>
            <H3>
              {formatValues(
                totalsYear3pluss.find((item) => item.id === 'service').value
              )}
            </H3>
          </Metadata>
          <Metadata>
            <ParagraphXSmall>
              {t('app:advisor:strategySimulator:cost:tables:productFee:title')}
            </ParagraphXSmall>
            <H3>
              {formatValues(
                totalsYear3pluss.find((item) => item.id === 'products').value
              )}
            </H3>
          </Metadata>
          <Metadata>
            <ParagraphXSmall>
              {t('app:advisor:strategySimulator:cost:tables:totalCost:title')}
            </ParagraphXSmall>
            <H3>
              {formatValues(
                totalsYear3pluss.find((item) => item.id === 'totals').value
              )}
            </H3>
          </Metadata>
        </MetadataWrapper>
      </BaseSection>
      {twoColumnTableCondition ? (
        <BaseSection columns={3}>
          <Header>
            <SpriteIcon id="Liquidity" />
            <H4>{t('app:advisor:strategySimulator:cost:year1')}</H4>
          </Header>
          <PortfolioCharacteristicsItemPieChart
            size={70}
            innerRadius={23}
            data={totalsYear1
              .filter(
                (item) =>
                  item.id !== 'externalReporting' && item.id !== 'totals'
              )
              .map((item) => ({
                allocation: item.value,
                group: item.group,
              }))}
            customValueFormatter={formatValues}
            labelPosition="bottom"
          />
        </BaseSection>
      ) : null}
      <BaseSection columns={twoColumnTableCondition ? 3 : 5}>
        {twoColumnTableCondition ? (
          <Header>
            <SpriteIcon id="Liquidity" />
            <H4>{t('app:advisor:strategySimulator:cost:year4plus')}</H4>
          </Header>
        ) : null}
        <PortfolioCharacteristicsItemPieChart
          size={70}
          innerRadius={23}
          data={totalsYear3pluss
            .filter(
              (item) => item.id !== 'externalReporting' && item.id !== 'totals'
            )
            .map((item) => ({
              allocation: item.value,
              group: item.group,
            }))}
          customValueFormatter={formatValues}
          labelPosition="bottom"
        />
      </BaseSection>
      <StyledGridElement columns={12} style={{ marginTop: SPACING_16 }}>
        <TitleWrapper>
          <H3>{t('app:advisor:strategySimulator:cost:detailsTitle')}</H3>
          <MultipleSwitch
            backgroundColor={backgroundWhite}
            activeBackgroundColor={accent.ocean230}
            color={accent.ocean270}
            activeColor={accent.ocean490}
            onChange={(value) => {
              setDataExtractor(value);
            }}
            options={[
              {
                label: t(
                  'app:advisor:strategySimulator:cost:valueInPercentage'
                ),
                value: 'percentage',
              },
              {
                label: t('app:advisor:strategySimulator:cost:valueInKr'),
                value: 'amount',
              },
            ]}
          />
        </TitleWrapper>
        <CostTable
          managementType={strategy.managementType}
          dataExtractor={dataExtractor}
          costData={investmentStrategyCosts}
          advisoryFeeRelevant={advisoryFeeRelevant}
          structuringFeeRelevant={structuringFeeRelevant}
        />

        <TitleWrapper>
          <H3>{t('app:advisor:strategySimulator:cost:detailsTitle')}</H3>
          <Toggle
            onChange={() => setShowAll(!showAll)}
            value={showAll}
            label={t('app:advisor:strategySimulator:cost:productsToggle')}
          />
        </TitleWrapper>
        <ProductCostTable
          dataExtractor={dataExtractor}
          showAll={showAll}
          products={products}
          allocation={assetAllocations}
        />
      </StyledGridElement>
    </>
  );
};
