import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, t } from '@formue-app/core';

import { SPACING_24, SPACING_2 } from '../../../constants/spacing';
import { SectionWithTitle } from '../../common/SectionWithTitle';

import { InvestmentPreferencesContent } from './InvestmentPreferencesContent';
import { AdvisorsComment } from './AdvisorsComment';
import { MainGrid } from '../../layout';

const {
  entities: {
    investmentProfile: { sustainabilityPreferencesSelector },
  },
} = selectors;
const StyledSectionWithTitle = styled(SectionWithTitle)`
  ${(props) =>
    props.hasAdvisorComment
      ? `
        margin-bottom: ${SPACING_2};
      `
      : ''}
`;

const StyledMainGrid = styled(MainGrid)`
  padding: 0;
  max-width: 100%;
`;

export const InvestmentPreferences = () => {
  const sustainabilityPreferences = useSelector(
    sustainabilityPreferencesSelector
  );
  const advisorsCommentOnPreferences =
    sustainabilityPreferences?.advisorComment;

  return (
    <>
      <StyledSectionWithTitle
        title={t(
          'app:wealthPlan:investmentProfile:sustainabilityPreferences:title'
        )}
        columns={12}
      >
        <StyledMainGrid>
          <InvestmentPreferencesContent />
        </StyledMainGrid>
      </StyledSectionWithTitle>

      {advisorsCommentOnPreferences ? (
        <AdvisorsComment
          title={t(
            'app:wealthPlan:investmentProfile:advisorsCommentOnPreferences'
          )}
          subTitle={''}
          body={advisorsCommentOnPreferences}
          columns={12}
          style={{ marginBottom: SPACING_24 }}
        />
      ) : null}
    </>
  );
};
