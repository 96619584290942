import React from 'react';
import styled from 'styled-components';
import { accent } from '../../../../constants/colors';
import { SPACING_32 } from '../../../../constants/spacing';
import { PieChart } from '../../../graphs/PieChart';

const StyledPieChart = styled(PieChart)`
  margin-bottom: ${SPACING_32};
`;

export const PortfolioStrategyCircle = (props) => {
  const { isActiveSlide, numInside, numOutside } = props;
  const segments = numInside + numOutside;

  return (
    <StyledPieChart
      data={Array(segments)
        .fill()
        .map((item, index) => ({
          value: 1,
          color: index < numInside ? accent.velvet3 : accent.velvet1,
        }))}
      size={120}
      padAngle={3}
      animated={true}
      innerRadius={45}
      cornerRadius={1}
      numinside={numInside}
      isActiveSlide={isActiveSlide}
      holeColor="transparent"
    />
  );
};
