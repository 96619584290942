import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { SummaryBoxClickable } from '../../common/SummaryBox';
import { accent, textLightest } from '../../../constants/colors';
import { H3 } from '../../texts/H3';
import {
  SPACING_8,
  SPACING_24,
  SPACING_16,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../../constants/spacing';
import { SpriteIcon } from '../../common/SpriteIcon';
import backgroundUrl from '../../../assets/portfolio/esg-summary-background.svg';

const StyledSummaryBox = styled(SummaryBoxClickable)`
  margin-top: ${SPACING_8};
  margin-bottom: -48px;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: 148px !important;
    padding-top: ${SPACING_24};
    background: #173621 url(${backgroundUrl}) no-repeat top right;
    border-bottom-left-radius: ${BORDER_RADIUS_EXTRA_LARGE};
    border-bottom-right-radius: ${BORDER_RADIUS_EXTRA_LARGE};
    padding: ${SPACING_16};
  }

  footer {
    background: transparent;
    position: relative;
    top: -48px;
    border-top: 1px solid ${accent.moss1};

    a {
      color: ${textLightest};
    }
  }
`;

export const SustainabilitySummary = (props) => {
  const { columns } = props;

  return (
    <StyledSummaryBox
      icon={<SpriteIcon id="Sustainability" size={24} />}
      iconSpacing={12}
      titleFontSize={15}
      title={t('app:sustainability:title')}
      url="/portfolio/lenses/sustainability"
      label={t('app:sustainability:title')}
      linkColor={textLightest}
      columns={columns}
    >
      <H3
        style={{
          color: accent.forest250,
        }}
      >
        {t('app:sustainability:summaryText')}
      </H3>
    </StyledSummaryBox>
  );
};
