import React from 'react';
import styled, { css } from 'styled-components';

import {
  t,
  sortByAssetClass,
  getAssetClassName,
  formatMoney,
  percentage,
} from '@formue-app/core';

import {
  SPACING_24,
  SPACING_16,
  SPACING_4,
  SPACING_8,
} from '../../../constants/spacing';
import { accent, textLightest } from '../../../constants/colors';
import {
  MobileWrapper,
  NotMobileWrapper,
  mobileCondition,
  printCondition,
} from '../../../constants/media';

import { H4, Paragraph, ParagraphSmall, ParagraphXSmall } from '../../texts';

import { Bar } from './PortfolioStrategyItem';
import {
  BoundariesLabel,
  CurrentDistributionLabel,
  StrategyLabel,
} from './PortfolioStrategyLabels';
import { StyledCell, StyledHeaderCell, TableList } from '../../lists/TableList';
import { SpriteIcon } from '../../common/SpriteIcon';
import { BaseSection } from '../../common/BaseSection';

const ErrorWrapper = styled.div`
  width: 60%;

  @media ${mobileCondition} {
    margin-top: ${SPACING_24};
    width: 100%;
  }
`;

const StyledTableList = styled(TableList)`
  border-spacing: 0 0;
  th {
    border-bottom: 1px solid
      ${(props) => (props.light ? accent.velvet480 : accent.sand1)};
  }

  tr {
    background: transparent;
  }

  ${(props) =>
    props.light &&
    css`
      color: ${textLightest};

      p,
      td,
      span {
        color: ${textLightest};
      }
    `}
`;

const LightStyledCell = styled(StyledCell)`
  padding: ${SPACING_16} 8px;

  span {
    font-weight: 400;
  }

  &:first-child {
    padding-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:last-child {
    padding-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media ${printCondition} {
    padding-top: ${SPACING_8};
    padding-bottom: ${SPACING_8};

    &:first-child {
      padding-left: ${SPACING_16};
    }

    &:last-child {
      padding-right: ${SPACING_16};
    }

    span {
      font-size: 10px;
      vertical-align: bottom;
    }

    &:nth-last-child(-n + 2) {
      span {
        font-weight: 600;
      }
    }
  }
`;

const LightStyledHeaderCell = styled(StyledHeaderCell)`
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: ${SPACING_16};
  }

  @media ${printCondition} {
    &:first-child {
      padding-left: ${SPACING_16};
    }

    &:last-child {
      padding-right: ${SPACING_16};
    }
  }
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${SPACING_24};
  border-top: 1px solid
    ${(props) => (props.light ? accent.velvet480 : accent.sand1)};

  ${(props) =>
    props.light &&
    css`
      color: ${textLightest};

      p,
      td,
      span {
        color: ${textLightest};
      }
    `}

  @media ${printCondition} {
    padding-top: ${SPACING_8};

    p {
      font-size: 10px;
    }
  }
`;

const MobileContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  margin-top: ${SPACING_16};
`;

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
`;

const MobileTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const MobileTextRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_4};
  margin-left: auto;
`;

export const StrategyList = (props) => {
  const {
    strategy,
    openItems = [],
    setOpenItems,
    style,
    light = false,
    ...rest
  } = props;

  if (!strategy) {
    return null;
  }

  const maxValue = Math.max(
    ...strategy.map((item) => Math.max(item.marketShare, item.upperLimit))
  );

  const totalStrategyAmount = strategy.reduce(
    (acc, curr) => acc + curr.marketValue,
    0
  );

  let columns = [
    {
      key: 'assetClass',
      label: t('app:portfolio:portfolioStrategy:assetClass'),
      render: ({ assetClass }) => (
        <span style={{ fontWeight: 500 }}>{getAssetClassName(assetClass)}</span>
      ),
      width: '15%',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'product',
      render: ({ upperLimit, lowerLimit, strategyShare, marketShare }) => {
        const max = (upperLimit * 100) / maxValue;
        const min = (lowerLimit * 100) / maxValue;
        const strategy = (strategyShare * 100) / maxValue;
        const market = (marketShare * 100) / maxValue;

        return (
          <Bar
            max={max}
            min={min}
            strategy={strategy}
            market={market}
            light={light}
            {...rest}
          />
        );
      },
      width: '23%',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'marketValue',
      label: t('app:portfolio:portfolioStrategy:marketValue'),
      render: ({ marketValue }) => formatMoney(marketValue),
      width: '8%',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
      type: 'number',
    },
    {
      key: 'marketShare',
      label: <CurrentDistributionLabel />,
      render: ({ marketShare }) => percentage(marketShare * 100),
      width: '10%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'strategyShare',
      label: <StrategyLabel light={light} />,
      render: ({ strategyShare }) => percentage(strategyShare * 100),
      width: '10%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'upperLimit',
      label: <BoundariesLabel light={light} />,
      render: ({ upperLimit, lowerLimit }) =>
        `${percentage(lowerLimit * 100)}-${percentage(upperLimit * 100)}`,
      width: '10%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'diffkr',
      label: t('app:portfolio:portfolioStrategy:deviationKr'),
      render: ({ marketShare, strategyShare, upperLimit, lowerLimit }) => {
        const isInside = marketShare >= lowerLimit && marketShare <= upperLimit;
        const diffPercentage = (strategyShare - marketShare) * -1;
        const diff = totalStrategyAmount * diffPercentage;
        return (
          <span style={{ fontWeight: isInside ? 400 : 500 }}>
            {formatMoney(diff)}
          </span>
        );
      },
      width: '12%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'diffPercentage',
      label: t('app:portfolio:portfolioStrategy:deviationPercentage'),
      render: ({ marketShare, strategyShare, upperLimit, lowerLimit }) => {
        const isInside = marketShare >= lowerLimit && marketShare <= upperLimit;
        const diffPercentage = (strategyShare - marketShare) * -1;
        return (
          <TextIconWrapper>
            <span
              style={{
                fontWeight: isInside ? 400 : 500,
                marginRight: isInside ? SPACING_16 : 0,
              }}
            >
              {percentage(diffPercentage * 100)}
            </span>
            {!isInside ? (
              <SpriteIcon
                id="Info"
                navigational={true}
                inverted={light}
                size={12}
              />
            ) : null}
          </TextIconWrapper>
        );
      },
      width: '12%',
      type: 'number',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
  ];

  return (
    <>
      <NotMobileWrapper>
        <StyledTableList
          id="strategyList"
          sortable={false}
          columns={columns}
          rows={strategy.sort(sortByAssetClass)}
          openItems={openItems}
          keyExtractor={(item, index) => `strategy-asset-class-${index}`}
          light={light}
          style={style}
          {...rest}
        />
      </NotMobileWrapper>
      <MobileWrapper>
        <MobileContentWrapper>
          {strategy.sort(sortByAssetClass).map((item, index) => {
            const {
              upperLimit,
              lowerLimit,
              strategyShare,
              marketShare,
              assetClass,
            } = item;

            const max = (upperLimit * 100) / maxValue;
            const min = (lowerLimit * 100) / maxValue;
            const strategy = (strategyShare * 100) / maxValue;
            const market = (marketShare * 100) / maxValue;

            const isInside =
              marketShare >= lowerLimit && marketShare <= upperLimit;
            const diff = totalStrategyAmount * (strategyShare - marketShare);

            return (
              <StyledBaseSection key={`strategy-${index}`}>
                <H4>{getAssetClassName(assetClass)}</H4>
                <Bar
                  max={max}
                  min={min}
                  strategy={strategy}
                  market={market}
                  light={light}
                />
                <MobileTextWrapper>
                  <MobileTextRowWrapper>
                    <CurrentDistributionLabel />
                    <ParagraphSmall>
                      {percentage(marketShare * 100)}
                    </ParagraphSmall>
                  </MobileTextRowWrapper>
                  <MobileTextRowWrapper>
                    <StrategyLabel />
                    <ParagraphSmall>
                      {percentage(strategyShare * 100)}
                    </ParagraphSmall>
                  </MobileTextRowWrapper>
                  <MobileTextRowWrapper>
                    <BoundariesLabel />
                    <ParagraphSmall>{`${percentage(
                      lowerLimit * 100
                    )}-${percentage(upperLimit * 100)}`}</ParagraphSmall>
                  </MobileTextRowWrapper>
                  <MobileTextRowWrapper>
                    {isInside ? (
                      <SpriteIcon
                        id="OK"
                        navigational={true}
                        inverted={light}
                        size={12}
                        style={{ opacity: 0.5 }}
                      />
                    ) : (
                      <SpriteIcon
                        id="Info"
                        navigational={true}
                        inverted={light}
                        size={12}
                      />
                    )}

                    <ParagraphSmall
                      style={{ fontWeight: isInside ? 400 : 500 }}
                    >
                      {formatMoney(diff)}
                    </ParagraphSmall>
                  </MobileTextRowWrapper>
                </MobileTextWrapper>
              </StyledBaseSection>
            );
          })}
        </MobileContentWrapper>
      </MobileWrapper>

      <TotalWrapper light={light}>
        <Paragraph style={{ fontWeight: 500, width: '35%' }}>
          {t('app:portfolio:portfolioStrategy:strategytotalPortfolio')}
        </Paragraph>
        <Paragraph
          style={{
            fontWeight: 500,
            textAlign: 'right',
            paddingRight: SPACING_8,
            width: '10%',
          }}
        >
          {formatMoney(totalStrategyAmount)}
        </Paragraph>
        <TextIconWrapper>
          <SpriteIcon
            id="Info"
            navigational={true}
            inverted={light}
            size={12}
          />
          <ParagraphXSmall style={{ fontStyle: 'italic' }}>
            {t('app:portfolio:portfolioStrategy:deviationFootnote')}
          </ParagraphXSmall>
        </TextIconWrapper>
      </TotalWrapper>
    </>
  );
};

export const PortfolioStrategyList = (props) => {
  const { style, activeStrategy, ...rest } = props;

  if (!activeStrategy || activeStrategy?.strategyShare === null) {
    return (
      <ErrorWrapper>
        <H4>{t('app:portfolio:portfolioStrategy:emptyData')}</H4>
      </ErrorWrapper>
    );
  }

  return (
    <StrategyList
      strategy={activeStrategy.strategy}
      animate={true}
      style={style}
      {...rest}
    />
  );
};
