import React, { useState } from 'react';
import styled from 'styled-components';

import { formatBigMoney, t } from '@formue-app/core';

import { accent, textLightest } from '../../../../constants/colors';
import { BORDER_RADIUS_LARGE, SPACING_24 } from '../../../../constants/spacing';
import { H3, H6 } from '../../../texts';
import { CollapsibleListItem } from '../../../common/CollapsibleListItem';
import { CategoryList } from './CategoryList';
import { mobileCondition } from '../../../../constants/media';
import { SpriteIcon } from '../../../common/SpriteIcon';
import { SpriteIconFunctional } from '../../../common/SpriteIconFunctional';

const Wrapper = styled.div`
  @media ${mobileCondition} {
    svg {
      display: block !important;
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0;
  margin-right: auto;
`;

export const CategoryButton = (props) => {
  const { dataset, category, active = true, isActiveSlide, ...rest } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  if (!dataset[category]) return null;

  const categoryData = dataset[category];

  const iconByCategory = (category) => {
    switch (category) {
      case 'safety':
        return (
          <SpriteIcon
            id="Safety"
            size={32}
            inverted={true}
            navigational={true}
          />
        );
      case 'preserveAndGrow':
        return (
          <SpriteIcon
            id="Preserve and Develop"
            size={32}
            inverted={true}
            navigational={true}
          />
        );
      case 'create':
        return (
          <SpriteIcon
            id="Create"
            size={32}
            inverted={true}
            navigational={true}
          />
        );
      default:
        break;
    }
  };

  return (
    <Wrapper {...rest}>
      <CollapsibleListItem
        showArrow={true}
        arrowComponent={
          <SpriteIconFunctional
            id="Simple arrow"
            direction="Down"
            color={textLightest}
          />
        }
        arrowSize={20}
        style={{
          padding: `20px ${SPACING_24}`,
          background: accent.velvet490,
          borderBottomLeftRadius: !isExpanded ? BORDER_RADIUS_LARGE : 0,
          borderBottomRightRadius: !isExpanded ? BORDER_RADIUS_LARGE : 0,
        }}
        detailsStyle={{
          background: accent.velvet490,
          marginTop: 0,
          borderTopLeftRadius: !isExpanded ? BORDER_RADIUS_LARGE : 0,
          borderTopRightRadius: !isExpanded ? BORDER_RADIUS_LARGE : 0,
        }}
        details={
          <CategoryList
            dataset={dataset}
            category={category}
            active={active}
            textColor={accent.velvet2}
          />
        }
        onExpand={(value) => {
          setIsExpanded(value);
        }}
      >
        <>
          {iconByCategory(category)}
          <TextWrapper>
            <H6 style={{ color: accent.velvet2 }}>
              {t(`app:wealthBalance:categories:${category}`)}
            </H6>
            <H3 style={{ color: textLightest }}>
              {formatBigMoney(categoryData.amount, false)}
            </H3>
          </TextWrapper>
        </>
      </CollapsibleListItem>
    </Wrapper>
  );
};
