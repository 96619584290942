import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, t, generateSensitivityMessage } from '@formue-app/core';

import { SPACING_8, SPACING_16, SPACING_24 } from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

import { ParagraphSmall, H6, Paragraph } from '../../texts';
import { BaseSection } from '../../common/BaseSection';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrows/arrow-right-list.svg';

const {
  entities: {
    investmentProfile: { winningAppetiteSelector, lossAversionSelector },
  },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_24};
  background: ${accent.sand140};
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${SPACING_8};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledTitle = styled(Paragraph)`
  font-weight: 500;
  margin-top: ${SPACING_8};
  margin-bottom: ${SPACING_16};
`;

const StyledText = styled(ParagraphSmall)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const StyledBulletpoint = styled(ArrowIcon)`
  margin: 2px ${SPACING_16} 0 0;
`;

export const ReactionList = (props) => {
  const { type, ...rest } = props;
  const winningAppetite = useSelector(winningAppetiteSelector);
  const lossAversion = useSelector(lossAversionSelector);
  const data = { winningAppetite, lossAversion };

  const { quantile } = data[type];

  return (
    <StyledBaseSection {...rest}>
      <H6 style={{ opacity: 0.7 }}>
        {t(`app:wealthPlan:investmentProfile:yourReaction:${type}`)}
      </H6>
      <StyledTitle>
        {t(`app:wealthPlan:investmentProfile:yourReactionIngress:${type}`)}
      </StyledTitle>
      {generateSensitivityMessage(quantile, type).map((text, index) => (
        <StyledRow key={`bulletpoint-${index}`}>
          <StyledBulletpoint />
          <StyledText>{text}</StyledText>
        </StyledRow>
      ))}
    </StyledBaseSection>
  );
};
