import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { t, selectors } from '@formue-app/core';
import { accent } from '../../../constants/colors';
import { BaseSection } from '../../common/BaseSection';
import { H6, ParagraphSmall } from '../../texts';
import { CheckIcon } from '../../common/CheckIcon';
import {
  BORDER_RADIUS_SMALL,
  SPACING_16,
  SPACING_8,
} from '../../../constants/spacing';

const {
  entities: {
    investmentProfile: {
      riskCapacityAssetClassSelector,
      riskCapacityAssetClassTypesSelector,
    },
  },
} = selectors;

const StyledContainer = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  padding-top: ${SPACING_8};
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px ${SPACING_16};
  border-radius: ${BORDER_RADIUS_SMALL};

  &:nth-child(even) {
    background: ${accent.sand150};
  }

  &:last-child {
    border-bottom: 0;
  }
  &:first-child {
    border-bottom: 0;
  }
`;

const StyledColumnLarge = styled.div`
  display: flex;
  flex: 1;
`;

const StyledColumnSmall = styled.div`
  display: flex;
  width: 100px;
  justify-content: center;
`;

export const KnowledgeAndExperienceTable = (props) => {
  const { knowledge, experience } = useSelector(riskCapacityAssetClassSelector);
  const assetClassTypes = useSelector(riskCapacityAssetClassTypesSelector);

  const renderRows = () => {
    return assetClassTypes.map((type, index) => {
      return (
        <StyledRow key={index}>
          <StyledColumnLarge>
            <ParagraphSmall>
              {t(
                `app:wealthPlan:investmentProfile:knowledgeAndExperienceAnswers:${type}`
              )}
            </ParagraphSmall>
          </StyledColumnLarge>
          <StyledColumnSmall>
            {knowledge.includes(type) && (
              <CheckIcon size={20} checked={knowledge.includes(type)} />
            )}
          </StyledColumnSmall>
          <StyledColumnSmall>
            {experience.includes(type) && (
              <CheckIcon size={20} checked={experience.includes(type)} />
            )}
          </StyledColumnSmall>
        </StyledRow>
      );
    });
  };

  return (
    <StyledContainer {...props}>
      <StyledRow>
        <StyledColumnLarge>
          <H6>{t('app:wealthPlan:investmentProfile:investments')}</H6>
        </StyledColumnLarge>
        <StyledColumnSmall>
          <H6>{t('app:wealthPlan:investmentProfile:knowledge')}</H6>
        </StyledColumnSmall>
        <StyledColumnSmall>
          <H6>{t('app:wealthPlan:investmentProfile:experience')}</H6>
        </StyledColumnSmall>
      </StyledRow>
      {renderRows()}
    </StyledContainer>
  );
};
