import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getFlagsSelector, selectors, t, useResource } from '@formue-app/core';

import { H2 } from '../components/texts';
import { GridElement } from '../components/layout';
import { MultipleSwitch } from '../components/formElements';

import { accent } from '../constants/colors';

import { StrategyPreferencesSettings } from '../components/advisorsCorner/strategySimulator/PreferencesSettings';
import { PreferencesKeyFigures } from '../components/advisorsCorner/strategySimulator/PreferencesKeyFigures';
import { PreferencesProducts } from '../components/advisorsCorner/strategySimulator/PreferencesProducts';
import { BaseSection } from '../components/common/BaseSection';
import { SPACING_24 } from '../constants/spacing';
import { investmentStrategyByIdSelector } from '@formue-app/core/src/store/entities/selectors/investmentStrategies';
import { CenteredActivityIndicator } from '../components/common/ActivityIndicator';

const StyledGridElement = styled(GridElement)`
  display: flex;
`;

const TabHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

const {
  entities: {
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsByStrategyIdSelector,
    },
    investmentStrategyPortfolioCharacteristics: {
      investmentStrategyPortfolioCharacteristicsByStrategyIdSelector,
    },
    investmentStrategyProducts: {
      investmentStrategyProductsByStrategyIdSelector,
    },
    investmentStrategyPreferences: {
      investmentStrategyPreferencesByStrategyIdSelector,
    },
  },
} = selectors;

export const StrategyDetailsPreferencesPage = (props) => {
  const { id } = useParams();
  useResource([
    {
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGY_PORTFOLIO_CHARACTERISTICS/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGY_PRODUCT_ALLOCATIONS/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGY_PREFERENCES/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGIES/INDEX',
    },
  ]);
  const strategy = useSelector(investmentStrategyByIdSelector(id));

  const assetAllocations = useSelector(
    investmentStrategyAssetAllocationsByStrategyIdSelector(id)
  );
  const portfolioCharacteristics = useSelector(
    investmentStrategyPortfolioCharacteristicsByStrategyIdSelector(id)
  );
  const products = useSelector(
    investmentStrategyProductsByStrategyIdSelector(id)
  );
  const preferences = useSelector(
    investmentStrategyPreferencesByStrategyIdSelector(id)
  );

  const options = [
    {
      value: 'keyFigures',
      label: t('app:advisor:strategySimulator:preferences:keyFigures'),
    },
    {
      value: 'products',
      label: t('app:advisor:strategySimulator:preferences:products'),
    },
  ];

  const [tab, setTab] = useState('keyFigures');

  const showPreferences =
    (preferences && preferences?.length) ||
    (strategy?.preferences &&
      strategy?.preferences.highYieldShare &&
      strategy?.preferences.coreShare &&
      strategy?.preferences.index &&
      strategy?.preferences.mtaShare &&
      strategy?.preferences.sustainability);

  return (
    <>
      {showPreferences ? (
        <>
          <StyledGridElement columns={12}>
            <H2 style={{ marginRight: 'auto' }}>
              {t('app:advisor:strategySimulator:preferences:title')}
            </H2>
          </StyledGridElement>

          <StrategyPreferencesSettings
            strategyId={id}
            strategy={strategy}
            preferences={preferences}
          />
        </>
      ) : null}

      {!portfolioCharacteristics ? (
        <GridElement columns={8}>
          <CenteredActivityIndicator />
        </GridElement>
      ) : (
        <BaseSection
          columns={showPreferences ? 8 : 12}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TabHeader>
            <H2>{options.find((item) => item.value === tab).label}</H2>
            <MultipleSwitch
              style={{ marginLeft: 'auto' }}
              activeBackgroundColor={accent.ocean1}
              color={accent.ocean1}
              onChange={(value) => {
                setTab(value);
              }}
              options={options}
            />
          </TabHeader>
          {tab === 'keyFigures' ? (
            <PreferencesKeyFigures
              portfolioCharacteristics={portfolioCharacteristics}
              strategy={strategy}
              style={{ marginTop: SPACING_24 }}
            />
          ) : (
            <PreferencesProducts
              style={{ marginTop: SPACING_24 }}
              assetAllocations={assetAllocations}
              products={products}
              strategy={strategy}
            />
          )}
        </BaseSection>
      )}
    </>
  );
};
