import React from 'react';
import styled from 'styled-components';

import {
  SPACING_16,
  SPACING_24,
  SPACING_48,
  SPACING_8,
  SPACING_4,
  BORDER_RADIUS_LARGE,
} from '../../constants/spacing';
import { accent, assetClassDefaultColorScheme } from '../../constants/colors';
import { CollapsibleListItem } from '../common/CollapsibleListItem';
import { H3, H4, Paragraph, StyledParagraphXSmall } from '../texts';

import { GridElement } from '../layout';
import {
  formatMoney,
  getAssetClassName,
  getColorForAssetClass,
  percentage,
  sortByAssetClass,
  t,
} from '@formue-app/core';
import { TableList } from '../lists';
import { BaseSection } from '../common/BaseSection';
import { paragraphSmallSize } from '../../constants/text';
import { DotIcon } from '../common/DotIcon';
import { InvestmentStrategyDisclaimer } from './InvestmentStrategyDisclaimer';

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  justify-content: space-between;
  & + .details > div {
    padding-top: 0 !important;
  }
`;

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: ${SPACING_48};
  padding: ${SPACING_24};
  flex-direction: column;
`;

const StyledTableList = styled(TableList)`
  tbody tr:nth-child(odd) {
    background-color: ${accent.sand150};
  }
  thead tr th:first-child {
    padding-left: 0;
  }
  tbody tr th {
    padding: ${SPACING_8} ${SPACING_16} !important;
  }
  tr th:nth-child(n + 2) {
    text-align: right;
    span {
      justify-content: flex-end;
    }
  }
`;

const TableHeading = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_8};
`;

export const ExternalBadge = styled.span`
  background: ${accent.ocean220};
  color: ${accent.ocean490};
  display: inline-block;
  align-items: center;
  padding: 0 ${SPACING_4};
  margin-left: ${SPACING_16};
  border-radius: ${BORDER_RADIUS_LARGE};
  font-size: 11px;
  line-height: 24px;
  font-weight: 500;
`;

const ProductsTable = ({ assetAllocations, products, strategy, ...rest }) => {
  const totalPortfolio = strategy ? strategy.totalPortfolio : null;
  const formuePortfolio = strategy
    ? strategy.totalPortfolio - strategy.externalPortfolio
    : null;

  // The strategy share value we get from SF and the API is share based of the formue portfolio
  // we want to convert that to share of _total_ portfolio
  const convertStrategyShareToTotalStrategyShare = (strategyShare) => {
    if (!strategyShare) {
      return 0;
    }
    // Value of product in Formue portfolio
    const strategyShareValue = formuePortfolio * strategyShare;
    // Convert that to a share/percentage based on total portfolio
    const totalStrategyShare = strategyShareValue / totalPortfolio;
    return totalStrategyShare;
  };

  return assetAllocations
    .sort(sortByAssetClass)
    .map(({ assetClass, strategyShare }, index) => {
      const filteredProducts = products.filter(
        (product) => product.assetClass === assetClass
      );
      return (
        <StyledBaseSection key={`cost-estimate-item-${index}`}>
          <StyledTableList
            style={{ width: '100%' }}
            sortable={false}
            columns={[
              {
                width: '70%',
                key: 'key',
                label: (
                  <TableHeading>
                    <DotIcon
                      color={getColorForAssetClass(
                        assetClass,
                        assetClassDefaultColorScheme
                      )}
                    />
                    <H4 style={{ fontWeight: 500, textTransform: 'none' }}>
                      {getAssetClassName(assetClass)}
                    </H4>
                  </TableHeading>
                ),
                render: ({ name, external }) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Paragraph>
                      {name}
                      {external ? (
                        <>
                          <ExternalBadge>
                            {t('app:globals:external')}
                          </ExternalBadge>
                        </>
                      ) : null}
                    </Paragraph>
                  </div>
                ),
              },
              {
                key: 'id',
                width: '30%',
                label: (
                  <Paragraph style={{ fontWeight: 500, textTransform: 'none' }}>
                    {`${t(
                      'app:advisor:strategySimulator:product:strategy'
                    )}: ${percentage(strategyShare * 100)}`}
                  </Paragraph>
                ),
                render: (product) => {
                  return (
                    <Paragraph>
                      {product.external
                        ? formatMoney(totalPortfolio * product.strategyShare)
                        : formatMoney(
                            totalPortfolio *
                              convertStrategyShareToTotalStrategyShare(
                                product.strategyShare
                              )
                          )}{' '}
                      <span style={{ fontSize: paragraphSmallSize }}>{`(${
                        product.external
                          ? percentage(product?.strategyShare * 100, 2)
                          : percentage(
                              convertStrategyShareToTotalStrategyShare(
                                product?.strategyShare
                              ) * 100,
                              2
                            )
                      })`}</span>
                    </Paragraph>
                  );
                },
              },
            ]}
            rows={filteredProducts}
            keyExtractor={(item, index) => `strategy-products-${index}`}
          />
        </StyledBaseSection>
      );
    });
};

export const InvestmentStrategyProducts = (props) => {
  return (
    <GridElement columns={12}>
      <StyledCollapsibleListItem
        details={
          <>
            <ProductsTable {...props} />
            <InvestmentStrategyDisclaimer />
          </>
        }
        detailsStyle={{
          paddingInline: SPACING_48,
          marginTop: 0,
          padding: 0,
          background: 'transparent',
        }}
        detailsInnerStyle={{
          gap: SPACING_8,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          marginTop: SPACING_8,
        }}
        expandedStyle={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        startExpanded={false}
        style={{ paddingBlock: SPACING_24, paddingInline: SPACING_24 }}
      >
        <div>
          <H3>{t('app:wealthPlan:investmentStrategy:portfolioInvestments')}</H3>
          <StyledParagraphXSmall>
            {t('app:wealthPlan:investmentStrategy:totalPortfolio')}
          </StyledParagraphXSmall>
        </div>
      </StyledCollapsibleListItem>
    </GridElement>
  );
};
