import React from 'react';

import { t } from '@formue-app/core';

import { SectionList } from '../lists';
import { EmptyPending } from './listItems';

import {
  TaskListItem,
  ExpertInputListItem,
  SigningTaskListItem,
} from './listItems';

import { H2 } from '../texts';
import { CenteredActivityIndicator } from '../common/ActivityIndicator';
import { accent } from '../../constants/colors';
import { GridElement } from '../layout';

export const TodoList = (props) => {
  const { loading } = props;

  const renderItem = (item) => {
    switch (item.type) {
      case 'expertInputs':
        return <ExpertInputListItem id={item.id} />;
      case 'signingEvents':
        return <SigningTaskListItem id={item.id} />;
      default:
        return <TaskListItem id={item.id} />;
    }
  };

  const renderSectionHeader = ({ section: { title, data } }) => {
    if (title === 'open' && data.length === 0) {
      return (
        <>
          <H2>{t(`app:todo:${title}`)}</H2>
          <EmptyPending {...props} />
        </>
      );
    } else if (data.length > 0) {
      return <H2>{t(`app:todo:${title}`)}</H2>;
    }
  };

  if (loading) {
    return (
      <GridElement columns="12">
        <CenteredActivityIndicator background={accent.sand1} />
      </GridElement>
    );
  }

  return (
    <SectionList
      {...props}
      ordered={true}
      renderItem={renderItem}
      keyExtractor={(item, index) => `todo-${index}`}
      renderSectionHeader={renderSectionHeader}
      listStyle={{ padding: 0 }}
    />
  );
};
