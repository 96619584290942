import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  getAssetClassName,
  selectors,
  sortByAssetGroup,
  sumAssetClassesByAssetGroup,
  t,
} from '@formue-app/core';

import {
  SPACING_24,
  SPACING_120,
  SPACING_88,
  SPACING_32,
  SPACING_16,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

import { H2, H3, ParagraphXSmall } from '../../texts';
import { GridElement, MainGrid } from '../../layout';

import { BaseSection } from '../../common/BaseSection';
import { Metrics } from '../../strategySimulator/Metrics';
import { FixedPyramidChart } from '../../wealthPlan/InvestmentSimulation/FixedPyramidChart';
import { AssetClassList } from '../../advisorsCorner/strategySimulator/AssetClassList';

const Wrapper = styled(MainGrid)`
  flex: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: ${SPACING_120};
  margin-bottom: ${SPACING_88};
  max-width: 1232px;
`;

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  width: 100%;
  border: 1px solid ${accent.sand2};
  overflow: hidden;
`;

const StyledFixedPyramidChart = styled(FixedPyramidChart)`
  margin-left: auto;
  margin-right: -17%;
  margin-bottom: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_32};
  width: 100%;
`;

const ContentHeader = styled.div`
  display: flex;
  gap: ${SPACING_24};
  align-items: center;
`;

const StyledMetrics = styled(Metrics)`
  width: 100%;
  flex-direction: row;

  & > div {
    flex: 50%;
  }
`;

const MetricsWrapper = styled.div`
  width: 100%;
  gap: ${SPACING_32};
  display: flex;
`;

const {
  entities: {
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsMetadataByIdSelector,
      investmentStrategyAssetAllocationsByStrategyIdSelector,
    },
    investmentStrategyIndexChanges: {
      investmentStrategyIndexChangesMetadataByIdSelector,
    },
  },
} = selectors;

export const StrategyDetails = (props) => {
  const { title, keys, showKeyFigures = true } = props;
  const { search } = useLocation();
  const strategyId = new URLSearchParams(search).get('strategyId');

  const indexChangesMetadata = useSelector(
    investmentStrategyIndexChangesMetadataByIdSelector(strategyId)
  );
  const assetAllocationMetadata = useSelector(
    investmentStrategyAssetAllocationsMetadataByIdSelector(strategyId)
  );
  const assetAllocations = useSelector(
    investmentStrategyAssetAllocationsByStrategyIdSelector(strategyId)
  );

  const [editableAssetAllocations, setEditableAssetAllocations] =
    useState(null);
  const [assetGroups, setAssetGroups] = useState([]);

  useEffect(() => {
    if (assetAllocations) {
      setEditableAssetAllocations(
        assetAllocations.filter(
          (assetClass) => assetClass.investmentStrategy.id === strategyId
        )
      );
    }
  }, [assetAllocations, strategyId]);

  useEffect(() => {
    if (editableAssetAllocations) {
      setAssetGroups(sumAssetClassesByAssetGroup(editableAssetAllocations));
    }
  }, [editableAssetAllocations]);

  return (
    <Wrapper>
      <GridElement columns={12}>
        <H2>{t(title)}</H2>
      </GridElement>

      <StyledBaseSection columns={12}>
        <Content>
          <ContentHeader>
            <H3>{t('app:advisor:strategySimulator:assetAllocation:title')}</H3>
          </ContentHeader>

          {editableAssetAllocations ? (
            <AssetClassList
              editMode={true}
              showInputs={false}
              editableAssetAllocations={editableAssetAllocations}
              strategyShareKey={keys.strategyShare}
              lowerLimitKey={keys.lowerLimit}
              upperLimitKey={keys.upperLimit}
            />
          ) : null}
        </Content>
        <StyledFixedPyramidChart
          height={414}
          data={assetGroups
            .map((item) => ({
              group: item.id,
              label: getAssetClassName(item.id),
              value: item.value,
            }))
            .sort(sortByAssetGroup)
            .reverse()}
          labelPosition="left"
        />
      </StyledBaseSection>

      {showKeyFigures ? (
        <StyledBaseSection style={{ flexDirection: 'column' }} columns={12}>
          <H3 style={{ marginBottom: SPACING_24 }}>
            {t(
              'app:advisor:strategySimulator:assetAllocation:strategyMetadataTitle'
            )}
          </H3>
          <ParagraphXSmall style={{ marginBottom: SPACING_16 }}>
            {t('app:advisor:strategySimulator:export:strategyDisclaimer')}
          </ParagraphXSmall>

          <MetricsWrapper>
            <StyledMetrics
              sectionTitle={t(
                'app:advisor:strategySimulator:assetAllocation:expected'
              )}
              returnTitle={t(
                'app:advisor:strategySimulator:assetAllocation:yearlyReturn'
              )}
              volatilityTitle={t(
                'app:advisor:strategySimulator:assetAllocation:yearlyVolatility'
              )}
              returnValue={assetAllocationMetadata?.expectedPerformance?.return}
              volatilityValue={
                assetAllocationMetadata?.expectedPerformance?.risk
              }
            />
            <StyledMetrics
              sectionTitle={t(
                'app:advisor:strategySimulator:assetAllocation:historical'
              )}
              returnTitle={t(
                'app:advisor:strategySimulator:assetAllocation:yearlyReturn'
              )}
              volatilityTitle={t(
                'app:advisor:strategySimulator:assetAllocation:yearlyVolatility'
              )}
              returnValue={
                indexChangesMetadata?.historicalPerformance?.annualAverageReturn
              }
              volatilityValue={
                indexChangesMetadata?.historicalPerformance?.annualAverageRisk
              }
            />
          </MetricsWrapper>
        </StyledBaseSection>
      ) : null}
    </Wrapper>
  );
};
