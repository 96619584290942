import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { H2, Paragraph } from '../texts';
import { BaseSection } from '../common/BaseSection';
import { selectors, t } from '@formue-app/core';
import { ButtonSmall } from '../buttons';
import { SPACING_12, SPACING_20, SPACING_48 } from '../../constants/spacing';
import { Checkbox } from '../formElements';
import { Modal } from '../common/Modal';
import { TermsAndConditionsNO } from './portfolioManagementContent/TermsAndConditionsNO';
import { TermsAndConditionsDK } from './portfolioManagementContent/TermsAndConditionsDK';
import { TermsAndConditionsSE } from './portfolioManagementContent/TermsAndConditionsSE';
import { useSelector } from 'react-redux';

const Container = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${SPACING_20};
`;

const AcceptanceWrapper = styled.div`
  display: flex;
  padding-top: ${SPACING_12};
  gap: ${SPACING_12};
`;

const { operatingCountrySelector } = selectors.auth;

export const InvestmentStrategyPortfolioManagement = ({
  termsAcceptedCallback,
  signingEnabled,
}) => {
  const operatingCountry = useSelector(operatingCountrySelector);

  const [termsAccepted, setTermsAccepted] = useState(!signingEnabled);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    termsAcceptedCallback(termsAccepted);
  }, [termsAccepted, termsAcceptedCallback]);

  return (
    <Container columns={12}>
      <Paragraph>
        {t('app:wealthPlan:investmentStrategy:portfolioManagement:description')}
      </Paragraph>
      <ButtonSmall
        buttonstyle="outline"
        label={t(
          'app:wealthPlan:investmentStrategy:portfolioManagement:buttonText'
        )}
        style={{ width: 'auto' }}
        onClick={() => {
          setModalOpen(true);
        }}
      />
      <AcceptanceWrapper>
        <Checkbox
          id="portfolio-management-terms"
          checked={termsAccepted}
          onChange={() => {
            setTermsAccepted(!termsAccepted);
          }}
          style={{ margin: 0 }}
          disabled={!signingEnabled}
        />
        <Paragraph
          style={{ fontWeight: 500, opacity: signingEnabled ? 1 : 0.5 }}
        >
          {t(
            'app:wealthPlan:investmentStrategy:portfolioManagement:acceptanceText'
          )}
        </Paragraph>
      </AcceptanceWrapper>

      <Modal
        contentLabel={t(
          'app:wealthPlan:investmentStrategy:portfolioManagement:title'
        )}
        isOpen={modalOpen}
        onRequestClose={() => {
          setModalOpen(false);
        }}
        contentWidth={1080}
        padding={SPACING_48}
        iconSize={20}
        labelComponent={H2}
      >
        {operatingCountry === 'no' && <TermsAndConditionsNO />}
        {operatingCountry === 'se' && <TermsAndConditionsSE />}
        {operatingCountry === 'dk' && <TermsAndConditionsDK />}
      </Modal>
    </Container>
  );
};
