import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  getSocialResponsibilityIndicators,
  localizeFloat,
  selectors,
  t,
} from '@formue-app/core';
import { BaseSection } from '../../../common/BaseSection';
import { H2, Paragraph, ParagraphXSmall } from '../../../texts';
import {
  accent,
  backgroundWhite,
  textMain,
} from '../../../../constants/colors';
import {
  SPACING_32,
  SPACING_12,
  SPACING_8,
  SPACING_48,
  BORDER_RADIUS_EXTRA_LARGE,
} from '../../../../constants/spacing';
import { Button } from '../../../buttons';
import { Modal } from '../../../common/Modal';
import { SustainabilityPieChart } from './SustainabilityPieChart';
import { SocialResponsibilityModalContent } from './SocialResponsibilityModalContent';

const i18nPrefix = 'app:sustainability:socialResponsibility';

const {
  entities: {
    portfolioLenses: {
      allPortfolioLensesSelector,
      portfolioLensesMetadataSelector,
    },
  },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_32};
  display: flex;
  flex-direction: column;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${SPACING_12};
  padding-bottom: ${SPACING_32};
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
`;

const PieChartContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: -2px;
`;

export const SocialResponsibility = (props) => {
  const {
    backgroundColor = backgroundWhite,
    textColor = textMain,
    chartColors = [accent.velvet1, accent.sand2],
    showModalButton = true,
  } = props;
  const portfolioLenses = useSelector(allPortfolioLensesSelector);
  const portfolioLensesMetadata = useSelector(portfolioLensesMetadataSelector);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  if (!portfolioLenses) return null;

  const indicators = getSocialResponsibilityIndicators(
    portfolioLenses,
    portfolioLensesMetadata
  );

  return (
    <StyledBaseSection {...props} style={{ backgroundColor }}>
      <Wrapper>
        <SustainabilityPieChart
          data={Array(5)
            .fill()
            .map((item, index) => ({
              value: 1,
              color: index > indicators - 1 ? chartColors[1] : chartColors[0],
            }))}
        >
          <PieChartContent>
            <H2 style={{ color: textColor }}>{indicators}</H2>
            <ParagraphXSmall style={{ top: -3, color: textColor }}>
              of 5
            </ParagraphXSmall>
          </PieChartContent>
        </SustainabilityPieChart>
      </Wrapper>
      <H2 style={{ color: textColor }}>{t(`${i18nPrefix}:title`)}</H2>
      <Paragraph style={{ padding: `${SPACING_8} 0`, color: textColor }}>
        {t(`${i18nPrefix}:entryText`, {
          value: localizeFloat(indicators, 0),
        })}
      </Paragraph>
      {showModalButton && (
        <>
          <ButtonWrapper>
            <Button
              onClick={() => setModalIsOpen(true)}
              style={{ marginTop: 32 }}
            >
              {t('app:globals:readMore')}
            </Button>
          </ButtonWrapper>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel={t('app:sustainability:socialResponsibility:title')}
            labelComponent={H2}
            padding={SPACING_48}
          >
            <SocialResponsibilityModalContent />
          </Modal>
        </>
      )}
    </StyledBaseSection>
  );
};
