import React from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { getAssetClassName, t } from '@formue-app/core';
import { H4, Paragraph } from '../../texts';
import { accent } from '../../../constants/colors';
import { SPACING_16, SPACING_20 } from '../../../constants/spacing';
import { BaseSection } from '../../common/BaseSection';

import { SpriteIcon } from '../../common/SpriteIcon';

const StyledBaseSection = styled(BaseSection)`
  padding: 16px 16px 0 16px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING_16} 0;
  border-bottom: 1px solid ${accent.sand1};

  &:last-of-type {
    border-bottom: none;
  }
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledText = styled(Paragraph)`
  font-weight: 500;
  margin-left: 15px;
`;

export const FundClassification = ({
  classification,
  assetClass,
  assetGroup,
  ...rest
}) => {
  const assetClassName = getAssetClassName(assetClass);
  const assetGroupName = getAssetClassName(assetGroup);
  return (
    <StyledBaseSection {...rest}>
      <StyledContainer>
        <H4>{t('app:portfolio:funds:classification')}</H4>
        <StyledRow>
          <StyledIconWrapper>
            <SpriteIcon id="Portfolio" size={SPACING_20} />
            <StyledText>{t('app:portfolio:funds:assetClass')}</StyledText>
          </StyledIconWrapper>
          <Paragraph>{assetClassName}</Paragraph>
        </StyledRow>
        <StyledRow>
          <StyledIconWrapper>
            <SpriteIcon id="Menu" size={SPACING_20} />
            <StyledText>{t('app:portfolio:funds:function')}</StyledText>
          </StyledIconWrapper>
          <Paragraph>{assetGroupName}</Paragraph>
        </StyledRow>
        <StyledRow>
          <StyledIconWrapper>
            <SpriteIcon id="Security" size={SPACING_20} />
            <StyledText>
              {t('app:portfolio:funds:productClassification')}
            </StyledText>
          </StyledIconWrapper>
          <Paragraph>
            {t(
              `app:portfolio:funds:productClassificationTypes:${camelcase(
                classification
              )}`
            )}
          </Paragraph>
        </StyledRow>
      </StyledContainer>
    </StyledBaseSection>
  );
};
