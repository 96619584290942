import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, t } from '@formue-app/core';

import { accent, textLightest } from '../../../constants/colors';
import { ANNUAL_REVIEW_CONTENT_SPACING } from '../../../constants/spacing';

import { slideAnimationDelay } from '../../../constants/animation';

import { GoalsList } from '../slideComponents/goals/GoalsList';
import { Question } from '../slideComponents/Question';
import { MultipleSwitch } from '../../formElements';

const {
  entities: {
    goals: { allGoalsSelector },
  },
} = selectors;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
  position: relative;
`;

const StyledMultipleSwitch = styled(MultipleSwitch)`
  position: absolute;
  top: -32px;
  right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

export const GoalsSlide = (props) => {
  const { isActiveSlide } = props;
  const goals = useSelector(allGoalsSelector);
  const [showCompleted, setShowCompleted] = useState(false);

  const inProgressGoals = goals.filter((item) => item.status !== 'Completed');
  const completedGoals = goals.filter((item) => item.status === 'Completed');

  return (
    <Wrapper>
      <StyledMultipleSwitch
        backgroundColor={accent.forest250}
        activeBackgroundColor={accent.forest1}
        color={accent.forest1}
        activeColor={textLightest}
        onChange={(value) => {
          setShowCompleted(value);
        }}
        options={[
          {
            label: t('app:presentation:annualReview:goals:current'),
            value: false,
          },
          {
            label: t('app:presentation:annualReview:goals:completed'),
            value: true,
          },
        ]}
      />
      <GoalsList
        isActiveSlide={isActiveSlide}
        goals={showCompleted ? completedGoals : inProgressGoals}
      />
      <Question
        isActiveSlide={isActiveSlide}
        bubbleColor={accent.forest1}
        textColor={accent.forest250}
        questionText={t('app:presentation:annualReview:goals:question')}
        customAnimationDelay={
          slideAnimationDelay * 1.2 + inProgressGoals.length * 0.2
        }
        vertical={true}
      />
    </Wrapper>
  );
};
