import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  selectors,
  t,
  truncateString,
  usePortfolioRelationshipFilter,
} from '@formue-app/core';

import { NotMobileWrapper } from '../../../constants/media';
import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_24,
  SPACING_8,
  SPACING_12,
  SPACING_4,
} from '../../../constants/spacing';
import { backgroundWhite } from '../../../constants/colors';
import { easeInOut, shortAnimationTime } from '../../../constants/animation';
import { MobileWrapper } from '../../../constants/media';

import { ParagraphSmall } from '../../../components/texts';
import { SpriteIcon } from '../../../components/common/SpriteIcon';
import { H5 } from '../../texts';
import { useSelector } from 'react-redux';

const StrategyPickerWrapper = styled.div`
  display: flex;
  gap: ${SPACING_16};
  align-items: center;
  position: fixed;
  bottom: ${SPACING_24};
  background: ${backgroundWhite};
  border-radius: ${BORDER_RADIUS_LARGE};
  padding: ${SPACING_8} ${SPACING_16};
  cursor: pointer;

  transition: bottom ${easeInOut} ${shortAnimationTime};
  z-index: 2;
  opacity: 0;
  left: ${SPACING_24};

  ${(props) =>
    props.show &&
    css`
      bottom: ${SPACING_24};
      opacity: 1;
    `}
`;

const MobilePill = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_12};
  background: ${backgroundWhite};
  padding: ${SPACING_8} ${SPACING_12};
  border-radius: ${SPACING_24};

  position: fixed;
  z-index: 2;
  opacity: 0;
  transition: all ${easeInOut} ${shortAnimationTime};
  top: -51px;
  right: ${SPACING_16};

  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      top: calc(51px + ${SPACING_16});
    `}
`;

const StrategyIcon = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_4};
`;
const {
  entities: {
    strategy: { allStrategiesSelector },
  },
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

export const FilterPill = (props) => {
  const options = usePortfolioRelationshipFilter();
  const allStrategies = useSelector(allStrategiesSelector);
  const activeStrategyId = useSelector(activeStrategySelector);
  let [activeStrategy, setActiveStrategy] = useState();

  useEffect(() => {
    if (allStrategies.length > 0 && activeStrategyId) {
      setActiveStrategy(
        allStrategies.find((strategy) => strategy.id === activeStrategyId)
      );
    }
  }, [activeStrategyId, allStrategies]);

  const { showStrategyFilter, showPortfolioFilter, setFilterModalOpen } = props;
  return (
    <>
      <MobileWrapper>
        <MobilePill
          show={showStrategyFilter || showPortfolioFilter}
          onClick={() => setFilterModalOpen(true)}
        >
          <StrategyIcon>
            {showStrategyFilter ? <SpriteIcon id="Strategy" size={16} /> : null}
            {showPortfolioFilter ? (
              <SpriteIcon id="Individual" size={16} />
            ) : null}
            <H5>
              {showStrategyFilter
                ? 1
                : options.filter((item) => item.selected).length}
            </H5>
          </StrategyIcon>
          <SpriteIcon id="Filter" navigational={true} />
        </MobilePill>
      </MobileWrapper>
      <NotMobileWrapper>
        <StrategyPickerWrapper
          show={showStrategyFilter || showPortfolioFilter}
          onClick={() => setFilterModalOpen(true)}
        >
          <SpriteIcon id="Filter" navigational={true} />
          <div style={{ display: 'flex', gap: SPACING_8 }}>
            <ParagraphSmall>
              {t('app:report:annualReview:filters:clients')}:{' '}
              <span style={{ fontWeight: 500 }}>
                {truncateString(
                  `${usePortfolioRelationshipFilter()
                    .filter((item) => item.selected)
                    .map((item) => item.name)}`,
                  20
                )}
              </span>
            </ParagraphSmall>
            <ParagraphSmall>|</ParagraphSmall>
            <ParagraphSmall>
              {t('app:report:annualReview:filters:strategy')}:{' '}
              <span style={{ fontWeight: 500 }}>
                {t(
                  `app:presentation:annualReview:portfolioStrategy:strategyTypes:${activeStrategy?.strategyType}`
                )}
              </span>
            </ParagraphSmall>
          </div>
        </StrategyPickerWrapper>
      </NotMobileWrapper>
    </>
  );
};
