import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import camelcase from 'camelcase';

import { toLocaleDateString, t } from '@formue-app/core';

import { accessTokenSelector } from '../../store/auth/selectors';

import { tabletCondition, mobileCondition } from '../../constants/media';
import { StyledCell } from '../lists/TableList';
import { UnreadIndicator } from '../common/UnreadIndicator';
import { H6, H5, H4, Paragraph } from '../texts';

import { SpriteIcon } from '../common/SpriteIcon';
import { SPACING_16 } from '../../constants/spacing';

const LightStyledCell = styled(StyledCell)`
  span {
    font-weight: 300;
  }
`;

const DocumentNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const useDocumentsListColumns = (props) => {
  const items = props;

  const tabletSize = useMediaQuery({
    query: tabletCondition,
  });

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  const accessToken = useSelector(accessTokenSelector);
  const isImpersonating = useSelector((state) => state.ui.user.isImpersonating);
  const isEmployeeClientUser = useSelector(
    (state) => state.ui.user.isEmployeeClientUser
  );
  const shouldBeAbleToOpenDocuments = !isImpersonating || isEmployeeClientUser;

  if (!items.length) {
    return null;
  }

  const generateDownloadLink = (url) => `${url}?secret_token=${accessToken}`;

  const renderDownloadLink = ({ url, name }) =>
    shouldBeAbleToOpenDocuments && (
      <a
        href={generateDownloadLink(url)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SpriteIcon id="Download" size={SPACING_16} />
      </a>
    );

  const renderUnreadIndicator = ({ read }) => {
    return <UnreadIndicator isUnread={!read} />;
  };

  // Define the table columns
  let columns = [
    { key: 'unread', label: null, render: renderUnreadIndicator, width: '5%' },
    {
      key: 'name',
      label: t('app:documents:table:name'),
      primary: true,
      width: '40%',
    },
    {
      key: 'relationship',
      label: t('app:documents:table:relationship'),
      width: '20%',
      component: LightStyledCell,
    },
    {
      key: 'contentType',
      label: t('app:documents:table:type'),
      render: ({ contentType }) =>
        t(`app:documents:documentTypes:${camelcase(contentType)}`),
      width: '20%',
      component: LightStyledCell,
    },
    {
      key: 'modifiedAt',
      label: t('app:documents:table:date'),
      render: ({ modifiedAt }) => toLocaleDateString(modifiedAt),
      width: '10%',
    },
    { key: 'url', label: null, render: renderDownloadLink, width: '5%' },
  ];

  if (tabletSize) {
    columns = [
      {
        key: 'unread',
        label: null,
        render: renderUnreadIndicator,
        width: '5%',
      },
      {
        key: 'name',
        label: t('app:documents:table:name'),
        render: ({ name, relationship, modifiedAt }) => (
          <DocumentNameWrapper>
            <H5>{toLocaleDateString(modifiedAt)}</H5>
            <H4>{name}</H4>
            <Paragraph>{relationship}</Paragraph>
          </DocumentNameWrapper>
        ),
        width: '65%',
      },
      {
        key: 'contentType',
        label: t('app:documents:table:type'),
        render: ({ contentType }) =>
          t(`app:documents:documentTypes:${camelcase(contentType)}`),
        width: '25%',
      },
      { key: 'url', label: null, render: renderDownloadLink, width: '5%' },
    ];
  } else if (mobileSize) {
    columns = [
      {
        key: 'name',
        label: t('app:documents:table:name'),
        render: ({ name, read, relationship, modifiedAt, contentType }) => (
          <DocumentNameWrapper>
            <H6>
              {renderUnreadIndicator({ read })}{' '}
              {t(`app:documents:documentTypes:${camelcase(contentType)}`)} –{' '}
              {toLocaleDateString(modifiedAt)}
            </H6>
            <H4>{name}</H4>
            <Paragraph>{relationship}</Paragraph>
          </DocumentNameWrapper>
        ),
        width: '95%',
      },
      { key: 'url', label: null, render: renderDownloadLink, width: '5%' },
    ];
  }

  return columns;
};
