import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import {
  selectors,
  formatBigMoney,
  percentage,
  t,
  getAssetClassName,
} from '@formue-app/core';

import { H2Small, H3 } from '../../texts';

import { accent, textLightest } from '../../../constants/colors';
import { easeInOut, shortAnimationTime } from '../../../constants/animation';
import { BORDER_RADIUS_SMALL, SPACING_16 } from '../../../constants/spacing';
import { PortfolioConstructionPyramidStaticLabel } from './PortfolioConstructionPyramidStaticLabel';
import { PortfolioPyramid } from '../../common/PortfolioPyramid';

const LabelValues = styled.div`
  display: flex;
  gap: ${(props) => Math.min(7 / props.scaler, 10)}px;

  ${(props) =>
    props.labelPosition === 'right'
      ? css`
          justify-content: flex-start;
        `
      : css`
          justify-content: flex-end;
        `}
`;

const Label = styled(H3)`
  transition: all ${easeInOut} ${shortAnimationTime};

  span {
    color: ${textLightest};
    font-weight: 500;
  }
`;

const LabelStrategy = styled(Label)`
  margin-bottom: 0;
`;

const ProgressBar = styled.div`
  height: ${SPACING_16};
  width: 350px;
  background: ${accent.sand2};
  border-radius: ${BORDER_RADIUS_SMALL};
  overflow: hidden;
`;

const ProgressBarInner = styled.div`
  height: 100%;
`;

const {
  entities: {
    strategy: { allStrategiesSelector },
  },
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

export const PortfolioConstructionPyramid = (props) => {
  const {
    isActiveSlide = true,
    height = 364,
    visibleType = 'percent',
    labelPosition = 'right',
    setActiveItem = () => {},
    valueColor = textLightest,
    labelColor = accent.velvet2,
    progressBar,
    staticLabels,
  } = props;

  const [activeStrategy, setActiveStrategy] = useState(null);
  const [pyramidLabels, setPyramidLabels] = useState([]);
  const [activeSlice] = useState(undefined);

  const allStrategies = useSelector(allStrategiesSelector);
  const activeStrategyId = useSelector(activeStrategySelector);

  useEffect(() => {
    if (allStrategies.length && activeStrategyId) {
      setActiveStrategy(
        allStrategies.find((strategy) => strategy.id === activeStrategyId)
      );
    }
  }, [allStrategies, activeStrategyId]);

  useEffect(() => {
    setActiveItem(pyramidLabels[activeSlice]);
  }, [activeSlice, setActiveItem, pyramidLabels]);

  const scaler = 364 / height;

  useEffect(() => {
    if (activeStrategy) {
      // We use 364 as the base size for the pyramid scaling everything according to that

      const alternativeGrowth = activeStrategy.assetGroups.find(
        (item) => item.assetGroup === 'AV'
      );
      const marketGrowth = activeStrategy.assetGroups.find(
        (item) => item.assetGroup === 'MV'
      );
      const stability = activeStrategy.assetGroups.find(
        (item) => item.assetGroup === 'S'
      );

      setPyramidLabels([
        {
          labelKey: 'AV',
          translationKey: 'alternativeGrowth',
          value: alternativeGrowth.marketShare,
          strategy: alternativeGrowth.strategyShare,
          offset: -(80 / scaler),
          color: '#C76FF2',
        },
        {
          labelKey: 'MV',
          translationKey: 'marketGrowth',
          value: marketGrowth.marketShare,
          strategy: marketGrowth.strategyShare,
          offset: -(10 / scaler),
          color: '#C5A5D6',
        },
        {
          labelKey: 'S',
          translationKey: 'stability',
          value: stability.marketShare,
          strategy: stability.strategyShare,
          offset: 50 / scaler,
          color: '#6D4694',
        },
      ]);
    }
  }, [activeStrategy, height, scaler]);

  if (!activeStrategy) {
    return null;
  }

  return (
    <PortfolioPyramid
      {...props}
      labels={pyramidLabels}
      labelComponent={(item) =>
        staticLabels ? (
          <PortfolioConstructionPyramidStaticLabel labelKey={item.labelKey} />
        ) : (
          <>
            <Label
              isActiveSlice={activeSlice === item.index}
              style={{
                fontSize: '0.5em',
                lineHeight: '100%',
                color: labelColor,
              }}
            >
              {getAssetClassName(item.labelKey)}
            </Label>
            <H2Small
              style={{
                color: valueColor,
                fontSize: '0.8em',
                lineHeight: '0.8em',
              }}
              isActiveSlice={activeSlice === item.index}
            >
              {visibleType === 'percent'
                ? percentage(item.value * 100, 1)
                : null}
              {visibleType === 'money'
                ? formatBigMoney(activeStrategy.marketValue * item.value, false)
                : null}
            </H2Small>
            {progressBar ? (
              <ProgressBar>
                <ProgressBarInner
                  style={{
                    width: `${item.strategy * 100}%`,
                    backgroundColor: item.color,
                  }}
                />
              </ProgressBar>
            ) : (
              <LabelValues labelPosition={labelPosition} scaler={scaler}>
                <LabelStrategy
                  isActiveSlice={activeSlice === item.index}
                  isActiveSlide={isActiveSlide}
                  style={{
                    fontSize: 'max(0.4em, 10px)',
                    lineHeight: '100%',
                    color: labelColor,
                  }}
                >
                  {t(
                    `app:presentation:annualReview:portfolioConstruction:strategy`
                  )}
                </LabelStrategy>
                <LabelStrategy
                  style={{
                    color: valueColor,
                    fontSize: 'max(0.4em, 10px)',
                    lineHeight: '100%',
                  }}
                  isActiveSlice={activeSlice === item.index}
                >
                  {percentage(item.strategy * 100, 1)}
                </LabelStrategy>
              </LabelValues>
            )}
          </>
        )
      }
    />
  );
};
