import React from 'react';
import styled, { css } from 'styled-components';

import { localizeFloat, t, truncateString } from '@formue-app/core';
import { H2, H4, ParagraphSmall } from '../../../texts';
import { backgroundWhite, accent } from '../../../../constants/colors';
import { SectorIcon } from './SectorIcon';
import { numbersFontStack } from '../../../../fonts';
import { easeInOut, longAnimationTime } from '../../../../constants/animation';
import { SPACING_4 } from '../../../../constants/spacing';

const StyledBubble = styled.div`
  border-radius: 25px;
  position: absolute;
  padding: 5px;
  width: ${(props) => props.size * props.scale}px;
  height: ${(props) => props.size * props.scale}px;
  top: ${(props) => props.position.y * props.scale}px;
  left: ${(props) => props.position.x * props.scale}px;
  border-radius: ${(props) => (props.size * props.scale) / 2}px;
  background: ${(props) =>
    !props.highlight ? 'transparent' : props.featuredBackgroundColor};
  border-width: 2;
  border-color: ${(props) =>
    !props.highlight ? props.borderColor : 'transparent'};
  border-style: solid;

  scale: ${(props) => (props.animate ? 1 : 0)};
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: ${(props) => props.index * 0.2 + 0.6}s;
`;

const StyledBubbleContent = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledLabel = styled(ParagraphSmall)`
  font-size: 11px;
  width: 66px;
  text-align: center;
  padding-bottom: 6px;
`;

const StyledH2 = styled(H2)`
  font-family: ${numbersFontStack};
  font-weight: 500;
  padding-top: 6px;
  padding-bottom: 2px;
  margin-top: ${SPACING_4};
`;

const StyledH4 = styled(H4)`
  font-family: ${numbersFontStack};
  padding-top: 6px;
  padding-bottom: 2px;
  margin-top: ${SPACING_4};
`;

const StyledSectorIcon = styled(SectorIcon)`
  width: 25px;
  max-width: 70%;
  display: flex;

  svg {
    vertical-align: baseline;
  }

  ${(props) =>
    props.iconColor &&
    css`
      svg {
        path {
          fill: ${props.iconColor};
        }
      }
    `}
`;

export const Bubble = ({
  size = 15,
  position,
  animated,
  defaultPosition,
  onPress,
  sector,
  highlight,
  partOfKnownSectorPortfolio,
  scaleMax,
  scaleMin,
  numberOfCompanies,
  scale = 1,
  animate,
  index,
  borderColor = accent.raspberry1,
  featuredIconColor,
  defaultIconColor = backgroundWhite,
  featuredTextColor = accent.ocean490,
  featuredBackgroundColor = backgroundWhite,
}) => {
  const percentage = localizeFloat(partOfKnownSectorPortfolio * 100);
  return (
    <StyledBubble
      size={size}
      scale={scale}
      position={position}
      highlight={highlight}
      animate={animate}
      index={index}
      borderColor={borderColor}
      featuredBackgroundColor={featuredBackgroundColor}
    >
      <StyledBubbleContent style={{ scale: highlight ? scale : 1 }}>
        <StyledSectorIcon
          sector={sector}
          iconColor={size > 80 ? featuredIconColor : defaultIconColor}
        />
        {highlight &&
          (size > 120 ? (
            <StyledH2 style={{ color: featuredTextColor }}>
              {percentage}
            </StyledH2>
          ) : (
            <StyledH4 style={{ color: featuredTextColor }}>
              {percentage}
            </StyledH4>
          ))}
        {highlight && (
          <StyledLabel style={{ color: featuredTextColor }}>
            {truncateString(t(`app:sectors:${sector}:title`), 10)}
          </StyledLabel>
        )}
      </StyledBubbleContent>
    </StyledBubble>
  );
};
