import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectors, t } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { ANNUAL_REVIEW_CONTENT_SPACING } from '../../../constants/spacing';

import { H1 } from '../../texts';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';

import { SectorsBubbleChart } from '../../portfolio/lenses/sectors/SectorsBubbleChart';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: 0;
  align-items: flex-start;
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 50%;
  position: relative;
`;

const {
  entities: {
    securities: { securitiesBySectorSelector },
  },
} = selectors;

export const Sectors = (props) => {
  const { titleColor, highlightColor, isActiveSlide } = props;

  const bySector = useSelector(securitiesBySectorSelector);
  const topSector = Object.values(bySector).sort(
    (a, b) => b.partOfKnownSectorPortfolio - a.partOfKnownSectorPortfolio
  )[0];

  return (
    <Wrapper>
      {topSector ? (
        <>
          <ContentWrapper>
            <H1
              style={{
                color: accent[titleColor],
              }}
            >
              <Trans
                i18nKey={t('app:presentation:annualReview:sectors:pageTitle')}
                values={{
                  numberOfSectors: Object.keys(bySector).length,
                  topSector: t(`app:sectors:${topSector.sector}:title`),
                }}
                components={{
                  strong: (
                    <span
                      style={{ color: accent[highlightColor], fontWeight: 700 }}
                    />
                  ),
                }}
              />
            </H1>
          </ContentWrapper>

          <ContentWrapper>
            <SectorsBubbleChart
              scale={1.3}
              style={{ marginLeft: 'auto' }}
              animate={isActiveSlide}
              sectors={bySector}
            />
          </ContentWrapper>
        </>
      ) : (
        <CenteredActivityIndicator
          columns={12}
          size={60}
          loadingColor={accent[highlightColor]}
        />
      )}
    </Wrapper>
  );
};
