import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { localizeFloat, selectors, t } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_32,
  SPACING_64,
  ANNUAL_REVIEW_REPORT_CONTENT_SPACING,
} from '../../../constants/spacing';
import { H2Size } from '../../../constants/text';
import {
  easeInOut,
  extraLongAnimationTime,
} from '../../../constants/animation';
import { titleFontStack } from '../../../fonts';

import { H3 } from '../../texts';

import { HorizontalBarChart } from '../../graphs/HorizontalBarChart';
import { LensesText } from '../investmentStrategyComponents/LensesText';
import { SelectDropdown } from '../../formElements';
import { DotIcon } from '../../common/DotIcon';
import { useEnabledStrategies } from '../slides/useEnabledStrategies';
import { ActivityIndicator } from '../../common/ActivityIndicator';

const Base = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: ${SPACING_64};
  align-items: center;
  position: relative;
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

// This is a bit of a hack to style the label, but there is unfortnetly no good way to pass styles
// down to the chart
const GraphWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 300px;

  h5 {
    color: ${(props) => accent[props.highlightColor]};
  }

  p {
    color: ${(props) => accent[props.titleColor]};
  }

  h5,
  p {
    font-size: ${H2Size};
    font-family: ${titleFontStack};
    font-weight: 700;
  }
`;

const {
  entities: {
    investmentStrategyPortfolioCharacteristics: {
      investmentStrategyPortfolioCharacteristicsByStrategyIdSelector,
    },
  },
  auth: { operatingCountrySelector },
} = selectors;

export const SustainabilitySlide = (props) => {
  const { isActiveSlide, titleColor, highlightColor } = props;

  const strategies = useEnabledStrategies();

  const [selectedStrategy, setSelectedStrategy] = useState(strategies[0]);
  const portfolioCharacteristics = useSelector(
    investmentStrategyPortfolioCharacteristicsByStrategyIdSelector(
      selectedStrategy?.id
    )
  );

  const operatingCountry = useSelector(operatingCountrySelector);

  const local =
    portfolioCharacteristics?.sustainability?.carbonFootprint >
    portfolioCharacteristics?.sustainability?.domesticBenchmark
      ? 'more'
      : 'less';

  const global =
    portfolioCharacteristics?.sustainability?.carbonFootprint >
    portfolioCharacteristics?.sustainability?.globalBenchmark
      ? 'more'
      : 'less';

  const title = `${t('app:esg:carbonFootprint:moreLess1')} ${t(
    `app:globals:${local}`
  )} ${t('app:esg:carbonFootprint:moreLess2')} ${t(
    `app:globals:${global}`
  )} ${t('app:esg:carbonFootprint:moreLess3')}`;

  return (
    <Base>
      <SelectDropdown
        options={strategies.map((strategy) => ({
          ...strategy,
          label: strategy.title,
          value: strategy.id,
        }))}
        icon={(item) => <DotIcon radius={8} color={item?.color} />}
        optionsIcon={(item) => <DotIcon radius={8} color={item?.color} />}
        selectedCallback={(options) => setSelectedStrategy(options[0])}
        subtitle={t('app:portfolio:portfolioStrategy:chooseStrategy')}
        style={{
          position: 'absolute',
          right: ANNUAL_REVIEW_REPORT_CONTENT_SPACING,
          top: -43,
          zIndex: 1,
        }}
      />

      {!portfolioCharacteristics ? (
        <ActivityIndicator />
      ) : (
        <>
          <ContentWrapper
            style={{ alignItems: 'center', width: '64%', paddingRight: 24 }}
          >
            <GraphWrapper
              titleColor={titleColor}
              highlightColor={highlightColor}
            >
              <HorizontalBarChart
                style={{ width: '90%' }}
                barWidth={50}
                valueMargin={35}
                barPadding={12}
                animate={isActiveSlide}
                animationDelay={900}
                data={[
                  {
                    label: t('app:esg:localization:your'),
                    value:
                      portfolioCharacteristics.sustainability.carbonFootprint,
                    color: accent.moss3,
                    barType: 'rounded',
                    delay: 0,
                  },
                  {
                    label: t('app:esg:localization:country', {
                      context: operatingCountry,
                    }),
                    value:
                      portfolioCharacteristics.sustainability.domesticBenchmark,
                    color: accent.moss1,
                    barType: 'triangle',
                    delay: 100,
                  },
                  {
                    label: t('app:esg:localization:world'),
                    value:
                      portfolioCharacteristics.sustainability.globalBenchmark,
                    color: accent.moss2,
                    barType: 'triangleInverted',
                    delay: 200,
                  },
                ]}
                angle={15}
              />

              <H3
                style={{
                  color: accent[titleColor],
                  maxWidth: '50%',
                  marginTop: SPACING_32,

                  bottom: isActiveSlide ? 0 : -25,
                  position: 'relative',
                  opacity: isActiveSlide ? 1 : 0,
                  transition: `all ${easeInOut} ${extraLongAnimationTime} 1.4s`,
                }}
              >
                {title}
              </H3>
            </GraphWrapper>
            {/* Waiting for data */}
            {/* <SummaryWrapper>
              <SustainabilitySummary
                pieChartData={formatPieChartData(
                  [{ amount: 92 }, { amount: 8 }],
                  [accent.forest2, accent.forest1],
                  true
                )}
              >
                <SummaryInnerWrapper>
                  <H2>{percentage(0.08, 0, false, false)}</H2>
                  <ParagraphSmall>
                    Share of sustainable investments
                  </ParagraphSmall>
                </SummaryInnerWrapper>
              </SustainabilitySummary>
              <SustainabilitySummary
                pieChartData={formatPieChartData(
                  [{ amount: 180 }, { amount: 50 }, { amount: 100 }],
                  [accent.forest3, accent.forest2, accent.forest1],
                  true
                )}
              >
                <SummaryInnerWrapper>
                  <ListItem color={accent.forest1}>
                    Sustainable investments (art.9) 45%
                  </ListItem>
                  <ListItem color={accent.forest2}>
                    ESG integrated (art.8) 25%
                  </ListItem>
                  <ListItem color={accent.forest3}>Undefined 30%</ListItem>
                </SummaryInnerWrapper>
              </SustainabilitySummary>
            </SummaryWrapper> */}
          </ContentWrapper>
          <ContentWrapper
            style={{
              justifyContent: 'center',
              width: 'auto',
              flex: 1,
            }}
          >
            <LensesText
              i18nKey={t('app:presentation:annualReview:esg:pageTitle')}
              values={{
                carbonFootprint: localizeFloat(
                  portfolioCharacteristics.sustainability.carbonFootprint
                ),
              }}
              style={{ marginBottom: 300 }}
            />
          </ContentWrapper>
        </>
      )}
    </Base>
  );
};
