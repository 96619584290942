import React, { useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { t } from '@formue-app/core';

import {
  SPACING_48,
  SPACING_32,
  SPACING_16,
  SPACING_88,
  SPACING_8,
} from '../../../constants/spacing';
import { accent, textLightest } from '../../../constants/colors';
import { desktopCondition, mobileCondition } from '../../../constants/media';

import { PortfolioConstructionPyramid } from '../../portfolio/strategy/PortfolioConstructionPyramid';
import { ReportTitle } from '../reportComponent/ReportTitle';
import { MultipleSwitch } from '../../formElements';
import { Modal } from '../../common/Modal';
import { H3, Paragraph } from '../../texts';

import { ReactComponent as StabilityIcon } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-stability-icon-small.svg';
import { ReactComponent as MarketGrowthIcon } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-market-growth-icon-small.svg';
import { ReactComponent as AlternativeGrowthIcon } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-alternative-growth-icon-small.svg';
import { ButtonSmall } from '../../buttons/Button';

const pyramidMobileSize = 364;
const pyramidDesktopSize = 450;

const PyramidWrapper = styled.div`
  overflow: hidden;
  width: 100vw;

  @media ${desktopCondition} {
    width: 100%;
  }
`;

const StyledPortfolioConstructionPyramid = styled(PortfolioConstructionPyramid)`
  width: 100%;
  margin-top: ${SPACING_88};

  @media ${desktopCondition} {
    //458 = pyramid width
    margin-right: 100%;
    translate: calc(-50% + ${pyramidDesktopSize}px / 2);
  }

  @media ${mobileCondition} {
    //371 = pyramid width
    margin-top: ${SPACING_48};
    overflow: hidden;
    width: calc(100% + ${pyramidMobileSize}px / 2);
  }
`;

const StyledMultipleSwitch = styled(MultipleSwitch)`
  margin-top: ${SPACING_48};
`;

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${SPACING_8};
  gap: ${SPACING_8};
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${SPACING_32};
  gap: ${SPACING_16};

  @media ${mobileCondition} {
    flex-direction: column;

    & > div {
      margin-right: 0;
    }
  }
`;

export const PortfolioConstructionSection = (props) => {
  const { backgroundColor, title, isVisible, ...rest } = props;

  const [visibleType, setVisibleType] = useState('percent');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  const groups = ['alternativeGrowth', 'marketGrowth', 'stability'];

  const icons = {
    stability: <StabilityIcon />,
    marketGrowth: <MarketGrowthIcon />,
    alternativeGrowth: <AlternativeGrowthIcon />,
  };

  return (
    <>
      <ReportTitle
        {...rest}
        title={title}
        style={{ marginBottom: SPACING_32 }}
      />
      <ButtonSmall
        buttonstyle="tonal"
        style={{ marginBottom: 'auto' }}
        onClick={() => setModalIsOpen(true)}
        textcolor={textLightest}
        buttoncolorhover={accent.velvet190}
        buttoncoloractive={accent.velvet1}
        buttoncolor={accent.velvet1}
      >
        {t('app:globals:readMore')}
      </ButtonSmall>
      <PyramidWrapper>
        <StyledPortfolioConstructionPyramid
          isActiveSlide={isVisible}
          enableClick={false}
          visibleType={visibleType}
          height={mobileSize ? pyramidMobileSize : pyramidDesktopSize}
          labelPosition="left"
        />
      </PyramidWrapper>

      <StyledMultipleSwitch
        activeBackgroundColor={accent.velvet1}
        color={accent.velvet1}
        backgroundColor={accent.velvet270}
        activeColor={textLightest}
        onChange={(value) => {
          setVisibleType(value);
        }}
        options={[
          {
            label: t(
              'app:presentation:annualReview:returnPerYear:returnInPercentage'
            ),
            value: 'percent',
          },
          {
            label: t('app:presentation:annualReview:returnPerYear:returnInKr'),
            value: 'money',
          },
        ]}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentWidth={1064}
        backgroundColor={accent.sand1}
      >
        <ModalContentWrapper>
          {groups.reverse().map((key, index) => (
            <div key={`group-${key}`}>
              <SectionHeader>
                {icons[key]}
                <H3>{t(`app:portfolio:structure:${key}:title`)}</H3>
              </SectionHeader>
              <Paragraph>{t(`app:portfolio:structure:${key}:text`)}</Paragraph>
            </div>
          ))}
        </ModalContentWrapper>
      </Modal>
    </>
  );
};
