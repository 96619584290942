import React from 'react';
import { useSelector } from 'react-redux';
import { sumBy } from 'lodash';
import styled from 'styled-components';

import {
  selectors,
  t,
  useResource,
  usePortfolioChartDataDates,
} from '@formue-app/core';

import { SPACING_8 } from '../../../constants/spacing';
import { accent, backgroundWhite } from '../../../constants/colors';
import { H6 } from '../../texts';
import { GainTitle } from './GainTitle';
import { GainValue } from './GainValue';
import { PortfolioActivityIndicator } from './PortfolioActivityIndicator';

const {
  entities: {
    portfolios: { portfolioTotalSelector },
  },
} = selectors;

const Wrapper = styled.div``;

const StyledTitle = styled(H6)`
  color: ${accent.velvet2};
  margin-bottom: ${SPACING_8};
`;

export const PortfolioGainTotal = (props) => {
  const {
    color = backgroundWhite,
    highlightColor = accent.velvet2,
    selectedClasses = [],
    ...rest
  } = props;
  const portfolioTotal = useSelector(portfolioTotalSelector);
  const { startDate, endDate } = usePortfolioChartDataDates('twr');
  const { period: activePeriod } = useSelector(
    (state) => state.ui.portfolio.filters
  );
  const loading = useResource(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);

  if (loading) {
    return (
      <PortfolioActivityIndicator
        style={{ alignSelf: 'flex-start' }}
        highlightColor={highlightColor}
        loadingColor={highlightColor}
        size={24}
      />
    );
  }

  // If we have no data
  if (!portfolioTotal) {
    return (
      <Wrapper {...rest}>
        <StyledTitle>{t('app:errorMessages:noPortfolio:title')}</StyledTitle>
      </Wrapper>
    );
  }

  const { gain, twr } = portfolioTotal;

  const gainValue = selectedClasses.length
    ? sumBy(selectedClasses, (selectedClass) => selectedClass.gain)
    : gain;

  return (
    <Wrapper {...rest}>
      <GainTitle
        selectedClasses={selectedClasses}
        highlightColor={highlightColor}
        fromDate={startDate}
        toDate={activePeriod === 'CUSTOM' ? endDate : null}
      />
      <GainValue
        value={gainValue}
        twr={twr}
        color={color}
        selectedClasses={selectedClasses}
      />
    </Wrapper>
  );
};
