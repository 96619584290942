import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { percentage, t, selectors } from '@formue-app/core';
import { H2, ParagraphLarge } from '../../../texts';
import { SustainabilityPieChart } from './SustainabilityPieChart';
import { accent } from '../../../../constants/colors';
import { SPACING_32, SPACING_64 } from '../../../../constants/spacing';

const {
  entities: {
    portfolioLenses: { temperatureRiseDataSelector },
  },
} = selectors;

const Container = styled.div`
  padding: ${SPACING_32} 0;
`;

const RowWrapper = styled.div`
  display: flex;
`;

const TextWrapper = styled.div`
  padding: 0 ${SPACING_64} 0 ${SPACING_32};
`;

export const ContributionToParisAgreement = ({
  navigation,
  value = 33,
  ...rest
}) => {
  const temperatureRiseData = useSelector(temperatureRiseDataSelector);

  if (!temperatureRiseData) return null;

  const {
    companiesBelow_1_5,
    companies_1_5_to_2,
    companies_2_to_3_2,
    companiesAbove_3_2,
  } = temperatureRiseData;

  return (
    <Container {...rest}>
      <RowWrapper>
        <SustainabilityPieChart
          size={120}
          holeRadius={40}
          data={[
            {
              value: companiesBelow_1_5 + companies_1_5_to_2,
              color: accent.forest170,
            },
            {
              value: companies_2_to_3_2 + companiesAbove_3_2,
              color: accent.raspberry370,
            },
          ]}
        />
        <TextWrapper>
          <H2 style={{ paddingBottom: 4 }}>
            {percentage(
              (companiesBelow_1_5 + companies_1_5_to_2) * 100,
              0,
              false,
              false
            )}
          </H2>

          <ParagraphLarge
            style={{
              fontWeight: 400,
              fontSize: 22,
            }}
          >
            <Trans
              i18nKey={t(
                'app:sustainability:accordions:parisAgreement:shareOfCompaniesContributing'
              )}
              components={{
                strong: (
                  <ParagraphLarge
                    style={{
                      fontWeight: 400,
                      fontSize: 22,
                    }}
                  />
                ),
              }}
            />
          </ParagraphLarge>
        </TextWrapper>
      </RowWrapper>
    </Container>
  );
};
