import React from 'react';
import { useSelector } from 'react-redux';

import { t, selectors } from '@formue-app/core';
import { BaseSection } from '../common/BaseSection';
import { StyledGridElement } from '../layout';
import { Paragraph, H2 } from '../texts';

const { operatingCountrySelector } = selectors.auth;

export const LegalDisclaimer = (props) => {
  const { columns, ...rest } = props;

  const operatingCountry = useSelector(operatingCountrySelector);
  return (
    <StyledGridElement columns={columns} {...rest}>
      <H2 style={{ marginBottom: 20 }}>
        {t(`app:settings:legalDisclaimer:title`)}
      </H2>
      <BaseSection>
        <Paragraph style={{ whiteSpace: 'pre-line' }}>
          {t(`app:settings:legalDisclaimer:description`, {
            context: operatingCountry,
          })}
        </Paragraph>
      </BaseSection>
    </StyledGridElement>
  );
};
