import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';
import {
  ENVIRONMENT_INVESTING_THEMES,
  SOCIETY_INVESTING_THEMES,
} from '@formue-app/core/src/constants/investingThemes';

import { H3, H6, Paragraph } from '../../../texts';
import { SustainabilityBaseItem } from './SustainabilityBaseItem';
import { ImpactList } from './ImpactList';
import { ReactComponent as IconHands } from '../../../../assets/wealth-plan/icon-hands.svg';
import {
  SPACING_24,
  SPACING_32,
  SPACING_8,
} from '../../../../constants/spacing';
import { accent } from '../../../../constants/colors';

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${accent.sand2};
  margin: ${SPACING_32} 0 ${SPACING_8} 0;
`;

export const ImproveNegativeImpactsByMonitoring = (props) => {
  const i18nPrefix =
    'app:wealthPlan:investmentProfile:sustainabilityPreferences:reducingNegativeImpact';
  return (
    <SustainabilityBaseItem {...props}>
      <H6 style={{ paddingBottom: SPACING_8 }}>{t(`${i18nPrefix}:title`)}</H6>
      <H3 style={{ flex: 1, marginBottom: SPACING_24 }}>
        {t(`${i18nPrefix}:byMonitoring:title`)}
      </H3>
      <DescriptionWrapper>
        <IconHands />
        <Paragraph style={{ flex: 1, paddingLeft: SPACING_24 }}>
          {t(`${i18nPrefix}:byMonitoring:text`)}
        </Paragraph>
      </DescriptionWrapper>
      <Divider />
      <ImpactList
        title={t(`${i18nPrefix}:environment`)}
        items={ENVIRONMENT_INVESTING_THEMES.map((theme) => ({
          title: t(`${i18nPrefix}:investingThemes:${theme.key}`),
          iconId: theme.iconId,
        }))}
      />

      <ImpactList
        title={t(`${i18nPrefix}:society`)}
        items={SOCIETY_INVESTING_THEMES.map((theme) => ({
          title: t(`${i18nPrefix}:investingThemes:${theme.key}`),
          iconId: theme.iconId,
        }))}
      />
    </SustainabilityBaseItem>
  );
};
