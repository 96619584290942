import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { t } from '@formue-app/core';

import { RouteWithSubRoutes } from '../../components/router';
import {
  SubNavigation,
  subNavigationStylesThemeDark,
} from '../../components/navigation/SubNavigation';
import { SpriteIcon } from '../../components/common/SpriteIcon';
import { accent } from '../../constants/colors';

const renderIcon = (type, active, size) => {
  switch (type) {
    case 'investment-simulator':
      return (
        <SpriteIcon
          id="Risk"
          navigational={!active}
          inverted={!active}
          size={size}
        />
      );
    case 'strategies':
      return (
        <SpriteIcon
          id="Portfolio"
          navigational={!active}
          inverted={!active}
          size={size}
        />
      );
    default:
      return null;
  }
};

export const InvestmentStrategiesPage = (props) => {
  const { routes, location } = props;

  // If we are one a "InvestmentStrategies" we don't really have anything to show so do an
  // automatic redirect to the first sub route.

  return (
    <>
      {location.pathname === '/wealth-plan/investment-strategies' && (
        <Redirect to={routes[0].path} />
      )}
      <SubNavigation
        routes={routes}
        renderIcon={renderIcon}
        stylesTheme={{
          ...subNavigationStylesThemeDark,
          itemHoverColor: accent.velvet480,
        }}
        maxTextWidth={600}
        title={(type) =>
          type === 'investment-simulator'
            ? t('app:wealthPlan:investmentSimulator:title')
            : t('app:wealthPlan:investmentStrategy:navigation:title')
        }
        text={(type) =>
          type === 'investment-simulator'
            ? t('app:wealthPlan:investmentSimulator:subtitle')
            : t('app:wealthPlan:investmentStrategy:navigation:subtitle')
        }
      />
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </>
  );
};
