import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { groupBy } from 'lodash';

import {
  selectors,
  actions,
  t,
  usePortfolioRelationshipFilter,
  usePortfolioExternalReportingFilter,
  useStartegyOptions,
  config,
} from '@formue-app/core';

import { accent, backgroundWhite } from '../../../constants/colors';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  BORDER_RADIUS_MODAL,
  SPACING_16,
  SPACING_24,
  SPACING_32,
  SPACING_48,
} from '../../../constants/spacing';
import { StrategyPickerContent } from '../../portfolio/strategy/StrategyPicker';
import { mobileCondition } from '../../../constants/media';
import { H4LineHeight, H4Size } from '../../../constants/text';
import { RelationshipFilter } from '../../portfolio/filters/RelationshipFilter';
import { H3 } from '../../texts';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: ${accent.sand1};
  border-radius: ${BORDER_RADIUS_MODAL};
  padding: ${SPACING_48};
  gap: ${SPACING_48};
  width: 100%;
  max-width: 900px;

  ${(props) =>
    props.isMobile &&
    css`
      flex-direction: column;
      padding: 0;
      border-radius: 0;
      background: transparent;
      gap: ${SPACING_16};
      margin-top: ${SPACING_16};
    `}
`;

const StyledStrategyPickerContent = styled(StrategyPickerContent)`
  position: relative;
  translate: 0 0;
  top: 0;
  box-shadow: none;
  padding: ${SPACING_32};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  width: 50%;

  ${(props) =>
    props.isMobile &&
    css`
      background-color: ${accent.sand150};
      width: 100%;
      padding: ${SPACING_16};
    `}

  @media ${mobileCondition} {
    & > h3 {
      text-transform: initial;
      font-size: ${H4Size};
      line-height: ${H4LineHeight};
    }
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: ${SPACING_24};

  ${(props) =>
    props.isMobile &&
    css`
      width: 100%;
      padding: ${SPACING_16};
    `}
`;

const StyledRelationshipFilter = styled(RelationshipFilter)`
  background-color: ${backgroundWhite};
  padding: ${SPACING_32};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};

  ${(props) =>
    props.isMobile &&
    css`
      background-color: ${accent.sand150};

      &:hover {
        background-color: ${accent.sand150};
      }
    `}
`;

const {
  entities: {
    accounts: { allAccountsSelector },
  },
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

const {
  ui: { portfolio: portfolioActions },
} = actions;

export const FiltersSectionContent = (props) => {
  const { isModalContent = false } = props;
  const dispatch = useDispatch();
  const accounts = useSelector(allAccountsSelector);
  const allStrategies = useStartegyOptions();
  const combinedStrategies = groupBy(allStrategies, 'accounts');
  const activeStrategyId = useSelector(activeStrategySelector);
  let [activeStrategy, setActiveStrategy] = useState();

  useEffect(() => {
    if (allStrategies.length > 0 && activeStrategyId) {
      const strategy = allStrategies.find(
        (strategy) => strategy.id === activeStrategyId
      );

      if (strategy) {
        setActiveStrategy(strategy);
      } else {
        dispatch(portfolioActions.activeStrategy(allStrategies[0].id));
      }
    }
  }, [activeStrategyId, allStrategies, dispatch]);

  const isMobile = useMediaQuery({
    query: mobileCondition,
  });

  return (
    <Wrapper isMobile={isModalContent ? isModalContent : isMobile}>
      <FilterWrapper isMobile={isModalContent ? isModalContent : isMobile}>
        <StyledRelationshipFilter
          title={t('app:filters:relationshipsTitle')}
          options={usePortfolioRelationshipFilter()}
          TitleComponent={H3}
          isMobile={isModalContent ? isModalContent : isMobile}
        />

        <StyledRelationshipFilter
          title={t('app:filters:accountTitle')}
          options={usePortfolioExternalReportingFilter().map((item) => ({
            ...item,
            name: t(`app:filters:${item.name}`, {
              companyName: config.title,
            }),
          }))}
          TitleComponent={H3}
          isMobile={isModalContent ? isModalContent : isMobile}
        />
      </FilterWrapper>
      <StyledStrategyPickerContent
        accounts={accounts}
        color={accent.sand1}
        iconStyles={{ navigational: false, inverted: false }}
        combinedStrategies={combinedStrategies}
        activeStrategy={activeStrategy}
        isMobile={isModalContent ? isModalContent : isMobile}
      />
    </Wrapper>
  );
};
