import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, t } from '@formue-app/core';
import { ButtonSmall } from '../buttons';
import { SPACING_16, SPACING_8 } from '../../constants/spacing';
import { accent } from '../../constants/colors';

const {
  entities: {
    feed: { feedTypesSelector },
  },
} = selectors;

const StyledContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: ${SPACING_16};
`;

// Margin correction (1px / 0px) is there due to a quirkiness of the layout
// when border-width change
const StyledButton = styled(ButtonSmall)`
  border-radius: 20px;
  padding: ${SPACING_8} ${SPACING_16};
  outline: none;
  margin: 1px 1px;
  background-color: transparent;
  ${(props) =>
    props.selected &&
    css`
      margin: 0px 0px;
      border-color: #464f81;
      border-width: 2px;
      background-color: ${accent.ocean480};
    `}
`;

export const FeedFilters = ({ style, onClick }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const feedTypes = useSelector(feedTypesSelector);

  const handleClick = (filter) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter((item) => item !== filter));
    } else {
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  useEffect(() => {
    onClick(selectedFilters);
  }, [selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledContainer style={style}>
      {feedTypes.map((feedType) => (
        <StyledButton
          buttonstyle="outline"
          selected={selectedFilters.includes(feedType)}
          label={t(`app:eventCalendar:${feedType}`)}
          onClick={() => {
            handleClick(feedType);
          }}
          key={`filter-${feedType}`}
        />
      ))}
    </StyledContainer>
  );
};
