import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  t,
  selectors,
  toLocaleDateString,
  localizeFloat,
  formatBigMoney,
  useResource,
} from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_48,
  SPACING_24,
} from '../../../constants/spacing';

import { GridElement, MainGrid } from '../../layout';
import { H2 } from '../../texts';
import { StyledRow, StyledCell, TableList } from '../../lists/TableList';
import { accent } from '../../../constants/colors';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';

const Wrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  max-width: calc(100% - 95px * 2);
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const LightStyledCell = styled(StyledCell)`
  span {
    font-weight: 400;
  }
`;

const LightStyledRow = styled(StyledRow)`
  background: ${accent.sand370};
`;

const {
  entities: {
    transactions: { lastYearTransactions },
    financialValues: { allfinancialValuesItemsSelector },
  },
} = selectors;

export const WealthDevelopmentDetails = (props) => {
  const { isActiveSlide } = props;

  const isLoading = useResource([
    'FINANCIALVALUES/INDEX',
    'TRANSACTIONS/INDEX',
  ]);
  const transactions = useSelector(lastYearTransactions);
  const financialValues = useSelector(allfinancialValuesItemsSelector);

  const currYear = financialValues.find(
    (item) => item.p === new Date().getFullYear()
  );
  const prevYear = financialValues.find(
    (item) => item.p === new Date().getFullYear() - 1
  );
  const prevPrevYear = financialValues.find(
    (item) => item.p === new Date().getFullYear() - 2
  );

  const buyTransactions = transactions.filter((item) => item.type === 'Kjøp');
  const salesTransactions = transactions.filter((item) => item.type === 'Salg');
  const returnTransactions = transactions.filter(
    (item) => item.type === 'Utbytte'
  );
  const intrestTransactions = transactions.filter(
    (item) => item.type === 'Renter'
  );
  const costTransactions = transactions.filter(
    (item) => item.type === 'Kostnader'
  );

  const buySellColumns = [
    {
      key: 'date',
      label: t('app:globals:date'),
      render: ({ date }) => toLocaleDateString(date),
      width: '15%',
      defaultSort: true,
    },
    {
      key: 'type',
      label: t('app:portfolio:transaction'),
      component: LightStyledCell,
      width: '10%',
    },
    {
      key: 'product',
      label: t('app:portfolio:product'),
      component: LightStyledCell,
      width: '40%',
    },
    {
      key: 'quantity',
      label: t('app:portfolio:shares'),
      component: LightStyledCell,
      render: ({ quantity: q }) => (q ? localizeFloat(q, 4) : ''),
      type: 'number',
      width: '10%',
    },
    {
      key: 'price',
      label: t('app:portfolio:price'),
      component: LightStyledCell,
      render: ({ price: p }) => (p ? localizeFloat(p, 2) : ''),
      type: 'number',
      width: '10%',
    },
    {
      key: 'amount',
      label: t('app:globals:amount'),
      render: ({ amount: a }) => (a ? formatBigMoney(a) : ''),
      type: 'number',
      width: '15%',
    },
  ];

  let summaryColumns = [
    {
      key: 'title',
      width: '50%',
    },
    {
      key: 'amount',
      render: ({ amount: a }) => (a ? formatBigMoney(a) : ''),
      type: 'number',
      width: '50%',
    },
  ];

  let overviewColumns = [
    {
      key: 'title',
      width: '50%',
    },
    {
      key: 'prevYearValue',
      label: t('app:presentation:annualReview:portfolioDevelopment:lastYear'),
      render: ({ prevYearValue: val, prefix }) =>
        val ? formatBigMoney(val, prefix) : 0,
      type: 'number',
      width: '25%',
    },
    {
      key: 'currYearValue',
      label: t('app:presentation:annualReview:portfolioDevelopment:yearToDate'),
      render: ({ currYearValue: val, prefix }) =>
        val ? formatBigMoney(val, prefix) : 0,
      type: 'number',
      width: '25%',
    },
  ];

  if (!isActiveSlide) {
    return null;
  }

  return (
    <Wrapper>
      <GridElement columns={12} style={{ marginTop: SPACING_48 }}>
        <H2>
          {t('app:presentation:annualReview:portfolioDevelopment:overview')}
        </H2>
        {isLoading ? (
          <CenteredActivityIndicator background={accent.sand3} />
        ) : financialValues.length ? (
          <TableList
            className="table-list"
            columns={overviewColumns}
            rows={[
              {
                title: t(
                  'app:presentation:annualReview:portfolioDevelopment:startValue'
                ),
                prevYearValue: prevPrevYear?.mv || 0,
                currYearValue: prevYear?.mv || 0,
                prefix: false,
              },
              {
                title: t(
                  'app:presentation:annualReview:portfolioDevelopment:depositsWithdrawals'
                ),
                prevYearValue: prevYear?.deposits + prevYear?.withdrawals || 0,
                currYearValue: currYear?.deposits + currYear?.withdrawals || 0,
              },
              {
                title: t(
                  'app:presentation:annualReview:portfolioDevelopment:return'
                ),
                prevYearValue: prevYear?.gain || 0,
                currYearValue: currYear?.gain || 0,
              },
              {
                title: t(
                  'app:presentation:annualReview:portfolioDevelopment:endValue'
                ),
                prevYearValue: prevYear?.mv || 0,
                currYearValue: currYear?.mv || 0,
                prefix: false,
              },
            ]}
            keyExtractor={(item, index) => `buy-transaction-${index}`}
            showHeader={true}
            sortable={false}
            style={{ marginTop: 0, marginBottom: SPACING_24 }}
          />
        ) : null}
      </GridElement>
      <GridElement columns={12}>
        <H2 style={{ marginBottom: SPACING_24 }}>
          {t('app:presentation:annualReview:portfolioDevelopment:buy')}
        </H2>
        {isLoading ? (
          <CenteredActivityIndicator background={accent.sand3} />
        ) : buyTransactions.length ? (
          <>
            <TableList
              className="table-list"
              columns={buySellColumns}
              rows={buyTransactions}
              keyExtractor={(item, index) => `buy-transaction-${index}`}
              showHeader={true}
              style={{ marginTop: 0 }}
            />

            <TableList
              className="table-list"
              columns={summaryColumns}
              rowComponent={LightStyledRow}
              rows={[
                {
                  title: t(
                    'app:presentation:annualReview:portfolioDevelopment:totalBuy'
                  ),
                  amount: buyTransactions
                    .map((item) => item.amount)
                    .reduce((sum, item) => sum + item),
                },
              ]}
              showHeader={false}
              keyExtractor={(item, index) => `buy-transaction-${index}`}
              style={{ marginTop: 0, marginBottom: SPACING_24 }}
            />
          </>
        ) : null}
      </GridElement>
      <GridElement columns={12}>
        <H2 style={{ marginBottom: SPACING_24 }}>
          {t('app:presentation:annualReview:portfolioDevelopment:sell')}
        </H2>
        {isLoading ? (
          <CenteredActivityIndicator background={accent.sand3} />
        ) : salesTransactions.length ? (
          <>
            <TableList
              className="table-list"
              columns={buySellColumns}
              rows={salesTransactions}
              keyExtractor={(item, index) => `sell-transaction-${index}`}
              style={{ marginTop: 0 }}
            />
            <TableList
              className="table-list"
              columns={summaryColumns}
              rowComponent={LightStyledRow}
              rows={[
                {
                  title: t(
                    'app:presentation:annualReview:portfolioDevelopment:totalSell'
                  ),
                  amount: salesTransactions
                    .map((item) => item.amount)
                    .reduce((sum, item) => sum + item),
                },
              ]}
              showHeader={false}
              keyExtractor={(item, index) => `buy-transaction-${index}`}
              style={{ marginTop: 0, marginBottom: SPACING_24 }}
            />
          </>
        ) : null}
      </GridElement>
      <GridElement columns={12}>
        <H2 style={{ marginBottom: SPACING_24 }}>
          {t('app:presentation:annualReview:portfolioDevelopment:divident')}
        </H2>
        {isLoading ? (
          <CenteredActivityIndicator background={accent.sand3} />
        ) : returnTransactions.length ? (
          <>
            <TableList
              className="table-list"
              columns={buySellColumns}
              rows={returnTransactions}
              keyExtractor={(item, index) => `sell-transaction-${index}`}
              style={{ marginTop: 0 }}
            />
            <TableList
              className="table-list"
              columns={summaryColumns}
              rowComponent={LightStyledRow}
              rows={[
                {
                  title: t(
                    'app:presentation:annualReview:portfolioDevelopment:totalDivident'
                  ),
                  amount: returnTransactions
                    .map((item) => item.amount)
                    .reduce((sum, item) => sum + item),
                },
              ]}
              showHeader={false}
              keyExtractor={(item, index) => `buy-transaction-${index}`}
              style={{ marginTop: 0, marginBottom: SPACING_24 }}
            />
          </>
        ) : null}
      </GridElement>
      <GridElement columns={12}>
        <H2 style={{ marginBottom: SPACING_24 }}>
          {t('app:presentation:annualReview:portfolioDevelopment:interest')}
        </H2>
        {isLoading ? (
          <CenteredActivityIndicator background={accent.sand3} />
        ) : intrestTransactions.length ? (
          <>
            <TableList
              className="table-list"
              columns={buySellColumns}
              rows={intrestTransactions}
              keyExtractor={(item, index) => `sell-transaction-${index}`}
              style={{ marginTop: 0 }}
            />
            <TableList
              className="table-list"
              columns={summaryColumns}
              rowComponent={LightStyledRow}
              rows={[
                {
                  title: t(
                    'app:presentation:annualReview:portfolioDevelopment:totalInterest'
                  ),
                  amount: intrestTransactions
                    .map((item) => item.amount)
                    .reduce((sum, item) => sum + item),
                },
              ]}
              showHeader={false}
              keyExtractor={(item, index) => `buy-transaction-${index}`}
              style={{ marginTop: 0, marginBottom: SPACING_24 }}
            />
          </>
        ) : null}
      </GridElement>
      <GridElement columns={12}>
        <H2 style={{ marginBottom: SPACING_24 }}>
          {t('app:presentation:annualReview:portfolioDevelopment:cost')}
        </H2>
        {isLoading ? (
          <CenteredActivityIndicator background={accent.sand3} />
        ) : costTransactions.length ? (
          <>
            <TableList
              className="table-list"
              columns={buySellColumns}
              rows={costTransactions}
              keyExtractor={(item, index) => `sell-transaction-${index}`}
              style={{ marginTop: 0 }}
            />
            <TableList
              className="table-list"
              columns={summaryColumns}
              rowComponent={LightStyledRow}
              rows={[
                {
                  title: t(
                    'app:presentation:annualReview:portfolioDevelopment:totalCost'
                  ),
                  amount: costTransactions
                    .map((item) => item.amount)
                    .reduce((sum, item) => sum - item),
                },
              ]}
              showHeader={false}
              keyExtractor={(item, index) => `buy-transaction-${index}`}
              style={{ marginTop: 0, marginBottom: SPACING_24 }}
            />
          </>
        ) : null}
      </GridElement>
    </Wrapper>
  );
};
