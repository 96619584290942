import React from 'react';
import styled from 'styled-components';

import {
  getAssetClassName,
  getColorForAssetClassGroup,
  formatInvestmentStrategyImplementationPlanData,
  monthRange,
  t,
  percentage,
} from '@formue-app/core';

import { SPACING_32 } from '../../../constants/spacing';
import { assetClassDefaultColorScheme } from '../../../constants/colors';
import { Paragraph } from '../../texts';
import { StackedBar } from '../../charts/StackedBar';
import { slideAnimationDelay } from '../../../constants/animation';

const Base = styled.div``;

const ChartWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  gap: ${(props) => props.gap}px;
  margin-bottom: ${SPACING_32};
`;

const Legend = styled.div`
  display: flex;
  flex: 1;
  gap: ${SPACING_32};
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const LegendItem = styled(Paragraph)`
  position: relative;
  padding-left: 20px;

  &:after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${(props) => props.color};
    position: absolute;
    left: 2px;
    top: 8px;
  }
`;

export const ImplementationPlanChart = (props) => {
  const {
    animate,
    implementationPlan,
    gap = 100,
    barWidth = 80,
    reverseRender,
    legendOnSide,
    showValueLabels,
    ...rest
  } = props;

  if (!implementationPlan) {
    return null;
  }

  let formattedStrategyData =
    formatInvestmentStrategyImplementationPlanData(implementationPlan);

  let strategyDataForRender = formattedStrategyData;
  if (reverseRender) {
    strategyDataForRender = formattedStrategyData.reverse();
  }

  return (
    <Base {...rest}>
      <ChartWrapper style={{ position: 'relative' }} gap={gap}>
        {implementationPlan.rounds > 1
          ? [...Array(implementationPlan.rounds)].map((round, index) => (
              <StackedBar
                height={286}
                key={`bar-${index}`}
                items={strategyDataForRender.map((group) => ({
                  value: group.sharePerRound[index] * 100,
                  group: group.assetGroup,
                  label: getAssetClassName(group.assetGroup),
                }))}
                animate={animate}
                title={t('app:advisor:strategySimulator:implementation:steps', {
                  step: index + 1,
                })}
                label={t(
                  'app:advisor:strategySimulator:implementation:months',
                  {
                    range: monthRange(index, implementationPlan.monthsPerRound),
                  }
                )}
                width={barWidth}
                showValue={showValueLabels}
                formatValue={(value) => percentage(value, 2, false, false)}
                animationDelay={slideAnimationDelay + 0.2 * index}
              />
            ))
          : null}
        <StackedBar
          height={286}
          items={strategyDataForRender?.map((group, index) => ({
            value: group.target * 100,
            group: group.assetGroup,
            label: getAssetClassName(group.assetGroup),
          }))}
          animate={animate}
          title={t('app:advisor:strategySimulator:implementation:target')}
          width={barWidth}
          showValue={showValueLabels}
          formatValue={(value) => percentage(value, 2, false, false)}
          animationDelay={
            slideAnimationDelay +
            0.2 * (Array(implementationPlan.rounds).length + 1)
          }
        />
        {legendOnSide && (
          <Legend
            style={{
              position: 'relative',
              flexDirection: 'column-reverse',
              marginLeft: 24,
              bottom: 34,
              alignItems: 'flex-start',
              gap: 20,
            }}
          >
            {formattedStrategyData?.map((group, index) => (
              <LegendItem
                key={`label-${index}`}
                color={getColorForAssetClassGroup(
                  group.assetGroup,
                  assetClassDefaultColorScheme
                )}
              >
                {getAssetClassName(group.assetGroup)}
              </LegendItem>
            ))}
          </Legend>
        )}
      </ChartWrapper>
      {!legendOnSide && (
        <Legend>
          {formattedStrategyData?.map((group, index) => (
            <LegendItem
              key={`label-${index}`}
              color={getColorForAssetClassGroup(
                group.assetGroup,
                assetClassDefaultColorScheme
              )}
            >
              {getAssetClassName(group.assetGroup)}
            </LegendItem>
          ))}
        </Legend>
      )}
    </Base>
  );
};
