import { useSelector } from 'react-redux';
import camelcase from 'camelcase';

import { t } from '../localization';
import { uniqByWithException } from '../helpers';

/**
 * Custom react hook to retrieve a signing events trade orders
 * @param {} signingEvent
 */
export const useSigningEventsTradeOrders = (signingEvent) => {
  if (!signingEvent) return [];
  const allTradeOrders = useSelector(
    (state) => state.entities.tradeOrders.byId
  );

  const tradeOrders = signingEvent.tradeOrders.length
    ? signingEvent.tradeOrders.map((order) => allTradeOrders[order.id])
    : [];

  // Return all trade orders that have switchId
  return uniqByWithException(tradeOrders, 'switchId', null);
};

export const getSigningEventTitle = (signingEvent) => {
  if (
    signingEvent.subCategory === 'TRX Order' ||
    signingEvent.subCategory === 'TRX Ordre'
  ) {
    return t('app:globals:transactionTypes:trxOrder');
  }
  return signingEvent.title;
};

export const getSigningEventSubCategory = (signingEvent) => {
  if (!signingEvent || !signingEvent.subCategory) {
    return null;
  }
  return t(
    `app:globals:transactionTypes:${camelcase(signingEvent.subCategory)}`
  );
};

export const getSigningEventNeedToSign = (status) => {
  switch (status.toLowerCase()) {
    case 'completed':
    case 'cancelled': {
      return false;
    }
    case 'ongoing':
      return true;
    default: {
      return true;
    }
  }
};

export const getSigningEventPending = (status) => {
  switch (status.toLowerCase()) {
    case 'completed':
    case 'cancelled': {
      return false;
    }
    case 'ongoing':
      return true;
    default: {
      return false;
    }
  }
};
