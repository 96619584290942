import React from 'react';
import styled from 'styled-components';

import { t, selectors, useResource } from '@formue-app/core';

import { SPACING_24 } from '../../../constants/spacing';

import { H3, ParagraphXSmall } from '../../texts';
import { RiskProfile } from './RiskProfile';
import { RiskTolerance } from './RiskTolerance';
import { ReactionList } from './ReactionList';
import { GridElement, MainGrid } from '../../layout';
import { SectionWithTitle } from '../../common/SectionWithTitle';
import { RiskChart } from './RiskChart';
import { useSelector } from 'react-redux';

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const {
  entities: {
    investmentProfile: { investmentStrategyRiskAndLockInSelector },
    cashFlowEvents: { allCashFlowEventsMetadataSelector },
  },
} = selectors;

export const RiskModalContent = (props) => {
  useResource(['CASH_FLOW_EVENTS/INDEX', 'INVESTMENT_PROFILE/INDEX']);
  const cashFlowEventsMetaData = useSelector(allCashFlowEventsMetadataSelector);

  const totalPortfolio = cashFlowEventsMetaData.totalPortfolio;

  const investmentStrategyRiskAndLockIn = useSelector(
    investmentStrategyRiskAndLockInSelector
  );

  if (!investmentStrategyRiskAndLockIn) {
    return null;
  }

  return (
    <>
      {totalPortfolio ? (
        <GridElement columns={12}>
          <H3>
            {t(
              'app:wealthPlan:investmentProfile:riskAndLockin:risk:timeHorizonTitle'
            )}
          </H3>
          <ParagraphXSmall>
            {t(
              'app:wealthPlan:investmentProfile:riskAndLockin:risk:timeHorizonSubTitle'
            )}
          </ParagraphXSmall>
          <RiskChart style={{ marginTop: SPACING_24 }} />
        </GridElement>
      ) : null}

      <SectionWithTitle
        title={t(
          'app:wealthPlan:investmentProfile:riskAndLockin:risk:emotialAbilityForRiskTitle'
        )}
        style={{ marginTop: totalPortfolio ? 64 : 0 }}
      >
        <H3>
          {t(
            'app:wealthPlan:investmentProfile:riskAndLockin:risk:riskProfileTitle'
          )}
        </H3>
        <ParagraphXSmall>
          {t(
            'app:wealthPlan:investmentProfile:riskAndLockin:risk:riskProfileSubTitle'
          )}
        </ParagraphXSmall>

        <StyledSubGrid>
          <RiskProfile style={{ marginTop: SPACING_24 }} columns={12} />

          <RiskTolerance columns={12} />

          <ReactionList type="lossAversion" columns={6} />

          <ReactionList type="winningAppetite" columns={6} />
        </StyledSubGrid>
      </SectionWithTitle>
    </>
  );
};
