import React, { useState } from 'react';
import { VictoryLine } from 'victory';
import styled from 'styled-components';

import {
  t,
  percentage,
  filterSustainabilityTimelineChartData,
  getSustainabilityTrend,
  toLocaleDateString,
} from '@formue-app/core';
import { accent } from '../../../../constants/colors';
import { H2, H2Small, Paragraph } from '../../../texts';
import { PeriodFilterDropdown } from './PeriodFilterDropdown';
import { TrendBadge } from './TrendBadge';
import { SPACING_12, SPACING_20 } from '../../../../constants/spacing';

const Container = styled.div`
  transition: opacity 0.3s ease-in-out;
  position: relative;
`;

const StyledPeriodFilterDropdown = styled(PeriodFilterDropdown)`
  position: absolute;
  right: 0;
  top: 0;
`;

const Value = styled(H2)`
  float: right;
  padding-top: ${SPACING_12};
`;

const DateAxis = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: ${SPACING_12};
`;

const Date = styled(Paragraph)``;

export const SustainabilityLineChart = (props) => {
  const {
    title = t('app:portfolio:development'),
    data,
    value,
    trendSelector,
    positiveTrend = (trend) => trend > 0,
    formatTrendValue = (val) => val,
    trendInPercentage = true,
  } = props;

  const [selectedPeriod, setSelectedPeriod] = useState('TWELVEMONTHS');

  if (!data.length) return null;

  const chartData = filterSustainabilityTimelineChartData(data, selectedPeriod);
  const trend = getSustainabilityTrend(
    trendSelector,
    selectedPeriod,
    trendInPercentage
  );
  const formattedTrendValue = formatTrendValue(trend);

  return (
    <Container>
      {title && <H2Small style={{ paddingBottom: 8 }}>{title}</H2Small>}
      <StyledPeriodFilterDropdown
        selectedCallback={(selected) => {
          setSelectedPeriod(selected);
        }}
      />
      <TrendBadge
        title={
          trendInPercentage
            ? percentage(formattedTrendValue, 2, false, false)
            : formattedTrendValue
        }
        trend={trend}
        style={{ paddingBottom: 24 }}
        direction={trend > 0 ? 'Up' : 'Down'}
        positiveTrend={positiveTrend(trend)}
      />
      <VictoryLine
        domainPadding={{ y: [10, 10] }}
        y="value"
        height={250}
        data={chartData}
        interpolation="monotoneX"
        style={{
          data: {
            stroke: accent.ocean4,
            strokeWidth: 3,
          },
        }}
        padding={0}
      />

      <DateAxis>
        <Date>
          {toLocaleDateString(chartData[0].date, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </Date>
        <Date>
          {toLocaleDateString(chartData[chartData.length - 1].date, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </Date>
      </DateAxis>
    </Container>
  );
};
