import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, t } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_16,
  SPACING_24,
  SPACING_32,
} from '../../../constants/spacing';

import { H2 } from '../../texts';
import { GridElement, MainGrid } from '../../layout';
import { AdvisorsComment } from '../../wealthPlan/investmentProfile/AdvisorsComment';
import { RiskModalContent } from '../../wealthPlan/investmentProfile/RiskModalContent';
import { LockinModalContent } from '../../wealthPlan/investmentProfile/LockinModalContent';
import { PaddedBaseSection } from '../../common/BaseSection';
import { KnowledgeAndExperience } from '../../wealthPlan/investmentProfile/KnowledgeAndExperience';

import { RiskCapacity } from '../../wealthPlan/investmentProfile/RiskCapacity';
import { RiskTolerance } from '../../wealthPlan/investmentProfile/RiskTolerance';

import { InvestmentPreferencesChoices } from '../../wealthPlan/investmentProfile/InvestmentPreferencesChoices';
import { SlideErrorMessage } from '../slideComponents/SlideErrorMessage';

const Wrapper = styled(MainGrid)`
  width: 100%;
  height: 100%;
  max-width: calc(100% - 95px * 2);
  padding: 0;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const {
  entities: {
    investmentProfile: {
      investmentProfileHasContent,
      sustainabilityPreferencesSelector,
      investmentStrategyRiskAndLockInSelector,
      knowledgeAndTrainingCommentSelector,
    },
  },
} = selectors;

export const InvestmentProfileDetails = (props) => {
  const { isActiveSlide } = props;

  const hasContent = useSelector(investmentProfileHasContent);
  const sustainabilityPreferences = useSelector(
    sustainabilityPreferencesSelector
  );

  const knowledgeAndTrainingComment = useSelector(
    knowledgeAndTrainingCommentSelector
  );

  const investmentStrategyRiskAndLockIn = useSelector(
    investmentStrategyRiskAndLockInSelector
  );

  if (!isActiveSlide) {
    return null;
  }

  return investmentStrategyRiskAndLockIn ? (
    <Wrapper>
      <GridElement columns={12}>
        <H2>{t('app:wealthPlan:investmentProfile:riskAndLockin:riskTitle')}</H2>
      </GridElement>
      <PaddedBaseSection columns={12}>
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t('app:wealthPlan:investmentProfile:riskAndLockin:risk:title')}
        </H2>
        <RiskModalContent />
      </PaddedBaseSection>
      <AdvisorsComment
        title={t('app:wealthPlan:investmentProfile:advisorsComment')}
        subTitle={t('app:wealthPlan:investmentProfile:riskCapacity')}
        body={investmentStrategyRiskAndLockIn?.riskAndLockInComment}
        columns={12}
      />

      <GridElement columns={12} style={{ marginTop: SPACING_24 }}>
        <H2>
          {t('app:wealthPlan:investmentProfile:riskAndLockin:lockInTitle')}
        </H2>
      </GridElement>
      <PaddedBaseSection columns={12}>
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t('app:wealthPlan:investmentProfile:riskAndLockin:lockIn:title')}
        </H2>
        <LockinModalContent />
      </PaddedBaseSection>
      <AdvisorsComment
        title={t('app:wealthPlan:investmentProfile:advisorsComment')}
        subTitle={t('app:wealthPlan:investmentProfile:riskCapacity')}
        body={investmentStrategyRiskAndLockIn?.riskAndLockInComment}
        columns={12}
      />

      <GridElement columns={12} style={{ marginTop: SPACING_24 }}>
        <H2>
          {t('app:wealthPlan:investmentProfile:knowledgeAndExperienceTitle')}
        </H2>
      </GridElement>
      <KnowledgeAndExperience columns={12} />
      <AdvisorsComment
        title={t(
          'app:wealthPlan:investmentProfile:knowledgeAndTrainingComment'
        )}
        subTitle={''}
        body={knowledgeAndTrainingComment}
        columns={12}
      />
    </Wrapper>
  ) : (
    <Wrapper>
      {hasContent ? (
        <>
          {sustainabilityPreferences ? null : <InvestmentPreferencesChoices />}

          <RiskCapacity style={{ margin: 0, marginTop: SPACING_32 }} />
          <RiskTolerance style={{ margin: 0 }} />
          <AdvisorsComment style={{ margin: 0 }} />
        </>
      ) : (
        <SlideErrorMessage />
      )}
    </Wrapper>
  );
};
