import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';

import {
  formatHexOpacity,
  t,
  RiskProfileEnum,
  selectors,
} from '@formue-app/core';

import { accent } from '../../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_32,
  SPACING_24,
  SPACING_8,
} from '../../../constants/spacing';

import { H3, H6, Paragraph, ParagraphSmall } from '../../texts';
import { mobileCondition } from '../../../constants/media';
import { BaseSection } from '../../common/BaseSection';
import { SpriteIcon } from '../../common/SpriteIcon';

const {
  entities: {
    investmentProfile: { riskProfileIdSelector },
  },
} = selectors;

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_24};
  background: ${accent.sand140};
`;

const InnerWrapper = styled.div`
  display: flex;

  @media ${mobileCondition} {
    flex-direction: column;
  }
`;

const StyledWrapper = styled.div`
  text-align: center;
  padding: ${SPACING_24};
  padding-top: ${SPACING_32};
  border-radius: ${BORDER_RADIUS_LARGE};
  border: 1px solid transparent;
  opacity: 0.5;

  svg {
    vertical-align: bottom;
  }

  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      background: ${accent.sand1};
      border: 1px solid ${accent.sand3};
    `}
`;

const StyledTitle = styled(H3)`
  margin-top: ${SPACING_24};
  margin-bottom: ${SPACING_8};
`;

const StyledNoDataSentence = styled(Paragraph)``;

export const RiskProfile = (props) => {
  const riskProfileId = useSelector(riskProfileIdSelector);
  const riskProfileType = RiskProfileEnum[riskProfileId];

  const profiles = [
    {
      type: 'conservative',
      color: accent.ocean2,
      title: t(
        'app:wealthPlan:investmentProfile:riskProfiles:conservative:title'
      ),
      text: t(
        'app:wealthPlan:investmentProfile:riskProfiles:conservative:text'
      ),
    },
    {
      type: 'moderate',
      color: formatHexOpacity(accent.ocean1, 0.7),
      title: t('app:wealthPlan:investmentProfile:riskProfiles:moderate:title'),
      text: t('app:wealthPlan:investmentProfile:riskProfiles:moderate:text'),
    },
    {
      type: 'dynamic',
      color: formatHexOpacity(accent.ocean4, 0.8),
      title: t('app:wealthPlan:investmentProfile:riskProfiles:dynamic:title'),
      text: t('app:wealthPlan:investmentProfile:riskProfiles:dynamic:text'),
    },
  ];
  return (
    <>
      <StyledBaseSection {...props}>
        <H6 style={{ marginBottom: SPACING_8 }}>Din riskprofil</H6>
        <InnerWrapper>
          {profiles.map(({ type, color, title, text }, index) => (
            <StyledWrapper
              active={type === riskProfileType}
              key={`risk-type-${index}`}
            >
              <SpriteIcon id="Individual" size={56} />
              <StyledTitle>{title}</StyledTitle>
              <ParagraphSmall>{text}</ParagraphSmall>
            </StyledWrapper>
          ))}
        </InnerWrapper>
      </StyledBaseSection>
      {isNil(riskProfileId) && (
        <BaseSection
          columns={8}
          style={{
            background: 'transparent',
            paddingLeft: 0,
            paddingTop: SPACING_8,
          }}
        >
          <StyledNoDataSentence>
            {t('app:wealthPlan:investmentProfile:riskToleranceNoData')}
          </StyledNoDataSentence>
        </BaseSection>
      )}
    </>
  );
};
