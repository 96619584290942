import React from 'react';
import styled from 'styled-components';

import { useMediaQuery } from 'react-responsive';

import {
  formatBigMoney,
  percentage,
  useUnderlyingFunds,
  t,
} from '@formue-app/core';
import { H4, Paragraph, ParagraphSmall, ParagraphXSmall } from '../../texts';
import { accent } from '../../../constants/colors';
import { SPACING_32, SPACING_16, SPACING_8 } from '../../../constants/spacing';
import { StyledCell, TableList } from '../../lists';
import { DonutGraph } from '../../graphs/DonutGraph';
import { tabletCondition, mobileCondition } from '../../../constants/media';

const StyledContainer = styled.div`
  margin-top: ${SPACING_32};
`;

const LightStyledCell = styled(StyledCell)`
  vertical-align: middle;
  padding: 13px 16px;
  span {
    font-weight: 400;
  }
`;

const CellInner = styled.div`
  display: flex;
  align-items: center;
`;

const StyledNumber = styled.div`
  display: flex;
  background: ${accent.ocean250};
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  height: 32px;
  width: 32px;

  @media ${mobileCondition} {
    position: absolute;
    left: 8;
    top: -2px;
    height: 24px;
    width: 24px;
    border-radius: 12px;
  }
`;

const NumberText = styled(Paragraph)`
  text-align: center;
  font-weight: 500;
`;

const StyledDonutGraph = styled(DonutGraph)`
  margin-right: ${SPACING_8};
`;

const MobileItem = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
`;

const MobileItemInner = styled.div`
  width: 65%;

  @media ${mobileCondition} {
    width: 100%;
  }
`;

const MobileItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MobileItemColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileItemValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MobileItemLabel = styled(ParagraphXSmall)`
  color: ${accent.ocean170};
  margin-bottom: ${SPACING_8};
`;

const MobileItemValue = styled(ParagraphSmall)`
  color: ${accent.ocean490};
`;

const MobileItemTitle = styled(H4)`
  padding-bottom: ${SPACING_8};

  @media ${mobileCondition} {
    padding-left: 36px;
    padding-bottom: ${SPACING_16};
  }
`;

export const FundUnderlying = ({ underlyingSecurities, marketValue }) => {
  const funds = useUnderlyingFunds(underlyingSecurities, marketValue);

  const tabletSize = useMediaQuery({
    query: tabletCondition,
  });

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  // Define the table columns
  let columns = [
    {
      key: 'name',
      label: t('app:portfolio:funds:fund'),
      width: '50%',
      render: ({ name }, index) => (
        <CellInner>
          <StyledNumber>
            <NumberText>{index + 1}</NumberText>
          </StyledNumber>
          <H4>
            {name === '[Missing funds data]'
              ? t('app:portfolio:funds:missingFundsData')
              : name}
          </H4>
        </CellInner>
      ),
    },
    {
      key: 'shareInFund',
      label: t('app:portfolio:funds:shareOfFund'),
      component: LightStyledCell,
      width: '15%',
      render: ({ shareInFund }) => (
        <CellInner>
          <StyledDonutGraph value={shareInFund} />
          {percentage(shareInFund * 100, 2, true, false)}
        </CellInner>
      ),
    },
    {
      key: 'marketValue',
      label: t('app:portfolio:funds:myAmount'),
      component: LightStyledCell,
      width: '15%',
      render: ({ marketValue }) => formatBigMoney(marketValue, false),
    },
    {
      key: 'shareInPortfolio',
      label: t('app:portfolio:funds:shareOfMyPortfolio'),
      type: 'number',
      component: LightStyledCell,
      width: '20%',
      render: ({ shareInPortfolio }) => (
        <CellInner>
          <StyledDonutGraph value={shareInPortfolio} />
          {percentage(shareInPortfolio * 100, 2, true, false)}
        </CellInner>
      ),
    },
  ];

  if (tabletSize || mobileSize) {
    columns = [
      {
        key: 'product',
        render: (
          { name, shareInFund, marketValue, shareInPortfolio },
          index
        ) => (
          <MobileItem>
            <StyledNumber>
              <NumberText>{index + 1}</NumberText>
            </StyledNumber>
            <MobileItemInner>
              <MobileItemTitle>{name}</MobileItemTitle>
              <MobileItemRow>
                <MobileItemColumn>
                  <MobileItemLabel>
                    {t('app:portfolio:funds:shareOfFund')}
                  </MobileItemLabel>
                  <MobileItemValueWrapper>
                    <StyledDonutGraph value={shareInFund} />
                    <MobileItemValue>
                      {percentage(shareInFund * 100, 2, false, false)}
                    </MobileItemValue>
                  </MobileItemValueWrapper>
                </MobileItemColumn>
                <MobileItemColumn>
                  <MobileItemLabel>
                    {t('app:portfolio:funds:myAmount')}
                  </MobileItemLabel>
                  <MobileItemValue>
                    {formatBigMoney(marketValue, false)}
                  </MobileItemValue>
                </MobileItemColumn>
                <MobileItemColumn>
                  <MobileItemLabel>
                    {t('app:portfolio:funds:shareOfMyPortfolio')}
                  </MobileItemLabel>
                  <MobileItemValueWrapper>
                    <StyledDonutGraph value={shareInPortfolio} />
                    <MobileItemValue>
                      {percentage(shareInPortfolio * 100, 2, false, false)}
                    </MobileItemValue>
                  </MobileItemValueWrapper>
                </MobileItemColumn>
              </MobileItemRow>
            </MobileItemInner>
          </MobileItem>
        ),
      },
    ];
  }

  return (
    <StyledContainer>
      {/* To be brought back once we solve the issues with data source
      <H3 style={{ marginBottom: `${SPACING_24}` }}>
        {t('app:portfolio:funds:underlyingFunds')}
      </H3>
      */}
      <TableList
        className="table-list"
        columns={columns}
        rows={funds}
        keyExtractor={(item, index) => `transaction-${index}`}
        infiniteScroll={true}
        sortable={false}
      />
    </StyledContainer>
  );
};
