import React from 'react';

import { t } from '@formue-app/core';

import { SelectDropdown, SimpleSelectDropdown } from '../../formElements';

export const TimeHorizonSelect = (props) => {
  const { onChange, simple = false, ...rest } = props;

  const dateRange = [
    {
      label: t('app:portfolio:portfolioStrategy:timeHorizon', { value: 10 }),
      itemLabel: t('app:portfolio:portfolioStrategy:timeHorizonItem', {
        value: 10,
      }),
      value: 10,
    },
    {
      label: t('app:portfolio:portfolioStrategy:timeHorizon', { value: 20 }),
      itemLabel: t('app:portfolio:portfolioStrategy:timeHorizonItem', {
        value: 20,
      }),
      value: 20,
    },
    {
      label: t('app:portfolio:portfolioStrategy:timeHorizon', { value: 30 }),
      itemLabel: t('app:portfolio:portfolioStrategy:timeHorizonItem', {
        value: 30,
      }),
      value: 30,
    },
    {
      label: t('app:portfolio:portfolioStrategy:timeHorizon', { value: 40 }),
      itemLabel: t('app:portfolio:portfolioStrategy:timeHorizonItem', {
        value: 40,
      }),
      value: 40,
    },
    {
      label: t('app:portfolio:portfolioStrategy:timeHorizon', { value: 50 }),
      itemLabel: t('app:portfolio:portfolioStrategy:timeHorizonItem', {
        value: 50,
      }),
      value: 50,
    },
  ];

  const Component = simple ? SimpleSelectDropdown : SelectDropdown;

  return (
    <Component
      options={dateRange}
      defaultSelectedIndex={1}
      subtitle={t('app:portfolio:portfolioStrategy:chooseTimeHorizon')}
      {...rest}
    />
  );
};
