import React, { useState } from 'react';
import styled from 'styled-components';

import { useErrorState, useResource, t } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  ANNUAL_REVIEW_REPORT_CONTENT_SPACING,
} from '../../../constants/spacing';
import { ReturnContributionChart } from '../slideComponents/portfolio/ReturnContributionChart';
import { ChartLegend } from '../slideComponents/portfolio/ChartLegend';
import { useQueryState } from 'react-router-use-location-state';
import { SlideErrorMessage } from '../slideComponents/SlideErrorMessage';
import { accent } from '../../../constants/colors';

const Base = styled.div`
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const ChartWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-bottom: 100px;
  align-items: center;
  justify-content: center;
`;

const HoldingsChartWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 0 10px;
`;

export const ReturnContributionPerAssetClassSlide = (props) => {
  const loading = useResource(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);
  const error = useErrorState(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);
  const [selected, setSelected] = useState();
  const [exportMode] = useQueryState('exportMode', false);

  if (loading) return null;
  if (error) return <SlideErrorMessage />;

  return (
    <Base>
      <ChartWrapper style={{ position: 'relative' }}>
        {!exportMode ? (
          <ChartLegend
            style={{
              position: 'absolute',
              right: ANNUAL_REVIEW_REPORT_CONTENT_SPACING,
              top: -40,
              zIndex: 1,
            }}
            isSelectable
            selectedCallback={(selectedItem) => {
              setSelected(selectedItem);
            }}
            items={[
              {
                label: t('app:assetClasses:stability'),
                color: accent.velvet1,
                key: 'S',
              },
              {
                label: t('app:assetClasses:marketGrowth'),
                color: accent.velvet2,
                key: 'MV',
              },
              {
                label: t('app:assetClasses:alternativeGrowth'),
                color: accent.velvet3,
                key: 'AV',
              },
            ]}
          />
        ) : null}
        <HoldingsChartWrapper
          style={{ flexDirection: 'column', paddingTop: 100 }}
        >
          <ReturnContributionChart selected={selected} />
        </HoldingsChartWrapper>
      </ChartWrapper>
    </Base>
  );
};
