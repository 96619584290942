import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import styled from 'styled-components';

import {
  sortByAssetClass,
  percentage,
  selectors,
  useResource,
  t,
} from '@formue-app/core';

import { BaseSection } from '../components/common/BaseSection';
import { H2, Paragraph } from '../components/texts';

import { CenteredActivityIndicator } from '../components/common/ActivityIndicator';
import { ButtonSmall } from '../components/buttons';
import { GridElement } from '../components/layout';
import { accent, uiNegative } from '../constants/colors';
import { SPACING_16, SPACING_24 } from '../constants/spacing';
import {
  ProductEditSection,
  ProductSection,
} from '../components/advisorsCorner/strategySimulator/ProductItems';

const StyledGridElement = styled(GridElement)`
  display: flex;
  gap: ${SPACING_16};
  flex-direction: column;
`;

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  gap: ${SPACING_16};
  flex-direction: row;
  align-items: center;
  border: 1px solid ${accent.ocean220};
  padding: ${SPACING_16} ${SPACING_24};
`;

const {
  entities: {
    investmentStrategyAssetAllocations: {
      investmentStrategyAssetAllocationsByStrategyIdSelector,
    },
    investmentStrategyProducts: {
      investmentStrategyProductsByStrategyIdSelector,
    },
    investmentStrategies: { investmentStrategyByIdSelector },
  },
} = selectors;

export const StrategyDetailsProductsPage = (props) => {
  const { id } = useParams();

  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    {
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: id },
    },
    {
      type: 'INVESTMENT_STRATEGY_PRODUCT_ALLOCATIONS/INDEX',
      args: { strategyId: id },
    },
  ]);

  const features = { strategyToolBeta: false }; //useSelector(getFlagsSelector);

  const strategy = useSelector(investmentStrategyByIdSelector(id));
  const products = useSelector(
    investmentStrategyProductsByStrategyIdSelector(id)
  );
  const assetAllocations = useSelector(
    investmentStrategyAssetAllocationsByStrategyIdSelector(id)
  );
  const [editProducts, setEditProducts] = useState(false);
  const [totalStrategyShare, setTotalStrategyShare] = useState(false);
  const [editableProducts, setEditableProducts] = useState([]);

  useEffect(() => {
    if (assetAllocations) {
      setTotalStrategyShare(
        assetAllocations
          .map((item) => item.strategyShare)
          .reduce((total, acc) => {
            return (acc + total) * 100;
          }, 0)
      );
    }
  }, [assetAllocations]);

  useEffect(() => {
    if (!editableProducts?.length && products?.length) {
      setEditableProducts(products);
    }
  }, [products, editableProducts]);

  if (loading) {
    return (
      <GridElement columns={12}>
        <CenteredActivityIndicator />
      </GridElement>
    );
  }

  return (
    <>
      <StyledGridElement columns={12}>
        <div style={{ display: 'flex' }}>
          <H2>{t('app:advisor:strategySimulator:product:title')}</H2>
          {features.strategyToolBeta ? (
            <ButtonSmall
              style={{ marginLeft: 'auto' }}
              onClick={() => setEditProducts(!editProducts)}
            >
              {t('app:advisor:strategySimulator:product:editProducts')}
            </ButtonSmall>
          ) : null}
        </div>
      </StyledGridElement>
      {assetAllocations.sort(sortByAssetClass).map((assetClass, index) => {
        return editProducts ? (
          <ProductEditSection
            item={assetClass}
            products={editableProducts.filter(
              (item) => item.assetClass === assetClass.assetClass
            )}
            editableProducts={editableProducts}
            setEditableProducts={setEditableProducts}
            key={`edit-section-${assetClass.assetClass}-${index}`}
          />
        ) : (
          <ProductSection
            item={assetClass}
            strategy={strategy}
            products={editableProducts.filter(
              (item) => item.assetClass === assetClass.assetClass
            )}
            key={`section-${assetClass.assetClass}-${index}`}
          />
        );
      })}

      {editProducts ? (
        <StyledBaseSection columns={12}>
          <ButtonSmall style={{ marginRight: 'auto' }} onClick={() => {}}>
            {t('app:advisor:strategySimulator:product:reset')}
          </ButtonSmall>
          <Paragraph style={{ fontWeight: 500, marginRight: SPACING_16 }}>
            {t('app:advisor:strategySimulator:product:total')}{' '}
            {percentage(totalStrategyShare)}
            {totalStrategyShare > 100 ? (
              <span style={{ color: uiNegative, fontWeight: 500 }}>
                {' '}
                - {t(
                  'app:advisor:strategySimulator:product:reduceAllocation'
                )}{' '}
                {percentage(totalStrategyShare - 100)}
              </span>
            ) : null}
          </Paragraph>
          <ButtonSmall onClick={() => {}}>
            {t('app:globals:cancel')}
          </ButtonSmall>
          <ButtonSmall onClick={() => {}}>{t('app:globals:save')}</ButtonSmall>
        </StyledBaseSection>
      ) : null}
    </>
  );
};
