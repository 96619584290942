import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import { accent } from '../../../constants/colors';
import { SPACING_24, SPACING_8 } from '../../../constants/spacing';
import { List } from '../../common/List';
import { GridElement, MainGrid } from '../../layout';
import { Paragraph } from '../../texts';

import { FeeTable } from './FeeTable';

const StyledContainer = styled(MainGrid)`
  padding: 0;
  margin-top: ${SPACING_24};
`;

const Line = styled(GridElement)`
  margin: ${SPACING_8} 0;
  background-color: ${accent.sand370};
  height: 1px;
`;

export const FeeContent = (props) => {
  let items = [
    {
      title: t(`app:portfolio:costReport:fee:list:title1`),
      content: t(`app:portfolio:costReport:fee:list:body1`),
    },
    {
      title: t(`app:portfolio:costReport:fee:list:title2`),
      content: t(`app:portfolio:costReport:fee:list:body2`),
    },
    {
      title: t(`app:portfolio:costReport:fee:list:title3`),
      content: t(`app:portfolio:costReport:fee:list:body3`),
    },
    {
      title: t(`app:portfolio:costReport:fee:list:title4`),
      content: t(`app:portfolio:costReport:fee:list:body4`),
    },
    {
      title: t(`app:portfolio:costReport:fee:list:title5`),
      content: t(`app:portfolio:costReport:fee:list:body5`),
    },
    {
      title: t(`app:portfolio:costReport:fee:list:title6`),
      content: t(`app:portfolio:costReport:fee:list:body6`),
    },
  ];

  return (
    <StyledContainer>
      <GridElement columns={8}>
        <Paragraph>{t('app:portfolio:costReport:fee:intro')}</Paragraph>
      </GridElement>
      <Line columns={12} />
      <GridElement columns={8}>
        <List style={{ margin: 0 }} items={items} />
      </GridElement>
    </StyledContainer>
  );
};
