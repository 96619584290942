import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { actions, selectors, t, useResource } from '@formue-app/core';

import { BaseSection } from '../components/common/BaseSection';
import { H2, Paragraph } from '../components/texts';
import { GridElement } from '../components/layout';
import { Select, Toggle } from '../components/formElements';

import { SPACING_32, SPACING_24 } from '../constants/spacing';
import { accent } from '../constants/colors';
import { CenteredActivityIndicator } from '../components/common/ActivityIndicator';
import { ImplementationPlanTable } from '../components/advisorsCorner/strategySimulator/ImplementationPlanTable';

const Header = styled.div`
  display: flex;
  gap: ${SPACING_24};
`;

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  gap: ${SPACING_32};
`;

const StyledSelect = styled(Select)`
  border: 1px solid ${accent.ocean220};
  width: 100%;
`;

const StyledToggle = styled(Toggle)`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const {
  entities: {
    investmentStrategyImplementationPlans: {
      investmentStrategyImplementationPlansByIdSelector,
    },
  },
} = selectors;

const {
  entities: {
    investmentStrategyImplementationPlans:
      investmentStrategyImplementationPlansActions,
  },
} = actions;

export const StrategyDetailsImplementationPage = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const loading = useResource([
    {
      type: 'INVESTMENT_STRATEGY_IMPLEMENTATION_PLANS/INDEX',
      args: { strategyId: id },
    },
  ]);

  const implementationPlan = useSelector(
    investmentStrategyImplementationPlansByIdSelector(id)
  );

  const features = { strategyToolBeta: false }; //useSelector(getFlagsSelector);

  const [implementationPlanToggle, setImplementationPlanToggle] =
    useState(false);
  const [showImplementationPlan, setShowImplementationPlan] = useState(false);

  const [implementationRound, setImplementationRound] = useState(
    implementationPlan?.rounds
  );
  const [implementationTime, setImplementationTime] = useState(
    implementationPlan?.monthsPerRound
  );

  useEffect(() => {
    if (implementationPlan) {
      setImplementationRound(implementationPlan.rounds);
      setImplementationTime(implementationPlan.monthsPerRound);
    }
  }, [implementationPlan]);

  useEffect(() => {
    if (implementationPlan?.assetClasses) {
      setShowImplementationPlan(true);
      // Set toggle to true if we have asset classes which
      // indicates that we want to use an implementation plan
      setImplementationPlanToggle(true);
    }
  }, [implementationPlan]);

  const updateImplementationPlan = () => {
    dispatch(
      investmentStrategyImplementationPlansActions.updateRequestBegin({
        args: {
          strategyId: id,
          payload: {
            rounds: implementationRound,
            monthsPerRound: implementationTime,
          },
        },
      })
    );
  };

  if (loading) {
    return (
      <GridElement columns={12}>
        <CenteredActivityIndicator />
      </GridElement>
    );
  }

  return (
    <>
      <GridElement columns={12}>
        <H2>{t('app:advisor:strategySimulator:implementation:title')}</H2>
      </GridElement>
      {features.strategyToolBeta ? (
        <StyledBaseSection columns={12}>
          <Paragraph style={{ fontWeight: 500 }}>
            {t(
              'app:advisor:strategySimulator:implementation:implementationTime'
            )}
          </Paragraph>

          <StyledToggle
            value={implementationPlanToggle}
            onChange={() =>
              setImplementationPlanToggle(!implementationPlanToggle)
            }
            small={true}
            disabled={!features.strategyToolBeta}
          />
        </StyledBaseSection>
      ) : null}
      {showImplementationPlan ? (
        <GridElement columns={12}>
          {implementationPlan?.rounds > 1 ? (
            <Header>
              <div style={{ width: '25%' }}>
                <StyledSelect
                  popoutLabel={true}
                  placeholder={t(
                    'app:advisor:strategySimulator:implementation:numberOfRounds'
                  )}
                  label={t(
                    'app:advisor:strategySimulator:implementation:numberOfRounds'
                  )}
                  defaultValue={implementationPlan?.rounds}
                  options={[
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                  ]}
                  onChange={(e) => {
                    setImplementationRound(parseInt(e.target.value));
                    updateImplementationPlan();
                  }}
                  disabled={!features.strategyToolBeta}
                />
              </div>
              <div style={{ width: '25%' }}>
                <StyledSelect
                  popoutLabel={true}
                  placeholder={t(
                    'app:advisor:strategySimulator:implementation:numberOfMonths'
                  )}
                  label={t(
                    'app:advisor:strategySimulator:implementation:numberOfMonths'
                  )}
                  defaultValue={implementationPlan?.monthsPerRound}
                  options={[
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                  ]}
                  onChange={(e) => {
                    setImplementationTime(parseInt(e.target.value));
                    updateImplementationPlan();
                  }}
                  disabled={!features.strategyToolBeta}
                />
              </div>
            </Header>
          ) : null}
          <ImplementationPlanTable
            implementationPlan={implementationPlan}
            rounds={implementationRound}
            monthsPerRound={implementationTime}
          />
        </GridElement>
      ) : (
        <StyledBaseSection columns={12}>
          <Paragraph>
            {t('app:advisor:strategySimulator:implementation:missingData')}
          </Paragraph>
        </StyledBaseSection>
      )}
    </>
  );
};
