import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { t } from '@formue-app/core';

import { accent, backgroundWhite, textLightest } from '../../constants/colors';
import { SPACING_32, SPACING_16 } from '../../constants/spacing';

import { SlideTitle } from './SlideTitle';

import { SlideTableOfContents } from './SlideTableOfContents';
import { SpriteIcon } from '../common/SpriteIcon';
import { Button } from '../buttons';

const AdminWrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  background-color: ${(props) =>
    props.editMode ? accent.ocean4 : accent.sand1};
  z-index: 10;
`;

const StyledSlideTitle = styled(SlideTitle)`
  padding-left: ${SPACING_32};
  padding-right: ${SPACING_32};

  h1,
  h6 {
    transition-delay: 0s !important;
  }
`;

const EditButton = styled(Button)`
  position: absolute;
  bottom: ${SPACING_32};
  right: ${SPACING_32};

  ${(props) =>
    props.editMode &&
    css`
      border-color: ${backgroundWhite};
    `};
`;

export const AdminOverlay = (props) => {
  const {
    slides,
    enabledSlides,
    setActiveSlides,
    setActiveIndex,
    setShowAdminPanel,
  } = props;

  const [editMode, setEditMode] = useState(false);

  return (
    <AdminWrapper editMode={editMode}>
      <StyledSlideTitle
        titleColor={editMode ? 'white' : 'ocean490'}
        subTitleColor={editMode ? 'white' : 'ocean490'}
        titleKey={'app:presentation:common:editPresentationTitle'}
        subTitle={'app:presentation:common:editPresentationSubTitle'}
        isActiveSlide={true}
      />

      <div style={{ padding: SPACING_32 }}>
        <SlideTableOfContents
          slides={slides}
          editMode={editMode}
          setEditMode={setEditMode}
          setActiveSlides={setActiveSlides}
          onItemNavigate={(item) => {
            setActiveIndex(enabledSlides.indexOf(item));
            setEditMode(false);
            setShowAdminPanel(false);
          }}
        />
      </div>

      <EditButton
        buttonstyle="outline"
        onClick={() => setEditMode(!editMode)}
        editMode={editMode}
        icon={
          <SpriteIcon
            id="Settings"
            size={SPACING_16}
            color={editMode && backgroundWhite}
            accentColor={editMode && backgroundWhite}
          />
        }
        textcolor={editMode && textLightest}
        buttoncolorhover={editMode && accent.ocean490}
      >
        {editMode
          ? t('app:presentation:common:savePresentation')
          : t('app:presentation:common:editPresentation')}
      </EditButton>
    </AdminWrapper>
  );
};
