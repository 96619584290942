import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { t, selectors } from '@formue-app/core';
import { H3 } from '../../../texts';
import { accent } from '../../../../constants/colors';

const {
  entities: {
    securities: { securitiesBySectorSelector },
  },
} = selectors;

export const SectorsText = ({ fontSize, lineHeight, style }) => {
  const bySector = useSelector(securitiesBySectorSelector);
  const topSector = Object.values(bySector).sort(
    (a, b) => b.partOfKnownSectorPortfolio - a.partOfKnownSectorPortfolio
  )[0];

  if (!topSector) return null;

  return (
    <div style={style}>
      <H3
        style={{
          color: accent.raspberry250,
          fontSize,
          lineHeight: `${lineHeight}px`,
        }}
      >
        <Trans
          i18nKey="app:sectors:entryText"
          values={{
            numberOfSectors: Object.keys(bySector).length,
            topSector: t(`app:sectors:${topSector.sector}:title`),
          }}
          components={{
            strong: (
              <span style={{ color: accent.raspberry3, fontWeight: 500 }} />
            ),
          }}
        />
      </H3>
    </div>
  );
};
