import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import {
  selectors,
  percentage,
  formatMoney,
  getAssetClassName,
  getColorForAssetClassGroup,
} from '@formue-app/core';

import { ReactComponent as StabilityIcon } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-stability-icon.svg';
import { ReactComponent as MarketGrowthIcon } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-market-growth-icon.svg';
import { ReactComponent as AlternativeGrowthIcon } from '../../../assets/icons/portfolio/portfolio-structure-pyramid-alternative-growth-icon.svg';

import { BaseSection } from '../../common/BaseSection';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { FixedPyramidChart } from '../../wealthPlan/InvestmentSimulation/FixedPyramidChart';

import { H6, Paragraph } from '../../texts';

import { H4Size } from '../../../constants/text';
import {
  accent,
  assetClassDefaultColorScheme as colorScheme,
  textLightest,
} from '../../../constants/colors';
import {
  mobileCondition,
  tabletCondition,
  desktopCondition,
  notDesktopCondition,
} from '../../../constants/media';
import {
  BORDER_RADIUS_LARGE,
  SPACING_32,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../constants/spacing';

const { assetGroupDataSelector } = selectors.ui.portfolio;

const StyledWrapper = styled(BaseSection)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 28px 54px;
  flex-flow: row nowrap;

  @media ${notDesktopCondition} {
    flex-flow: column;
    padding: 0;
  }
`;

const StyledFixedPyramid = styled(FixedPyramidChart)`
  margin-right: 77px;

  @media ${tabletCondition} {
    margin: 21px 0;
  }
  @media ${mobileCondition} {
    margin: 28px;
  }
`;

const StyledAssetClassDescription = styled.article`
  display: flex;
  flex-flow: row no-wrap;
  align-items: center;
  border-top: 1px solid ${accent.sand3};
  border-left: 1px solid ${accent.sand3};
  padding: 14px 0 14px ${SPACING_32};

  @media ${desktopCondition} {
    &:first-child {
      border-top: none;
    }

    header {
      min-width: 191px;
      display: flex;
      flex-flow: column;
      margin-right: ${SPACING_16};
    }
  }

  @media ${notDesktopCondition} {
    padding: ${SPACING_24} 90px ${SPACING_24} 0;
    position: relative;
    border-left: none;
    flex-flow: column;
    align-items: flex-start;
    min-height: 154px;
  }
`;

const StyledAssetClassIconWrapper = styled.div`
  margin-left: 10px;

  @media ${notDesktopCondition} {
    position: absolute;
    top: 0;
    right: 0;

    svg {
      height: 130px;
    }
  }
`;

const AssetGroupData = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: ${H4Size};
  margin-top: ${SPACING_8};
`;

const StyledShare = styled.p`
  background: ${(props) => props.color};
  color: ${textLightest};
  border-radius: ${BORDER_RADIUS_LARGE};
  width: auto;
  text-align: center;
  padding: 4px 8px 7px;
  margin-right: ${SPACING_16};
  font-weight: 500;
  line-height: 15px;
`;
const StyledMarketValue = styled.p`
  font-weight: 500;
`;

export const PortfolioStructureContent = (props) => {
  const { t } = useTranslation();
  const assetGroupData = useSelector(assetGroupDataSelector);
  const isNotDesktop = useMediaQuery({ query: notDesktopCondition });

  if (!assetGroupData) {
    return <CenteredActivityIndicator />;
  }

  const groups = ['alternativeGrowth', 'marketGrowth', 'stability'];

  const icons = {
    stability: <StabilityIcon />,
    marketGrowth: <MarketGrowthIcon />,
    alternativeGrowth: <AlternativeGrowthIcon />,
  };

  const assetClassGroupData = Object.values(assetGroupData).map((item) => ({
    value: item.share / 100,
    label: getAssetClassName(item.group),
    group: item.group,
    color: getColorForAssetClassGroup(item.group, colorScheme),
  }));

  return (
    <StyledWrapper>
      <StyledFixedPyramid
        height="200"
        showLegend={isNotDesktop}
        data={assetClassGroupData}
      />

      <div>
        {groups.reverse().map((key, index) => (
          <StyledAssetClassDescription key={key}>
            <header>
              <H6>{t(`app:portfolio:structure:${key}:title`)}</H6>
              <AssetGroupData>
                <StyledShare color={colorScheme[index]}>
                  {percentage(assetGroupData[key].share)}
                </StyledShare>
                <StyledMarketValue>
                  {formatMoney(assetGroupData[key].marketValue)}
                </StyledMarketValue>
              </AssetGroupData>
            </header>
            <Paragraph>{t(`app:portfolio:structure:${key}:text`)}</Paragraph>

            <StyledAssetClassIconWrapper>
              {icons[key]}
            </StyledAssetClassIconWrapper>
          </StyledAssetClassDescription>
        ))}
      </div>
    </StyledWrapper>
  );
};
