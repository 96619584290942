import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  formatGainValue,
  formatTwrValue,
  selectors,
  t,
  useErrorState,
  useResource,
} from '@formue-app/core';

import { SPACING_48, SPACING_24, SPACING_64 } from '../../../constants/spacing';
import { accent, textLightest } from '../../../constants/colors';

import { ReportTitle } from '../reportComponent/ReportTitle';
import { MultipleSwitch } from '../../formElements';
import { PortfolioReturnPerYearTitle } from '../slideComponents/portfolio/PortfolioReturnPerYearTitle';
import { PortfolioReturnPerYearChart } from '../slideComponents/portfolio/PortfolioReturnPerYearChart';
import { desktopCondition, mobileCondition } from '../../../constants/media';
import { ReportErrorMessage } from '../reportComponent/ReportErrorMessage';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';

const {
  entities: {
    financialValues: { allfinancialValuesItemsSelector },
  },
} = selectors;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;
`;

const StyledPortfolioReturnPerYearTitle = styled(PortfolioReturnPerYearTitle)`
  text-align: center;

  @media ${desktopCondition} {
    white-space: pre-line;
  }

  @media ${mobileCondition} {
    max-width: 100%;
  }
`;

const GraphWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  height: 600px;
  margin-top: ${SPACING_64};
  padding-bottom: ${SPACING_24};
  padding-top: ${SPACING_24};
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${SPACING_48};
`;

export const PortfolioReturnPerYearSection = (props) => {
  const { backgroundColor, title, isVisible, ...rest } = props;

  const financialValues = useSelector(allfinancialValuesItemsSelector);
  const loading = useResource(['FINANCIALVALUES/INDEX']);
  const error = useErrorState(['FINANCIALVALUES/INDEX']);
  const [dataType, setDataType] = useState('twr');

  const thisYear = financialValues[financialValues.length - 1];
  const lastYear = financialValues[financialValues.length - 2];

  if (error) {
    return <ReportErrorMessage {...rest} />;
  }

  return (
    <>
      <ReportTitle {...rest} />

      <HeaderWrapper>
        {!loading ? (
          <StyledPortfolioReturnPerYearTitle
            lastYear={lastYear?.p}
            lastYearValue={
              dataType === 'twr'
                ? formatTwrValue(lastYear?.twr, 1, true)
                : formatGainValue(lastYear?.gain)
            }
            thisYearValue={
              dataType === 'twr'
                ? formatTwrValue(thisYear?.twr, 1, true)
                : formatGainValue(thisYear?.gain)
            }
            {...props}
          />
        ) : null}
      </HeaderWrapper>

      <GraphWrapper>
        <MultipleSwitch
          backgroundColor={accent.ocean270}
          activeBackgroundColor={accent.ocean1}
          color={accent.ocean1}
          activeColor={textLightest}
          onChange={(value) => {
            setDataType(value);
          }}
          options={[
            {
              label: t(
                'app:presentation:annualReview:returnPerYear:returnInPercentage'
              ),
              value: 'twr',
            },
            {
              label: t(
                'app:presentation:annualReview:returnPerYear:returnInKr'
              ),
              value: 'gain',
            },
          ]}
        />

        {!loading ? (
          <PortfolioReturnPerYearChart
            financialValues={financialValues}
            dataType={dataType}
          />
        ) : (
          <CenteredActivityIndicator />
        )}
      </GraphWrapper>
    </>
  );
};
