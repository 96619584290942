import React from 'react';

import { constants, t } from '@formue-app/core';
import { SimpleSelectDropdown } from '../../../formElements/SimpleSelectDropdown';

const { SUSTAINABILITY_PERIODS } = constants.qlik;

export const PeriodFilterDropdown = (props) => {
  const { selectedCallback = (selected) => selected, ...rest } = props;

  return (
    <SimpleSelectDropdown
      {...props}
      options={Object.keys(SUSTAINABILITY_PERIODS).map((periodKey) => ({
        label: t(`app:filters:period:${periodKey}`),
        value: periodKey,
        key: periodKey,
      }))}
      selectedCallback={(item) => selectedCallback(item.value)}
      selectedItemKey="TWELVEMONTHS"
      width={230}
    />
  );
};
