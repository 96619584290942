import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useQueryState } from 'react-router-use-location-state';
import { useTranslation } from 'react-i18next';

import {
  selectors,
  filterList,
  config,
  useResource,
  getCSVDownloadLink,
} from '@formue-app/core';

import { backgroundMain } from '../../constants/colors';

import { GridElement } from '../../components/layout/GridElement';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { TransactionList } from '../../components/portfolio/transactions/TransactionList';
import { ListFilterHeader } from '../../components/lists/ListFilterHeader';
import { accessTokenSelector } from '../../store/auth/selectors';
import { ReportingPeriodFilter } from '../../components/portfolio/reporting/ReportingPeriodFilter';

const {
  entities: {
    transactions: { transactionAllIdsSelector, transactionByIdSelector },
  },
  ui: {
    portfolio: { activeFiltersSelector },
  },
} = selectors;

const StyledCenteredActivityIndicator = styled(CenteredActivityIndicator)`
  margin-top: 50px;
`;

export const TransactionsPage = () => {
  const loading = useResource(['TRANSACTIONS/INDEX']);
  const { t } = useTranslation();
  const [transactionType, setTransactionType] = useQueryState(
    'transactionType',
    ''
  );
  const [product, setProduct] = useQueryState('product', '');
  const allIds = useSelector(transactionAllIdsSelector);
  const byId = useSelector(transactionByIdSelector);
  const activeFilters = useSelector(activeFiltersSelector);
  const accessToken = useSelector(accessTokenSelector);
  const { language: chosenLanguage } = useSelector((state) => state.ui.user);

  const filters = {
    type: {
      type: 'select',
      value: transactionType,
      onChange: setTransactionType,
      label: t('app:portfolio:transaction'),
    },
    product: {
      type: 'select',
      value: product,
      onChange: setProduct,
      label: t('app:portfolio:product'),
    },
  };

  const [transactions, filterData, clearFilter] = filterList(
    allIds,
    byId,
    filters
  );

  const doExport = () => {
    const link = getCSVDownloadLink(
      `${config.api.host}${config.api.baseUrl}/v2/qlik/transactions.csv`,
      activeFilters,
      chosenLanguage,
      accessToken
    );
    window.open(link);
  };

  if (loading) {
    return (
      <GridElement columns={12}>
        <StyledCenteredActivityIndicator
          columns={12}
          size={30}
          background={backgroundMain}
          loadingText={t('app:loading:transactions')}
        />
      </GridElement>
    );
  }

  return (
    <>
      <ListFilterHeader
        filters={filters}
        data={filterData}
        onClear={clearFilter}
        onExport={doExport}
      >
        <ReportingPeriodFilter
          inputstyle="filled"
          style={{ width: '100%', maxWidth: 250, minWidth: 157 }}
        />
      </ListFilterHeader>
      <TransactionList items={transactions} />
    </>
  );
};
