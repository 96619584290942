import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { formatPeriodLabel, getLocalizedMonthName, t } from '@formue-app/core';

import { SPACING_24, SPACING_8 } from '../../constants/spacing';
import {
  mobileCondition,
  printCondition,
  tabletCondition,
} from '../../constants/media';
import { GridElement } from '../../components/layout/GridElement';
import { Toggle, SearchInput, Select } from '../../components/formElements';
import { Button, ButtonSmall } from '../buttons';
import { SpriteIcon } from '../common/SpriteIcon';
import { backgroundWhite } from '../../constants/colors';

const StyledGridElement = styled(GridElement)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1px;

  @media ${tabletCondition} {
    flex-direction: column;
    margin-top: 0px;
  }

  @media ${printCondition} {
    display: none;
  }
`;

const FilterRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${SPACING_24};
  width: 100%;

  @media ${mobileCondition} {
    padding: 0px;
    margin: 0px;
    width: 100%;
    gap: ${SPACING_8};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-left: auto;
  gap: ${SPACING_24};

  @media ${mobileCondition} {
    display: none;
  }
`;

const inputStyle = css`
  max-width: 250px;

  @media ${tabletCondition} {
    max-width: initial;
    flex: 1 1 0px;
  }

  @media ${mobileCondition} {
    max-width: initial;
    width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  ${inputStyle}
`;

const StyledSearchInput = styled(SearchInput)`
  ${inputStyle}
  width: 100%;
`;

const Filter = (props) => {
  const {
    label,
    labels,
    id,
    value,
    type,
    searchIndex,
    onChange,
    onClick,
    data,
    filterKey,
    style,
  } = props;

  switch (type) {
    case 'toggle':
      return (
        <Toggle
          key={label}
          value={value}
          onChange={() => onChange()}
          id={id}
          label={label}
          labels={labels}
          className="filterItem"
          style={style}
          small={true}
        />
      );
    case 'select':
      // Prevent adding multiple "all" options because of React Strict Mode multiple renders
      if (!data[filterKey][0]?.all) {
        data[filterKey].unshift({
          value: '',
          label: t('app:filters:all'),
          all: true,
        });
      }
      return (
        <StyledSelect
          key={label}
          name={label}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          placeholder={label}
          options={data[filterKey]?.map((option) => {
            if (typeof option !== 'object') {
              return { value: option, label: option };
            } else {
              return { ...option };
            }
          })}
          className="filterItem"
          style={style}
          inputstyle="filled"
        />
      );
    case 'search':
      if (searchIndex) {
        return (
          <StyledSearchInput
            key={label}
            name={label}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            placeholder={label}
            style={style}
            className="filterItem"
            inputstyle="filled"
          />
        );
      } else {
        return null;
      }
    case 'date':
      const [activePeriod, startDate, endDate] = value;

      return (
        <Button
          buttonstyle="tonal"
          onClick={onClick}
          invert={true}
          buttoncolor={backgroundWhite}
          style={{
            fontSize: 15,
            fontWeight: 400,
            paddingTop: 15,
            paddingBottom: 15,
          }}
          icon={
            <SpriteIcon
              size={16}
              id="Calendar"
              inverted={false}
              navigational={true}
            />
          }
        >
          {t('app:filters:timeSpanTitle')}
          {activePeriod === 'CUSTOM'
            ? ` ${getLocalizedMonthName(
                new Date(startDate).getMonth(),
                false
              )} ${new Date(startDate).getFullYear()} - ${getLocalizedMonthName(
                new Date(endDate).getMonth(),
                false
              )} ${new Date(endDate).getFullYear()}`
            : ` ${formatPeriodLabel(activePeriod)}`}
        </Button>
      );
    default:
      return null;
  }
};

export const ListFilterHeader = (props) => {
  const {
    filters,
    data,
    onClear,
    onExport,
    onReload,
    enablePrint = true,
    children,
    ...rest
  } = props;
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const filtersApplied = !!Object.entries(filters).filter(
    ([key, filter]) => !!filters[key].value
  ).length;

  const buttonGroupCondition =
    !!onExport ||
    !!onClear ||
    !!onReload ||
    (!!onClear && filtersApplied) ||
    enablePrint;

  return (
    <StyledGridElement columns={12} {...rest}>
      <FilterRow>
        {Object.entries(filters).map(([filterKey, filter]) => (
          <Filter
            {...filter}
            data={data}
            key={filterKey}
            filterKey={filterKey}
          />
        ))}

        {children}

        {buttonGroupCondition && (
          <ButtonGroup>
            {!!onClear && filtersApplied && (
              <ButtonSmall
                buttonstyle="tonal"
                onClick={onClear}
                style={{ marginLeft: 'auto' }}
                invert={true}
              >
                {t('app:portfolio:clearFilter')}
              </ButtonSmall>
            )}
            {enablePrint && (
              <ButtonSmall
                buttonstyle="tonal"
                onClick={() => window.print()}
                invert={true}
              >
                {t('app:globals:print')}
              </ButtonSmall>
            )}
            {!!onExport && (
              <ButtonSmall buttonstyle="tonal" onClick={onExport} invert={true}>
                {t('app:globals:export')}
              </ButtonSmall>
            )}
            {!!onReload && (
              <ButtonSmall buttonstyle="tonal" onClick={onReload} invert={true}>
                {t('app:globals:reload')}
              </ButtonSmall>
            )}
          </ButtonGroup>
        )}
      </FilterRow>
    </StyledGridElement>
  );
};
