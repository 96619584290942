import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSelector } from 'react-redux';

import {
  generateFullName,
  generateSpeedometerCustomColors,
  riskAndLockinValueToText,
  selectors,
  t,
  useResource,
} from '@formue-app/core';

import { accent } from '../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_24,
  SPACING_16,
} from '../../constants/spacing';
import { easeInOut, longAnimationTime } from '../../constants/animation';

import { BaseSection } from '../common/BaseSection';
import { H3, H6, Paragraph, ParagraphXSmall } from '../texts';

import { RecommendedStrategyModal } from '../advisorsCorner/strategySimulator/RecommendedStrategyModal';
import { InvestmentProfileModal } from '../wealthPlan/investmentProfile/InvestmentProfileModal';
import { RiskModalContent } from '../wealthPlan/investmentProfile/RiskModalContent';
import { LockinModalContent } from '../wealthPlan/investmentProfile/LockinModalContent';
import { SpeedometerChart } from '../graphs/SpeedometerChart';
import { CenteredActivityIndicator } from '../common/ActivityIndicator';
import { PreferencesModal } from '../advisorsCorner/strategySimulator/PreferencesModal';

import { ReactComponent as PreferencesIcon } from '../../assets/icons/advisor-corner/preferences-icon.svg';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  gap: ${SPACING_24};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-style: italic;
  }
`;

const StrategySections = styled.div`
  display: flex;
  gap: ${SPACING_16};
  width: 80%;
  margin-left: auto;
`;

const StrategySection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: ${SPACING_24};
  background: ${accent.sand1};
  padding: ${SPACING_24};
  border-radius: ${BORDER_RADIUS_LARGE};
  text-align: center;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}

  &:hover {
    p {
      text-decoration: underline;
    }
  }
`;

const fadeIn = keyframes`
  0% {
    translate: 0 10px;
    opacity: 0;
  }
  100% {
    translate: 0 0px;
    opacity: 1;
  }
`;

const Label = styled(H6)`
  translate: 0 10px;
  opacity: 0;
  margin-top: -22px;
  text-align: center;
  animation: ${fadeIn} ${longAnimationTime} ${easeInOut};
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
`;

const SpeedoWrapper = styled.div`
  height: 74px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const {
  entities: {
    investmentProfile: {
      investmentProfileHasContent,
      investmentStrategyRiskAndLockInSelector,
    },
    cashFlowEvents: { allCashFlowEventsMetadataSelector },
  },
  ui: {
    user: { currentUserSelector },
  },
} = selectors;

export const InvestmentProfileHeader = (props) => {
  const loading = useResource([
    'INVESTMENT_PROFILES/INDEX',
    'CASH_FLOW_EVENTS/INDEX',
  ]);

  const [recommendedStrategyModalOpen, setRecommendedStrategyModalOpen] =
    useState(false);
  const [riskModalOpen, setRiskModalOpen] = useState(false);
  const [lockInModalOpen, setLockInModalOpen] = useState(false);
  const [preferencesOpen, setPreferencesOpen] = useState(false);
  const [recommendedStrategy, setRecommendedStrategy] = useState({
    risk: null,
    lockIn: null,
  });
  const user = useSelector(currentUserSelector);
  const investmentProfile = useSelector(investmentProfileHasContent);
  const investmentStrategyRiskAndLockIn = useSelector(
    investmentStrategyRiskAndLockInSelector
  );
  const { totalPortfolio } = useSelector(allCashFlowEventsMetadataSelector);

  const riskAndLockInCondition = Boolean(
    totalPortfolio &&
      investmentStrategyRiskAndLockIn?.risk !== undefined &&
      investmentStrategyRiskAndLockIn.lockIn !== undefined
  );

  useEffect(() => {
    if (investmentStrategyRiskAndLockIn) {
      setRecommendedStrategy({
        risk: investmentStrategyRiskAndLockIn.risk,
        lockIn: investmentStrategyRiskAndLockIn.lockIn,
      });
    }
  }, [investmentStrategyRiskAndLockIn]);

  return (
    <>
      <StyledBaseSection columns={12}>
        <TextContainer style={{ width: 300 }}>
          <H6>{t('app:globals:client')}</H6>
          <H3>{generateFullName(user)}</H3>
          {!riskAndLockInCondition ? (
            <Paragraph style={{ marginTop: 'auto' }}>
              {t('app:advisor:strategySimulator:warning')}
            </Paragraph>
          ) : null}
        </TextContainer>
        <StrategySections>
          <StrategySection
            disabled={!riskAndLockInCondition}
            onClick={() => {
              riskAndLockInCondition && setRiskModalOpen(true);
            }}
          >
            {loading ? (
              <CenteredActivityIndicator />
            ) : (
              <SpeedoWrapper>
                <SpeedometerChart
                  borderWidth={20}
                  value={recommendedStrategy.risk}
                  pieCount={3}
                  padAngle={3}
                  cornerRadius={4}
                  startAngle={-90}
                  endAngle={90}
                  width={130}
                  height={65}
                  arrowPosition="top"
                  colorScale={generateSpeedometerCustomColors(
                    recommendedStrategy.risk,
                    accent.ocean1,
                    accent.ocean250
                  )}
                  pointerColor={accent.ocean4}
                />
                <Label>
                  {riskAndLockinValueToText(recommendedStrategy.risk)}
                </Label>
              </SpeedoWrapper>
            )}
            <TextContainer style={{ gap: 7 }}>
              <H3 style={{ marginTop: 'auto' }}>
                {t('app:advisor:strategySimulator:risk')}
              </H3>
              <ParagraphXSmall>{t('app:globals:seeMore')}</ParagraphXSmall>
            </TextContainer>
          </StrategySection>
          <StrategySection
            disabled={!riskAndLockInCondition}
            onClick={() => {
              riskAndLockInCondition && setLockInModalOpen(true);
            }}
          >
            {loading ? (
              <CenteredActivityIndicator />
            ) : (
              <SpeedoWrapper>
                <SpeedometerChart
                  borderWidth={20}
                  value={recommendedStrategy.lockIn}
                  pieCount={3}
                  padAngle={3}
                  cornerRadius={4}
                  startAngle={-90}
                  endAngle={90}
                  width={130}
                  height={65}
                  arrowPosition="top"
                  colorScale={generateSpeedometerCustomColors(
                    recommendedStrategy.lockIn,
                    accent.ocean1,
                    accent.ocean250
                  )}
                  pointerColor={accent.ocean4}
                />
                <Label>
                  {riskAndLockinValueToText(recommendedStrategy.lockIn)}
                </Label>
              </SpeedoWrapper>
            )}
            <TextContainer style={{ gap: 7 }}>
              <H3>{t('app:advisor:strategySimulator:lockIn')}</H3>
              <ParagraphXSmall>{t('app:globals:seeMore')}</ParagraphXSmall>
            </TextContainer>
          </StrategySection>
          {/* <StrategySection
            disabled={!riskAndLockInCondition}
            onClick={() => {
              riskAndLockInCondition && setRecommendedStrategyModalOpen(true);
            }}
          >
            {loading ? (
              <CenteredActivityIndicator />
            ) : (
              <StrategyMatrix
                style={{ width: 74, height: 74 }}
                risk={recommendedStrategy.risk}
                lockin={recommendedStrategy.lockIn}
              />
            )}
            <TextContainer style={{ gap: 7 }}>
              <H3>Strategy</H3>
              <ParagraphXSmall>{t('app:globals:seeMore')}</ParagraphXSmall>
            </TextContainer>
          </StrategySection> */}
          <StrategySection
            onClick={() => {
              setPreferencesOpen(true);
            }}
          >
            {!investmentProfile && loading ? (
              <CenteredActivityIndicator />
            ) : (
              <PreferencesIcon />
            )}
            <TextContainer style={{ gap: 7 }}>
              <H3>{t('app:advisor:strategySimulator:preferences:title')}</H3>
              <ParagraphXSmall>{t('app:globals:seeMore')}</ParagraphXSmall>
            </TextContainer>
          </StrategySection>
        </StrategySections>
      </StyledBaseSection>
      {!investmentProfile && loading ? null : (
        <PreferencesModal
          isOpen={preferencesOpen}
          onRequestClose={() => setPreferencesOpen(false)}
        />
      )}
      {loading ? null : (
        <>
          <RecommendedStrategyModal
            isOpen={recommendedStrategyModalOpen}
            onRequestClose={() => setRecommendedStrategyModalOpen(false)}
            risk={recommendedStrategy.risk}
            lockIn={recommendedStrategy.lockIn}
          />
          <InvestmentProfileModal
            isOpen={riskModalOpen}
            onRequestClose={() => setRiskModalOpen(false)}
            title={t(
              'app:wealthPlan:investmentProfile:riskAndLockin:risk:title'
            )}
            subTitle={t(
              'app:wealthPlan:investmentProfile:riskAndLockin:risk:subTitle'
            )}
            value={recommendedStrategy.risk}
          >
            <RiskModalContent />
          </InvestmentProfileModal>
          <InvestmentProfileModal
            isOpen={lockInModalOpen}
            onRequestClose={() => setLockInModalOpen(false)}
            title={t(
              'app:wealthPlan:investmentProfile:riskAndLockin:lockIn:title'
            )}
            subTitle={t(
              'app:wealthPlan:investmentProfile:riskAndLockin:lockIn:subTitle'
            )}
            value={recommendedStrategy.lockIn}
          >
            <LockinModalContent />
          </InvestmentProfileModal>
        </>
      )}
    </>
  );
};
