import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import striptags from 'striptags';
import he from 'he';

import { selectors, t, useResource } from '@formue-app/core';

import { actions as podcastActions } from '../store/ui/podcast';
import { MenuPage } from './base/MenuPage';
import { GridElement } from '../components/layout';
import { H2, Paragraph } from '../components/texts';
import { BaseSection } from '../components/common/BaseSection';
import {
  BORDER_RADIUS_LARGE,
  SPACING_48,
  SPACING_24,
} from '../constants/spacing';
import { accent, backgroundWhite } from '../constants/colors';
import { PodcastsPlaylist } from '../components/podcast/PodcastsPlaylist';
import { PodcastsPagePlayer } from '../components/podcast/PodcastPagePlayer';

const imageFallback = require('../assets/images/default/formue.png');

const StyledBaseSection = styled(BaseSection)`
  padding: ${SPACING_48};
`;

const StyledImageBackground = styled(BaseSection)`
  height: 100%;
  background-size: cover;
  background-position: center;
`;

const PodcastSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING_48};
  background-color: ${accent.ocean4};
  justify-content: space-between;
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const {
  entities: {
    podcastEpisodes: { podcastEpisodesById },
  },
} = selectors;

export const PodcastPage = (props) => {
  const loading = useResource(['PODCAST_EPISODES/INDEX']);
  const episodeId = useSelector((state) => state.ui.podcast.episodeId);
  const queryParams = new URLSearchParams(
    document.location.search.substring(1)
  );
  let id = queryParams.get('id');

  // If there is no "id" in query parameters try with the one
  // from UI podcast store
  if (!id) {
    id = episodeId;
  }

  const dispatch = useDispatch();
  const episodesById = useSelector(podcastEpisodesById);
  const episode = id ? episodesById[id] : Object.values(episodesById)[0];

  useEffect(() => {
    if (!loading) {
      dispatch(podcastActions.setEpisodeId(episode.id));
    }
  }, [episode, dispatch, loading]);

  if (loading) return null;

  const { podcast } = episode;
  const podcastId = id ? podcast.itunesId : null;

  const ingressNoHtml = striptags(podcast.description);
  const decodedHtml = he.decode(ingressNoHtml);

  return (
    <MenuPage {...props} title={t('app:eventCalendar:podcasts')} subpage={true}>
      <GridElement columns={6}>
        <PodcastSection>
          <StyledImageBackground
            style={{
              backgroundImage: `url('${episode.podcast.image}'), url('${imageFallback.default}')`,
              height: 280,
              width: 280,
            }}
          />
          <H2
            style={{
              color: backgroundWhite,
              paddingTop: SPACING_48,
              paddingBottom: SPACING_24,
            }}
          >
            {podcast.title}
          </H2>
          <Paragraph style={{ color: accent.ocean2 }}>{decodedHtml}</Paragraph>
        </PodcastSection>
      </GridElement>
      <GridElement columns={6}>
        <StyledBaseSection
          style={{ padding: 0, backgroundColor: 'transparent' }}
        >
          <PodcastsPagePlayer podcastImage={podcast.image} />
          <PodcastsPlaylist podcastId={podcastId} />
        </StyledBaseSection>
      </GridElement>
    </MenuPage>
  );
};
