import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import {
  t,
  useTodoPresentationListSections,
  selectors,
  actions,
  useErrorState,
} from '@formue-app/core';

import { accent } from '../../../constants/colors';
import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  SPACING_16,
} from '../../../constants/spacing';
import { H3 } from '../../texts';
import { TaskListItem } from '../slideComponents/todo/TaskListItem';
import { AddTodoItem } from '../slideComponents/todo/AddTodoItem';
import { AddTodoOvelay } from '../slideComponents/todo/AddTodoOvelay';
import { SectionList } from '../../lists';
import { slideAnimationDelay } from '../../../constants/animation';
import { EditTodoOvelay } from '../slideComponents/todo/EditTodoOvelay';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const TodoWrapper = styled.div`
  display: flex;
  gap: 75px;
  width: 100%;
  height: 100%;
  margin-top: 95px;
`;

const ContentWrapper = styled.div`
  width: calc(100% / 3);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const {
  ui: {
    user: { currentUserSelector },
  },
  entities: {
    accountTeamMembers: { mainAdvisorSelector, teamMembersSelector },
  },
} = selectors;
const {
  entities: { tasks: tasksActions },
  ui: { flashMessages: flashMessagesActions },
} = actions;

export const TodoSlide = (props) => {
  const { isActiveSlide } = props;
  const dispatch = useDispatch();
  const [addOverlay, openAddOverlay] = useState(false);
  const [editOverlay, openEditOverlay] = useState(false);
  const [editId, setEditId] = useState(false);
  const [newTaskPriority, openNewTaskPriority] = useState('');

  const updateError = useErrorState(['TASKS/UPDATE', 'TASKS/CREATE']);

  const sections = useTodoPresentationListSections();

  const highItems = sections.filter((item) => item.title === 'high');
  const normalItems = sections.filter((item) => item.title === 'normal');
  const completedItems = sections.filter((item) => item.title === 'completed');

  const mainAdvisor = useSelector(mainAdvisorSelector);
  const members = useSelector(teamMembersSelector);
  const accountTeam = [mainAdvisor, ...members];
  const user = useSelector(currentUserSelector);

  useEffect(() => {
    if (updateError) {
      dispatch(
        flashMessagesActions.notify({
          title: t('app:presentation:annualReview:todo:update:error:title'),
          message: t('app:presentation:annualReview:todo:update:error:text'),
          type: 'warning',
        })
      );
    }
  }, [dispatch, updateError]);

  const onUpdate = (updatedItem) => {
    dispatch(
      tasksActions.doOptimisticUpdate({
        data: updatedItem,
      })
    );

    dispatch(
      tasksActions.updateRequestBegin({
        data: {
          ...updatedItem,
          assignee: updatedItem.assignee.id,
          updatedAt: new Date().toISOString(),
        },
      })
    );
  };

  const onEdit = (id) => {
    openEditOverlay(true);
    setEditId(id);
  };

  return (
    <>
      <AddTodoOvelay
        user={user}
        overlay={addOverlay}
        openOverlay={openAddOverlay}
        accountTeam={accountTeam}
        newTaskPriority={newTaskPriority}
      />
      <EditTodoOvelay
        user={user}
        overlay={editOverlay}
        openOverlay={openEditOverlay}
        accountTeam={accountTeam}
        editId={editId}
        onUpdate={onUpdate}
      />
      <Wrapper>
        <TodoWrapper>
          <ContentWrapper>
            <SectionList
              maxHeight={412}
              animateItems={false}
              enter={isActiveSlide}
              animationDelay={slideAnimationDelay}
              sections={highItems}
              renderItem={(item) => (
                <TaskListItem onUpdate={onUpdate} onEdit={onEdit} item={item} />
              )}
              keyExtractor={(subTask, index) => `high-priority-${index}`}
              ListHeaderComponent={
                <H3
                  style={{ color: accent.ocean490, marginBottom: SPACING_16 }}
                >
                  {t(`app:presentation:annualReview:measures:categories:high`)}
                </H3>
              }
              ListEmptyComponent={
                <AddTodoItem
                  openOverlay={openAddOverlay}
                  openNewTaskPriority={openNewTaskPriority}
                  priority="High"
                />
              }
              renderSectionFooter={() => {
                return (
                  <AddTodoItem
                    openOverlay={openAddOverlay}
                    openNewTaskPriority={openNewTaskPriority}
                    priority="High"
                  />
                );
              }}
            />
          </ContentWrapper>
          <ContentWrapper>
            <SectionList
              maxHeight={412}
              animateItems={false}
              enter={isActiveSlide}
              animationDelay={
                slideAnimationDelay + 0.3 + 0.1 * highItems[0].data?.length
              }
              sections={normalItems}
              renderItem={(item) => (
                <TaskListItem onUpdate={onUpdate} onEdit={onEdit} item={item} />
              )}
              keyExtractor={(subTask, index) => `normal-priority-${index}`}
              ListHeaderComponent={
                <H3
                  style={{ color: accent.ocean490, marginBottom: SPACING_16 }}
                >
                  {t(
                    `app:presentation:annualReview:measures:categories:normal`
                  )}
                </H3>
              }
              ListEmptyComponent={
                <AddTodoItem
                  openOverlay={openAddOverlay}
                  openNewTaskPriority={openNewTaskPriority}
                  priority="Normal"
                />
              }
              renderSectionFooter={() => {
                return (
                  <AddTodoItem
                    openOverlay={openAddOverlay}
                    openNewTaskPriority={openNewTaskPriority}
                    priority="Normal"
                  />
                );
              }}
            />
          </ContentWrapper>
          <ContentWrapper>
            <SectionList
              maxHeight={412}
              animateItems={false}
              enter={isActiveSlide}
              animationDelay={
                slideAnimationDelay + 0.3 + 0.1 * normalItems[0]?.data?.length
              }
              sections={completedItems}
              renderItem={(item) => (
                <TaskListItem onUpdate={onUpdate} onEdit={onEdit} item={item} />
              )}
              keyExtractor={(subTask, index) => `todo-${index}`}
              ListHeaderComponent={
                <H3
                  style={{ color: accent.ocean490, marginBottom: SPACING_16 }}
                >
                  {t(
                    `app:presentation:annualReview:measures:categories:completed`
                  )}
                </H3>
              }
            />
          </ContentWrapper>
        </TodoWrapper>
      </Wrapper>
    </>
  );
};
