import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

import { H3 } from '../../../texts';
import { accent, overlayColor } from '../../../../constants/colors';
import {
  SPACING_48,
  SPACING_24,
  SPACING_16,
} from '../../../../constants/spacing';

import { BaseSection } from '../../../common/BaseSection';
import { Input, Label, Select } from '../../../formElements';
import { SpriteIconFunctional } from '../../../common/SpriteIconFunctional';
import { Button } from '../../../buttons';

import { t, actions } from '@formue-app/core';
import {
  easeInOut,
  longAnimationTime,
  shortAnimationTime,
} from '../../../../constants/animation';

const Wrapper = styled.div`
  background: ${overlayColor};
  position: fixed;
  top: -100%;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  opacity: 0;
  transition: opacity ${easeInOut} ${shortAnimationTime};

  ${(props) =>
    props.overlay &&
    css`
      top: 0;
      opacity: 1;
    `}
`;

const Panel = styled(BaseSection)`
  padding: ${SPACING_48};
  background: ${accent.sand1};
  position: relative;
  top: 30px;
  opacity: 0;
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: 0.1s;

  ${(props) =>
    props.overlay &&
    css`
      top: 0px;
      opacity: 1;
    `}
`;

const StyledSpriteIconFunctional = styled(SpriteIconFunctional)`
  cursor: pointer;
  position: absolute;
  top: ${SPACING_24};
  right: ${SPACING_24};
`;

const {
  entities: { tasks: tasksActions },
} = actions;

export const AddTodoOvelay = (props) => {
  const dispatch = useDispatch();
  const {
    overlay,
    openOverlay,
    newTaskPriority = 'normal',
    accountTeam = [],
    user,
  } = props;

  const [assignee, setAssignee] = useState();
  const [title, setTitle] = useState('');
  const [validForm, setValidForm] = useState(true);

  useEffect(() => {
    const valid = !(assignee && title);
    setValidForm(valid);
  }, [assignee, title]);

  // Fix a bug where navigating text field caused prtesentation to switch slides
  // Now we disable the keydown event when the overlay is open
  let oldKeyDown = useRef(window.onkeydown);

  useEffect(() => {
    if (overlay) {
      window.onkeydown = null;
    } else {
      window.onkeydown = oldKeyDown.current;
    }
  }, [overlay]);

  if (!user) return null;

  const options = [
    ...accountTeam.map((item) => ({
      label: item?.name,
      value: item?.ssid,
    })),
    ...user.accessibleAccounts.map((account) => ({
      label: account.name,
      value: account.ssid,
    })),
  ];

  const onSubmit = (event) => {
    event.preventDefault();
    const postObject = {
      assignee,
      customer: user.ssid,
      priority: newTaskPriority,
      title,
      taskType: 'Wealth Plan',
    };

    dispatch(tasksActions.createRequestBegin({ data: postObject }));

    setAssignee(null);
    setTitle('');

    openOverlay(false);
  };

  return (
    <Wrapper overlay={overlay}>
      <Panel overlay={overlay}>
        <StyledSpriteIconFunctional
          id="Close"
          onClick={() => openOverlay(false)}
        />
        <H3 style={{ color: accent.ocean490, marginBottom: SPACING_24 }}>
          {t('app:presentation:annualReview:measures:form:title')}
        </H3>
        <form id="self-declaration-form" onSubmit={(event) => onSubmit(event)}>
          <Label>
            {t('app:presentation:annualReview:measures:form:assigneeLabel')}
          </Label>
          <br />
          <Select
            placeholder={t(
              'app:presentation:annualReview:measures:form:assigneePLaceholder'
            )}
            onChange={(event) => {
              setAssignee(event.target.value);
            }}
            id="assignee"
            name="assignee"
            value={assignee ? assignee : ''}
            options={options}
            style={{
              marginBottom: SPACING_16,
              width: 360,
            }}
          />
          <br />
          <Label>
            {t('app:presentation:annualReview:measures:form:descriptionLabel')}
          </Label>
          <br />
          <Input
            onChange={(event) => setTitle(event.target.value)}
            type="text"
            value={title}
            id="title"
            name="title"
            style={{
              marginBottom: SPACING_16,
              width: 360,
            }}
          />
          <br />
          <Button disabled={validForm} highlight={true}>
            {t('app:presentation:annualReview:measures:form:submitButton')}
          </Button>
        </form>
      </Panel>
    </Wrapper>
  );
};
