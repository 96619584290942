import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { t, toLocaleDateString } from '@formue-app/core';

import {
  accent,
  backgroundWhite,
  hoverBoxShadowLvl2,
} from '../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_32,
  SPACING_24,
  SPACING_8,
  SPACING_48,
  SPACING_16,
} from '../../constants/spacing';
import { easeInOut, shortAnimationTime } from '../../constants/animation';

import { H3, H6, ParagraphSmall } from '../texts';
import { StrategyMatrix } from '../common/StrategyMatrix';
import { FlipArrow } from '../common/FlipArrow';
import { UnreadIndicator } from '../common/UnreadIndicator';
import { strategyTitleWithAccountName } from '../../services/utils/strategies';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StrategyItem = styled.li`
  border-radius: ${BORDER_RADIUS_LARGE};

  a {
    border-radius: ${BORDER_RADIUS_LARGE};
    background: ${backgroundWhite};
    padding: ${SPACING_24};
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    transition: box-shadow ${easeInOut} ${shortAnimationTime};

    &:has(+ ul) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: ${SPACING_48};
    }

    &:hover {
      box-shadow: ${hoverBoxShadowLvl2};
    }
  }

  ul {
    background: ${backgroundWhite};
    padding: ${SPACING_24};
    padding-top: ${SPACING_16};
    list-style: none;
    gap: ${SPACING_8};
    display: flex;
    flex-direction: column;

    li a {
      background: ${accent.sand150};
      border-radius: ${BORDER_RADIUS_LARGE};

      &:hover {
        box-shadow: none;
      }
    }
  }
`;

const StatusIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: ${SPACING_8};
`;

export const StrategyListItem = (props) => {
  const { strategy, strategies = [] } = props;

  const isParent = strategy.children.length > 0;
  const childStrategies = strategies.filter((s) =>
    strategy.children.some((e) => e.id === s.id)
  );

  return (
    <StrategyItem key={`strategy-item-${strategy.id}`}>
      <Link to={`/advisor/strategy-simulator/details/${strategy.id}`}>
        <StrategyMatrix
          risk={strategy.risk}
          lockin={strategy.lockIn}
          style={{ marginRight: 20 }}
        />
        <TextContainer>
          <H3 style={{ color: accent.ocean470, marginRight: 'auto' }}>
            {strategyTitleWithAccountName(strategy)}
          </H3>
          <ParagraphSmall>
            {t('app:advisor:strategySimulator:lastEdited')}:{' '}
            {toLocaleDateString(strategy.lastModifiedAt)}
          </ParagraphSmall>
        </TextContainer>
        <StatusIndicator>
          <UnreadIndicator isUnread={true} />
          <H6>{strategy.status}</H6>
        </StatusIndicator>
        <FlipArrow style={{ marginLeft: SPACING_32 }} />
      </Link>

      {isParent ? (
        <ul>
          {childStrategies.map((child) => (
            <StrategyListItem strategy={child} />
          ))}
        </ul>
      ) : null}
    </StrategyItem>
  );
};
