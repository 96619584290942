import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { isNumber } from 'lodash';

import { t, selectors } from '@formue-app/core';

import { H6, ParagraphSmall, ParagraphXSmall } from '../../texts';
import { accent, backgroundWhite } from '../../../constants/colors';
import { useResize } from '../../../services/hooks';
import {
  BORDER_RADIUS_SMALL,
  SPACING_24,
  SPACING_8,
  SPACING_2,
} from '../../../constants/spacing';
import {
  easeInOut,
  longAnimationTime,
  slideAnimationDelay,
} from '../../../constants/animation';

const {
  entities: {
    investmentProfile: { investmentProfilePreferencesSelector },
  },
} = selectors;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 5;
`;

const BarWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 5px 0;
  margin-bottom: ${SPACING_24};

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: ${accent.ocean3};
    top: 0;
  }

  &:after {
    right: 0;
  }
`;

const Bar = styled.div`
  background-color: ${accent.ocean3};
  height: 2px;
  width: 100%;
`;

const Heading = styled(H6)`
  color: ${backgroundWhite};
  padding-bottom: 4px;
`;

const Text = styled(ParagraphSmall)`
  color: ${backgroundWhite};
`;

const Dot = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${accent.ocean3};
  top: 0;
  left: 50%;
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: ${slideAnimationDelay}s;

  ${(props) =>
    props.active &&
    css`
      left: ${props.dotLeftOffset}px;
    `}
`;

const Label = styled(ParagraphXSmall)`
  position: absolute;
  color: ${accent.ocean4};
  background: ${accent.ocean3};
  border-radius: ${BORDER_RADIUS_SMALL};
  padding: ${SPACING_2} ${SPACING_8};
  translate: calc(-50% + 6px) calc(-100% - 10px);
  top: 0;
  left: 50%;
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: ${slideAnimationDelay}s;

  ${(props) =>
    props.active &&
    css`
      left: ${props.dotLeftOffset}px;
    `}

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background: ${accent.ocean3};
    position: absolute;
    bottom: -4px;
    left: 50%;
    translate: -3px;
    rotate: 45deg;
  }
`;

const TextWrapper = styled.div`
  width: 40%;
  position: relative;
  opacity: 0;
  text-align: ${(props) => (props.rightAlign ? 'right' : 'left')};
  transition: all ${easeInOut} ${longAnimationTime};
  transition-delay: ${slideAnimationDelay + 0.3}s;

  ${(props) =>
    props.rightAlign
      ? css`
          text-align: right;
          right: 25px;
        `
      : css`
          left: 25px;
        `}

  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      ${props.rightAlign
        ? css`
            right: 0px;
          `
        : css`
            left: 0px;
          `}
    `}
`;

export const UnwantedRisk = (props) => {
  const { active = true } = props;
  const preferences = useSelector(investmentProfilePreferencesSelector);

  const hasRiskLevelValue =
    preferences.hasOwnProperty('riskLevel') && isNumber(preferences.riskLevel);
  let value = (preferences.riskLevel * 25) / 100;
  // the marker should never be on the middle (0.5)
  // the range is 1-4. Example inputs/outputs:
  // input: 1 => output: 0
  // input: 2 => output: 0.25
  // input: 3 => output: 0.75
  // input: 4 => output: 1
  if (preferences.riskLevel < 3) {
    value -= 0.25;
  }
  const barRef = useRef();
  const { width } = useResize(barRef);
  const dotLeftOffset = value * width - 16;

  if (!hasRiskLevelValue) {
    return null;
  }

  return (
    <div style={{ paddingTop: 60 }}>
      <BarWrapper>
        <Bar ref={barRef}>
          <Label dotLeftOffset={dotLeftOffset} active={active}>
            {t('app:wealthPlan:investmentProfile:yourChoice')}
          </Label>
          <Dot dotLeftOffset={dotLeftOffset} active={active}></Dot>
        </Bar>
      </BarWrapper>
      <Wrapper>
        <TextWrapper active={active}>
          <Heading>{t('app:wealthPlan:investmentProfile:preserve')}</Heading>
          <Text>{t('app:wealthPlan:investmentProfile:unwantedRiskText1')}</Text>
        </TextWrapper>
        <TextWrapper rightAlign={true} active={active}>
          <Heading>{t('app:wealthPlan:investmentProfile:grow')}</Heading>
          <Text>{t('app:wealthPlan:investmentProfile:unwantedRiskText2')}</Text>
        </TextWrapper>
      </Wrapper>
    </div>
  );
};
