import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import {
  t,
  actions,
  getSigningEventSubCategory,
  toLocaleDateString,
} from '@formue-app/core';

import { TodoListItemLink } from '../TodoListItemLink';
import { Column } from '../TodoListItem';
import { tabletCondition, mobileCondition } from '../../../../constants/media';

import { H4, H6, Paragraph } from '../../../texts';
import { SPACING_16 } from '../../../../constants/spacing';
import { SpriteIcon } from '../../../common/SpriteIcon';

const {
  ui: { readStatus: readActions, signingEvents: signingEventsActions },
} = actions;

const StyledTodoListItemLink = styled(TodoListItemLink)`
  margin-left: auto;

  @media ${mobileCondition} {
    margin: 12px auto 0 auto;
  }
`;

const StyledColumn = styled(Column)`
  display: flex;
  align-items: center;

  @media ${mobileCondition} {
    padding: ${SPACING_16} !important;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  margin-right: ${SPACING_16};
  min-width: 24px;
  min-height: 24px;
`;

const RightAlignCell = styled(Column)`
  display: flex;
  justify-content: flex-end;
  min-width: 160px;
`;

const StyledTitle = styled(H4)`
  word-break: break-word;
`;

const DueDateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MobileHeader = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 4px;
`;

export const useSigningTaskColumns = (props) => {
  const { id, item } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const tabletSize = useMediaQuery({
    query: tabletCondition,
  });

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  if (!item) {
    return null;
  }

  let {
    title,
    completedAt: date,
    dueAt,
    accountName: name,
    subCategory,
    document,
    status,
  } = item;

  const type = subCategory
    ? getSigningEventSubCategory(item)
    : t('app:todo:fallbackTaskCategory');

  // Create object containing link props, e.g onClick handler and label
  let link;
  if (document && status !== 'completed') {
    const openLink = () => {
      dispatch(readActions.saveReadItem({ type: 'signingEvents', id }));
      dispatch(signingEventsActions.addToInProgress(id));
      window.open(document.signatureUrl, '_blank');
    };

    link = {
      onClick: openLink,
      label: t('app:todoItem:buttonUnsignedStateLabel'),
    };
  }

  // SPECIAL HANDLING OF ONCLICK HANDLER FOR SIGNING EVENTS RELATED
  // TO INVESTMENT STRATEGIES
  //
  // For investment strategies, we don't want to send the user directly
  // to the Signicat document signing page, we want to send them to the
  // investment strategy details page where they can see the details of
  // of their strategy before they sign it. If we later on want to do this
  // for more types of signing documents it would probably be wise to move
  // this logic out of this hook, and into something else, but for now this
  // should work fine.
  if (item.investmentStrategy?.id && link) {
    title = t('app:todo:investmentStrategy:listItemTitle');
    if (link) {
      link.onClick = () => {
        history.push(`/investment-strategy/${item.investmentStrategy.id}`);
      };
    }
  }

  let StatusIcon;
  switch (status.toLowerCase()) {
    case 'ongoing':
      StatusIcon = <SpriteIcon id="Iteration" size={24} />;
      link = null;
      break;
    case 'completed':
      StatusIcon = <SpriteIcon id="Task" size={24} />;
      link = null;
      break;
    default:
      StatusIcon = <SpriteIcon id="Document Single" size={24} />;
      break;
  }

  const showDateCondition = status.toLowerCase() === 'completed' && !!date;
  const showDueDateCondition = status.toLowerCase() !== 'completed' && !!dueAt;

  // Define the table columns
  let columns = [
    {
      key: 'title',
      render: () => (
        <>
          <IconWrapper>{StatusIcon}</IconWrapper>
          <TitleWrapper>
            <StyledTitle>{title}</StyledTitle>
            {showDueDateCondition ? (
              <DueDateWrapper>
                <SpriteIcon id="Time" size={12} style={{ marginRight: 4 }} />
                <H6>{toLocaleDateString(dueAt)}</H6>
              </DueDateWrapper>
            ) : null}
          </TitleWrapper>
        </>
      ),
      component: StyledColumn,
      width: '40%',
    },
    {
      key: 'date',
      render: () => (showDateCondition ? toLocaleDateString(date) : null),
      width: '15%',
    },
    {
      key: 'name',
      render: () => name,
      width: '20%',
    },
    {
      key: 'type',
      render: () => type,
      width: '15%',
    },
    {
      key: 'action',
      render: () => <StyledTodoListItemLink link={link} />,
      component: RightAlignCell,
      width: '10%',
    },
  ];

  if (tabletSize || mobileSize) {
    columns = [
      {
        key: 'title',
        render: () => (
          <>
            <div>
              <MobileHeader>
                <H6 style={{ marginRight: 5, lineHeight: '20px' }}>{type}</H6>
                {showDueDateCondition ? (
                  <DueDateWrapper>
                    <SpriteIcon
                      id="Time"
                      size={12}
                      style={{ marginRight: 4 }}
                    />
                    <H6>{toLocaleDateString(dueAt)}</H6>
                  </DueDateWrapper>
                ) : null}
                {showDateCondition ? (
                  <H6 style={{ height: 16 }}>{toLocaleDateString(date)}</H6>
                ) : null}
              </MobileHeader>

              <H4 style={{ marginBottom: 5, wordBreak: 'break-word' }}>
                {title}
              </H4>
              <Paragraph>{name}</Paragraph>
              <StyledTodoListItemLink link={link} />
            </div>
          </>
        ),
        component: StyledColumn,
        width: '100%',
      },
    ];
  }

  return columns;
};
