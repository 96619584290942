import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { percentage, t, selectors } from '@formue-app/core';
import { accent } from '../../../../constants/colors';
import { H4 } from '../../../texts';
import { StretchedHorizontalBarChart } from '../../../graphs/StretchedHorizontalBarChart';
import { VerticalDivider } from '../../../common/VerticalDivider';

const {
  entities: {
    portfolioLenses: { temperatureRiseDataSelector },
  },
} = selectors;

const Container = styled.div`
  position: relative;
`;

export const ContributionToParisAgreementChart = ({ navigation, ...rest }) => {
  const temperatureRiseData = useSelector(temperatureRiseDataSelector);

  if (!temperatureRiseData) return null;

  const {
    companiesBelow_1_5,
    companies_1_5_to_2,
    companies_2_to_3_2,
    companiesAbove_3_2,
  } = temperatureRiseData;

  const ITEMS = [
    {
      label: t('app:sustainability:accordions:parisAgreement:below1.5'),
      value: companiesBelow_1_5,
      color: accent.forest2,
    },
    {
      label: t('app:sustainability:accordions:parisAgreement:below2'),
      value: companies_1_5_to_2,
      color: accent.forest250,
    },
    {
      label: t('app:sustainability:accordions:parisAgreement:below3.2'),
      value: companies_2_to_3_2,
      color: accent.raspberry330,
    },
    {
      label: t('app:sustainability:accordions:parisAgreement:above3.2'),
      value: companiesAbove_3_2,
      color: '#F692A3',
    },
  ];
  return (
    <Container {...rest}>
      <VerticalDivider
        color={accent.ocean420}
        style={{ position: 'absolute', left: -40, top: 0 }}
      />
      <H4 style={{ paddingBottom: 12 }}>
        {t(
          'app:sustainability:accordions:parisAgreement:shareOfCompaniesInPortfolio'
        )}
      </H4>
      <StretchedHorizontalBarChart
        data={ITEMS}
        barWidth={32}
        formatValue={(value) => percentage(value * 100, 1, false, false)}
      />
    </Container>
  );
};
