import { css } from 'styled-components';
import { accent, backgroundWhite } from './colors';

export const whiteScrollbar = css`
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${backgroundWhite};
  }

  &::-webkit-scrollbar-track:hover {
    background-color: ${accent.sand150};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${accent.sand2};
    background-clip: padding-box;
    border-radius: 50vw;
    border: 3px solid rgba(0, 0, 0, 0);
  }
`;
