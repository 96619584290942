import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import rmMarkdown from 'remove-markdown';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';

import { t, selectors, actions, useResource } from '@formue-app/core';

import { BaseSection } from '../common/BaseSection';
import { AdvisorsProfileImages } from './AdvisorsProfileImages';
import { Modal } from '../common/Modal';

import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  List,
  ListItem,
  Paragraph,
  ParagraphSmall,
} from '../texts';

import { accent, textLink } from '../../constants/colors';
import { mobileCondition, tabletCondition } from '../../constants/media';
import {
  SPACING_32,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../constants/spacing';
import { H5Size, H5LineHeight } from '../../constants/text';

const ContentWrapper = styled.div`
  height: 100%;

  @media ${tabletCondition} {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${SPACING_16};
  }
`;

const StyledAdvisorsProfileImages = styled(AdvisorsProfileImages)`
  margin-bottom: ${SPACING_16};

  @media ${tabletCondition} {
    margin-bottom: 0;
  }
`;

const ReadMore = styled.span`
  font-size: ${H5Size};
  line-height: ${H5LineHeight};
  font-weight: 500;
  color: ${textLink};
  margin-top: auto;
  cursor: pointer;

  @media ${tabletCondition} {
    margin-top: 0;
    width: 20%;
  }

  @media ${mobileCondition} {
    text-align: left;
    margin-top: ${SPACING_8};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledBaseSection = styled(BaseSection)`
  background-color: ${accent.sand3};
`;

const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid #e0d5c6;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${SPACING_24};
  margin-top: 58px;
`;

const StyledParagraph = styled(Paragraph)`
  margin: ${SPACING_16} 0;
`;

const TextWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: ${SPACING_32};
  }
`;

const {
  entities: {
    personalGreeting: { personalGreetingSelector },
  },
} = selectors;

const {
  entities: {
    personalGreeting: { updateRequestBegin },
  },
} = actions;

const PersonalGreetingContent = (props) => {
  const {
    personalGreeting: { body, subject },
  } = props;

  return (
    <>
      <Header>
        <H1>{subject}</H1>
        <StyledAdvisorsProfileImages style={{ marginBottom: 0 }} />
      </Header>
      <TextWrapper>
        <ReactMarkdown
          components={{
            p: StyledParagraph,
            h1: H2,
            h2: H2,
            h3: H3,
            h4: H4,
            h5: H5,
            h6: H6,
            ul: List,
            li: ListItem,
          }}
          remarkPlugins={[breaks]}
        >
          {body}
        </ReactMarkdown>
      </TextWrapper>
    </>
  );
};

export const PersonalGreeting = (props) => {
  const { columns = 2 } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useResource(['PERSONAL_GREETINGS/INDEX']);
  const dispatch = useDispatch();

  const personalGreeting = useSelector(personalGreetingSelector);
  const isImpersonating = useSelector((state) => state.ui.user.isImpersonating);

  useEffect(() => {
    if (personalGreeting && !isImpersonating) {
      if (modalIsOpen && !personalGreeting.read) {
        dispatch(
          updateRequestBegin({
            args: {
              id: personalGreeting.id,
            },
            data: {
              read: true,
            },
          })
        );
      }
    }
  });

  if (!personalGreeting || !personalGreeting.body) {
    return null;
  }

  const { body } = personalGreeting;
  const excerpt = `${body.slice(0, 100).replace(/(\r\n|\n|\r)/gm, ' ')}…`;

  return (
    <StyledBaseSection columns={columns}>
      <ContentWrapper>
        <StyledAdvisorsProfileImages />
        <ParagraphSmall>{rmMarkdown(excerpt)}</ParagraphSmall>

        <ReadMore onClick={() => setModalIsOpen(true)}>
          {t('app:wealthPlan:personalGreeting:readMore')}
        </ReadMore>
      </ContentWrapper>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        backgroundColor={accent.sand3}
      >
        <PersonalGreetingContent personalGreeting={personalGreeting} />
      </Modal>
    </StyledBaseSection>
  );
};
