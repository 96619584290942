import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import camelcase from 'camelcase';

import { selectors, t } from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  ANNUAL_REVIEW_REPORT_CONTENT_SPACING,
  SPACING_64,
} from '../../../constants/spacing';
import { accent, textLightest } from '../../../constants/colors';
import { SectorsBubbleChart } from '../../portfolio/lenses/sectors/SectorsBubbleChart';
import { LensesText } from '../investmentStrategyComponents/LensesText';
import { useEnabledStrategies } from '../slides/useEnabledStrategies';
import { ActivityIndicator } from '../../common/ActivityIndicator';
import { SelectDropdown } from '../../formElements';
import { DotIcon } from '../../common/DotIcon';

const Base = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-top: ${SPACING_64};
  align-items: center;
  position: relative;
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const {
  entities: {
    investmentStrategyPortfolioCharacteristics: {
      investmentStrategyPortfolioCharacteristicsByStrategyIdSelector,
    },
  },
} = selectors;

export const SectorsSlide = (props) => {
  const { isActiveSlide } = props;

  const strategies = useEnabledStrategies();

  const [selectedStrategy, setSelectedStrategy] = useState(strategies[0]);
  const portfolioCharacteristics = useSelector(
    investmentStrategyPortfolioCharacteristicsByStrategyIdSelector(
      selectedStrategy?.id
    )
  );

  const formattedSectors =
    portfolioCharacteristics?.sector?.allocations
      ?.map((item) => ({
        partOfKnownSectorPortfolio: item.allocation,
        sector: camelcase(item.sector),
        securities: [],
      }))
      .sort(
        (a, b) => b.partOfKnownSectorPortfolio - a.partOfKnownSectorPortfolio
      ) || [];

  return (
    <Base>
      <SelectDropdown
        options={strategies.map((strategy) => ({
          ...strategy,
          label: strategy.title,
          value: strategy.id,
        }))}
        icon={(item) => <DotIcon radius={8} color={item?.color} />}
        optionsIcon={(item) => <DotIcon radius={8} color={item?.color} />}
        selectedCallback={(options) => setSelectedStrategy(options[0])}
        subtitle={t('app:portfolio:portfolioStrategy:chooseStrategy')}
        style={{
          position: 'absolute',
          right: ANNUAL_REVIEW_REPORT_CONTENT_SPACING,
          top: -43,
          zIndex: 1,
        }}
      />

      <ContentWrapper style={{ alignItems: 'center', width: '64%' }}>
        {!portfolioCharacteristics ? (
          <ActivityIndicator />
        ) : (
          <SectorsBubbleChart
            scale={1.7}
            animate={isActiveSlide}
            defaultIconColor={null}
            featuredIconColor={accent.raspberry3}
            featuredBackgroundColor={accent.raspberry4}
            featuredTextColor={textLightest}
            borderColor={accent.raspberry250}
            sectors={formattedSectors}
          />
        )}
      </ContentWrapper>
      <ContentWrapper
        style={{ justifyContent: 'center', width: 'auto', flex: 1 }}
      >
        <LensesText
          i18nKey="app:sectors:entryText"
          values={{
            numberOfSectors: formattedSectors?.length,
            topSector: t(`app:sectors:${formattedSectors[0]?.sector}:title`),
          }}
        />
      </ContentWrapper>
    </Base>
  );
};
