import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  t,
  usePortfolioExternalReportingFilter,
  config,
  usePortfolioInvestmentAccountsFilter,
  usePortfolioTaxClassFilter,
} from '@formue-app/core';

import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
  SPACING_24,
  SPACING_4,
  SPACING_8,
} from '../../../constants/spacing';
import {
  accent,
  backgroundWhite,
  hoverBoxShadowLvl1,
  hoverBoxShadowLvl2,
} from '../../../constants/colors';
import { mobileCondition } from '../../../constants/media';

import {
  FilledSelectStyle,
  OutlineSelectStyle,
  StyledInputBase,
} from '../../formElements';
import { Paragraph } from '../../texts';
import { easeInOut, shortAnimationTime } from '../../../constants/animation';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';
import { ToggleFilterGroupDetails, ToggleFilterGroupTitle } from '../filters';
import { FilterSelectionPill } from '../../common/FilterSelectionPill';
import { useClickOutside } from '../../../services/hooks';
import { whiteScrollbar } from '../../../constants/scrollbar';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${SPACING_4};
`;

const Select = styled.div`
  ${StyledInputBase}
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_8};
  cursor: pointer;
  max-width: 416px;

  ${(props) => props.inputstyle === 'filled' && FilledSelectStyle}
  ${(props) => props.inputstyle === 'outline' && OutlineSelectStyle}


   & > svg {
    transition: rotate ${easeInOut} ${shortAnimationTime};
    ${(props) =>
      props.position === 'top'
        ? css`
            rotate: ${(props) => (props.open ? '0deg' : '180deg')};
          `
        : css`
            rotate: ${(props) => (props.open ? '180deg' : '0deg')};
          `}
  }
`;

const StyledSpriteIconFunctional = styled(SpriteIconFunctional)`
  margin-right: 3px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
  background: ${backgroundWhite};
  padding: ${SPACING_24};
  position: absolute;
  width: 416px;
  right: 0;
  box-shadow: ${hoverBoxShadowLvl2};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  z-index: 3;

  max-height: calc(100vh - 460px);
  overflow-y: scroll;

  ${whiteScrollbar}

  ${(props) =>
    props.position === 'bottom'
      ? css`
          translate: 0 100%;
          bottom: -5px;
        `
      : css`
          top: -5px;
          translate: 0 -100%;
        `}

  @media ${mobileCondition} {
    width: 100%;
    box-shadow: ${hoverBoxShadowLvl1};
  }
`;

const StyledFilterGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${accent.sand150};
  padding: ${SPACING_24} ${SPACING_16};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  gap: ${SPACING_24};
`;

const FilterGroupWrapper = (props) => {
  const { options, title } = props;

  if (!options.length) return null;

  return (
    <StyledFilterGroupWrapper>
      <ToggleFilterGroupTitle
        title={title}
        options={options}
        showSelected={false}
      />
      <ToggleFilterGroupDetails title={title} options={options} />
    </StyledFilterGroupWrapper>
  );
};

export const ReportingPortfolioFilter = (props) => {
  const { arrowColor = accent.ocean490, position = 'bottom', ...rest } = props;

  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);

  const externalreportingFilter = usePortfolioExternalReportingFilter();
  const accountsFilter = usePortfolioInvestmentAccountsFilter();
  const taxClassFilter = usePortfolioTaxClassFilter();

  useClickOutside(wrapperRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  return (
    <Wrapper ref={wrapperRef} {...rest}>
      <Select onClick={() => setOpen(!open)} open={open} inputstyle="outline">
        <Paragraph style={{ marginRight: 'auto' }}>
          {t('app:filters:accountTitle')}
        </Paragraph>
        <FilterSelectionPill
          data={[
            externalreportingFilter.filter((item) => item.selected).length,
            taxClassFilter
              ? taxClassFilter.filter((item) => item.selected).length
              : null,
            accountsFilter
              ? accountsFilter.filter((item) => item.selected).length
              : null,
          ].filter(Boolean)}
        />
        <StyledSpriteIconFunctional
          id="Simple arrow"
          direction="Down"
          color={arrowColor}
          size={14}
        />
      </Select>
      {open ? (
        <ContentWrapper position={position}>
          <FilterGroupWrapper
            title={t('app:filters:accountTitle')}
            options={externalreportingFilter.map((item) => ({
              ...item,
              name: t(`app:filters:${item.name}`, {
                companyName: config.title,
              }),
            }))}
          />

          <FilterGroupWrapper
            title={t('app:filters:taxClassTitle')}
            options={taxClassFilter}
          />

          <FilterGroupWrapper
            title={t('app:filters:investmentSavingsAccount')}
            options={accountsFilter}
          />
        </ContentWrapper>
      ) : null}
    </Wrapper>
  );
};
