import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { NotMobileWrapper, MobileWrapper } from '../../constants/media';

import { Button } from '../buttons';

import { actions as navigationActions } from '../../store/ui/navigation';

import { SpriteIcon } from '../common/SpriteIcon';

export const FilterButton = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toggle = () =>
    dispatch(navigationActions.togglePopover('portfolioFilters'));

  return (
    <>
      <Button buttonstyle="tonal" onClick={toggle}>
        <MobileWrapper>
          <SpriteIcon id="Filter" navigational={true} size={14} />
        </MobileWrapper>
        <NotMobileWrapper>
          <SpriteIcon
            id="Filter"
            navigational={true}
            size={14}
            style={{ verticalAlign: 'bottom' }}
          />
          {t('app:portfolio:filter')}
        </NotMobileWrapper>
      </Button>
    </>
  );
};
