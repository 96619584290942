import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors, t, useResource } from '@formue-app/core';
import { BaseSection } from '../../components/common/BaseSection';
import { NoDataMessage } from '../../components/wealthPlan/NoDataMessage';
import { GoalList } from '../../components/wealthPlan/goals/GoalList';
import { HeaderWithBackgroundImage } from '../../components/common/HeaderWithBackgroundImage';
import { textLightest } from '../../constants/colors';
import { SPACING_24, SPACING_88 } from '../../constants/spacing';
import { H2 } from '../../components/texts';
import { GridElement } from '../../components/layout';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';

import backgroundImage from '../../assets/wealth-plan/goals-background.jpg';

const {
  entities: {
    goals: { allGoalsSelector, goalsHasContentSelector },
  },
} = selectors;

const StyledHeading = styled(H2)`
  grid-column: span 12;
  padding-top: ${SPACING_24};
`;

export const GoalsPage = () => {
  const loading = useResource(['GOALS/INDEX']);
  const hasContent = useSelector(goalsHasContentSelector);
  const goals = useSelector(allGoalsSelector);
  const inProgressGoals = goals.filter((item) => item.status !== 'Completed');
  const completedGoals = goals.filter((item) => item.status === 'Completed');

  return (
    <>
      <BaseSection columns={12} style={{ padding: 0 }}>
        <HeaderWithBackgroundImage
          title={t('app:wealthPlan:goals:title')}
          subtitle={t('app:wealthPlan:goals:subtitle')}
          backgroundImage={backgroundImage}
          textColor={textLightest}
          fullSize={true}
        />
      </BaseSection>
      {hasContent ? (
        <>
          <GoalList items={inProgressGoals} />
          <StyledHeading>
            {t('app:wealthPlan:goals:completedListTitle')}
          </StyledHeading>
          <GoalList items={completedGoals} />
        </>
      ) : loading ? (
        <GridElement columns={12}>
          <CenteredActivityIndicator
            size={30}
            style={{ marginTop: SPACING_88 }}
          />
        </GridElement>
      ) : (
        <NoDataMessage columns={12} />
      )}
    </>
  );
};
