import { t } from '@formue-app/core';
import React from 'react';
import styled from 'styled-components';

import { accent } from '../../../constants/colors';
import {
  SPACING_32,
  ANNUAL_REVIEW_CONTENT_SPACING,
} from '../../../constants/spacing';
import { Button } from '../../buttons';
import { H1 } from '../../texts';
import { SidebarImage } from '../SidebarImage';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const StyledButton = styled(Button)`
  z-index: 2;
  margin-top: 72px;
`;

const Title = styled(H1)`
  color: ${(props) => accent[props.titleColor]};
  margin-bottom: ${SPACING_32};
  margin-top: ${ANNUAL_REVIEW_CONTENT_SPACING};
  font-size: 84px;
`;

const TextContainer = styled.div`
  width: 60%;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

export const ThankYouSlide = (props) => {
  const {
    id,
    title,
    titleColor,
    buttoncolor,
    buttoncolorhover,
    buttonTextColor,
    presentationType,
  } = props;

  return (
    <Wrapper>
      <SidebarImage id={id} presentationType={presentationType} />
      <TextContainer>
        <Title titleColor={titleColor}>{t(title)}</Title>
        <StyledButton
          buttoncolor={accent[buttoncolor]}
          buttoncolorhover={accent[buttoncolorhover]}
          textcolor={accent[buttonTextColor]}
          to="/"
        >
          {t('app:presentation:annualReview:thankYou:button')}
        </StyledButton>
      </TextContainer>
    </Wrapper>
  );
};
