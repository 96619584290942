import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors, t } from '@formue-app/core';

import { SPACING_48, SPACING_32 } from '../../../constants/spacing';

import { ReportTitle } from '../reportComponent/ReportTitle';
import { Advisor } from '../slideComponents/Advisor';
import { OfficeInfo } from '../../accountTeam';
import { ContactForm } from '../reportComponent/ContactForm';
import { Paragraph } from '../../texts';

const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  margin: ${SPACING_32} auto;
  gap: ${SPACING_48};
`;

const {
  entities: {
    accountTeamMembers: { mainAdvisorSelector },
    offices: { officesById },
  },
} = selectors;

export const ContactSection = (props) => {
  const { backgroundColor, isVisible, ...rest } = props;

  const [office, setOffice] = useState(null);

  const mainAdvisor = useSelector(mainAdvisorSelector);
  const offices = useSelector(officesById);

  useEffect(() => {
    if (mainAdvisor) {
      setOffice(offices[mainAdvisor.office.id]);
    }
  }, [offices, mainAdvisor]);

  return (
    <>
      <ReportTitle {...rest} />

      <WrapperInner>
        <Paragraph style={{ textAlign: 'center' }}>
          {t('app:report:annualReview:contact:intro')}
        </Paragraph>

        <ContactForm />

        {mainAdvisor ? <Advisor contact={mainAdvisor} /> : null}
        {office ? <OfficeInfo office={office} /> : null}
      </WrapperInner>
    </>
  );
};
