import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { t, actions as coreActions } from '@formue-app/core';

import { SPACING_8, BORDER_RADIUS_LARGE } from '../../constants/spacing';
import {
  textLightest,
  backgroundWhite,
  primary,
  hoverBoxShadowLvl2,
  hoverBoxShadowLvl3,
} from '../../constants/colors';
import { paragraphSmallSize } from '../../constants/text';
import { H4 } from '../texts/H4';
import SelectArrowIconOpen from '../../assets/icons/form-elements/select-arrow-icon-open.svg';
import SelectArrowIconClose from '../../assets/icons/form-elements/select-arrow-icon-close.svg';

import iconNo from '../../assets/images/flags/nb.png';
import iconSv from '../../assets/images/flags/sv.png';
import iconDa from '../../assets/images/flags/da.png';
import iconEn from '../../assets/images/flags/en.png';
import { easeInOut, shortAnimationTime } from '../../constants/animation';

const {
  ui: { user: userActions },
} = coreActions;

const StyledNav = styled.nav`
  z-index: 2;
  border-radius: ${BORDER_RADIUS_LARGE};
  transition: box-shadow ${shortAnimationTime} ${easeInOut};

  &:hover {
    box-shadow: ${hoverBoxShadowLvl2};
  }

  ul {
    visibility: hidden;
    display: none;
    opacity: 0;
    transition: opacity 250ms linear;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ${(props) =>
    props.showName &&
    `
    background-image: url(${SelectArrowIconOpen});
    background-repeat: no-repeat;
    background-position: right 12px top 12px;
    `}

  &.active {
    background-color: ${backgroundWhite} !important;
    box-shadow: ${hoverBoxShadowLvl3};
    border-radius: ${BORDER_RADIUS_LARGE};

    ul {
      visibility: visible;
      display: inherit;
      opacity: 1;
    }

    span {
      color: ${primary};
    }

    ${(props) =>
      props.showName &&
      `
    background-image: url(${SelectArrowIconClose});
    background-repeat: no-repeat;
    background-position: right 12px top 12px;
    `}

  }
}`;

const StyledListItem = styled.li`
  padding: ${(props) => (props.showName ? '14px 10px' : `${SPACING_8}`)};
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
  align-items: center;
  justify-content: ${(props) =>
    props.align && props.align === 'left' ? 'flex-start' : 'flex-end'};
  gap: ${SPACING_8};

  span {
    font-size: ${paragraphSmallSize};
    font-weight: 500;
    color: ${textLightest};
  }

  img {
    margin-left: ${SPACING_8};
  }
`;

const StyledChosenLanguage = styled(StyledListItem)`
  font-weight: 500;
`;

export const LanguageSelectorDropDown = (props) => {
  const [open, setOpen] = useState(false);
  const { className, align, showName, ...rest } = props;
  const { languages, language: chosenLanguage } = useSelector(
    (state) => state.ui.user
  );

  let allLanguageKeys = Object.keys(languages);
  const dispatch = useDispatch();

  const flags = {
    nb: iconNo,
    sv: iconSv,
    da: iconDa,
    en: iconEn,
  };

  return (
    <StyledNav
      className={[className, open ? 'active' : '']}
      showName={showName}
      {...rest}
    >
      <StyledChosenLanguage
        as="p"
        align={align}
        showName={showName}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {!showName && <span>{chosenLanguage.toUpperCase()}</span>}
        <img
          src={flags[chosenLanguage]}
          width={20}
          height={20}
          alt={`${chosenLanguage} flag icon`}
        />
        {showName && t(`app:settings:languageKey:${chosenLanguage}`)}
      </StyledChosenLanguage>

      <ul>
        {allLanguageKeys
          .filter((key) => key !== chosenLanguage)
          .map((key, index) => {
            const value = languages[key];

            return (
              <StyledListItem
                key={`lang-${index}`}
                align={align}
                showName={showName}
                onClick={() => {
                  setOpen(false);
                  dispatch(userActions.setLanguage(key));
                }}
              >
                {!showName && <span>{value.toUpperCase()}</span>}
                <img
                  src={flags[value]}
                  width={20}
                  height={20}
                  alt={`${value} flag icon`}
                />
                {showName && <H4>{t(`app:settings:languageKey:${value}`)}</H4>}
              </StyledListItem>
            );
          })}
      </ul>
    </StyledNav>
  );
};
