import React from 'react';
import styled from 'styled-components';

import { getAssetClassName, sortByAssetGroup, t } from '@formue-app/core';

import { ButtonSmall } from '../buttons';
import { BaseSection } from '../common/BaseSection';
import { H3 } from '../texts';
import { FixedPyramidChart } from '../wealthPlan/InvestmentSimulation/FixedPyramidChart';
import { AssetClassList } from '../advisorsCorner/strategySimulator/AssetClassList';

import { SPACING_48, SPACING_24, SPACING_16 } from '../../constants/spacing';
import { accent } from '../../constants/colors';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: ${SPACING_48};
  padding: ${SPACING_24};
`;

const StyledFixedPyramidChart = styled(FixedPyramidChart)`
  margin-left: auto;
  margin-right: -16%;
  margin-bottom: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
  width: 100%;
`;

const ContentHeader = styled.div`
  display: flex;
  gap: ${SPACING_24};
  align-items: center;
`;

export const StrategyAssetClasses = (props) => {
  const {
    strategyToolBeta,
    editMode,
    setEditMode,
    editableAssetAllocations,
    setEditableAssetAllocations,
    assetGroups,
    assetAllocations,
    showInputs,
    ...rest
  } = props;
  return (
    <StyledBaseSection {...rest}>
      <Content>
        <ContentHeader>
          <H3>{t('app:advisor:strategySimulator:assetAllocation:title')}</H3>
          {strategyToolBeta ? (
            <ButtonSmall
              buttonstyle="outline"
              buttoncolorhover={accent.ocean220}
              onClick={() => {
                setEditMode(!editMode);
                editMode && setEditableAssetAllocations(assetAllocations);
              }}
            >
              {!editMode ? t('app:globals:edit') : t('app:globals:cancel')}
            </ButtonSmall>
          ) : null}
        </ContentHeader>

        <AssetClassList
          editMode={editMode}
          setEditMode={setEditMode}
          editableAssetAllocations={editableAssetAllocations}
          setEditableAssetAllocations={setEditableAssetAllocations}
          showInputs={showInputs}
        />
      </Content>
      <StyledFixedPyramidChart
        height={360}
        data={assetGroups
          .map((item) => ({
            group: item.id,
            label: getAssetClassName(item.id),
            value: item.value,
          }))
          .sort(sortByAssetGroup)
          .reverse()}
        labelPosition="left"
      />
    </StyledBaseSection>
  );
};
