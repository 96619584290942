import React from 'react';
import styled, { css } from 'styled-components';

import { t } from '@formue-app/core';

import { ReactComponent as NavigationArrow } from '../../assets/icons/arrows/arrow-right-list.svg';

import { SPACING_16, SPACING_24, SPACING_8 } from '../../constants/spacing';
import {
  accent,
  backgroundWhite,
  blackTranslucent,
} from '../../constants/colors';
import { easeInOut, shortAnimationTime } from '../../constants/animation';

import { H4 } from '../texts';
import { SpriteIconFunctional } from '../common/SpriteIconFunctional';

const FooterWrapper = styled.footer`
  position: fixed;
  padding: ${SPACING_24};
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.showDetails &&
    css`
      background-color: ${accent.sand3};
      box-shadow: 0px -2px 8px ${blackTranslucent(0.02)};
      padding-top: ${SPACING_8};
      padding-bottom: ${SPACING_8};
    `}

  transition: linear all ${shortAnimationTime};
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: ${backgroundWhite};
  border-radius: 16px;
  cursor: pointer;
  transition: all ${easeInOut} ${shortAnimationTime};
  transform-origin: bottom;

  &:hover {
    scale: 1.8;
  }
`;

const ShowDetailsText = styled(H4)`
  cursor: pointer;
  color: ${(props) =>
    !props.showDetails
      ? accent[props.activeSlide.titleColor]
      : accent.ocean490};
  transition: linear color ${shortAnimationTime};
  display: flex;
  align-items: center;
`;

const StyledArrowDown = styled(SpriteIconFunctional)`
  vertical-align: middle;
  margin-left: ${SPACING_8};
  rotate: ${(props) => (props.showdetails === 0 ? 0 : 180)}deg;
`;

export const PresentationFooter = (props) => {
  const {
    setAutoScroll,
    setActiveIndex,
    activeIndex,
    activeSlide,
    setShowDetails,
    showDetails,
    slidesLength,
  } = props;

  const firstSlide = activeIndex === 0;
  const lastSlide = activeIndex === slidesLength;

  return (
    <FooterWrapper showDetails={showDetails}>
      <span style={{ width: 32 }}>
        {!firstSlide && (
          <Arrow
            onClick={() => {
              setAutoScroll(true);
              setActiveIndex(activeIndex - 1);
              setShowDetails(false);
            }}
          >
            <NavigationArrow style={{ translate: -1, rotate: '180deg' }} />
          </Arrow>
        )}
      </span>

      {!!activeSlide.detailsComponent && (
        <ShowDetailsText
          activeSlide={activeSlide}
          showDetails={showDetails}
          onClick={() => {
            setAutoScroll(true);
            setShowDetails(!showDetails);
          }}
        >
          {showDetails
            ? t('app:presentation:common:back')
            : t('app:presentation:common:seeDetails')}
          <StyledArrowDown
            id="Simple arrow"
            direction="Down"
            size={SPACING_16}
            color={
              !showDetails ? accent[activeSlide.titleColor] : accent.ocean490
            }
            activeslide={activeSlide}
            showdetails={showDetails ? 1 : 0}
          />
        </ShowDetailsText>
      )}

      <span style={{ width: 32 }}>
        {!lastSlide && (
          <Arrow
            onClick={() => {
              setAutoScroll(true);
              setActiveIndex(activeIndex + 1);
              setShowDetails(false);
            }}
          >
            <NavigationArrow style={{ translate: 1 }} />
          </Arrow>
        )}
      </span>
    </FooterWrapper>
  );
};
