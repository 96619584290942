import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithSubRoutes } from '../../components/router';
import { SubPageNavigation } from '../../components/common/SubPageNavigation';
import { t } from '@formue-app/core';

export const LensesPage = (props) => {
  const { routes, location, title } = props;

  // If we are one a "LensesPage" we don't really have anything to show so do an
  // automatic redirect to the first sub route.
  return (
    <>
      {location.pathname === '/portfolio/lenses' && (
        <Redirect to={routes[0].path} />
      )}
      <SubPageNavigation
        title={title}
        subTitle={t('app:portfolio:lensesText')}
        routes={routes}
      />
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </>
  );
};
