import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import {
  accent,
  blackTranslucent,
  textLightest,
} from '../../../constants/colors';
import { SPACING_48, SPACING_32, SPACING_24 } from '../../../constants/spacing';
import { H6, ParagraphLarge, ParagraphSmall } from '../../texts';

import { SpriteIcon } from '../../common/SpriteIcon';

const SubTitle = styled(H6)`
  margin-bottom: ${SPACING_48};
`;

const ErrorWrapper = styled.div`
  background: ${blackTranslucent(0.13)};
  padding: ${SPACING_32} ${SPACING_24};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-width: 400px;
  border-radius: 24px;

  p {
    color: ${textLightest};
    text-align: center;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const ReportErrorMessage = (props) => {
  const { subTitle, subTitleColor } = props;

  return (
    <>
      <SubTitle
        style={{
          color: accent[subTitleColor],
          marginBottom: SPACING_48,
        }}
      >
        {t(subTitle)}
      </SubTitle>
      <ErrorWrapper>
        <SpriteIcon id="Independent" size={32} />
        <TextWrapper>
          <ParagraphLarge>
            {t('app:errorMessages:failedToRetrieveData:title')}
          </ParagraphLarge>
          <ParagraphSmall>
            {t('app:errorMessages:failedToRetrieveData:text')}
          </ParagraphSmall>
        </TextWrapper>
      </ErrorWrapper>
    </>
  );
};
