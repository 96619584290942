import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { BaseSection } from '../common/BaseSection';
import { t } from '@formue-app/core';
import { StyledGridElement } from '../layout';
import { H2 } from '../texts';
import { LanguageSelectorDropDown } from '../common/LanguageSelectorDropDown';
import { mobileCondition } from '../../constants/media';

const StyledBaseSection = styled(BaseSection)`
  padding: 0px;
`;

export const LanguageSelector = (props) => {
  const { columns = 6 } = props;

  const isMobile = useMediaQuery({
    query: mobileCondition,
  });

  return (
    <StyledGridElement columns={columns}>
      {!isMobile && (
        <H2 style={{ marginBottom: 20 }}>{t(`app:settings:language`)}</H2>
      )}
      <StyledBaseSection>
        <LanguageSelectorDropDown align="left" showName />
      </StyledBaseSection>
    </StyledGridElement>
  );
};
