import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';

import { selectors } from '@formue-app/core';

import { desktopCondition, tabletCondition } from '../constants/media';
import { MenuPage } from './base/MenuPage';
import { ExpertInputDetail } from '../components/todo/detailViews';

const { loadingSelector } = selectors.ui.todo;

const gridTemplate = css`
  @media ${desktopCondition} {
    grid-template-areas:
      '. . . . . . . . . . . .'
      '. . . . . . . . . . . .'
      '. . . . . . . . . . . .'
      'Bar Bar Bar Bar Bar Bar Bar Bar Sidebar Sidebar Sidebar Sidebar'
      'Status Status Status Status Status Status Status Status Sidebar Sidebar Sidebar Sidebar'
      'List List List List List List List List Sidebar Sidebar Sidebar Sidebar';
  }

  @media ${tabletCondition} {
    grid-template-areas:
      '. . . . . . . . . . . .'
      '. . . . . . . . . . . .'
      '. . . . . . . . . . . .'
      'Bar Bar Bar Bar Bar Bar Bar Bar Bar Bar Bar Bar'
      'Status Status Status Status Status Status Status Status Status Status Status Status'
      'Sidebar Sidebar Sidebar Sidebar Sidebar Sidebar Sidebar Sidebar Sidebar Sidebar Sidebar Sidebar'
      'List List List List List List List List List List List List';
  }
`;

export const TodoItemPage = (props) => {
  const { t } = useTranslation();
  const loading = useSelector(loadingSelector);
  const { id, type } = useParams();
  let title;

  let detailComponent;
  switch (type) {
    case 'expert-inputs':
      detailComponent = (
        <ExpertInputDetail id={id} type="expert-inputs" loading={loading} />
      );
      title = t('app:todo:screenTitle:expertInputs');
      break;
    default:
      break;
  }

  return (
    <MenuPage
      {...props}
      title={title}
      icon={''}
      gridTemplate={gridTemplate}
      subpage={true}
    >
      {detailComponent}
    </MenuPage>
  );
};
