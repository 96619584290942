import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';

import { formatBigMoney, percentage, selectors, t } from '@formue-app/core';

import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_20,
  SPACING_32,
  SPACING_8,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';

import { BaseSection } from '../../common/BaseSection';
import { StyledCell, StyledRow, TableList } from '../../lists';
import { SpriteIcon } from '../../common/SpriteIcon';
import { Modal } from '../../common/Modal';
import { H2, Paragraph, ParagraphSmall } from '../../texts';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';
import { VisibleInCountry } from '../../common/VisibleInCountry';
import { useSelector } from 'react-redux';

const CostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const CostTableWrapper = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const TableLabelWrapper = styled.div`
  display: flex;
  gap: ${SPACING_8};
`;

const LightStyledCell = styled(StyledCell)`
  span {
    font-weight: 400;
  }
`;

const LightStyledRow = styled(StyledRow)`
  &:nth-child(odd) {
    background: ${accent.sand150};
  }

  &:last-child {
    span {
      font-weight: 500;
    }
  }
`;

const InfoWrapper = styled.span`
  position: relative;
  margin-left: 8px;
  cursor: pointer;
`;

const Footnote = styled.div`
  display: flex;
  gap: ${SPACING_8};
  margin-top: ${SPACING_20};
`;

const SlideNavigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const NavigationButtonStyle = css`
  display: flex;
  border-radius: ${BORDER_RADIUS_LARGE};
  padding: ${SPACING_16};
  justify-content: center;
  align-items: center;
  background: ${accent.ocean230};
  cursor: pointer;
  outline: none;
  border: none;

  &:hover {
    background: ${accent.ocean250};
  }
`;

const StyledButtonBack = styled(ButtonBack)`
  ${NavigationButtonStyle}
`;
const StyledButtonNext = styled(ButtonNext)`
  ${NavigationButtonStyle}
`;

const CostTableInfoCarousel = (props) => {
  const { carouselModal, setCarouselModal, slideFilter } = props;

  const slides = {
    ongoingServiceFee: (
      <>
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t('app:advisor:strategySimulator:cost:modals:infoAnnualFee:title')}
        </H2>
        <Paragraph>
          {t('app:advisor:strategySimulator:cost:modals:infoAnnualFee:content')}
        </Paragraph>
        <VisibleInCountry country="no">
          <Footnote>
            <SpriteIcon id="Create" size={16} />
            <ParagraphSmall>
              {t('app:advisor:strategySimulator:cost:modals:taxDisclamer')}
            </ParagraphSmall>
          </Footnote>
        </VisibleInCountry>
      </>
    ),
    advisoryFee: (
      <>
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t('app:advisor:strategySimulator:cost:modals:infoAdvisorFee:title')}
        </H2>
        <Paragraph>
          {t(
            'app:advisor:strategySimulator:cost:modals:infoAdvisorFee:content'
          )}
        </Paragraph>
        <VisibleInCountry country="no">
          <Footnote>
            <SpriteIcon id="Create" size={16} />
            <ParagraphSmall>
              {t(
                'app:advisor:strategySimulator:cost:modals:partialTaxDisclamer'
              )}
            </ParagraphSmall>
          </Footnote>
        </VisibleInCountry>
      </>
    ),
    structuringFee: (
      <>
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t(
            'app:advisor:strategySimulator:cost:modals:infoStructuringFee:title'
          )}
        </H2>
        <Paragraph>
          {t(
            'app:advisor:strategySimulator:cost:modals:infoStructuringFee:content'
          )}
        </Paragraph>
        <VisibleInCountry country="no">
          <Footnote>
            <SpriteIcon id="Create" size={16} />
            <ParagraphSmall>
              {t(
                'app:advisor:strategySimulator:cost:modals:partialTaxDisclamer'
              )}
            </ParagraphSmall>
          </Footnote>
        </VisibleInCountry>
      </>
    ),
    productCost: (
      <>
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t('app:advisor:strategySimulator:cost:modals:infoProductFee:title')}
        </H2>
        <Paragraph>
          {t(
            'app:advisor:strategySimulator:cost:modals:infoProductFee:content'
          )}
        </Paragraph>
      </>
    ),
    platformCost: (
      <>
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t('app:advisor:strategySimulator:cost:modals:infoPlatformFee:title')}
        </H2>
        <Paragraph>
          {t(
            'app:advisor:strategySimulator:cost:modals:infoPlatformFee:content'
          )}
        </Paragraph>
      </>
    ),
    externalReportingFee: (
      <>
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t('app:advisor:strategySimulator:cost:modals:infoExternalFee:title')}
        </H2>
        <Paragraph>
          {t(
            'app:advisor:strategySimulator:cost:modals:infoExternalFee:content'
          )}
        </Paragraph>
      </>
    ),
  };

  const filteredSlides = Object.keys(slides).filter((key) =>
    slideFilter.find((item) => key === item.costType)
  );

  return (
    <CarouselProvider
      naturalSlideWidth={390}
      naturalSlideHeight={390}
      totalSlides={filteredSlides.length}
      infinite={true}
      currentSlide={filteredSlides.indexOf(carouselModal)}
    >
      <Modal
        isOpen={Boolean(carouselModal)}
        onRequestClose={() => setCarouselModal(null)}
        contentWidth={450}
        padding={SPACING_32}
      >
        <Slider>
          {filteredSlides.map((slide, index) => (
            <Slide key={slide} index={index}>
              {slides[slide]}
            </Slide>
          ))}
        </Slider>
        <SlideNavigation>
          <StyledButtonBack>
            <SpriteIconFunctional id="Pointer arrow" direction="Left" />
          </StyledButtonBack>
          <StyledButtonNext>
            <SpriteIconFunctional id="Pointer arrow" direction="Right" />
          </StyledButtonNext>
        </SlideNavigation>
      </Modal>
    </CarouselProvider>
  );
};

const { operatingCountrySelector } = selectors.auth;

export const CostTable = (props) => {
  const {
    costData,
    dataExtractor = 'percentage',
    advisoryFeeRelevant,
    structuringFeeRelevant,
    managementType,
  } = props;
  const operatingCountry = useSelector(operatingCountrySelector);
  const [carouselModal, setCarouselModal] = useState(null);

  const twoColumnTableCondition = advisoryFeeRelevant || structuringFeeRelevant;

  // We don't want to show fees with no costs (or 0), so we filter out those where
  // there is no costs in any of the years we receive data for
  const filteredCostData = costData.filter((item) => {
    // If the strategy is migrated we need to check the last value (year 4+)
    // Else we check the first value (year 1) even if year 4+ does not have any cost
    if (!twoColumnTableCondition) {
      return Boolean(item.costByYear[3].amount);
    } else {
      return Boolean(item.costByYear[0].amount);
    }
  });

  const serviceCost = filteredCostData.filter(
    (item) => item.group === 'service'
  );
  const productCost = filteredCostData.filter(
    (item) => item.group === 'products'
  );
  const platformCost = filteredCostData.filter(
    (item) => item.group === 'platform'
  );
  const totalCost = filteredCostData.filter((item) => item.group === 'totals');
  const externalReportingCost = filteredCostData.filter(
    (item) => item.group === 'externalReporting'
  );

  const formatValues = (value) => {
    if (!value) return '–';
    if (dataExtractor === 'percentage') return percentage(value * 100, 2);
    if (dataExtractor === 'amount') return formatBigMoney(value, false);
  };

  const getColumn = (props) => {
    const { columnTitle, icon, showLabel = false } = props;

    let columns = [
      {
        key: 'title',
        label: (
          <TableLabelWrapper>
            <SpriteIcon id={icon} size={16} />
            {columnTitle}
          </TableLabelWrapper>
        ),
        width: '70%',
        component: LightStyledCell,
        render: ({ title, infoFunction }) => (
          <>
            {title}
            {infoFunction ? (
              <InfoWrapper onClick={() => infoFunction()}>
                <SpriteIcon id="Info" navigational={true} size={SPACING_16} />
              </InfoWrapper>
            ) : null}
          </>
        ),
      },
      {
        key: 'year1',
        label: showLabel ? t('app:advisor:strategySimulator:cost:year1') : null,
        width: '15%',
        type: 'number',
        render: ({ year1 }) => formatValues(year1),
        component: LightStyledCell,
      },
      {
        key: 'year4plus',
        label: showLabel
          ? t('app:advisor:strategySimulator:cost:year4plus')
          : null,
        width: '15%',
        type: 'number',
        render: ({ year4plus }) => formatValues(year4plus),
        component: LightStyledCell,
      },
    ];

    if (!twoColumnTableCondition) {
      columns = [
        {
          key: 'title',
          label: (
            <TableLabelWrapper>
              <SpriteIcon id={icon} size={16} />
              {columnTitle}
            </TableLabelWrapper>
          ),
          width: '75%',
          component: LightStyledCell,
          render: ({ title, infoFunction }) => (
            <>
              {title}
              {infoFunction ? (
                <InfoWrapper onClick={() => infoFunction()}>
                  <SpriteIcon id="Info" navigational={true} size={SPACING_16} />
                </InfoWrapper>
              ) : null}
            </>
          ),
        },
        {
          key: 'year4plus',
          label: showLabel
            ? t('app:advisor:strategySimulator:cost:costColumn')
            : null,
          width: '25%',
          type: 'number',
          render: ({ year4plus }) => formatValues(year4plus),
          component: LightStyledCell,
        },
      ];
    }

    return columns;
  };

  return (
    <>
      <CostWrapper>
        {serviceCost.length ? (
          <CostTableWrapper>
            <TableList
              sortable={false}
              rowComponent={LightStyledRow}
              columns={getColumn({
                columnTitle:
                  t(
                    'app:advisor:strategySimulator:cost:tables:serviceFee:title'
                  ) + `${operatingCountry === 'se' ? '**' : ''}`,
                icon: 'Advisor',
                showLabel: true,
              })}
              rows={[
                ...serviceCost.map((item, index) => ({
                  title: t(
                    `app:advisor:strategySimulator:cost:tables:serviceFee:${item.costType}`
                  ),
                  year1: item.costByYear[0][dataExtractor],
                  year4plus: item.costByYear[3][dataExtractor],
                  // To open the carousel modal on the right slide, we use index here, it's a bit "arbitrary"
                  // and likely to break if we change the number of rows or slides, but works if you
                  // know the index and the number of slides.
                  infoFunction: () => setCarouselModal(item.costType),
                })),
                {
                  title: t(
                    'app:advisor:strategySimulator:cost:tables:serviceFee:total'
                  ),
                  year1: serviceCost
                    .map((item) => item.costByYear[0][dataExtractor])
                    .reduce((acc, cur) => acc + cur, 0),
                  year4plus: serviceCost
                    .map((item) => item.costByYear[3][dataExtractor])
                    .reduce((acc, cur) => acc + cur, 0),
                },
              ]}
              keyExtractor={(_, index) => `formue-honorar-${index}`}
            />
            {serviceCost.some((item) => item.costType === 'structuringFee') ? (
              <>
                <ParagraphSmall>
                  {t(
                    `app:advisor:strategySimulator:cost:tables:serviceFee:structuringFeeDisclamer`
                  )}
                </ParagraphSmall>
              </>
            ) : null}
            <VisibleInCountry country="se">
              <ParagraphSmall>
                {managementType === 'advisory'
                  ? t(
                      'app:advisor:strategySimulator:cost:tables:serviceFee:curtageAdvisoryDisclamer'
                    )
                  : t(
                      'app:advisor:strategySimulator:cost:tables:serviceFee:curtagePortfolioManagmentDisclamer'
                    )}
              </ParagraphSmall>
            </VisibleInCountry>
          </CostTableWrapper>
        ) : null}
        <CostTableWrapper>
          <TableList
            sortable={false}
            rowComponent={LightStyledRow}
            columns={getColumn({
              columnTitle: t(
                'app:advisor:strategySimulator:cost:tables:productFee:title'
              ),
              icon: 'Holdings',
            })}
            rows={[
              ...productCost.map((item) => ({
                title: t(
                  `app:advisor:strategySimulator:cost:tables:productFee:${item.costType}`
                ),
                year1: item.costByYear[0][dataExtractor],
                year4plus: item.costByYear[3][dataExtractor],
                infoFunction: () => setCarouselModal(item.costType),
              })),
              {
                title: t(
                  'app:advisor:strategySimulator:cost:tables:productFee:total'
                ),
                year1: productCost
                  .map((item) => item.costByYear[0][dataExtractor])
                  .reduce((acc, cur) => acc + cur, 0),
                year4plus: productCost
                  .map((item) => item.costByYear[3][dataExtractor])
                  .reduce((acc, cur) => acc + cur, 0),
              },
            ]}
            keyExtractor={(_, index) => `product-cost-${index}`}
          />
        </CostTableWrapper>
        {platformCost.length ? (
          <CostTableWrapper>
            <TableList
              sortable={false}
              rowComponent={LightStyledRow}
              columns={getColumn({
                columnTitle: t(
                  'app:advisor:strategySimulator:cost:tables:platformFee:title'
                ),
                icon: 'Holdings',
              })}
              rows={[
                ...platformCost.map((item) => ({
                  title: t(
                    `app:advisor:strategySimulator:cost:tables:platformFee:${item.costType}`
                  ),
                  year1: item.costByYear[0][dataExtractor],
                  year4plus: item.costByYear[3][dataExtractor],
                  infoFunction: () => setCarouselModal(item.costType),
                })),
                {
                  title: t(
                    'app:advisor:strategySimulator:cost:tables:platformFee:total'
                  ),
                  year1: platformCost
                    .map((item) => item.costByYear[0][dataExtractor])
                    .reduce((acc, cur) => acc + cur, 0),
                  year4plus: platformCost
                    .map((item) => item.costByYear[3][dataExtractor])
                    .reduce((acc, cur) => acc + cur, 0),
                },
              ]}
              keyExtractor={(_, index) => `platform-cost-${index}`}
            />
          </CostTableWrapper>
        ) : null}
        <CostTableWrapper>
          <TableList
            sortable={false}
            rowComponent={LightStyledRow}
            columns={getColumn({
              columnTitle: t(
                'app:advisor:strategySimulator:cost:tables:totalCost:title'
              ),
              icon: 'Asset Management',
            })}
            rows={[
              ...totalCost.map((item) => ({
                title: t(
                  `app:advisor:strategySimulator:cost:tables:totalCost:${item.costType}`
                ),
                year1: item.costByYear[0][dataExtractor],
                year4plus: item.costByYear[3][dataExtractor],
              })),
              {
                title: t(
                  'app:advisor:strategySimulator:cost:tables:totalCost:total'
                ),
                year1: totalCost
                  .map((item) => item.costByYear[0][dataExtractor])
                  .reduce((acc, cur) => acc + cur, 0),
                year4plus: totalCost
                  .map((item) => item.costByYear[3][dataExtractor])
                  .reduce((acc, cur) => acc + cur, 0),
              },
            ]}
            keyExtractor={(_, index) => `total-cost-${index}`}
          />
        </CostTableWrapper>
        {externalReportingCost.length ? (
          <CostTableWrapper>
            <TableList
              sortable={false}
              rowComponent={LightStyledRow}
              columns={getColumn({
                columnTitle: t(
                  'app:advisor:strategySimulator:cost:tables:externalFee:title'
                ),
                icon: 'Shares',
              })}
              rows={[
                ...externalReportingCost.map((item) => ({
                  title: t(
                    `app:advisor:strategySimulator:cost:tables:externalFee:${item.costType}`
                  ),
                  year1: item.costByYear[0][dataExtractor],
                  year4plus: item.costByYear[3][dataExtractor],
                  infoFunction: () => setCarouselModal(item.costType),
                })),
                {
                  title: t(
                    'app:advisor:strategySimulator:cost:tables:externalFee:total'
                  ),
                  year1: externalReportingCost
                    .map((item) => item.costByYear[0][dataExtractor])
                    .reduce((acc, cur) => acc + cur, 0),
                  year4plus: externalReportingCost
                    .map((item) => item.costByYear[3][dataExtractor])
                    .reduce((acc, cur) => acc + cur, 0),
                },
              ]}
              keyExtractor={(_, index) => `external-cost-${index}`}
            />
          </CostTableWrapper>
        ) : null}
      </CostWrapper>
      <CostTableInfoCarousel
        setCarouselModal={setCarouselModal}
        carouselModal={carouselModal}
        slideFilter={filteredCostData}
      />
    </>
  );
};
