import React from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { t } from '@formue-app/core';

import { SPACING_32, SPACING_16 } from '../../../constants/spacing';
import { primary, accent, backgroundWhite } from '../../../constants/colors';
import { NotMobileWrapper, mobileCondition } from '../../../constants/media';
import { PaddedBaseSection } from '../../common/BaseSection';

import { H6 } from '../../texts';

import { SpriteIcon } from '../../common/SpriteIcon';
import { SpriteIconFunctional } from '../../common/SpriteIconFunctional';

const Bar = styled.ol`
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0;
  margin: 0;

  &:after {
    content: ' ';
    display: block;
    background: ${accent.sand3};
    height: 2px;
    width: 100%;
    position: absolute;
    top: ${SPACING_16};
    opacity: 0.6;

    @media ${mobileCondition} {
      top: 12px;
    }
  }
`;

const Item = styled.li`
  padding: 0 ${SPACING_16};
  padding-bottom: ${SPACING_32};
  background-color: ${backgroundWhite};
  z-index: 1;

  &:first-child {
    padding-left: 40px;
  }

  &:last-child {
    padding-right: 40px;
  }

  @media ${mobileCondition} {
    padding: 0 10px;
    padding-bottom: 0;

    &:first-child {
      padding-left: 0px;
    }

    &:last-child {
      padding-right: 0px;
    }
  }
`;

const ItemInner = styled.div`
  background: ${(props) =>
    props.completed
      ? backgroundWhite
      : props.currentItem
      ? backgroundWhite
      : accent.ocean1};
  border: 2px solid ${accent.sand3};
  border-color: ${(props) =>
    props.completed ? accent.sand3 : backgroundWhite};
  border-radius: 50%;
  height: 36px;
  width: 36px;
  align-items: center;
  display: flex;
  justify-content: center;

  @media ${mobileCondition} {
    height: 26px;
    width: 26px;

    .check-icon {
      width: 10px;
    }
  }

  h4 {
    font-size: 15px;
    font-weight: 500;
    margin-top: -2px;
  }

  span {
    color: ${(props) => (props.completed ? primary : 'white')};
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  width: 120px;
  translate: calc(-50% + 18px);
  margin-top: 10px;
  text-align: center;
`;

export const ExpertInputProgressBar = (props) => {
  const { item } = props;
  let itemProgressReached = false;

  const { statuses } = item;
  let { status: currentStatus } = item;
  if (currentStatus) {
    currentStatus = camelcase(currentStatus);
  }

  return (
    <PaddedBaseSection>
      <Bar>
        {statuses.map((status, index) => {
          let currentItem = false;

          if (
            camelcase(status) === currentStatus &&
            statuses.length - 1 !== index
          ) {
            currentItem = true;
          }

          const item = (
            <Item key={`status-${index}`}>
              <ItemInner
                currentItem={currentItem}
                completed={itemProgressReached}
              >
                {currentItem ? (
                  <SpriteIcon id="In Progress" size={32} />
                ) : itemProgressReached ? (
                  <h4>{index + 1}</h4>
                ) : (
                  <SpriteIconFunctional
                    id="Check"
                    color={accent.ocean2}
                    size={SPACING_16}
                    className="check-icon"
                  />
                )}
              </ItemInner>
              <NotMobileWrapper>
                <TextWrapper>
                  <H6>{t(`app:todo:statuses:${camelcase(status)}`)}</H6>
                </TextWrapper>
              </NotMobileWrapper>
            </Item>
          );

          if (camelcase(status) === currentStatus) {
            itemProgressReached = true;
          }

          return item;
        })}
      </Bar>
    </PaddedBaseSection>
  );
};
