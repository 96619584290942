import React from 'react';

import { t } from '@formue-app/core';
import { BaseSection } from '../common/BaseSection';
import { H2, Paragraph } from '../texts';
import { SPACING_16 } from '../../constants/spacing';

export const NoDataMessage = (props) => {
  const { title, text, style, ...rest } = props;

  return (
    <BaseSection
      style={{
        flex: 0,
        paddingVertical: SPACING_16,
        marginTop: SPACING_16,
        ...style,
      }}
      {...rest}
    >
      <H2>{title ? title : t('app:errorMessages:noDataWealthplan:title')}</H2>
      <Paragraph style={{ marginTop: SPACING_16 }}>
        {text ? text : t('app:errorMessages:noDataWealthplan:text')}
      </Paragraph>
    </BaseSection>
  );
};
