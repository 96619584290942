import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  accent,
  backgroundWhite,
  textLightest,
} from '../../../constants/colors';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_12,
  SPACING_16,
  SPACING_24,
  SPACING_8,
} from '../../../constants/spacing';
import { SummaryBoxClickable } from '../../common/SummaryBox';
import { SectorsText } from '../lenses/sectors/SectorsText';

import backgroundImage from '../../../assets/portfolio/sectors-summary-background.png';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { useResource } from '@formue-app/core';
import { SpriteIcon } from '../../common/SpriteIcon';

const StyledSummaryBox = styled(SummaryBoxClickable)`
  margin-top: ${SPACING_8};
  margin-bottom: -48px;

  .content-wrapper {
    background: ${accent.raspberry4} url(${backgroundImage}) no-repeat center
      center;
    background-size: cover;
    height: 148px !important;
    padding-top: 20px;
    border-bottom-left-radius: ${BORDER_RADIUS_EXTRA_LARGE};
    border-bottom-right-radius: ${BORDER_RADIUS_EXTRA_LARGE};
    padding: ${SPACING_16};
  }
  footer {
    background: transparent;
    position: relative;
    top: -48px;
    border-top: 1px solid ${accent.raspberry1};

    a {
      color: ${textLightest};
    }
  }
`;

export const CompaniesSummary = (props) => {
  const { t } = useTranslation();
  const { columns } = props;
  const loading = useResource(['SECURITIES/INDEX']);

  return (
    <StyledSummaryBox
      icon={
        <SpriteIcon
          id="Company"
          color={accent.raspberry3}
          accentColor={accent.raspberry190}
          tertiaryColor={accent.raspberry480}
          size={24}
        />
      }
      iconSpacing={12}
      titleFontSize={15}
      title={t('app:portfolio:companiesAndSectors')}
      url="/portfolio/lenses/sectors"
      linkColor={textLightest}
      columns={columns}
    >
      {loading ? (
        <CenteredActivityIndicator loadingColor={backgroundWhite} />
      ) : (
        <SectorsText fontSize={18} lineHeight={21} />
      )}
    </StyledSummaryBox>
  );
};
