import React from 'react';
import styled from 'styled-components';
import moment from 'moment/min/moment-with-locales';
import { useSelector } from 'react-redux';

import { selectors } from '@formue-app/core';

import { t } from '@formue-app/core';
import { H5 } from '../../texts';
import { GridElement } from '../../layout';
import { mobileCondition } from '../../../constants/media';

import { SpriteIcon } from '../../common/SpriteIcon';
import { SPACING_16 } from '../../../constants/spacing';

const UpdatedInfowWrapper = styled(GridElement)`
  margin-top: 110px;

  grid-column: 5 / span 4;

  @media ${mobileCondition} {
    grid-column: 2 / span 10;
  }
`;

const UpdatedInfowInner = styled.div`
  text-align: center;
`;

const { portfolioUpdatedSelector } = selectors.ui.portfolio;

export const PortfolioUpdatedInfo = () => {
  let { updatedAt, calculatedAt } = useSelector(portfolioUpdatedSelector);

  if (!updatedAt || !calculatedAt) {
    return null;
  }

  if (updatedAt) {
    updatedAt = `${moment(updatedAt).format('L')} ${moment(updatedAt).format(
      'LT'
    )}`;
  }

  if (calculatedAt) {
    calculatedAt = moment(calculatedAt).format('L');
  }

  return (
    <UpdatedInfowWrapper columns={4}>
      <UpdatedInfowInner>
        <SpriteIcon id="Info" size={SPACING_16} navigational={true} />
        <H5 style={{ marginTop: 5 }}>
          {`${t('app:portfolio:updatedInfo1', { updatedAt })}
          ${t('app:portfolio:updatedInfo2', { calculatedAt })}`}
        </H5>
      </UpdatedInfowInner>
    </UpdatedInfowWrapper>
  );
};
