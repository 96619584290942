import React from 'react';
import styled from 'styled-components';

import { getFlagsSelector, t } from '@formue-app/core';

import { operatingCountrySelector } from '../../../store/auth/selectors';

import { SpriteIcon } from '../../common/SpriteIcon';
import { BaseSection } from '../../common/BaseSection';
import { ButtonSmall } from '../../buttons';
import { H2, Paragraph } from '../../texts';
import { SPACING_12, SPACING_48, SPACING_8 } from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import {
  mobileCondition,
  MobileWrapper,
  tabletCondition,
} from '../../../constants/media';

import reportingPromoIcon from '../../../assets/portfolio/reporting-promo-icon.svg';
import reportingPromoPerson from '../../../assets/portfolio/reporting-promo-person.svg';
import { useSelector } from 'react-redux';

const StyledBaseSection = styled(BaseSection)`
  background-color: ${accent.ocean230};
  padding: ${SPACING_48};
  background-image: url(${reportingPromoIcon}), url(${reportingPromoPerson});
  background-size: 180px, 220px;
  background-position: 20px 40px, 450px 140px;
  background-repeat: no-repeat;

  @media ${tabletCondition} {
    background-size: 180px, 0px;
  }

  @media ${mobileCondition} {
    background-size: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_12};
  max-width: 470px;
  margin-left: 180px;

  @media ${mobileCondition} {
    margin-left: 0px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_8};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${SPACING_8};
`;

export const ReportingPromotionCard = ({ columns = 12 }) => {
  const { isLead } = useSelector(getFlagsSelector);
  const operatingCountry = useSelector(operatingCountrySelector);

  if (isLead) return null;

  const pdfLink = {
    no: 'https://formue.my.salesforce.com/sfc/p/0Y000000pG6P/a/TY000007WAjO/8u5SNYy2w4aShfiEfscSzH92xuze8cKAwGbaYpeLVVU',
    se: 'https://formue.my.salesforce.com/sfc/p/0Y000000pG6P/a/TY000007WBlt/Pxh2zTArXdFV6PnYZCDhQDRb_M78dzk9NeV1BsVTIoo',
    dk: 'https://formue.my.salesforce.com/sfc/p/0Y000000pG6P/a/TY000007WBvZ/ux1BfIF9L.hUuZ7yGuEUgDshaRuKeyS674ggd7PGrFA',
  };

  return (
    <StyledBaseSection columns={columns}>
      <Content>
        <TitleWrapper>
          <MobileWrapper>
            <SpriteIcon id="Portfolio Report" size={30} />
          </MobileWrapper>
          <H2>{t('app:home:reportingPromo:title')}</H2>
        </TitleWrapper>
        <Paragraph>{t('app:home:reportingPromo:body')}</Paragraph>
        <ButtonWrapper>
          <ButtonSmall
            external={true}
            to={pdfLink[operatingCountry]}
            style={{ marginTop: SPACING_8 }}
          >
            {t('app:home:reportingPromo:pdfLink')}
          </ButtonSmall>
          <ButtonSmall
            buttonstyle="outline"
            to="/portfolio/periodic-reporting"
            style={{ marginTop: SPACING_8 }}
          >
            {t('app:home:reportingPromo:reportingLink')}
          </ButtonSmall>
        </ButtonWrapper>
      </Content>
    </StyledBaseSection>
  );
};
