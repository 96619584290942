import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { config } from '@formue-app/core';

import { ContentWrapper } from '../components/layout';
import { backgroundMain, primary } from '../constants/colors';

import { FullscreenPage } from './base/FullscreenPage';
import { Logo } from '../components/common/Logo';
import { SPACING_24 } from '../constants/spacing';
import { Button } from '../components/buttons';

const StyledContentWrapper = styled(ContentWrapper)`
  background: ${backgroundMain};
`;

const ContentBox = styled.section`
  margin: 0 auto;
`;

const StyledHeader = styled.header`
  margin-bottom: 20px;
  text-align: center;

  h1 {
    margin-bottom: 20px;
    font-size: 150px;
    line-height: 60px;
  }
`;

const SubtileSizes = {
  en: '60px',
  nb: '51px',
  sv: '46px',
  // TODO:dk - Verify that this size works
  da: '51px',
};
const Subtitle = styled.span`
  font-size: ${({ language }) => SubtileSizes[language]};
  line-height: 1em;
`;

const StyledLogo = styled(Logo)`
  position: absolute;
  top: 34px;
  left: 40px;
`;

const StyledHr = styled.hr`
  border: none;
  border-bottom: 1px solid ${primary};
  margin: 30px 0;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: ${SPACING_24};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export const NotFoundPage = (props) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { t, i18n } = useTranslation();

  const language = i18n.language;

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  const supportMail = t('app:login:supportMail', {
    // TODO:country - Use international support mail
    context: config.countryCode,
  });

  const supportClickHandler = () => {
    window.open(`mailto:${supportMail}`, '_self');
  };

  return (
    <FullscreenPage>
      <StyledLogo height="26" />

      <StyledContentWrapper position="center">
        <ContentBox>
          <StyledHeader>
            <h1>
              404
              <br />
              <Subtitle language={language}>{t('app:404:title')}</Subtitle>
            </h1>
          </StyledHeader>
          <StyledHr />
          <StyledButtonContainer>
            <StyledButton label={t('app:home:title')} to={'/'} />
            <StyledButton
              label={t('app:globals:support')}
              onClick={supportClickHandler}
            />
          </StyledButtonContainer>
        </ContentBox>
      </StyledContentWrapper>
    </FullscreenPage>
  );
};
