import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectors, t, useResource } from '@formue-app/core';

import { accent } from '../../constants/colors';
import { SPACING_24, SPACING_88 } from '../../constants/spacing';
import { contentRowGap } from '../../constants/grid';

import { MainGrid } from '../../components/layout';
import { GridElement } from '../../components/layout/GridElement';
import { BaseSection } from '../../components/common/BaseSection';
import { SectionWithTitle } from '../../components/common/SectionWithTitle';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { HeaderWithBackgroundImage } from '../../components/common/HeaderWithBackgroundImage';
import { UnwantedRisk } from '../../components/wealthPlan/investmentProfile/UnwantedRisk';
import { InvestmentPreferencesChoices } from '../../components/wealthPlan/investmentProfile/InvestmentPreferencesChoices';
import { InvestmentPreferences } from '../../components/wealthPlan/investmentProfile/InvestmentPreferences';
import { RiskCapacity } from '../../components/wealthPlan/investmentProfile/RiskCapacity';
import { AdvisorsComment } from '../../components/wealthPlan/investmentProfile/AdvisorsComment';
import { NoDataMessage } from '../../components/wealthPlan/NoDataMessage';
import { RiskAndLockIn } from '../../components/wealthPlan/investmentProfile/RiskAndLockIn';
import { RiskToleranceV1 } from '../../components/wealthPlan/investmentProfile/RiskToleranceV1';
import { KnowledgeAndExperience } from '../../components/wealthPlan/investmentProfile/KnowledgeAndExperience';

import backgroundImage from '../../assets/wealth-plan/investment-profile-background.jpg';

const {
  entities: {
    investmentProfile: {
      investmentProfileHasContent,
      investmentProfileSelector,
      sustainabilityPreferencesSelector,
      investmentStrategyDetailsSelector,
      knowledgeAndTrainingCommentSelector,
    },
    cashFlowEvents: { formattedCashFlowEvents },
  },
} = selectors;

const StyledContainer = styled(BaseSection)`
  background-color: transparent;
  padding: 0;
`;

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
  margin-top: ${contentRowGap};
`;

export const InvestmentProfilePage = () => {
  const loading = useResource([
    'INVESTMENT_PROFILES/INDEX',
    'CASH_FLOW_EVENTS/INDEX',
  ]);

  const hasContent = useSelector(investmentProfileHasContent);
  const investmentProfile = useSelector(investmentProfileSelector);
  const sustainabilityPreferences = useSelector(
    sustainabilityPreferencesSelector
  );
  const investmentStrategyDetails = useSelector(
    investmentStrategyDetailsSelector
  );
  const knowledgeAndTrainingComment = useSelector(
    knowledgeAndTrainingCommentSelector
  );
  const formattedCashFlow = useSelector(formattedCashFlowEvents);

  // Define some "rules" that can be used to determine what content we want to show
  // to whom. It depends on if they have done neuroprofiler survey or not, if they
  // have done capital needs/cashflow mapping in SF etc.
  const hasCapitalNeeds = formattedCashFlow.length;
  const hasNeuroprofiler = investmentProfile?.riskTolerance?.lossAversion;
  // We have risk and lockIn if we have both risk and lockIn (it's numerical values, so we need to allow for 0 and still be true)
  const hasRiskAndLockInData =
    investmentProfile?.riskAndLockIn?.lockIn !== undefined &&
    investmentProfile?.riskAndLockIn?.risk !== undefined;

  // Only show unwanted risk component for users with any investment profile
  // data, but hide it if they have both capital needs and neuroprofiler
  const showUnwantedRisk = hasContent && !(hasCapitalNeeds && hasNeuroprofiler);

  if (loading) {
    return (
      <GridElement columns={12}>
        <CenteredActivityIndicator
          size={30}
          style={{ marginTop: SPACING_88 }}
        />
      </GridElement>
    );
  }

  return (
    <>
      <StyledContainer columns={12}>
        <HeaderWithBackgroundImage
          title={t('app:wealthPlan:investmentProfile:title')}
          subtitle={t('app:wealthPlan:investmentProfile:subtitle')}
          backgroundImage={backgroundImage}
          textColor={accent.ocean250}
          fullSize={true}
        >
          {showUnwantedRisk ? <UnwantedRisk /> : null}
        </HeaderWithBackgroundImage>
        <StyledSubGrid>
          {hasContent ? (
            <>
              {hasRiskAndLockInData ? <RiskAndLockIn /> : null}

              {sustainabilityPreferences ? (
                <InvestmentPreferences />
              ) : (
                <InvestmentPreferencesChoices />
              )}

              {/*
                For users without new strategy setup, this is the old layout that should be
                removed when all users are over to new strategy
              */}
              {!hasRiskAndLockInData ? (
                <>
                  <RiskCapacity />
                  <AdvisorsComment
                    title={t(
                      'app:wealthPlan:investmentProfile:knowledgeAndTrainingComment'
                    )}
                    subTitle={''}
                    body={knowledgeAndTrainingComment}
                    columns={12}
                  />
                  <RiskToleranceV1
                    style={{ marginBottom: SPACING_24, marginTop: SPACING_24 }}
                  />
                  <SectionWithTitle
                    title={t(
                      'app:wealthPlan:investmentProfile:advisorsComment'
                    )}
                    style={{ marginBottom: SPACING_24 }}
                  >
                    <AdvisorsComment
                      title={t(
                        'app:wealthPlan:investmentProfile:advisorsComment'
                      )}
                      subTitle={t(
                        'app:wealthPlan:investmentProfile:generalCommentSubTitle'
                      )}
                      body={investmentStrategyDetails}
                      columns={12}
                    />
                  </SectionWithTitle>
                </>
              ) : null}

              {/*
                For users with new strategy setup and should be the default one when all users go
                over to new strategy
              */}
              {hasRiskAndLockInData ? (
                <SectionWithTitle
                  title={t(
                    'app:wealthPlan:investmentProfile:knowledgeAndExperienceTitle'
                  )}
                  style={{ marginBottom: SPACING_24 }}
                >
                  <KnowledgeAndExperience
                    columns={12}
                    style={{ marginBottom: SPACING_24 }}
                  />
                  <AdvisorsComment
                    title={t(
                      'app:wealthPlan:investmentProfile:knowledgeAndTrainingComment'
                    )}
                    subTitle={''}
                    body={knowledgeAndTrainingComment}
                    columns={12}
                  />
                </SectionWithTitle>
              ) : null}
            </>
          ) : (
            <NoDataMessage columns={12} />
          )}
        </StyledSubGrid>
      </StyledContainer>
    </>
  );
};
