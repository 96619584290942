import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import {
  useErrorState,
  useResource,
  usePrevious,
  t,
  actions,
} from '@formue-app/core';

import { shortAnimationTime } from '../../../constants/animation';
import { PresentationErrorMessage } from './PresentationErrorMessage';
import { PresentationConfirmationMessage } from './PresentationConfirmationMessage';
import { useQueryState } from 'react-router-use-location-state';
import { useDispatch } from 'react-redux';

const MessagesWrapper = styled.div`
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 100;
  opacity: 0;
  z-index: 0;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      z-index: 10;
    `}

  transition: linear all ${shortAnimationTime};
`;

const {
  ui: { portfolio: uiPortfolioActions },
} = actions;

export const AnnualReviewPresentationMessages = (props) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const [exportMode] = useQueryState('exportMode', false);
  useEffect(() => {
    const queryParams = new URLSearchParams(
      document.location.search.substring(1)
    );

    const period = queryParams.get('period');
    const startDate = queryParams.get('startDate');
    const endDate = queryParams.get('endDate');
    const externalReporting = queryParams.getAll('externalReporting');
    const excludedInvestmentAccounts = queryParams.getAll(
      'excludedInvestmentAccounts'
    );
    const excludedSsids = queryParams.getAll('excludedSsids');
    const excludedTaxClasses = queryParams.getAll('excludedTaxClasses');

    if (period || (startDate && endDate))
      dispatch(
        uiPortfolioActions.filterPeriod({
          period,
          startDate,
          endDate,
        })
      );
    if (externalReporting.length)
      dispatch(uiPortfolioActions.filterExternalReporting(externalReporting));
    if (excludedInvestmentAccounts.length)
      dispatch(
        uiPortfolioActions.filterInvestmentAccount(excludedInvestmentAccounts)
      );
    if (excludedSsids.length)
      dispatch(uiPortfolioActions.filterSsids(excludedSsids));
    if (excludedTaxClasses.length)
      dispatch(uiPortfolioActions.filterTaxClass(excludedTaxClasses));
  }, [dispatch]);

  const wealthPlanError = useErrorState([
    'FINANCIALVALUES/INDEX',
    'INVESTMENT_PROFILES/INDEX',
    'BALANCE_ENTRIES/INDEX',
    'FAMILY_MEMBERS/INDEX',
    'MILESTONES/INDEX',
    'ORGANIZATIONS/INDEX',
    'GOALS/INDEX',
    'TASKS/INDEX',
    'ACCOUNT_TEAM_MEMBERS/INDEX',
  ]);
  const strategyError = useErrorState([
    'STRATEGIES/INDEX',
    'ILLIQUID_CASHFLOWS/INDEX',
    'ILLIQUID_SECURITIES/INDEX',
  ]);
  const portfolioError = useErrorState([
    'MARKETVALUES/INDEX',
    'STRATEGIES/INDEX',
    'PORTFOLIOS/INDEX',
  ]);

  const sustainabilityError = useErrorState([
    'SECURITIES/INDEX',
    'PORTFOLIO_LENSES/INDEX',
  ]);

  const loading = useResource([
    'FINANCIALVALUES/INDEX',
    'INVESTMENT_PROFILES/INDEX',
    'BALANCE_ENTRIES/INDEX',
    'FAMILY_MEMBERS/INDEX',
    'MILESTONES/INDEX',
    'ORGANIZATIONS/INDEX',
    'GOALS/INDEX',
    'MARKETVALUES/INDEX',
    'TASKS/INDEX',
    'SECURITIES/INDEX',
    'STRATEGIES/INDEX',
    'PORTFOLIOS/INDEX',
    'ILLIQUID_CASHFLOWS/INDEX',
    'ILLIQUID_SECURITIES/INDEX',
    'ACCOUNT_TEAM_MEMBERS/INDEX',
    'PORTFOLIO_LENSES/INDEX',
  ]);

  const isErrorMessage =
    wealthPlanError || strategyError || portfolioError || sustainabilityError;
  const previousIsErrorMessage = usePrevious(isErrorMessage);

  // We need to decide if we want to show the message
  // Basic rules would be:
  // - Always show message if the previous was "error message"
  // - Always show "error messages"

  useEffect(() => {
    if (!loading) {
      if (previousIsErrorMessage) {
        setVisible(true);
      } else {
        if (isErrorMessage) {
          setVisible(true);
        }
        if (!isClosed) {
          setVisible(true);
        }
      }
    }
  }, [loading, previousIsErrorMessage, isErrorMessage, isClosed]);

  if (loading || exportMode) return null;

  return (
    <MessagesWrapper visible={visible}>
      {isErrorMessage ? (
        <PresentationErrorMessage
          resources={[
            { title: t('app:menu:wealthPlanTitle'), isError: wealthPlanError },
            { title: t('app:portfolio:strategy'), isError: strategyError },
            { title: t('app:portfolio:title'), isError: portfolioError },
            { title: t('app:portfolio:lenses'), isError: sustainabilityError },
          ]}
          closeCallback={() => {
            setVisible(false);
          }}
        />
      ) : (
        <PresentationConfirmationMessage
          closeCallback={() => {
            setVisible(false);
            setIsClosed(true);
          }}
        />
      )}
    </MessagesWrapper>
  );
};
