import React, { useEffect, useState } from 'react';

import {
  t,
  truncateString,
  selectors,
  usePortfolioRelationshipFilter,
} from '@formue-app/core';

import { ReportTitle } from '../reportComponent/ReportTitle';
import { FiltersSectionContent } from '../reportComponent/FiltersSectionContent';
import { H3, H5, Paragraph } from '../../texts';
import {
  SPACING_16,
  SPACING_24,
  SPACING_32,
  SPACING_64,
  SPACING_8,
} from '../../../constants/spacing';
import {
  DesktopWrapper,
  MobileWrapper,
  desktopCondition,
} from '../../../constants/media';
import { BaseSection } from '../../common/BaseSection';
import styled from 'styled-components';
import { accent, backgroundWhite } from '../../../constants/colors';
import { useSelector } from 'react-redux';
import { SpriteIcon } from '../../common/SpriteIcon';
import { ButtonSmall } from '../../buttons';

const Filter = styled(BaseSection)`
  display: flex;
  background: ${accent.sand150};
  cursor: pointer;
  padding-inline: ${SPACING_16};
  border-radius: ${SPACING_16};

  @media ${desktopCondition} {
    &:hover {
      background: ${backgroundWhite};
    }
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SPACING_16};
`;

const {
  entities: {
    strategy: { allStrategiesSelector },
  },
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

export const FiltersSection = (props) => {
  const {
    backgroundColor,
    title,
    subTitle,
    isVisible,
    setFilterModalOpen,
    ...rest
  } = props;

  const [activeStrategy, setActiveStrategy] = useState(null);

  let activeStrategyId = useSelector(activeStrategySelector);
  const allStrategies = useSelector(allStrategiesSelector);

  useEffect(() => {
    setActiveStrategy(
      allStrategies.find((strategy) => strategy.id === activeStrategyId)
    );
  }, [allStrategies, activeStrategyId]);

  return (
    <>
      <ReportTitle
        {...rest}
        title={title}
        children={
          <SpriteIcon
            id="Goal"
            size="64"
            style={{ marginBottom: SPACING_32 }}
          />
        }
      />

      <DesktopWrapper>
        <H3 style={{ marginTop: SPACING_24, marginBottom: SPACING_64 }}>
          {t(subTitle)}
        </H3>
        <FiltersSectionContent />
      </DesktopWrapper>

      <MobileWrapper>
        <Paragraph
          onClick={() => setFilterModalOpen(true)}
          style={{
            marginTop: SPACING_24,
            marginBottom: SPACING_64,
            textAlign: 'center',
          }}
        >
          {t(subTitle)}
        </Paragraph>
        <BaseSection style={{ width: '100%' }}>
          <Header>
            <H5>{t('app:portfolio:filter')}</H5>
            <ButtonSmall
              buttonstyle="tonal"
              onClick={() => setFilterModalOpen(true)}
            >
              {t('app:globals:edit')}
            </ButtonSmall>
          </Header>

          <FilterWrapper>
            <Filter>
              <Paragraph style={{ marginRight: 'auto' }}>
                {t('app:filters:relationshipsTitle')}
              </Paragraph>
              <Paragraph style={{ fontWeight: 500, marginTop: 0 }}>
                {truncateString(
                  `${usePortfolioRelationshipFilter()
                    .filter((item) => item.selected)
                    .map((item) => item.name)}`,
                  20
                )}
              </Paragraph>
            </Filter>
            <Filter>
              <Paragraph style={{ marginRight: 'auto' }}>
                {t('app:portfolio:portfolioStrategy:strategy')}
              </Paragraph>
              <Paragraph style={{ fontWeight: 500, marginTop: 0 }}>
                {t(
                  `app:presentation:annualReview:portfolioStrategy:strategyTypes:${activeStrategy?.strategyType}`
                )}
              </Paragraph>
            </Filter>
          </FilterWrapper>
        </BaseSection>
      </MobileWrapper>
    </>
  );
};
