import React, { useState } from 'react';
import styled from 'styled-components';

import { humanFriendlyDate, t } from '@formue-app/core';

import { H2Small, H3Small, ParagraphSmall, ParagraphXSmall } from '../texts';
import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
  SPACING_4,
  SPACING_8,
} from '../../constants/spacing';
import { tabletCondition } from '../../constants/media';
import { paragraphLineHeight, paragraphSize } from '../../constants/text';
import { accent, textLink } from '../../constants/colors';

import { AdvisorsProfileImages } from '../wealthPlan/AdvisorsProfileImages';
import { MeetingsModal } from './MeetingsModal';

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 28px 40px 66px 40px;
  height: 100%;
`;

const StyledLink = styled(ParagraphSmall)`
  cursor: pointer;
  flex: 1;
  display: flex;
  color: ${textLink};
  font-weight: 500;
  margin-top: ${SPACING_8};

  @media ${tabletCondition} {
    font-size: ${paragraphSize};
    line-height: ${paragraphLineHeight};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledContentWrapper = styled.div`
  border-radius: ${SPACING_16};
  padding: ${SPACING_16};
  background: ${accent.sand150};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DateWrapper = styled.div`
  display: flex;
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
  background: ${accent.sand1};
  padding: ${SPACING_4} ${SPACING_8};
`;

export const MeetingCarouselItem = (props) => {
  const { slideTitle, meeting, setModalOpen } = props;

  return (
    <>
      <StyledContainer>
        <H3Small style={{ marginBottom: SPACING_16 }}>{slideTitle}</H3Small>
        <StyledContentWrapper>
          <TitleWrapper>
            <H2Small>{meeting.name}</H2Small>
            {meeting?.participants?.length ? (
              <AdvisorsProfileImages
                size={32}
                contacts={meeting.participants}
              />
            ) : null}
          </TitleWrapper>
          <DateWrapper>
            <ParagraphXSmall style={{ fontWeight: 500 }}>
              {humanFriendlyDate(meeting.startAt, true)} {meeting.location.city}
            </ParagraphXSmall>
          </DateWrapper>
        </StyledContentWrapper>
        <StyledLink
          onClick={() => {
            setModalOpen(true);
          }}
        >
          {t('app:globals:moreInfo')}
        </StyledLink>
      </StyledContainer>
    </>
  );
};
