import React from 'react';
import styled from 'styled-components';

import { t, useTodoPresentationListSections } from '@formue-app/core';

import { SPACING_16, SPACING_24 } from '../../../constants/spacing';

import { ReportTitle } from '../reportComponent/ReportTitle';
import { accent } from '../../../constants/colors';
import { SectionList } from '../../lists';
import { H3, Paragraph } from '../../texts';
import { TaskListItem } from '../slideComponents/todo/TaskListItem';
import { desktopCondition, mobileCondition } from '../../../constants/media';
import { GridElement } from '../../layout/GridElement';

const WrapperInner = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 30px;
  width: 100%;
  margin: auto;
  max-width: 800px;
  margin-top: 64px;

  @media ${mobileCondition} {
    grid-gap: 0px;
  }
`;

const StyledSectionList = styled(SectionList)`
  @media ${desktopCondition} {
    grid-column-start: 3;
    grid-column-end: 11;
  }

  @media ${mobileCondition} {
    &:not(:first-child) {
      margin-top: ${SPACING_24};
    }
  }
`;

export const TodoSection = (props) => {
  const { backgroundColor, isVisible, ...rest } = props;

  const sections = useTodoPresentationListSections();

  const highItems = sections.filter((item) => item.title === 'high');
  const normalItems = sections.filter((item) => item.title === 'normal');
  // const completedItems = sections.filter((item) => item.title === 'completed');

  const noTasks =
    highItems[0]?.data?.length === 0 && normalItems[0]?.data?.length === 0;

  return (
    <>
      <ReportTitle {...rest} />

      <WrapperInner>
        {noTasks ? (
          <GridElement columns={6} columnOffset={4}>
            <H3>{t('app:todo:noTasks:notCompleted:title')}</H3>
            <Paragraph>{t('app:todo:noTasks:notCompleted:text')}</Paragraph>
          </GridElement>
        ) : null}

        {highItems[0]?.data?.length > 0 ? (
          <StyledSectionList
            columns={8}
            maxHeight={412}
            animateItems={true}
            enter={isVisible}
            animationDelay={0.3 + 0.1 * highItems[0]?.data?.length}
            sections={highItems}
            renderItem={(item) => <TaskListItem item={item} />}
            keyExtractor={(subTask, index) => `high-priority-${index}`}
            renderIfEmpty={false}
            ListHeaderComponent={
              <H3 style={{ color: accent.ocean490, marginBottom: SPACING_16 }}>
                {t(`app:presentation:annualReview:measures:categories:high`)}
              </H3>
            }
            paginateItems={10}
          />
        ) : null}

        {normalItems[0]?.data?.length > 0 ? (
          <StyledSectionList
            columns={8}
            maxHeight={412}
            animateItems={true}
            enter={isVisible}
            animationDelay={0.3 + 0.1 * normalItems[0]?.data?.length}
            sections={normalItems}
            renderItem={(item) => <TaskListItem item={item} />}
            keyExtractor={(subTask, index) => `normal-priority-${index}`}
            renderIfEmpty={false}
            ListHeaderComponent={
              <H3
                style={{
                  color: accent.ocean490,
                  marginBottom: SPACING_16,
                }}
              >
                {t(`app:presentation:annualReview:measures:categories:normal`)}
              </H3>
            }
            paginateItems={10}
          />
        ) : null}
      </WrapperInner>
    </>
  );
};
