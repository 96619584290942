import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { t, selectors } from '@formue-app/core';

import { SPACING_48, SPACING_24 } from '../../../constants/spacing';
import { mobileCondition } from '../../../constants/media';

import { InfoCardHighlight } from '../../common/InfoCardHighlight';
import { SpriteIcon } from '../../common/SpriteIcon';

const { operatingCountrySelector } = selectors.auth;

const StyledInfoCardHighlight = styled(InfoCardHighlight)`
  padding: ${SPACING_48};

  @media ${mobileCondition} {
    padding: ${SPACING_24};
  }
`;

export const QuizInfoCard = ({ columns = 8, user }) => {
  const operatingCountry = useSelector(operatingCountrySelector);

  if (!user) {
    return null;
  }

  const { email, resultUrl } = user;

  const buttonLabel = resultUrl
    ? t('app:home:quiz:viewResults')
    : t('app:home:quiz:takeQuiz');

  const quizUrls = {
    no: `https://quiz.formue.no/?email=${email}`,
    se: `https://quiz.formue.se/?email=${email}`,
    dk: `https://quiz.formue.dk/?email=${email}`,
  };

  return (
    <StyledInfoCardHighlight
      columns={columns}
      icon={<SpriteIcon id="Joy" size={32} />}
      title={t('app:home:quiz:title')}
      body={t('app:home:quiz:body')}
      navLabel={buttonLabel}
      to={resultUrl ? resultUrl : quizUrls[operatingCountry]}
      external={true}
    />
  );
};
