import React from 'react';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import {
  SPACING_16,
  SPACING_8,
  SPACING_12,
  SPACING_4,
} from '../../constants/spacing';
import { H4, Paragraph } from '../texts';

import { GridElement } from '../layout';
import { SpriteIcon } from '../common/SpriteIcon';

const StyledContactCard = styled(GridElement)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${SPACING_12};
`;

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_4};
`;

const LinkedIcons = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: ${SPACING_8};

  &:hover {
    text-decoration: underline;
  }
`;

export const OfficeInfo = (props) => {
  const { office, ...rest } = props;

  if (!office) {
    return null;
  }

  const { name, address, phone, email } = office;

  let streetAddress = address.address1 ? `${address.address1} \n` : '';

  streetAddress += address.address2 ? `${address.address2} \n` : '';

  const zipcode =
    address.zipcode && address.city ? `${address.zipcode} ${address.city}` : '';

  return (
    <StyledContactCard {...rest}>
      <SpriteIcon id="Office" size={48} />
      <H4>
        {name} ({t('app:globals:yourOffice')})
      </H4>
      <SubSection>
        <Paragraph>{streetAddress}</Paragraph>
        <Paragraph>{zipcode}</Paragraph>
      </SubSection>

      <SubSection>
        <LinkedIcons href={`mailto:${email}`}>
          <SpriteIcon id="Contact Mail" size={SPACING_16} />
          <Paragraph>{email}</Paragraph>
        </LinkedIcons>
        <LinkedIcons href={`tlf:${phone}`}>
          <SpriteIcon id="Contact Phone" size={SPACING_16} />
          <Paragraph>{phone}</Paragraph>
        </LinkedIcons>
      </SubSection>
    </StyledContactCard>
  );
};
