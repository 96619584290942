import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryState } from 'react-router-use-location-state';
import * as lunr from 'lunr';
import { useMediaQuery } from 'react-responsive';
import camelcase from 'camelcase';

import { filterList, t, actions, useResource } from '@formue-app/core';

import {
  extendedDocumentsByIdSelector,
  extendedDocumentsAllIdsSelector,
} from '../store/ui/document/selectors';
import { MenuPage } from './base/MenuPage';
import { DocumentList } from '../components/documents/DocumentList';
import { ListFilterHeader } from '../components/lists/ListFilterHeader';
import { CenteredActivityIndicator } from '../components/common/ActivityIndicator';
import { mobileCondition } from '../constants/media';
import { SPACING_8 } from '../constants/spacing';
import { primary } from '../constants/colors';
import { GridElement } from '../components/layout/GridElement';

const {
  entities: { documents: documentsActions },
} = actions;

const Loading = (props) => (
  <GridElement columns={12} style={{ height: 200 }}>
    <CenteredActivityIndicator loadingColor={primary} size={50} columns={2} />
  </GridElement>
);

export const DocumentsPage = (props) => {
  const allIds = useSelector(extendedDocumentsAllIdsSelector);
  const byId = useSelector(extendedDocumentsByIdSelector);
  //const [loaded, setLoaded] = useState(false);
  const loading = useResource(['DOCUMENTS/INDEX']);

  const dispatch = useDispatch();
  const [search, setSearch] = useQueryState('search', '');
  const [relationship, setRelationship] = useQueryState('relationship', '');
  const [contentType, setContentType] = useQueryState('contentType', '');
  const [year, setYear] = useQueryState('year', '');
  const serializedSearchIndex = useSelector(
    (state) => state.ui.document.searchIndex
  );
  const [searchIndex, setSearchIndex] = useState(null);

  const mobileSize = useMediaQuery({
    query: mobileCondition,
  });

  const reloadDocuments = () => {
    dispatch(documentsActions.indexRequestBegin());
  };

  useEffect(() => {
    if (serializedSearchIndex) {
      const index = lunr.Index.load(JSON.parse(serializedSearchIndex));
      setSearchIndex(index);
    }
  }, [serializedSearchIndex]);

  // Trigger a load of documents when we enter the page to make sure we show
  // an updated list of documents. The documents archive might be large for some
  // customers so just loading this twice might not be the best idea.
  /* DISABLE THIS FOR NOW
   *
   * The documents library might be quite large, so fetching it twice uses
   * a bunch of CPU resources and network bandwith/usage. So unless we have
   * to do this, we should probably avoid so. There is a manual "refresh"
   * available for those who need.
  useEffect(() => {
    if (!loaded) {
      dispatch(documentsActions.indexRequestBegin());
      setLoaded(true);
    }
  }, [loaded, dispatch]);
  */

  let filters = {
    relationship: {
      value: relationship,
      onChange: setRelationship,
      type: 'select',
      label: t('app:documents:filters:relationship'),
    },
    contentType: {
      value: contentType,
      onChange: setContentType,
      type: 'select',
      label: t('app:documents:filters:types'),
      transform: (contentType) =>
        t(`app:documents:documentTypes:${camelcase(contentType)}`),
    },
    modifiedAt: {
      value: year,
      onChange: setYear,
      label: t('app:documents:filters:year'),
      type: 'select',
      transform: (date) => new Date(date).getFullYear().toString(),
    },
    search: {
      type: 'search',
      value: search,
      onChange: setSearch,
      label: t('app:documents:filters:search'),
      searchIndex,
      searchOptions: {
        wildcard: 'trailing',
      },
      style: { marginLeft: 'auto' },
    },
  };

  if (mobileSize) {
    filters = {
      search: {
        type: 'search',
        value: search,
        onChange: setSearch,
        label: t('app:documents:filters:search'),
        searchIndex,
        searchOptions: {
          wildcard: 'trailing',
        },
        style: { width: '100% ' },
      },
      relationship: {
        value: relationship,
        onChange: setRelationship,
        type: 'select',
        label: t('app:documents:filters:relationship'),
        style: {
          width: '70% ',
        },
      },
      modifiedAt: {
        value: year,
        onChange: setYear,
        label: t('app:documents:filters:year'),
        type: 'select',
        transform: (date) => new Date(date).getFullYear().toString(),
        style: {
          width: `calc(30% - ${SPACING_8})`,
        },
      },
      contentType: {
        value: contentType,
        onChange: setContentType,
        type: 'select',
        label: t('app:documents:filters:types'),
        transform: (contentType) =>
          t(`app:documents:documentTypes:${camelcase(contentType)}`),
        style: { width: '100%' },
      },
    };
  }
  const [documents, filterData] = filterList(allIds, byId, filters);

  return (
    <MenuPage {...props}>
      <ListFilterHeader
        filters={filters}
        data={filterData}
        onReload={reloadDocuments}
      />
      {loading ? <Loading /> : null}
      <DocumentList items={documents} />
    </MenuPage>
  );
};
