import i18next from 'i18next';

export function formatMoney(
  value,
  suffix = '',
  prefix,
  nullIndicator = '-',
  numberOfDecimals
) {
  // We don't want to show 0 if we don't have data, so instead
  // return a string indicating that we are missing data.
  if (!value && value !== 0) {
    return nullIndicator;
  }

  const isNegative = value < 0;
  const [intPart, decimalPart] = Math.abs(value)
    .toFixed(numberOfDecimals || 0)
    .split('.');

  let formattedValue = intPart
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .concat(decimalPart ? `.${decimalPart}` : '', suffix ? ` ${suffix}` : '');

  if (prefix) {
    formattedValue = `${isNegative ? '-' : ''}${prefix}${formattedValue}`;
  } else if (isNegative) {
    formattedValue = `-${formattedValue}`;
  }

  const localizedNUmber = `${formattedValue.replace(
    '.',
    i18next.language === 'sv' || i18next.language === 'nb' ? ',' : '.'
  )}`;

  return localizedNUmber.trim();
}

export const formatBigMoney = (value, prefix = true, nullIndicator = '-') => {
  // We don't want to show 0 if we don't have data, so instead
  // return a string indicating that we are missing data.
  if (!value && value !== 0) {
    return nullIndicator;
  }

  let formattedValue;
  const fixedValue = value.toFixed(0);
  switch (true) {
    case Math.abs(fixedValue) > 999999: {
      formattedValue = `${(value / 1000000)
        .toFixed(1)
        .toString()
        .replace('.', ',')} MKR`;
      break;
    }
    case Math.abs(fixedValue) > 999: {
      formattedValue = `${value
        .toFixed(0)
        .toString()
        .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} KR`;
      break;
    }
    case fixedValue === 0: {
      formattedValue = '0 KR';
      break;
    }
    default: {
      formattedValue = `${value
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} KR`;
      break;
    }
  }

  if (prefix) {
    if (parseFloat(formattedValue) < 0) {
      return `- ${formattedValue.substr(1)}`;
    }
    if (parseFloat(formattedValue) > 0) {
      return `+ ${formattedValue}`;
    }
    return formattedValue;
  }

  return formattedValue.replaceAll(' ', ' ').trimEnd();
};
