import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { t, config, generateCodeVerifier } from '@formue-app/core';

import { FullscreenPage } from './base/FullscreenPage';

import { mobileCondition } from '../constants/media';
import {
  SPACING_16,
  SPACING_24,
  SPACING_48,
  SPACING_8,
} from '../constants/spacing';
import { accent, backgroundMain, whiteTranslucent } from '../constants/colors';

import { Logo } from '../components/common/Logo';
import { H1Small, Paragraph } from '../components/texts';
import { LanguageSelectorDropDown } from '../components/common/LanguageSelectorDropDown';

import { WealthRegistration } from '../components/registration/WealthRegistration';
import { PersonalInfoRegistration } from '../components/registration/PersonalInfoRegistration';

import { actions as authActions } from '../store/auth';
import { CollapsibleListItem } from '../components/common/CollapsibleListItem';
import { SpriteIcon } from '../components/common/SpriteIcon';
import { ContentWrapper } from '../components/layout';

const StyledLogo = styled(Logo)`
  position: absolute;
  top: 34px;
  left: 40px;

  @media ${mobileCondition} {
    top: ${SPACING_16};
    left: ${SPACING_16};
  }
`;

const StyledLanguageSelectorDropDown = styled(LanguageSelectorDropDown)`
  position: absolute;
  top: 34px;
  right: 40px;

  span {
    color: ${accent.ocean4};
  }

  &:hover {
    background-color: ${whiteTranslucent(0.5)};
  }

  @media ${mobileCondition} {
    top: ${SPACING_8};
    right: 0;
  }
`;

const Content = styled.div`
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
`;

const StyledCollapsibleListItem = styled(CollapsibleListItem)`
  max-width: 700px;

  & + .details > div {
    padding-top: 0;

    pre {
      text-align: left;
      white-space: pre-wrap;
      margin: 0;
    }
  }
`;

const Step = (props) => {
  const { step, setStep, user, setUser, onSubmit, onGoBack } = props;

  switch (step) {
    case 0:
      return (
        <WealthRegistration
          onHandleNext={setStep}
          user={user}
          setUser={setUser}
        />
      );
    case 1:
      return (
        <PersonalInfoRegistration
          user={user}
          setUser={setUser}
          onSubmit={onSubmit}
          onGoBack={onGoBack}
        />
      );
    default:
      return (
        <WealthRegistration
          onHandleNext={setStep}
          user={user}
          setUser={setUser}
        />
      );
  }
};

export const RegistrationPage = () => {
  const dispatch = useDispatch();

  const error = useSelector((state) => state.auth.error);

  const [step, setStep] = useState(0);
  const [user, setUser] = useState({});
  const [codeVerifier] = useState(generateCodeVerifier());

  const onGoBack = () => {
    setStep(step - 1);
  };

  const onSubmit = () => {
    dispatch(
      authActions.startRegistration({
        data: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
          potentialValue: user.potentialValue || 0,
          source: user.source,
          marketing: user.marketing,
          marketingPhone: user.marketingPhone,
          country: config.countryCode,
        },
        codeVerifier,
      })
    );
  };

  return (
    <>
      <FullscreenPage>
        <div style={{ backgroundColor: backgroundMain }}>
          <StyledLogo height="16" />
          <StyledLanguageSelectorDropDown />

          {error ? (
            <ContentWrapper position="center">
              <Content>
                <H1Small style={{ marginBottom: SPACING_16 }}>
                  {t('app:login:registration:error')}
                </H1Small>
                <StyledCollapsibleListItem
                  details={<pre style={{ color: 'red' }}>{error.message}</pre>}
                  detailsStyle={{
                    paddingInline: SPACING_48,
                    marginTop: 0,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    paddingTop: 0,
                  }}
                  expandedStyle={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  startExpanded={false}
                  style={{
                    paddingBlock: SPACING_24,
                    paddingInline: SPACING_48,
                  }}
                >
                  <SpriteIcon id="Info" navigational={true} />
                  <Paragraph
                    style={{ margin: 0, marginRight: 'auto', fontWeight: 600 }}
                  >
                    {t('app:globals:error:accordionTitle')}
                  </Paragraph>
                </StyledCollapsibleListItem>
              </Content>
            </ContentWrapper>
          ) : (
            <Step
              step={step}
              setStep={setStep}
              user={user}
              setUser={setUser}
              onSubmit={onSubmit}
              onGoBack={onGoBack}
            />
          )}
        </div>
      </FullscreenPage>
    </>
  );
};
