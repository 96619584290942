import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { t, selectors } from '@formue-app/core';

import { accent, backgroundWhite } from '../../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_48,
  SPACING_32,
  SPACING_24,
  SPACING_16,
} from '../../../constants/spacing';

import { H2, H6, Paragraph } from '../../texts';
import { ExpandableListItem } from '../../common/ExpandableListItem';
import { GridElement } from '../../layout/GridElement';
import { mobileCondition, tabletCondition } from '../../../constants/media';

const {
  entities: {
    familyAndOwnership: { milestonesSelector },
  },
} = selectors;

const StyledGridElement = styled(GridElement)`
  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 48px;
`;

const MilestoneItem = styled.div`
  background-color: ${backgroundWhite};
  padding: ${SPACING_16};
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: ${SPACING_24};
  position: relative;
  border-radius: ${BORDER_RADIUS_LARGE};
  margin-bottom: 2px;

  &:before {
    display: block;
    content: '';
    background-color: ${accent.sand2};
    height: calc(100% - ${SPACING_32});
    width: 1px;
    left: 41px;
    position: absolute;
  }
`;

const Baloon = styled(H6)`
  display: flex;
  background-color: ${accent.sand2};
  border-radius: 50%;
  width: ${SPACING_48};
  height: ${SPACING_48};
  min-width: ${SPACING_48};
  min-height: ${SPACING_48};
  justify-content: center;
  align-items: center;
  color: ${accent.ocean490};
  z-index: 1;
`;

const StyledTitle = styled(H2)`
  margin-top: ${SPACING_24};
  margin-bottom: ${SPACING_16};

  @media ${mobileCondition} {
    margin-top: ${SPACING_24};
  }
`;

export const Milestone = (props) => {
  const { columns = 6 } = props;
  const milestones = useSelector(milestonesSelector);

  if (!milestones.length) {
    return null;
  }

  return (
    <StyledGridElement columns={columns}>
      <StyledTitle>
        {t('app:wealthPlan:familyAndOwnership:milestone:title')}
      </StyledTitle>

      <ExpandableListItem>
        {milestones.map((item, index) => (
          <MilestoneItem key={`milestone-${index}`}>
            <Baloon>{item.year}</Baloon>
            <TextWrapper>
              <Paragraph>{item.description}</Paragraph>
            </TextWrapper>
          </MilestoneItem>
        ))}
      </ExpandableListItem>
    </StyledGridElement>
  );
};
