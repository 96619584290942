import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useQueryState } from 'react-router-use-location-state';
import { groupBy } from 'lodash';

import { t, selectors, useResource } from '@formue-app/core';

import { accent } from '../../constants/colors';
import { H1, H2, H4, Paragraph, ParagraphLarge } from '../texts';
import {
  SPACING_48,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../constants/spacing';

import { BaseSection } from '../common/BaseSection';

import { GridElement } from '../layout';
import { CenteredActivityIndicator } from '../common/ActivityIndicator';
import { SlideItem, SlideTableOfContents } from './SlideTableOfContents';
import { SpriteIcon } from '../common/SpriteIcon';
import { Button } from '../buttons';

const StrategiesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const EditButton = styled(Button)`
  border-radius: 50px;
  padding: 8px 16px;
  margin-top: auto;
  border: 1px solid ${accent.ocean440};
  background: none;
  margin-bottom: 0;

  ${(props) =>
    props.editSlides &&
    css`
      margin-bottom: ${SPACING_16};
      background: ${accent.ocean408};
    `}

  svg {
    margin-right: 6px;
  }

  &:hover {
    background: ${accent.ocean408};
  }
`;

const StyledBaseSection = styled(BaseSection)`
  margin-bottom: ${SPACING_24};
  width: 500px;
`;

const {
  entities: {
    investmentStrategies: { allInvestmentStrategiesSelector },
  },
} = selectors;

export const StrategiesConfiguration = (props) => {
  const { slides, enabledSlides, setActiveSlides, setShowConfigurePanel } =
    props;

  const loading = useResource(['INVESTMENT_STRATEGIES/INDEX']);
  const investmentStrategies = useSelector(allInvestmentStrategiesSelector);
  const [enabledStrategies, setEnabledStrategies] = useQueryState(
    'enabledStrategies',
    []
  );

  const [editableStrategies, setEditableStrategies] = useState([]);
  const [selectedSlides, setSelectedSlides] = useState(slides);
  const [editSlides, setEditSlides] = useState(false);

  useEffect(() => {
    setSelectedSlides(slides);
  }, [slides]);

  useEffect(() => {
    setActiveSlides(selectedSlides.filter((item) => item.enabled));
  }, [setActiveSlides, selectedSlides]);

  useEffect(() => {
    setEnabledStrategies(
      editableStrategies
        .filter((item) => item.enabled && item.id)
        .map((item) => item?.id)
    );
  }, [editableStrategies, setEnabledStrategies]);

  useEffect(() => {
    if (investmentStrategies.length) {
      const strategies = investmentStrategies.map((item) => ({
        ...item,
        editable: true,
        enabled: false,
      }));

      setEditableStrategies(strategies);
    }
  }, [investmentStrategies, setEditableStrategies]);

  const strategyByAccountName = groupBy(editableStrategies, 'accountName');

  return (
    <>
      <GridElement columns={7}>
        <H1 style={{ marginBottom: SPACING_24 }}>
          {t('app:presentation:investmentStrategy:configure:title')}
        </H1>
        <ParagraphLarge style={{ marginBottom: SPACING_48 }}>
          {t(
            'app:presentation:investmentStrategy:configure:selectStrateigesAndSlides'
          )}
        </ParagraphLarge>
      </GridElement>

      <StyledBaseSection columns={7}>
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t('app:presentation:investmentStrategy:configure:selectStrategies')}
        </H2>
        <Paragraph style={{ marginBottom: SPACING_24 }}>
          {t(
            'app:presentation:investmentStrategy:configure:selectStrategiesForClient'
          )}
        </Paragraph>
        {loading ? (
          <CenteredActivityIndicator />
        ) : (
          <>
            {Object.keys(strategyByAccountName).map((key, index) => (
              <>
                <H4 style={{ marginBottom: SPACING_8, marginTop: SPACING_8 }}>
                  {key}
                </H4>
                <StrategiesList>
                  {strategyByAccountName[key]
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((strategy, index) => {
                      let disabledCondition = false;

                      // Only allow selecting 4 items
                      // Make sure its only previously unselected items are set to disabled
                      if (
                        enabledStrategies.length >= 4 &&
                        enabledStrategies.indexOf(strategy.id) < 0
                      ) {
                        disabledCondition = true;
                      }

                      return (
                        <SlideItem
                          key={`strategy-${index}`}
                          item={strategy}
                          section={editableStrategies}
                          disabled={disabledCondition}
                          editMode={true}
                          itemClickHandler={() => {
                            const copyEditableStrategies = [
                              ...editableStrategies,
                            ];
                            const index =
                              copyEditableStrategies.indexOf(strategy);
                            copyEditableStrategies[index].enabled =
                              !strategy.enabled;
                            setEditableStrategies(copyEditableStrategies);
                          }}
                          truncateLength={50}
                        />
                      );
                    })}
                </StrategiesList>
              </>
            ))}
          </>
        )}
      </StyledBaseSection>

      <StyledBaseSection
        style={{ width: editSlides ? '100%' : 500 }}
        columns={editSlides ? 12 : 7}
      >
        <H2 style={{ marginBottom: SPACING_16 }}>
          {t('app:presentation:investmentStrategy:configure:selectSlides')}
        </H2>
        <Paragraph style={{ marginBottom: SPACING_24 }}>
          {t(
            'app:presentation:investmentStrategy:configure:selectedSlidesText',
            {
              numberOfSelectedSlides: enabledSlides.length,
              numberOfSlides: slides.length,
            }
          )}
        </Paragraph>

        <EditButton
          onClick={() => setEditSlides(!editSlides)}
          editSlides={editSlides}
        >
          <SpriteIcon
            id="Settings"
            size={SPACING_16}
            style={{ verticalAlign: 'baseline' }}
          />
          <span>{t('app:presentation:share:selectedScreensButton')}</span>
        </EditButton>

        {editSlides ? (
          <SlideTableOfContents
            slides={selectedSlides}
            setActiveSlides={setSelectedSlides}
            editMode={true}
            lightBackground={true}
            style={{ gap: SPACING_8 }}
            truncateLength={33}
          />
        ) : null}
      </StyledBaseSection>

      <GridElement columns={7}>
        <Button
          onClick={() => {
            setShowConfigurePanel(false);
          }}
          disabled={!enabledStrategies.length}
        >
          {t('app:presentation:investmentStrategy:configure:startPresentation')}
        </Button>
      </GridElement>
    </>
  );
};
