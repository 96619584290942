import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  getFlagsSelector,
  selectors,
  t,
  useResource,
  actions,
} from '@formue-app/core';

import {
  SPACING_32,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../constants/spacing';

import { buttoncolor } from '../constants/colors';

import { SubMenuPage } from './base/SubMenuPage';
import { GridElement } from '../components/layout';
import { ButtonSmall } from '../components/buttons';

import { ReactComponent as SubMenuIcon } from '../assets/icons/advisor-corner/submenu-icon.svg';
import { Modal } from '../components/common/Modal';
import { H3 } from '../components/texts';
import { SubMenu } from '../components/common/SubMenu';
import { useSlides } from '../components/presentation/slides';
import { SpriteIcon } from '../components/common/SpriteIcon';

const StyledGridElement = styled(GridElement)`
  display: flex;
  gap: ${SPACING_16};
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: ${SPACING_24};
  position: relative;
`;

const DeleteModalContent = styled.div`
  padding: ${SPACING_24};
  display: flex;
  flex-direction: column;
  gap: ${SPACING_32};

  & > * {
    margin-right: auto;
  }
`;

const ExportButton = styled(ButtonSmall)`
  background-color: ${buttoncolor};
  transition: background-color 250ms ease-in-out;
  opacity: 1 !important;

  svg {
    position: ${(props) => (props.disabled ? 'absolute' : 'relative')};
    left: ${(props) => (props.disabled ? `calc(50% - ${SPACING_8})` : 'auto')};
  }
  span {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    transition: opacity 250ms ease-in-out;
    opacity: ${(props) => (props.disabled ? '0' : '1')};
  }
`;

const {
  entities: {
    investmentStrategies: {
      investmentStrategiesSelector,
      investmentStrategyByIdSelector,
    },
  },
  ui: {
    user: { currentUserSelector },
  },
} = selectors;

const {
  ui: {
    presentation: uiPresentationActions,
    flashMessages: flashMessagesActions,
  },
} = actions;

export const StrategyDetailsPage = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useResource(['INVESTMENT_STRATEGIES/INDEX']);

  const slides = useSlides()['investmentStrategyExport'];
  const currentUser = useSelector(currentUserSelector);
  const allInvestmentStrategies = useSelector(investmentStrategiesSelector);
  const strategy = useSelector(investmentStrategyByIdSelector(id));
  const { features } = useSelector(getFlagsSelector);

  const isParentStrategy = strategy ? strategy.children.length : null;
  const isChildStrategy = strategy ? !!strategy?.parent?.id : null;

  const [parentStrategy, setParentStrategy] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [exportButtonState, setExportButtonState] = useState('default');

  useEffect(() => {
    if (isChildStrategy) {
      const parentStrategy = allInvestmentStrategies.find(
        (item) => item.id === strategy?.parent?.id
      );

      setParentStrategy(parentStrategy);
    } else {
      setParentStrategy(null);
    }
  }, [allInvestmentStrategies, isChildStrategy, strategy]);

  const subMenuItems = [
    {
      onClick: () => {
        setShowSubMenu(!showSubMenu);
      },
      title: t('app:globals:edit'),
    },
    {
      onClick: () => {
        setShowDeleteDialog(!showDeleteDialog);
        setShowSubMenu(!showSubMenu);
      },
      title: t('app:globals:delete'),
      disabled: !features.strategyToolBeta,
    },
  ];

  useEffect(() => {
    let timer;
    switch (exportButtonState) {
      case 'inProgress': {
        timer = setTimeout(() => {
          setExportButtonState('completed');
        }, 1000);
        break;
      }
      case 'completed': {
        timer = setTimeout(() => {
          setExportButtonState('default');
        }, 4000);
        break;
      }
      default: {
        break;
      }
    }

    return () => clearTimeout(timer);
  }, [exportButtonState]);

  return (
    <>
      <SubMenuPage
        {...props}
        subpage={true}
        backOverrideUrl={
          parentStrategy
            ? `/advisor/strategy-simulator/details/${parentStrategy?.id}`
            : '/advisor/strategy-simulator'
        }
        title={strategy ? strategy.title : null}
        routes={isParentStrategy > 0 ? props.routes.slice(0, 1) : props.routes}
        subTitle={parentStrategy?.title}
      >
        <StyledGridElement columns={3} style={{ zIndex: 2 }}>
          <ButtonSmall to="/advisor/presentation/investment-strategy">
            <SpriteIcon
              id="Art"
              inverted={true}
              navigational={true}
              size={SPACING_16}
            />
            {t('app:advisor:strategySimulator:startPresentation')}
          </ButtonSmall>

          <div style={{ position: 'relative' }}>
            <ExportButton
              disabled={['inProgress', 'completed'].includes(exportButtonState)}
              onClick={(event) => {
                // Set the css width of the element to the size it has dynamically scaled to
                // so that we can do stuff with the contents of the button without affecting
                // it's size
                const { currentTarget: element } = event;
                const { offsetWidth: width, offsetHeight: height } = element;
                element.style.width = `${width}px`;
                element.style.height = `${height}px`;

                setExportButtonState('inProgress');
                dispatch(
                  uiPresentationActions.exportInvestmentStrategy({
                    userId: currentUser.ssid,
                    slides: slides
                      .filter((item) => item.enabled)
                      .map((slide) => slide.id),
                    strategyId: id,
                  })
                );
                dispatch(
                  flashMessagesActions.notify({
                    title: t(
                      'app:advisor:strategySimulator:exportConfirmationTitle'
                    ),
                    message: t(
                      'app:advisor:strategySimulator:exportConfirmation'
                    ),
                    type: 'success',
                  })
                );
              }}
            >
              <SpriteIcon
                id={
                  ['inProgress', 'completed'].includes(exportButtonState)
                    ? 'OK'
                    : 'Agreement'
                }
                inverted={true}
                navigational={true}
                size={SPACING_16}
              />
              <span>{t('app:advisor:strategySimulator:exportButton')}</span>
            </ExportButton>
          </div>

          <div
            style={{ cursor: 'pointer', marginBottom: 5 }}
            onClick={() => setShowSubMenu(!showSubMenu)}
          >
            <SubMenuIcon />
          </div>
          <SubMenu
            style={{ right: 0 }}
            items={subMenuItems}
            showSubMenu={showSubMenu}
          />
        </StyledGridElement>
      </SubMenuPage>
      <Modal
        isOpen={showDeleteDialog}
        onRequestClose={() => setShowDeleteDialog(false)}
        contentWidth={450}
      >
        <DeleteModalContent>
          <H3>{t('app:advisor:strategySimulator:deleteConfirmation')}</H3>
          <ButtonSmall>{t('app:globals:delete')}</ButtonSmall>
        </DeleteModalContent>
      </Modal>
    </>
  );
};
