import { stripInput } from './string';
import { t } from '../localization';

export const createAddressLine = (address) => {
  const { zipCode, city, street } = address;

  const zipCity = [zipCode, city].filter(Boolean).join(' ');
  const addressLine = [street, zipCity].filter(Boolean).join(', ');
  return addressLine;
};

export const getBankAccountStatus = (bankAccountStatus) => {
  let status = '';
  let verified;
  switch (bankAccountStatus) {
    case 'Under Review':
    case 'Manual registration: Deviation':
      status = t('app:globals:pending');
      verified = false;
      break;
    case 'Approved':
    case 'Approved Deviation':
      status = t('app:globals:verified');
      verified = false;
      break;
    default:
      verified = false;
      break;
  }

  return { status, verified };
};

export const isValidBankAccountNumber = (value) => {
  // You can now post both IBAN and regular
  // bank account numbers to the verify endpoint

  // Strip the input of spaces and match on conditional characters at the beginning
  // and between 11 and 22 numbers at the end. This ensures that both bank account numbers
  // and IBAN numbers will pass the validation
  const stripped = stripInput(value);

  if (!stripped) {
    return false;
  }

  return !!stripped.match(/(?:[A-Za-z]?){2}[0-9]{11,22}/g);
};
