import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RouteWithSubRoutes } from '../../components/router';
import { SubPageNavigation } from '../../components/common/SubPageNavigation';
import { selectors, t } from '@formue-app/core';

const { operatingCountrySelector } = selectors.auth;

export const ReportingPage = (props) => {
  const { routes, location, title } = props;

  const operatingCountry = useSelector(operatingCountrySelector);

  // If we are one a "ReportingPage" we don't really have anything to show so do an
  // automatic redirect to the first sub route.
  return (
    <>
      {location.pathname === '/portfolio/reporting' &&
        // Since DK does not have tex reporting we want to redirect to cost reporting by default in sted
        (operatingCountry === 'dk' ? (
          <Redirect to={routes[routes.length - 1]?.path} />
        ) : (
          <Redirect to={routes[0]?.path} />
        ))}
      <SubPageNavigation
        title={title}
        subTitle={t('app:portfolio:reporting:subTitle')}
        routes={routes}
      />
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </>
  );
};
