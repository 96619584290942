import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { animated, useTransition, config } from 'react-spring';

import { selectors, t } from '@formue-app/core';
import { MainGrid } from '../../../layout';
import { BaseSection } from '../../../common/BaseSection';
import { H2 } from '../../../texts';
import { SectorItem } from './SectorItem';
import { SPACING_8, SPACING_16 } from '../../../../constants/spacing';

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const StyledContainer = styled(BaseSection)`
  padding: 0;
  background-color: transparent;
`;

const AnimatedSectorItem = animated(SectorItem);

const {
  entities: {
    securities: { securitiesByIdSelector, securitiesBySectorSelector },
  },
} = selectors;

export const SectorsList = React.memo(
  ({ selectedContinent = 'Global', ...rest }) => {
    const bySector = useSelector(securitiesBySectorSelector);
    const securitiesById = useSelector(securitiesByIdSelector);

    const sortedSectors = Object.values(bySector).sort(
      (a, b) => b.partOfKnownSectorPortfolio - a.partOfKnownSectorPortfolio
    );

    const transitions = useTransition(sortedSectors, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      to: { opacity: 1 },
      trail: 80,
      reset: true,
      config: config.gentle,
    });

    return (
      <StyledContainer columns={12} {...rest}>
        <H2 style={{ marginBottom: SPACING_16, marginTop: SPACING_8 }}>
          {t('app:sectors:sectorAllocationTitle')}
        </H2>

        <StyledSubGrid>
          {transitions((style, sector, key, index) => {
            const sortedSecurities = sector.securities.sort(
              (a, b) =>
                securitiesById[b].partOfKnownSectorPortfolio -
                securitiesById[a].partOfKnownSectorPortfolio
            );

            const topThree = sortedSecurities
              .filter((securityId, index) => index < 3)
              .map((securityId) => securitiesById[securityId]);

            return (
              <AnimatedSectorItem
                securities={topThree}
                sector={sector.sector}
                partOfKnownSectorPortfolio={sector.partOfKnownSectorPortfolio}
                columns={4}
                style={style}
                key={index}
              />
            );
          })}
        </StyledSubGrid>
      </StyledContainer>
    );
  }
);
