import React from 'react';

import { t } from '@formue-app/core';

import { SPACING_48 } from '../../../constants/spacing';

import { InfoCardHighlight } from '../../common/InfoCardHighlight';
import { SpriteIcon } from '../../common/SpriteIcon';

export const TaxInfoCard = ({ columns = 8 }) => {
  return (
    <InfoCardHighlight
      columns={columns}
      icon={<SpriteIcon id="Document Multi" size={32} />}
      style={{ padding: SPACING_48 }}
      title={t('app:home:taxDetails:title')}
      body={t('app:home:taxDetails:body')}
      navLabel={t('app:home:taxDetails:navLabel')}
      to="/portfolio/reporting/tax/2024"
    />
  );
};
