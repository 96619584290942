import React from 'react';
import styled from 'styled-components';

import {
  formatMoney,
  getAssetClassName,
  percentage,
  selectors,
  t,
  useResource,
} from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import {
  SPACING_16,
  SPACING_24,
  SPACING_4,
  SPACING_8,
} from '../../../constants/spacing';
import { ParagraphSmall } from '../../texts';
import { accent } from '../../../constants/colors';
import { useSelector } from 'react-redux';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { printCondition } from '../../../constants/media';
import {
  StyledCell,
  StyledHeaderCell,
  StyledRow,
  TableList,
} from '../../lists';
import {
  paragraphSmallSize,
  paragraphXSmallSize,
} from '../../../constants/text';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};

  @media ${printCondition} {
    gap: ${SPACING_8};
    margin-right: ${SPACING_24} !important;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const LightStyledRow = styled(StyledRow)`
  &:last-child {
    th {
      border-bottom: none;

      padding-top: ${SPACING_16};

      span {
        font-weight: 500;
      }
    }
  }
`;

const LightStyledCell = styled(StyledCell)`
  padding: ${SPACING_4} 0;
  position: relative;
  border-bottom: 1px solid ${accent.sand1};

  &:last-child {
    padding-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:first-child {
    padding-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  span {
    font-size: ${paragraphSmallSize};
    font-weight: 400;
    text-align: left;
  }

  &:first-child {
    span {
      font-weight: 500;
    }
  }
`;

const LightStyledHeaderCell = styled(StyledHeaderCell)`
  font-weight: 500;
  padding: 0;
  font-size: ${paragraphXSmallSize};

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const {
  entities: {
    portfolios: { portfolioTotalSelector, portfolioAssetClassesSelector },
  },
} = selectors;

export const PeriodicActiveClassOverview = (props) => {
  const loading = useResource(['PORTFOLIOS/INDEX', 'PORTFOLIO_INDICES/INDEX']);

  const portfolioTotal = useSelector(portfolioTotalSelector);
  const assetClasses = useSelector(portfolioAssetClassesSelector);

  let assetClassColumns = [
    {
      key: 'p',
      label: t('app:portfolio:holdingsTable:assetClass'),
      width: '20%',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'mv',
      label: t('app:portfolio:holdingsTable:marketValue'),
      render: ({ mv }) => formatMoney(mv),
      type: 'number',
      width: '20%',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'gain',
      label: t('app:portfolio:holdingsTable:returnKroner'),
      render: ({ gain }) => formatMoney(gain),
      type: 'number',
      width: '20%',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
    {
      key: 'twr',
      label: t('app:portfolio:holdingsTable:returnPercentage'),
      render: ({ twr }) => percentage(twr * 100, 1),
      type: 'number',
      width: '20%',
      component: LightStyledCell,
      headerComponent: LightStyledHeaderCell,
    },
  ];

  const tableData = assetClasses.map((item, index) => {
    const assetClassTitle = getAssetClassName(item.class);

    return {
      p: assetClassTitle,
      quantity: item.quantity,
      priceDate: null,
      costPrice: item.costPrice,
      unrealizedGain: item.unrealizedGain,
      gain: item.gain,
      twr: item.twr,
      mv: item.mv,
    };
  });

  // Add total to the end of the dataset
  tableData.push({
    ...portfolioTotal,
    p: t('app:portfolio:periodicReporting:holdings:totalPortfolio'),
  });

  return (
    <StyledBaseSection columns={6}>
      <TextWrapper>
        <ParagraphSmall>
          {t('app:portfolio:periodicReporting:activeClassOverview')}
        </ParagraphSmall>
      </TextWrapper>
      {!portfolioTotal || loading ? (
        <CenteredActivityIndicator />
      ) : (
        <TableList
          columns={assetClassColumns}
          rows={tableData}
          keyExtractor={(item, index) => `asset-classes-${index}`}
          rowComponent={LightStyledRow}
          showHeader={true}
          sortable={false}
        />
      )}
    </StyledBaseSection>
  );
};
