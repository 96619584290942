import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import {
  usePortfolioRelationshipFilter,
  actions,
  selectors,
  t,
  config,
  useResource,
  getConfigurationVariable,
} from '@formue-app/core';

import { Modal } from '../../components/common/Modal';
import { GridElement } from '../../components/layout';
import { Select } from '../../components/formElements';
import { StyledCell, TableList } from '../../components/lists';

import { CostOverviewContent } from '../../components/portfolio/costAndFees/CostOverviewContent';
import { ProductCostContent } from '../../components/portfolio/costAndFees/ProductCostContent';
import { FeeContent } from '../../components/portfolio/costAndFees/FeeContent';

import { H4, H2, Paragraph, ParagraphXSmall } from '../../components/texts';

import { notMobileCondition } from '../../constants/media';
import {
  SPACING_48,
  SPACING_32,
  SPACING_24,
  SPACING_16,
} from '../../constants/spacing';
import { textLink } from '../../constants/colors';
import { ActivityIndicator } from '../../components/common/ActivityIndicator';

const {
  entities: { costReports: costReportsActions },
} = actions;

const {
  ui: {
    user: { accessibleAccountsSelector },
  },
  entities: {
    costReports: {
      productCostItemsSelector,
      recurringFeeItemsSelector,
      oneTimeFeeItemsSelector,
      reportingFeeItemsSelector,
      utmostFeeItemsSelector,
      nordnetFeeItemsSelector,
    },
  },
  auth: { operatingCountrySelector },
} = selectors;

const DisclamerWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-top: ${SPACING_16};

  @media ${notMobileCondition} {
    width: 80%;
  }
`;

const StyledParagraph = styled(Paragraph)`
  margin-top: ${SPACING_16};
`;

const LightStyledCell = styled(StyledCell)`
  span {
    font-weight: 400;
  }

  ${(props) =>
    props.item.feeCode === 'performance-fee' &&
    css`
      &:first-child {
        span {
          margin-left: ${SPACING_24};
        }
      }

      span {
        font-style: italic;
      }
    `}
`;

const StyledTableList = styled(TableList)`
  tbody tr:last-child {
    span {
      font-weight: 500;
    }
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: columns;
  align-items: center;
  gap: ${SPACING_24};
`;

export const CostReportPage = () => {
  const dispatch = useDispatch();
  const productCost = useSelector(productCostItemsSelector);
  const recurringFee = useSelector(recurringFeeItemsSelector);
  const oneTimeFee = useSelector(oneTimeFeeItemsSelector);
  const reportingFee = useSelector(reportingFeeItemsSelector);
  const utmostFee = useSelector(utmostFeeItemsSelector);
  const nordnetFee = useSelector(nordnetFeeItemsSelector);
  const accounts = useSelector(accessibleAccountsSelector);
  const operatingCountry = useSelector(operatingCountrySelector);

  const {
    no: publishedDateNo,
    se: publishedDateSe,
    dk: publishedDateDk,
  } = getConfigurationVariable('costNumbersPublished');

  const loading = useResource(['COST_REPORTS/INDEX']);

  const [costOverviewOpen, setCostOverviewOpen] = useState(false);
  const [productCostOpen, setProductCostOpen] = useState(false);
  const [feeOpen, setFeeOpen] = useState(false);

  const dates = [];
  // 2018 is the first year we have cost data
  const start = new Date().setFullYear('2018');
  const end = new Date().setFullYear(new Date().getFullYear() - 2);

  let loop = new Date(start);

  // Generate date range to show in filter
  while (loop <= end) {
    dates.push({ value: loop.getFullYear(), label: loop.getFullYear() });
    var newDate = loop.setFullYear(loop.getFullYear() + 1);
    loop = new Date(newDate);
  }

  // Only add previous year if date is past June to ensure we have data
  // Or they are published for the country of the user
  // (When the date is past the june we can turn off the country publishe date check to not show
  // the next year prematurly)
  if (
    (operatingCountry === 'no' &&
      new Date(publishedDateNo).getTime() < new Date().getTime() &&
      new Date(publishedDateNo).getFullYear() === new Date().getFullYear()) ||
    (operatingCountry === 'se' &&
      new Date(publishedDateSe).getTime() < new Date().getTime() &&
      new Date(publishedDateSe).getFullYear() === new Date().getFullYear()) ||
    (operatingCountry === 'dk' &&
      new Date(publishedDateDk).getTime() < new Date().getTime() &&
      new Date(publishedDateDk).getFullYear() === new Date().getFullYear()) ||
    new Date().getMonth() > 6
  ) {
    dates.push({
      value: new Date().getFullYear() - 1,
      label: new Date().getFullYear() - 1,
    });
  }

  const [activeYear, setActiveYear] = useState(dates[dates.length - 1].value);
  const [activeUser, setActiveUser] = useState(null);

  const relationsshipsOptions = usePortfolioRelationshipFilter().map(
    (item) => ({
      value: item.ssid,
      label: item.name,
    })
  );

  useEffect(() => {
    if (activeUser) {
      dispatch(costReportsActions.flush());
      dispatch(
        costReportsActions.indexRequestBegin({
          ssid: activeUser,
          year: activeYear,
        })
      );
    }
  }, [dispatch, activeUser, activeYear]);

  useEffect(() => {
    if (relationsshipsOptions.length && !activeUser) {
      setActiveUser(relationsshipsOptions[0].value);
    }
  }, [relationsshipsOptions, activeUser]);

  let costColumns = [
    {
      key: 'name',
      label: t('app:portfolio:costReport:table:productSuppliers'),
      width: '70%',
      infoFunction: () => setProductCostOpen(!productCostOpen),
      component: LightStyledCell,
    },
    {
      key: 'rate',
      label: t('app:portfolio:costReport:table:calculatedPercent'),
      width: '15%',
      type: 'number',
      component: LightStyledCell,
    },
    {
      key: 'amount',
      label: t('app:portfolio:costReport:table:calculatedAmount'),
      width: '15%',
      type: 'number',
      component: LightStyledCell,
    },
  ];

  let feeColumns =
    // TODO:dk Handle danish operatic country. In the current implementation we
    // default to using the same as for norway since it's a ternary operator
    // refactor all cases of this to be a switch statement
    operatingCountry === 'se'
      ? [
          {
            key: 'name',
            label: t('app:portfolio:costReport:table:feeToFormues', {
              companyName: config.title,
            }),
            width: '40%',
            infoFunction: () => setFeeOpen(!feeOpen),
            component: LightStyledCell,
          },
          {
            key: 'rate',
            label: t('app:portfolio:costReport:table:agreedPercent'),
            width: '15%',
            type: 'number',
            component: LightStyledCell,
          },
          {
            key: 'amountExVat',
            label: t('app:portfolio:costReport:table:amountExVat'),
            width: '15%',
            type: 'number',
            component: LightStyledCell,
          },
          {
            key: 'vatAmount',
            label: t('app:portfolio:costReport:table:vatAmount'),
            width: '15%',
            type: 'number',
            component: LightStyledCell,
          },
          {
            key: 'amount',
            label: t('app:portfolio:costReport:table:agreedAmount'),
            width: '15%',
            type: 'number',
            component: LightStyledCell,
          },
        ]
      : [
          {
            key: 'name',
            label: t('app:portfolio:costReport:table:feeToFormues', {
              companyName: config.title,
            }),
            width: '70%',
            infoFunction: () => setFeeOpen(!feeOpen),
            component: LightStyledCell,
          },
          {
            key: 'rate',
            label: t('app:portfolio:costReport:table:agreedPercent'),
            width: '15%',
            type: 'number',
            component: LightStyledCell,
          },
          {
            key: 'amount',
            label: t('app:portfolio:costReport:table:agreedAmount'),
            width: '15%',
            type: 'number',
            component: LightStyledCell,
          },
        ];

  let oneTimeFeeColumns =
    // TODO:dk Handle danish operatic country. In the current implementation we
    // default to using the same as for norway since it's a ternary operator
    // refactor all cases of this to be a switch statement
    operatingCountry === 'se'
      ? [
          {
            key: 'name',
            width: '40%',
            component: LightStyledCell,
          },
          {
            key: 'rate',
            width: '15%',
            type: 'number',
            component: LightStyledCell,
          },
          {
            key: 'amountExVat',
            width: '15%',
            type: 'number',
            component: LightStyledCell,
          },
          {
            key: 'vatAmount',
            width: '15%',
            type: 'number',
            component: LightStyledCell,
          },
          {
            key: 'amount',
            width: '15%',
            type: 'number',
            component: LightStyledCell,
          },
        ]
      : [
          {
            key: 'name',
            width: '70%',
            component: LightStyledCell,
          },
          {
            key: 'rate',
            width: '15%',
            type: 'number',
            component: LightStyledCell,
          },
          {
            key: 'amount',
            width: '15%',
            type: 'number',
            component: LightStyledCell,
          },
        ];

  let reportingFeeColumns = [
    {
      key: 'name',
      width: '70%',
      component: LightStyledCell,
    },
    {
      key: 'rate',
      width: '15%',
      type: 'number',
      component: LightStyledCell,
    },
    {
      key: 'amount',
      width: '15%',
      type: 'number',
      component: LightStyledCell,
    },
  ];

  let utmostFeeColumns = [
    {
      key: 'name',
      label: t('app:portfolio:costReport:table:utmostFee'),
      width: '85%',
      component: LightStyledCell,
    },
    {
      key: 'amount',
      label: t('app:portfolio:costReport:table:amount'),
      width: '15%',
      type: 'number',
      component: LightStyledCell,
    },
  ];

  let nordnetFeeColumns = [
    {
      key: 'name',
      label: t('app:portfolio:costReport:table:nordnetFee'),
      width: '85%',
      component: LightStyledCell,
    },
    {
      key: 'amount',
      label: t('app:portfolio:costReport:table:amount'),
      width: '15%',
      type: 'number',
      component: LightStyledCell,
    },
  ];

  // All fees have a sum entry added, so if only one entry, it will be a sum row of 0 amount.
  // We will always show the common fees and cost, but hide these which often has no rows
  const showReportingFee = !!reportingFee.length && reportingFee.length > 1;
  const showOneTimeFee = !!oneTimeFee.length && oneTimeFee.length > 1;
  const showUtmostFee = !!utmostFee.length && utmostFee.length > 1;
  const showNordnetFee = !!nordnetFee.length && nordnetFee.length > 1;

  return (
    <>
      <GridElement columns={8}>
        <H2>{t('app:portfolio:costReport:title')}</H2>
        <StyledParagraph>{t('app:portfolio:costReport:intro')}</StyledParagraph>
        <H4
          style={{
            color: textLink,
            marginTop: SPACING_16,
            marginBottom: SPACING_32,
            cursor: 'pointer',
          }}
          onClick={() => setCostOverviewOpen(!costOverviewOpen)}
        >
          {t('app:portfolio:costReport:readMore')}
        </H4>
      </GridElement>
      <GridElement columns={12}>
        <FilterWrapper>
          {accounts && (
            <Select
              options={relationsshipsOptions}
              onChange={(e) => {
                setActiveUser(e.target.value);
              }}
              popoutLabel={true}
              label={t('app:portfolio:costReport:chooseClient')}
            />
          )}
          <Select
            options={dates.reverse()}
            onChange={(e) => {
              setActiveYear(e.target.value);
            }}
            popoutLabel={true}
            label={t('app:portfolio:costReport:year')}
          />
          {loading && <ActivityIndicator size={25} />}
        </FilterWrapper>
      </GridElement>
      <GridElement columns={12} style={{ marginTop: SPACING_32 }}>
        {!!productCost.length && (
          <StyledTableList
            sortable={false}
            columns={costColumns}
            rows={productCost}
            keyExtractor={(item, index) => `cost-${index}`}
          />
        )}
      </GridElement>
      <GridElement columns={12} style={{ marginTop: SPACING_24 }}>
        {!!recurringFee.length && (
          <StyledTableList
            sortable={false}
            columns={feeColumns}
            rows={recurringFee}
            keyExtractor={(item, index) => `fee-${index}`}
          />
        )}
        {showReportingFee && (
          <StyledTableList
            sortable={false}
            columns={reportingFeeColumns}
            rows={reportingFee}
            keyExtractor={(item, index) => `fee-${index}`}
            style={{ marginTop: 0 }}
            showHeader={false}
          />
        )}
        {showOneTimeFee && (
          <StyledTableList
            sortable={false}
            columns={oneTimeFeeColumns}
            rows={oneTimeFee}
            keyExtractor={(item, index) => `fee-${index}`}
            style={{ marginTop: 0 }}
            showHeader={false}
          />
        )}
      </GridElement>
      <GridElement columns={12} style={{ marginTop: SPACING_32 }}>
        {showUtmostFee && (
          <StyledTableList
            sortable={false}
            columns={utmostFeeColumns}
            rows={utmostFee}
            keyExtractor={(item, index) => `fee-${index}`}
          />
        )}
      </GridElement>
      <GridElement columns={12} style={{ marginTop: SPACING_32 }}>
        {showNordnetFee && (
          <StyledTableList
            sortable={false}
            columns={nordnetFeeColumns}
            rows={nordnetFee}
            keyExtractor={(item, index) => `fee-${index}`}
          />
        )}
      </GridElement>
      <GridElement columns={12}>
        <DisclamerWrapper>
          <ParagraphXSmall>
            {t('app:portfolio:costReport:disclamer')}
          </ParagraphXSmall>
        </DisclamerWrapper>
      </GridElement>
      <Modal
        isOpen={costOverviewOpen}
        onRequestClose={() => setCostOverviewOpen(false)}
        contentLabel={t('app:portfolio:costReport:about:title')}
        padding={SPACING_48}
      >
        <CostOverviewContent />
      </Modal>
      <Modal
        isOpen={productCostOpen}
        onRequestClose={() => setProductCostOpen(false)}
        contentLabel={t('app:portfolio:costReport:productCost:title')}
        padding={SPACING_48}
      >
        <ProductCostContent />
      </Modal>
      <Modal
        isOpen={feeOpen}
        onRequestClose={() => setFeeOpen(false)}
        contentLabel={t('app:portfolio:costReport:fee:title')}
        padding={SPACING_48}
      >
        <FeeContent />
      </Modal>
    </>
  );
};
