import React, { useState, useEffect, useRef } from 'react';
import useCollapse from 'react-collapsed';
import styled from 'styled-components';

import { t } from '@formue-app/core';

import {
  BORDER_RADIUS_LARGE,
  SPACING_16,
  SPACING_8,
  SPACING_2,
} from '../../constants/spacing';
import { shortAnimationTime, easeInOut } from '../../constants/animation';
import { hoverBoxShadowLvl2 } from '../../constants/colors';

import { BaseListItem } from './BaseListItem';
import { ExpandableArrow } from './ExpandableArrow';
import { H4 } from '../texts';

const StyledBaseListItem = styled(BaseListItem)`
  cursor: pointer;
  padding: ${SPACING_16};
  transition: box-shadow ${shortAnimationTime} ${easeInOut};
  gap: ${SPACING_8};
  ${(props) => props.baseItemStyles}

  &:hover {
    box-shadow: ${hoverBoxShadowLvl2};
  }
`;

const ContentWrapper = styled.div`
  overflow: hidden;
  margin-bottom: ${SPACING_2};
  transition: height ${shortAnimationTime} ${easeInOut};
  border-bottom-right-radius: ${BORDER_RADIUS_LARGE};
  border-bottom-left-radius: ${BORDER_RADIUS_LARGE};
`;

export const ExpandableListItem = (props) => {
  const {
    children,
    startExpanded = false,
    height = 400,
    seeMoreText = t('app:globals:seeMore'),
    seeLessText = t('app:globals:seeLess'),
    flipArrowDirection = ['up', 'down'],
    baseItemStyles,
    ...rest
  } = props;

  const contentRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(startExpanded);
  const [contentHeight, setContentHeight] = useState(height);
  const { getToggleProps } = useCollapse({ isExpanded });

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setIsExpanded(startExpanded);
  }, [startExpanded]);

  useEffect(() => {
    setContentHeight(contentRef.current.clientHeight);
  }, [contentRef]);

  const showReadMore = contentHeight > height;

  return (
    <>
      <ContentWrapper
        {...rest}
        style={{
          height: isExpanded ? contentHeight : showReadMore ? height : 'auto',
        }}
      >
        <div ref={contentRef}>{children}</div>
      </ContentWrapper>
      {showReadMore ? (
        <StyledBaseListItem
          baseItemStyles={baseItemStyles}
          {...getToggleProps({ onClick: toggleExpanded })}
        >
          <H4 style={{ marginLeft: 'auto' }}>
            {isExpanded ? seeLessText : seeMoreText}
          </H4>
          <ExpandableArrow
            active={isExpanded}
            direction={
              isExpanded ? flipArrowDirection[0] : flipArrowDirection[1]
            }
          />
        </StyledBaseListItem>
      ) : null}
    </>
  );
};
