import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { InvestmentPreferencesChoiceItem } from './InvestmentPreferencesChoiceItem';
import { selectors, t } from '@formue-app/core';
import { backgroundWhite } from '../../../constants/colors';
import {
  BORDER_RADIUS_LARGE,
  SPACING_24,
  SPACING_8,
} from '../../../constants/spacing';
import { mobileCondition } from '../../../constants/media';
import { SectionWithTitle } from '../../common/SectionWithTitle';
import { BaseSection } from '../../common/BaseSection';

const {
  entities: {
    investmentProfile: { choiceTypesSelector, choicesSelector },
  },
} = selectors;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${SPACING_24};
  background-color: ${backgroundWhite};
  border-radius: ${BORDER_RADIUS_LARGE};

  @media ${mobileCondition} {
    flex-direction: column;
    gap: ${SPACING_8};
  }
`;

const RowWrapper = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
  padding: ${SPACING_24};
`;

export const InvestmentPreferencesChoicesContent = () => {
  const choices = useSelector(choicesSelector);
  const choiceTypes = useSelector(choiceTypesSelector);

  const renderChoiceItems = (indexes) => {
    return indexes.map((index) => {
      if (!choiceTypes[index]) {
        return null;
      }

      const choice = choiceTypes[index];
      const title = t(
        `app:wealthPlan:investmentProfile:preferencesTypes:${choice}:title`
      );
      const text = t(
        `app:wealthPlan:investmentProfile:preferencesTypes:${choice}:text`
      );
      const selected = choices.includes(choice);

      return (
        <InvestmentPreferencesChoiceItem
          style={{ flexGrow: 1, flexBasis: 0 }}
          title={title}
          text={text}
          selected={selected}
          key={`choice-item-${index}`}
        />
      );
    });
  };
  return (
    <RowWrapper>
      <StyledRow>{renderChoiceItems([0, 1])}</StyledRow>
      <StyledRow>{renderChoiceItems([2, 3])}</StyledRow>
      <StyledRow>{renderChoiceItems([4, 5])}</StyledRow>
    </RowWrapper>
  );
};

export const InvestmentPreferencesChoices = () => {
  return (
    <SectionWithTitle
      title={t('app:wealthPlan:investmentProfile:preferences')}
      columns={12}
      style={{ marginBottom: SPACING_24 }}
    >
      <InvestmentPreferencesChoicesContent />
    </SectionWithTitle>
  );
};
