import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { t, selectors } from '@formue-app/core';

import { SPACING_24, SPACING_16 } from '../../../constants/spacing';

import { H2, Paragraph } from '../../texts';
import { MainGrid } from '../../layout';
import { BaseSection } from '../../common/BaseSection';
import { FundInFundGraphic } from './FundInFundGraphic';

const StyledSubGrid = styled(MainGrid)`
  padding: 0;
  width: 100%;
  max-width: 100%;
`;

const StyledBaseSection = styled(BaseSection)`
  padding: 0;
`;

const {
  entities: {
    securities: { securitiesMetadataSelector },
  },
} = selectors;

export const FundInFund = (props) => {
  const {
    distinctFunds,
    fundOfFunds,
    directlyOwnedFunds,
    indirectlyOwnedFunds,
  } = useSelector(securitiesMetadataSelector);

  return (
    <StyledSubGrid>
      <StyledBaseSection
        columns={7}
        style={{ padding: SPACING_24, paddingLeft: 0 }}
      >
        <H2>{t('app:portfolioConstruction:fundsModal:title')}</H2>

        <Paragraph style={{ marginTop: SPACING_16 }}>
          {t('app:portfolioConstruction:fundsModal:paragraph1')}
        </Paragraph>
        <Paragraph style={{ marginTop: SPACING_16, marginBottom: SPACING_24 }}>
          {t('app:portfolioConstruction:fundsModal:paragraph2', {
            funds: distinctFunds,
            direct: directlyOwnedFunds,
            indirectly: indirectlyOwnedFunds,
          })}
        </Paragraph>
      </StyledBaseSection>
      <StyledBaseSection
        columns={5}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <FundInFundGraphic
          fundOfFunds={fundOfFunds}
          directly={directlyOwnedFunds}
          indirectly={indirectlyOwnedFunds}
        />
      </StyledBaseSection>
    </StyledSubGrid>
  );
};
