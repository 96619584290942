import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEqual, debounce } from 'lodash';
import styled from 'styled-components';

import {
  selectors,
  t,
  strategy,
  transformActiveFilters,
} from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { StrategyList } from '../strategy/PortfolioStrategyList';
import { Paragraph } from '../../texts';
import { printCondition } from '../../../constants/media';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';

const StyledBaseSection = styled(BaseSection)`
  @media ${printCondition} {
    border-top: none;
  }
`;

const {
  ui: {
    portfolio: { activeStrategySelector, activeFiltersSelector },
  },
} = selectors;

export const PeriodicStrategy = () => {
  const activeStrategyId = useSelector(activeStrategySelector);
  const activeFilters = useSelector(activeFiltersSelector);
  const debounceTime = useSelector((state) => state.ui.portfolio.debounceTime);

  const prevActiveFilters = useRef(null);

  let [activeStrategy, setActiveStrategy] = useState(null);
  const [strategyData, setStrategyData] = useState([]);
  const [localLoading, setLocalLoading] = useState(false);

  const fetchData = useRef(
    debounce(async (filters) => {
      try {
        setLocalLoading(true);
        const transformedFilters = transformActiveFilters(filters);
        const args = {
          ...transformedFilters,
          endDate:
            transformedFilters.endDate ||
            new Date().toISOString().split('T')[0],
        };
        const { data } = await strategy.get(args);
        setStrategyData(
          data.map((item) => ({
            id: item.id,
            type: item.type,
            ...item.attributes,
          }))
        );
      } catch (error) {
        console.error('Failed to fetch strategy data:', error);
      } finally {
        setLocalLoading(false);
      }
    }, debounceTime)
  ).current;

  useEffect(() => {
    fetchData(activeFilters);

    return () => {
      fetchData.cancel();
    };
  }, []);

  useEffect(() => {
    if (!isEqual(activeFilters, prevActiveFilters.current)) {
      prevActiveFilters.current = activeFilters;
      fetchData(activeFilters);
    }
  }, [activeFilters]);

  useEffect(() => {
    setActiveStrategy(
      strategyData.find((strategy) => strategy.id === activeStrategyId)
    );
  }, [strategyData, activeStrategyId]);

  return (
    <StyledBaseSection columns={4}>
      {localLoading ? (
        <CenteredActivityIndicator />
      ) : (
        <>
          {activeStrategy ? (
            <StrategyList strategy={activeStrategy.strategy} />
          ) : (
            <Paragraph>
              {t('app:portfolio:periodicReporting:strategy:noStrategyFound')}
            </Paragraph>
          )}
        </>
      )}
    </StyledBaseSection>
  );
};
