import { handleActions } from 'redux-actions';

import * as selectors from './selectors';
import { actions } from './actions';

export { actions };
export { sagas } from './sagas';
export { selectors };

const defaultState = {
  agendaItems: [
    {
      id: 1,
      title:
        'app:presentation:annualReview:agenda:agendaSubItem:wealthPlan:title',
      description:
        'app:presentation:annualReview:agenda:agendaSubItem:wealthPlan:description',
      image: 'wealthplanImageUrl',
    },
    {
      id: 2,
      title:
        'app:presentation:annualReview:agenda:agendaSubItem:strategy:title',
      description:
        'app:presentation:annualReview:agenda:agendaSubItem:strategy:description',
      image: 'strategyImageUrl',
    },
    {
      id: 3,
      title:
        'app:presentation:annualReview:agenda:agendaSubItem:portfolio:title',
      description:
        'app:presentation:annualReview:agenda:agendaSubItem:portfolio:description',
      image: 'portfolioImageUrl',
    },
    {
      id: 4,
      title:
        'app:presentation:annualReview:agenda:agendaSubItem:measures:title',
      description:
        'app:presentation:annualReview:agenda:agendaSubItem:measures:description',
      image: 'mesuresImageUrl',
    },
    {
      id: 5,
      title: 'app:presentation:annualReview:agenda:agendaSubItem:other:title',
    },
  ],
};

export const reducer = handleActions({}, defaultState);
