import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { generateFullName, selectors, t } from '@formue-app/core';
import {
  BORDER_RADIUS_SMALL,
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../constants/spacing';
import { ProfileIcon } from '../common/ProfileIcon';
import { GridElement } from '../layout';
import { printCondition } from '../../constants/media';
import { H4, Paragraph } from '../texts';
import { accent, backgroundWhite } from '../../constants/colors';
import { Logo } from '../common/Logo';
import { HamburgerIcon } from './HamburgerIcon';

const OuterWrapper = styled.div`
  padding: ${SPACING_16} ${SPACING_24};
  display: flex;

  justify-content: space-between;
  align-items: center;
  background-color: ${accent.ocean4};
`;

const LeftSideWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled(H4)`
  margin-left: ${SPACING_24};
  color: ${backgroundWhite};
`;

const Beta = styled(Paragraph)`
  color: ${accent.ocean250};
  background-color: #464f81;
  border-radius: ${BORDER_RADIUS_SMALL};
  padding: 4px;
  margin-left: ${SPACING_16};
  line-height: 1;
  font-weight: 500;
  font-size: 10px;
`;

const ProfileWrapper = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 5px;

  &:hover {
    h4 {
      text-decoration: underline;
    }
  }
`;

const StyledProfileIcon = styled(ProfileIcon)`
  margin-left: ${SPACING_8};
`;

const StyledGridElement = styled(GridElement)`
  @media ${printCondition} {
    display: none;
  }
`;

const { currentUserSelector } = selectors.ui.user;

export const ExtendedReportingHeader = (props) => {
  const { menuOpen, menuOpenCallback } = props;

  const user = useSelector(currentUserSelector);

  if (!user) {
    return <StyledGridElement {...props} style={{ height: 52 }} />;
  }

  const name = generateFullName(user);

  return (
    <StyledGridElement {...props}>
      <OuterWrapper>
        <LeftSideWrapper>
          <HamburgerIcon
            onClick={() => {
              menuOpenCallback(!menuOpen);
            }}
            active={menuOpen}
          />
          <Logo
            style={{ verticalAlign: 'bottom', marginLeft: 24 }}
            monochrome={true}
            height="17"
          />
          <StyledTitle>
            {t('app:portfolio:extendedReporting:summary:title')}
          </StyledTitle>
          <Beta>BETA</Beta>
        </LeftSideWrapper>
        <ProfileWrapper to="/profile">
          <H4 style={{ color: backgroundWhite }}>{name}</H4>
          <StyledProfileIcon name={name} size={32} />
        </ProfileWrapper>
      </OuterWrapper>
    </StyledGridElement>
  );
};
